import React from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import swal from 'sweetalert';
import axios from 'axios'
import Global from '../Global'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer, faTractor, faCopy } from '@fortawesome/free-solid-svg-icons'
import { InputBase } from '@material-ui/core/';
import MaterialTable from 'material-table'

import moment from 'moment'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  rootTwo: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  option: {
    fontSize: "0.8rem",
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputSelect: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputAutocomplete: {
    height: 3,
    fontSize: "0.8rem",
  },
  inputAutocompleteTable: {
    height: 17,
    fontSize: "13px",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tab: {
    height: "30px",
    marginTop: "0px",
    fontSize: "0.7rem",
  },
  tabs: {
    height: "48px"
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    padding: "0",
    zIndex: "30"
  },
  tabButton: {
    '&:hover': {
      backgroundColor: 'initial !important'
    },
    fontSize: 'inherit',
  },
  imitUnderline: {
    textAlign: 'center',
    width: "65%",
    padding: "3px 0 3px",
    fontSize: "0.8rem",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    "&:hover": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.87)"
    },
    "&:focus": {
      transition: "all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderBottom: "1px solid #228333"
    }
  },
  error: {
    textAlign: 'center',
    width: "65%",
    padding: "3px 0 3px",
    fontSize: "0.8rem",
    borderBottom: "1px solid red",
  }
}));
export default function InputAdornments(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState(props.data);
  const [value, setValue] = React.useState(0);
  const [error, setError] = React.useState(false)

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (prop) => (value) => {
    if (props.user.rol >= 1) {
      setValues({ ...values, [prop]: value });
    }
  };
  const saveAndClose = () => {
    axios.post(Global.url + 'updatebases', values, props.token).then(res => {
      if (res.data.status === "error") alert(res.data.message)
      else { props.setState({ open: true, message: res.data.message, severity: res.data.status, bases: res.data.bas }) }
    })
  }
  const save = () => {
    const info = Global.validateBases(values)
    if (info.obligatorios.length === 0) {
      saveAndClose()
    } else {
      swal({
        title: "Faltan campos obligatorios por rellenar",
        text: "Faltan: " + info.obligatorios.join(', '),
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
    }
  }
  const close = () => {
    if (props.user.rol >= 1) {
      const info = Global.validateBases(values, props.data)
      if (info.obligatorios.length > 0) {
        swal({
          title: "Faltan campos obligatorios por rellenar",
          text: "Faltan: " + info.obligatorios.join(', ') + ".",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Vale",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (!res && info.equal) props.close()
          else if (!res && !info.equal && values.idAyun) {
            if (error) {
              swal({
                title: "¿Desea salir sin guardar?",
                icon: "warning",
                buttons: {
                  salir: {
                    text: "Salir",
                    value: false,
                    className: 'popup-button-red',
                  },
                  ok: {
                    text: "Guardar",
                    className: 'popup-button',
                    value: true,
                  },
                },
              }).then(res => {
                if (res) saveAndClose()
                else props.close()
              })
            } else {
              props.close()
            }
          } else if (!values.idAyun && !res) {
            props.close()
          }
        })
      } else if (info.obligatorios.length === 0 && !info.equal && error) {
        swal({
          title: "¿Desea salir sin guardar?",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Guardar",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (res) saveAndClose()
          else props.close()
        })
      } else {
        props.close()
      }
    } else {
      props.close()
    }
  }
  const QError = () => {
    return (props.allData.filter(element => moment(element.vigencia).format("MM/YYYY") === moment(values.vigencia).format("MM/YYYY") && values._id !== element._id).length === 0)
  }
  React.useEffect(() => {
    setError(QError())
  }, [values, props.allData])
  React.useEffect(() => {
    setError(QError())
  }, [])
  return (
    <div className='popup'>
      <div className="popup-container">
        <IconButton style={{ position: "absolute", top: "10px", right: "20px", padding: "0", zIndex: "30" }} color="primary" onClick={close}>
          <CloseIcon color="primary" fontSize="large" />
        </IconButton>

        <div className="popup-inner">
          <div className="popup-full-height"></div>
          <div className={classes.root}>
            <AppBar position="static" component="div" elevation={0} style={{ height: "48px", backgroundColor: "#eee", float: "left", flexDirection: "row", justifyContent: "flex-start" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faServer} style={{ marginRight: "7px" }} />
                        General
                      </Button>
                }
                  {...a11yProps(0)} className={classes.tab} />
                <Tab
                  label={
                    <Button color='inherit' className={classes.tabButton}>
                      <FontAwesomeIcon icon={faTractor} style={{ marginRight: "7px" }} />
                        S.E.A.
                    </Button>
                  }
                  {...a11yProps(1)} className={classes.tab} />
                <Tab
                  label={
                    <Button color='inherit' className={classes.tabButton}>
                      <FontAwesomeIcon icon={faCopy} style={{ marginRight: "7px" }} />
                        Importación
                    </Button>
                  }
                  {...a11yProps(2)} className={classes.tab} />
              </Tabs>

            </AppBar>
            <TabPanel value={value} index={0} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>General:</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={8}> {/* Datos generales */}
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem" }}>
                            <tr>
                              <th>Descripción</th>
                              <th>Vigente desde</th>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <InputBase
                                  style={{ justifyContent: "center" }}
                                  value={values.nombre}
                                  onChange={(e) => handleChange("nombre")(e.target.value)}
                                  inputProps={{
                                    className: classes.imitUnderline,
                                    style:{width:"100%"}
                                  }}
                                />
                              </td>
                              <td>
                                <InputBase
                                  style={{ justifyContent: "center" }}
                                  inputProps={{
                                    className: moment(values.vigencia).isValid() || !error ? classes.imitUnderline : classes.error,
                                    style:{width:"100%"}
                                  }}
                                  defaultValue={moment(values.vigencia).format("MMYYYY")}
                                  onBlur={(e) => handleChange('vigencia')(moment("01/" + e.target.value, "DD/MM/YYYY").add(12, "hour"))}
                                  inputComponent={Global.formatMonth}
                                />
                              </td>
                              <td>{!error && <span style={{ color: "red" }}>&larr; Base existente con el mismo mes</span>}</td>
                            </tr>
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>Contingencias comunes:</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}> {/* Contingencias comunes G1-G7 */}
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem", margin: "0 auto" }}>
                            <tr>
                              <td></td>
                              <th>Mínimo (mes)</th>
                              <th>Máximo (mes)</th>
                            </tr>
                            {[1, 2, 3, 4, 5, 6, 7].map((x, i) => (
                              <tr key={i}>
                                <th>{String(x).length === 2 ? `${x}:` : `0${x}:`}</th>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.comunes[`g${x}`].min}
                                    onChange={(e) => {
                                      var res = values.comunes
                                      res[`g${x}`] = { ...res[`g${x}`], ["min"]: Number(e.target.value) }
                                      handleChange("comunes")(res)
                                    }}
                                    inputComponent={Global.formatEUR}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.comunes[`g${x}`].max}
                                    onChange={(e) => {
                                      var res = values.comunes
                                      res[`g${x}`] = { ...res[`g${x}`], ["max"]: Number(e.target.value) }
                                      handleChange("comunes")(res)
                                    }}
                                    inputComponent={Global.formatEUR}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}> {/* Contingencias comunes G8-G11 */}
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem", margin: "0 auto" }}>
                            <tr>
                              <td></td>
                              <th>Mínimo (día)</th>
                              <th>Máximo (día)</th>
                            </tr>
                            {[8, 9, 10, 11].map((x, i) => (
                              <tr key={i}>
                                <th>{String(x).length === 2 ? `${x}:` : `0${x}:`}</th>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.comunes[`g${x}`].min}
                                    onChange={(e) => {
                                      var res = values.comunes
                                      res[`g${x}`] = { ...res[`g${x}`], ["min"]: Number(e.target.value) }
                                      handleChange("comunes")(res)
                                    }}
                                    inputComponent={Global.formatEUR}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.comunes[`g${x}`].max}
                                    onChange={(e) => {
                                      var res = values.comunes
                                      res[`g${x}`] = { ...res[`g${x}`], ["max"]: Number(e.target.value) }
                                      handleChange("comunes")(res)
                                    }}
                                    inputComponent={Global.formatEUR}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>Contingencias profesionales (A.T. y E.P.):</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}> {/* Contingencias profesionales*/}
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem", margin: "0 auto" }}>
                            <tr>
                              <td></td>
                              <th>Mínimo (mes)</th>
                              <th>Máximo (mes)</th>
                            </tr>
                            <tr>
                              <th>Importe: </th>
                              <td>
                                <InputBase
                                  style={{ justifyContent: "center" }}
                                  value={values.profesionales.min}
                                  onChange={(e) => {
                                    var res = values.profesionales
                                    res = { ...res, ["min"]: Number(e.target.value) }
                                    handleChange("profesionales")(res)
                                  }}
                                  inputComponent={Global.formatEUR}
                                  inputProps={{
                                    className: classes.imitUnderline
                                  }}
                                />
                              </td>
                              <td>
                                <InputBase
                                  style={{ justifyContent: "center" }}
                                  value={values.profesionales.max}
                                  onChange={(e) => {
                                    var res = values.profesionales
                                    res = { ...res, ["max"]: Number(e.target.value) }
                                    handleChange("profesionales")(res)
                                  }}
                                  inputComponent={Global.formatEUR}
                                  inputProps={{
                                    className: classes.imitUnderline
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th style={{ height: "1.576em" }}></th>
                              <td> </td>
                            </tr>
                            <tr>
                              <th>SMI: </th>
                              <td>
                                <InputBase
                                  style={{ justifyContent: "center" }}
                                  value={values.profesionales.SMI}
                                  onChange={(e) => {
                                    var res = values.profesionales
                                    res = { ...res, ["SMI"]: Number(e.target.value) }
                                    handleChange("profesionales")(res)
                                  }}
                                  inputComponent={Global.formatEUR}
                                  inputProps={{
                                    className: classes.imitUnderline
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th>IPREM: </th>
                              <td>
                                <InputBase
                                  style={{ justifyContent: "center" }}
                                  value={values.profesionales.IPREM}
                                  onChange={(e) => {
                                    var res = values.profesionales
                                    res = { ...res, ["IPREM"]: Number(e.target.value) }
                                    handleChange("profesionales")(res)
                                  }}
                                  inputComponent={Global.formatEUR}
                                  inputProps={{
                                    className: classes.imitUnderline
                                  }}
                                />
                              </td>
                            </tr>
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>Sistema General. Bases:</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}> {/* Contingencias comunes G1-G7 */}
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem", margin: "0 auto" }}>
                            <tr>
                              <td></td>
                              <th>Mínimo (mes)</th>
                              <th>Máximo (mes)</th>
                            </tr>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((x, i) => (
                              <tr key={i}>
                                <th>{String(x).length === 2 ? `${x}:` : `0${x}:`}</th>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.sistemaGeneral[`g${x}`].min}
                                    onChange={(e) => {
                                      var res = values.SEA
                                      res.sistemaGeneral[`g${x}`] = { ...res.sistemaGeneral[`g${x}`], ["min"]: Number(e.target.value) }
                                      handleChange("SEA")(res)
                                    }}
                                    inputComponent={Global.formatEUR}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.sistemaGeneral[`g${x}`].max}
                                    onChange={(e) => {
                                      var res = values.SEA
                                      res.sistemaGeneral[`g${x}`] = { ...res.sistemaGeneral[`g${x}`], ["max"]: Number(e.target.value) }
                                      handleChange("SEA")(res)
                                    }}
                                    inputComponent={Global.formatEUR}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>Jornadas reales. Bases:</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}> {/* Contingencias comunes G1-G7 */}
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem", margin: "0 auto" }}>
                            <tr>
                              <td></td>
                              <th>Mínimo (día)</th>
                              <th>Máximo (día)</th>
                            </tr>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((x, i) => (
                              <tr key={i}>
                                <th>{String(x).length === 2 ? `${x}:` : `0${x}:`}</th>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.jornadasReales[`g${x}`].min}
                                    onChange={(e) => {
                                      var res = values.SEA
                                      res.jornadasReales[`g${x}`] = { ...res.jornadasReales[`g${x}`], ["min"]: Number(e.target.value) }
                                      handleChange("SEA")(res)
                                    }}
                                    inputComponent={Global.formatEUR}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.jornadasReales[`g${x}`].max}
                                    onChange={(e) => {
                                      var res = values.SEA
                                      res.jornadasReales[`g${x}`] = { ...res.jornadasReales[`g${x}`], ["max"]: Number(e.target.value) }
                                      handleChange("SEA")(res)
                                    }}
                                    inputComponent={Global.formatEUR}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <MaterialTable
                        title={"Importación"}
                        columns={[
                          { title: 'Descripción', field: 'nombre' },
                          { title: 'Vigencia desde', field: 'vigencia', type: 'date', defaultSort: 'desc', render: rowData => <span >{moment(rowData.vigencia).format("MM/YYYY")}</span>, },
                        ]}
                        data={query =>
                          new Promise((resolve, reject) => {
                            axios.get(Global.url + 'getbasestemplate', props.token)
                              .then(res => {
                                resolve({
                                  data: res.data.bases,
                                })
                              })
                          })
                        }
                        options={{
                          search: false,
                          pageSize: 10,
                          pageSizeOptions: [10, 15, 20, 25]
                        }}
                        localization={{
                          pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas'
                          },
                          toolbar: {
                            nRowsSelected: '{0} row(s) seleccionada',
                            searchPlaceholder: 'Buscar',
                            searchTooltip: 'Buscar',
                          },
                          header: {
                            actions: 'Acciones'
                          },
                          body: {
                            emptyDataSourceMessage: 'Sin datos',
                            filterRow: {
                              filterTooltip: 'Filtrar'
                            }
                          }
                        }}
                        onRowClick={(event, rowData, togglePanel) => {
                          swal({
                            title: "¿Estás seguro?",
                            text: "Se sustituirá los datos de la tabla actual por los datos de la plantilla",
                            icon: "warning",
                            buttons: {
                              salir: {
                                text: "Salir",
                                value: false,
                                className: 'popup-button-red',
                              },
                              ok: {
                                text: "Continuar",
                                className: 'popup-button',
                                value: true,
                              },
                            },
                          }).then(res => {
                            if (res) {
                              if (values._id) {
                                var data = rowData
                                data._id = values._id
                                setValues(data)
                                setValue(0)
                              } else {
                                setValues(rowData)
                                setValue(0)
                              }
                            }
                          })
                        }}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
          </div>
          {props.user.rol >= 1 &&
            <IconButton disabled={!error} style={{ display: "flex", flexDirection: "column", padding: "0" }} className={classes.buttonSave} color="primary" onClick={save}>
              <SaveIcon color="primary" />
              <div style={{ fontSize: "1rem" }}>
                {error ? "Guardar" : <span style={{ color: "red" }}>Base duplicada</span>}
              </div>
            </IconButton>
          }
        </div>
      </div>
    </div >
  );
}