import React from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import swal from 'sweetalert';
import axios from 'axios'
import Global from '../Global'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
function findName(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { nombre: "", primerApellido: "Selecciona una opción válida", segundoApellido: "" }
  }
}
function findDNI(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { dni: "Selecciona una opción válida" }
  }
}
function findExp(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { exped: "Selecciona una opción válida" }
  }
}
function findCod(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { codigoConvenio: "Selecciona una opción válida" }
  }
}
function findNameConvenio(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { nombreConvenio: "Selecciona una opción válida" }
  }
}
function findClaveContrato(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODCC === string) {
        return array[i]
      }
    }
  } else {
    return { CODCC: "Selecciona una opción válida", DESCC: "" }
  }
}
const useStyles = makeStyles((theme) => ({
  rootTwo: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  option: {
    fontSize: "0.8rem",
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputSelect: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputAutocomplete: {
    height: 3,
    fontSize: "0.8rem",
  },
  inputAutocompleteTable: {
    height: 17,
    fontSize: "13px",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tab: {
    height: "30px",
    marginTop: "0px",
    fontSize: "0.7rem",
  },
  tabs: {
    height: "48px"
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    padding: "0",
    zIndex: "30"
  },
  buttonDelete: {
    position: "absolute",
    bottom: "10px",
    right: "180px",
    padding: "0",
    zIndex: "30"
  },
  tabButton: {
    '&:hover': {
      backgroundColor: 'initial !important'
    },
    fontSize: 'inherit',
  },
  text: {
    display: "flex",
    flexDirection: "column"
  }
}));
export default function InputAdornments(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState(props.data);
  const [contratos, setContratos] = React.useState([]);
  const [value, setValue] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const setParad = (value) => {
    const info = Global.validateWorker(value)
    if (info.obligatorios.length === 0) {
      if (isValidDate(value)) {
        var duplicate = contratos.find(element => element.idParad === value._id)
        if (duplicate) {
          if (!duplicate._id) {
            var res = contratos;
            res[res.indexOf(duplicate)] = { ...res[res.indexOf(duplicate)], ["actions"]: "1" }
            setContratos([...res])
          } else {
            var res = contratos;
            res[res.indexOf(duplicate)] = { ...res[res.indexOf(duplicate)], ["actions"]: undefined }
            setContratos([...res])
          }
        } else setContratos([...contratos, { ["actions"]: "1", ["idParad"]: value._id }])
      }
    } else {
      swal({
        title: "Faltan campos obligatorios por rellenar del trabajador",
        text: `Antes de continuar tienes que rellenar todos los campos necesarios del trabajador. \n Faltan: ${info.obligatorios.join(', ')}`,
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
    }

  }
  const saveAndClose = () => {
    axios.post(Global.url + 'updateturnos', { turnos: values, contratos }, props.token).then(res => {
      if (res.data.status === "error") alert(res.data.message)
      else { props.setState({ open: true, message: res.data.message, severity: res.data.status, turnos: res.data.turnos, contratos: res.data.contratos, edit: false, edittionRow: {} }) }
    })
  }
  const save = () => {
    saveAndClose()
  }
  const close = () => {
    if (props.user.rol >= 1) {
      var res = false
      contratos.map((x, i) => {
        if (props.allData.indexOf(x) === -1) res = true
      })
      if (res) {
        swal({
          title: "¿Desea salir sin guardar?",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Guardar",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (res) saveAndClose()
          else props.close()
        })
      } else {
        props.close()
      }
    } else {
      props.close()
    }
  }
  const isValidDate = (parad) => {
    const contratos =
      props.allData
        .filter(element =>
          element.idTurno !== values._id && element.idParad === parad._id)
        .sort((a, b) =>
          moment(a.fechaAlta) - moment(b.fechaAlta))

    const sinFechaDeBaja = contratos.find(element => !element.fechaBaja)
    if (sinFechaDeBaja) {
      swal({
        title: "Error",
        text: `Existe un contrato abierto del trabajador. Para seguir editando este contrato debes cerrar el otro contrato del trabajador`,
        icon: "error",
        buttons: {
          salir: {
            text: "Vale",
            value: false,
            className: 'popup-button-red',
          },
          ok: {
            text: "Ver contrato",
            className: 'popup-button',
            value: true,
          },
        },
      }).then(res => {
        if (res) setValues(sinFechaDeBaja)
      })
      return false
    }
    const igualFechaAlta = contratos.find(element => moment(element.fechaAlta).isSame(moment(values.fechaAlta), "day"))
    if (igualFechaAlta) {
      swal({
        title: "Error",
        text: `Existe un contrato con la misma fecha de alta del trabajador`,
        icon: "error",
        buttons: {
          salir: {
            text: "Vale",
            className: 'popup-button-red',
          },
        },
      })
      return false
    }
    const posteriorFechaAlta = contratos.find(element => !values._id && moment(element.fechaAlta).isAfter(moment(values.fechaAlta)))
    var bajaCambiada = undefined
    if (posteriorFechaAlta) {
      if (!values.fechaBaja) bajaCambiada = moment(posteriorFechaAlta.fechaAlta).add(-1, "day")
      else {
        if (moment(values.fechaBaja).isSameOrAfter(moment(posteriorFechaAlta.fechaAlta), "day")) bajaCambiada = moment(posteriorFechaAlta.fechaAlta).add(-1, "day")
      }
    }
    if (bajaCambiada) {
      swal({
        title: "Advertencia",
        text: `La fecha de baja ha sido modificada`,
        icon: "warning",
        buttons: {
          salir: {
            text: "Vale",
            className: 'popup-button-red',
          },
        },
      })
      return false
    }
    const isBetweenAlta = contratos.find(element => {
      if (element.fechaBaja) {
        return (moment(values.fechaAlta).isBetween(element.fechaAlta, element.fechaBaja, "day", "()"))
      } else {
        return false
      }
    })
    if (isBetweenAlta) {
      swal({
        title: "Error",
        text: `Existe un contrato en las mismas fechas`,
        icon: "error",
        buttons: {
          salir: {
            text: "Vale",
            value: false,
            className: 'popup-button-red',
          },
        },
      })
      return false
    }
    return true
  }
  React.useEffect(() => {
    if (props.data._id) setContratos(props.allData.filter(element => element.idTurno === props.data._id))
  }, [])
  const deleteContrato = () => {
    axios.post(Global.url + 'deleteturno', values, props.token).then(res => {
      if (res.data.status === "error") alert(res.data.message)
      else { props.setState({ open: true, message: res.data.message, severity: res.data.status, turnos: res.data.turnos, contratos: res.data.contratos, edit: false, edittionRow: {} }) }
    })
  }

  const obra = findExp(values.idObra, props.obra);

  return (
    <div className='popup'>
      <div className="popup-container">
        <IconButton style={{ position: "absolute", top: "10px", right: "20px", padding: "0", zIndex: "30" }} color="primary" onClick={close}>
          <CloseIcon color="primary" fontSize="large" />
        </IconButton>

        <div className="popup-inner">
          <div className="popup-full-height"></div>
          <div className={classes.root}>
            <AppBar position="static" component="div" elevation={0} style={{ height: "48px", backgroundColor: "#eee", float: "left", flexDirection: "row", justifyContent: "flex-start" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                        Trabajadores turno {values.id}
                  </Button>
                }
                  {...a11yProps(0)} className={classes.tab} />
              </Tabs>

            </AppBar>
            <TabPanel value={value} index={0} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container >
                  <Grid item xs={12} sm={3} md={4} style={{boxShadow: "#eee 1px 1px 1px", zIndex:"30"}}>
                    <Typography variant="h6" className={classes.title}>
                      Trabajadores en el turno
                    </Typography>
                    <List dense={true}>
                      {contratos.map((value, i) => {
                        if (value.actions !== "-1") {
                          const trabajador = props.parad.find(x => x._id === value.idParad)
                          return (
                          <ListItem key={i}>
                            <ListItemText primary={trabajador.nombre + " " + trabajador.primerApellido + " " + trabajador.segundoApellido} secondary={trabajador.dni} />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => {
                                  var res = contratos;
                                  if (res[res.indexOf(value)]._id === undefined) res.splice(res[res.indexOf(value)], 1)
                                  else res[res.indexOf(value)] = { ...res[res.indexOf(value)], ["actions"]: "-1" }
                                  setContratos([...res])
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      }})}
                    </List>

                  </Grid>
                  <Grid item xs={12} sm={9} md={8}>
                    <Grid container>
                      <Grid item xs={12} style={{zIndex:"40"}}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Typography variant="body1" component="h2">Añadir trabajador</Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={8} md={5} style={{boxShadow: "#eee 1px 1px 1px", zIndex:"30"}}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Autocomplete
                            options={props.parad}
                            autoHighlight
                            classes={{
                              option: classes.option,
                              input: classes.inputAutocomplete
                            }}
                            value={findName(values.idParad, props.parad)}
                            onChange={(event, value) => {
                              if (value && props.user.rol >= 1) setParad(value)
                            }}
                            getOptionLabel={(option) => option.primerApellido === 'Selecciona una opción válida'?'Selecciona una opción válida':`${option.primerApellido} ${option.segundoApellido}, ${option.nombre}`}
                            renderOption={(option) => (
                              <React.Fragment>{`${option.primerApellido} ${option.segundoApellido}, ${option.nombre}`}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Nombre"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={8} sm={4} md={3} style={{boxShadow: "#eee 1px 1px 1px", zIndex:"30"}}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Autocomplete
                            options={props.parad}
                            autoHighlight
                            classes={{
                              option: classes.option,
                              input: classes.inputAutocomplete
                            }}
                            value={findDNI(values.idParad, props.parad)}
                            onChange={(event, value) => {
                              if (value && props.user.rol >= 1) setParad(value)
                            }}
                            getOptionLabel={(option) => `${option.dni}`}
                            renderOption={(option) => (
                              <React.Fragment>{`${option.dni}`}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="DNI"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Typography variant="body1" component="h2">Datos del turno de trabajo</Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={5} xl={2}> {/* FECHA ALTA */}
                        <Paper className={[classes.paper, classes.text]} style={{ textAlign: "left" }}>
                          <span style={{ fontSize: "0.8rem", fontWeight: "400", width: "100%" }}>Alta S.S.</span>
                          <span style={{ fontSize: "0.8rem", color: "black" }}>{moment(values.fechaAlta).format("DD/MM/YYYY")}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={5} xl={2}> {/* FECHA BAJA */}
                        <Paper className={[classes.paper, classes.text]} style={{ textAlign: "left" }}>
                          <span style={{ fontSize: "0.8rem", fontWeight: "400", width: "100%" }}>Baja S.S.</span>
                          <span style={{ fontSize: "0.8rem", color: "black" }}>{moment(values.fechaBaja).isValid() ? moment(values.fechaBaja).format("DD/MM/YYYY") : "No consta"}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Typography variant="body1" component="h2">Datos obra</Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Paper className={[classes.paper, classes.text]} style={{ textAlign: "left" }}>
                          <span style={{ fontSize: "0.8rem", fontWeight: "400", width: "100%" }}>Denominación</span>
                          <span style={{ fontSize: "0.8rem", color: "black" }}>{obra.denom}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <Paper className={[classes.paper, classes.text]} style={{ textAlign: "left" }}>
                          <span style={{ fontSize: "0.8rem", fontWeight: "400", width: "100%" }}>Cod. Expediente</span>
                          <span style={{ fontSize: "0.8rem", color: "black" }}>{obra.exped}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Typography variant="body1" component="h2">Tabla salarial asociada al contrato</Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Paper className={[classes.paper, classes.text]} style={{ textAlign: "left" }}>
                          <span style={{ fontSize: "0.8rem", fontWeight: "400", width: "100%" }}>Convenio</span>
                          <span style={{ fontSize: "0.8rem", color: "black" }}>{findNameConvenio(values.idTs, props.ts).nombreConvenio}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <Paper className={[classes.paper, classes.text]} style={{ textAlign: "left" }}>
                          <span style={{ fontSize: "0.8rem", fontWeight: "400", width: "100%" }}>Convenio</span>
                          <span style={{ fontSize: "0.8rem", color: "black" }}>{findCod(values.idTs, props.ts).codigoConvenio}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Typography variant="body1" component="h2">Contrato</Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Paper className={[classes.paper, classes.text]} style={{ textAlign: "left" }}>
                          <span style={{ fontSize: "0.8rem", fontWeight: "400", width: "100%" }}>Clave contrato</span>
                          <span style={{ fontSize: "0.8rem", color: "black" }}>{findClaveContrato(values.claveContrato, JSON.parse(localStorage.getItem("ZTA_claveContrato"))).CODCC + " - " + findClaveContrato(values.claveContrato, JSON.parse(localStorage.getItem("ZTA_claveContrato"))).DESCC}</span>
                        </Paper>
                      </Grid>

                      <Grid item xs={12} sm={5} md={3}>
                        <Paper className={[classes.paper, classes.text]} style={{ textAlign: "left" }}>
                          <span style={{ fontSize: "0.8rem", fontWeight: "400", width: "100%" }}>Cod. Oferta de Empleo</span>
                          <span style={{ fontSize: "0.8rem", color: "black" }}>{values.codOfertaEmpleo}</span>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={5} md={3}>
                        <Paper className={[classes.paper, classes.text]} style={{ textAlign: "left" }}>
                          <span style={{ fontSize: "0.8rem", fontWeight: "400", width: "100%" }}>Cod. Turno</span>
                          <span style={{ fontSize: "0.8rem", color: "black" }}>{values.id}</span>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>

                </Grid>
              </div>
            </TabPanel>
          </div>
          {props.user.rol >= 1 && values._id &&
            <IconButton style={{ display: "flex", flexDirection: "column", padding: "0", color: "red" }} className={classes.buttonDelete} onClick={() => {
              swal({
                title: "¿Desea eliminar el turno?",
                text: "Únicamente se eliminará el turno dejando los contratos asociados sin asignar a ningún turno, los cambios son definitivos y no lo podrás recuperar",
                icon: "warning",
                buttons: {
                  salir: {
                    text: "Cancelar",
                    className: 'popup-button',
                    value: false,
                  },
                  ok: {
                    text: "Eliminar",
                    value: true,
                    className: 'popup-button-red',
                  },
                },
              }).then(res => {
                if (res) deleteContrato()
              })
            }}>
              <DeleteIcon color="inherit" />
              <div style={{ fontSize: "1rem" }}>
                Eliminar
            </div>
            </IconButton>
          }
          {props.user.rol >= 1 &&
            <IconButton style={{ display: "flex", flexDirection: "column", padding: "0" }} className={classes.buttonSave} color="primary" onClick={save}>
              <SaveIcon color="primary" />
              <div style={{ fontSize: "1rem" }}>
                Guardar cambios
              </div>
            </IconButton>
          }
        </div>
      </div>
    </div >
  );
}