import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Icons from '../components/Icons'
import MaterialTable from '../components/MaterialTablePrimary'


import Edit from './CotizacionesBEdit'
import EditTipos from './CotizacionesTEdit'
import axios from 'axios'
import Global from '../Global'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign, faPercent } from '@fortawesome/free-solid-svg-icons'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
        backgroundColor: "#fff"
    },
    paper: {
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function Trabajadores(props) {
    const classes = useStyles();
    const columns = [
        { title: 'Descripción', field: 'nombre' },
        { title: 'Vigencia desde', field: 'vigencia', type: 'date', defaultSort: 'desc', render: rowData => <span >{moment(rowData.vigencia).format("MM/YYYY")}</span>, },
    ]
    const [bases, setBases] = React.useState([])
    const [tipos, setTipos] = React.useState([])
    const [edit, setEdit] = React.useState(false)
    const [editTipos, setEditTipos] = React.useState(false)
    const [edittionRow, setEdittionRow] = React.useState()
    const defaultData = {
        nombre: "General",
        vigencia: moment(),
        comunes: {
            g1: {
                min: 0,
                max: 0,
            },
            g2: {
                min: 0,
                max: 0,
            },
            g3: {
                min: 0,
                max: 0,
            },
            g4: {
                min: 0,
                max: 0,
            },
            g5: {
                min: 0,
                max: 0,
            },
            g6: {
                min: 0,
                max: 0,
            },
            g7: {
                min: 0,
                max: 0,
            },
            g8: {
                min: 0,
                max: 0,
            },
            g9: {
                min: 0,
                max: 0,
            },
            g10: {
                min: 0,
                max: 0,
            },
            g11: {
                min: 0,
                max: 0,
            },
        },
        profesionales: {
            min: 0,
            max: 0,
            SMI: 0,
            IPREM: 0
        },
        SEA: {
            sistemaGeneral: {
                g1: {
                    min: 0,
                    max: 0,
                },
                g2: {
                    min: 0,
                    max: 0,
                },
                g3: {
                    min: 0,
                    max: 0,
                },
                g4: {
                    min: 0,
                    max: 0,
                },
                g5: {
                    min: 0,
                    max: 0,
                },
                g6: {
                    min: 0,
                    max: 0,
                },
                g7: {
                    min: 0,
                    max: 0,
                },
                g8: {
                    min: 0,
                    max: 0,
                },
                g9: {
                    min: 0,
                    max: 0,
                },
                g10: {
                    min: 0,
                    max: 0,
                },
                g11: {
                    min: 0,
                    max: 0,
                },
            },
            jornadasReales: {
                g1: {
                    min: 0,
                    max: 0,
                },
                g2: {
                    min: 0,
                    max: 0,
                },
                g3: {
                    min: 0,
                    max: 0,
                },
                g4: {
                    min: 0,
                    max: 0,
                },
                g5: {
                    min: 0,
                    max: 0,
                },
                g6: {
                    min: 0,
                    max: 0,
                },
                g7: {
                    min: 0,
                    max: 0,
                },
                g8: {
                    min: 0,
                    max: 0,
                },
                g9: {
                    min: 0,
                    max: 0,
                },
                g10: {
                    min: 0,
                    max: 0,
                },
                g11: {
                    min: 0,
                    max: 0,
                },
            }
        },
    }
    const defaultDataTipos = {
        nombre: "General",
        vigencia: moment(),
        general: {
            contingencias: {
                comunes: {
                    empresa: 0,
                    trabajador: 0
                },
                hhExtFM: {
                    empresa: 0,
                    trabajador: 0
                },
                hhExtRest: {
                    empresa: 0,
                    trabajador: 0
                },
                /* mayores6567: {
                    empresa: 0,
                    trabajador: 0
                },
                mayores60: {
                    empresa: 0,
                    trabajador: 0
                } */
            },
            desempleo: {
                general: {
                    empresa: 0,
                    trabajador: 0
                },
                CTC: {
                    empresa: 0,
                    trabajador: 0
                },
                CTP: {
                    empresa: 0,
                    trabajador: 0
                },
            },
            conceptos: {
                garantiaSalarial: {
                    empresa: 0,
                    trabajador: 0
                },
                fp: {
                    empresa: 0,
                    trabajador: 0
                },
            }
        },
        SEA: {
            contingencias: {
                comunes: {
                    empresa: 0,
                    trabajador: 0
                },
            },
            desempleo: {
                CF: {
                    empresa: 0,
                    trabajador: 0
                },
                CT: {
                    empresa: 0,
                    trabajador: 0
                },
                especiales: {
                    empresa: 0,
                    trabajador: 0
                }
            },
            conceptos: {
                garantiaSalarial: {
                    empresa: 0,
                    trabajador: 0
                },
                fp: {
                    empresa: 0,
                    trabajador: 0
                },
            }
        }
    }
    React.useEffect(() => {
        setBases(props.bases)
    }, [props.bases])
    React.useEffect(() => {
        setTipos(props.tipos)
    }, [props.tipos])
    const refreshBases = () => {
        setBases([])
        axios.get(Global.url + "getbases", props.token).then(res => {
            props.setState({ bases: res.data.bases })
            setBases(res.data.bases)
        })
    }
    const refreshTipos = () => {
        setTipos([])
        axios.get(Global.url + "gettipos", props.token).then(res => {
            props.setState({ tipos: res.data.tipos })
            setTipos(res.data.tipos)
        })
    }
    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<div color='inherit' style={{ display: "flex", alignItems: "baseline" }}>
                                <FontAwesomeIcon icon={faEuroSign} style={{ marginRight: "7px" }} />
                                <Typography variant="h6">Bases</Typography>
                            </div>}
                                columns={columns}
                                data={bases}
                                options={{
                                    search: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 15, 20, 25]
                                }}
                                actions={[
                                    {
                                        icon: Icons.Add,
                                        isFreeAction: true,
                                        onClick: (event) => {
                                            setEdit(true)
                                            setEdittionRow(defaultData)
                                        }
                                    },
                                    {
                                        icon: Icons.Refresh,
                                        isFreeAction: true,
                                        onClick: () => refreshBases(),
                                    },
                                ]}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'filas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} row(s) seleccionada',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        }
                                    }
                                }}
                                onRowClick={(event, rowData, togglePanel) => {
                                    setEdittionRow(rowData)
                                    setEdit(true)
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<div color='inherit' style={{ display: "flex", alignItems: "baseline" }}>
                                <FontAwesomeIcon icon={faPercent} style={{ marginRight: "7px" }} />
                                <Typography variant="h6">Tipos</Typography>
                            </div>}
                                columns={columns}
                                data={tipos}
                                options={{
                                    search: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 15, 20, 25]
                                }}
                                actions={[
                                    {
                                        icon: Icons.Add,
                                        isFreeAction: true,
                                        onClick: (event) => {
                                            setEditTipos(true)
                                            setEdittionRow(defaultDataTipos)
                                        }
                                    },
                                    {
                                        icon: Icons.Refresh,
                                        isFreeAction: true,
                                        onClick: () => refreshTipos(),
                                    },
                                ]}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'filas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} row(s) seleccionada',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        }
                                    }
                                }}
                                onRowClick={(event, rowData, togglePanel) => {
                                    setEdittionRow(rowData)
                                    setEditTipos(true)
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            {edit &&
                <Edit close={() => setEdit(false)} data={edittionRow} allData={bases} token={props.token} user={props.user} setOpen={props.setOpen} setState={props.setState}></Edit>
            }
            {editTipos &&
                <EditTipos close={() => setEditTipos(false)} data={edittionRow} allData={tipos} token={props.token} user={props.user} setOpen={props.setOpen} setState={props.setState}></EditTipos>
            }
        </React.Fragment>
    );
}