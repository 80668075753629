import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faUserShield } from '@fortawesome/free-solid-svg-icons'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>{children}</Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(props.value);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Actualizar Chrome" {...a11yProps(0)} />
                    <Tab label="Descargar Chrome" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <div>
                <Button color='inherit' style={{textTransform:"none", fontSize:"20px"}} component="a" target="_blank" href="https://support.google.com/chrome/answer/95414?hl=es">
                    Ir a la web oficial
                    <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: "7px" }} />
                </Button>
                <Button color='inherit' style={{textTransform:"none", fontSize:"20px"}} onClick={()=>props.setStep(2)}>
                    Seguir utilizando mi navegador
                    <FontAwesomeIcon icon={faUserShield} style={{ marginLeft: "7px" }} />
                </Button>
                </div>
                <div>
                <iframe frameborder="0" style={{ overflow: "hidden", height: "80%", width: "98%", position: "absolute" }} height="80%" width="100%" src="/updateChrome.html" allowfullscreen></iframe>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Button color='inherit' style={{textTransform:"none", fontSize:"20px"}} component="a" target="_blank" href="https://www.google.com/intl/es_es/chrome/">
                    Ir a la web oficial
                    <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: "7px" }} />
                </Button>
                <div>
                <iframe frameborder="0" style={{ overflow: "hidden", height: "80%", width: "98%", position: "absolute" }} height="80%" width="100%" src="https://www.google.com/intl/es_es/chrome/"></iframe>
                </div>
            </TabPanel>
        </div>
    );
}