import React, { Component } from 'react';
import MaterialTable from 'material-table';

import axios from 'axios'
import Global from '../Global'

import Alert from '../components/Alert'

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = theme => ({
    input: {
        display: 'none',
    },
});
const lookUpAyun = (data) => {
    var res = {}
    data.forEach(ayun => {
        res[ayun._id] = ayun.nombre
    });
    return res
}
class TableClub extends Component {
    state = {
        columns: [
            { title: 'Apellidos', field: 'apellidos' },
            { title: 'Nombre', field: 'nombre' },
            { title: 'DNI', field: 'dni' },
            { title: 'Rol', field: 'rol', type: 'numeric', lookup: { 0: 'Consultor', 1: 'Editor', 2: "Administrador" }, },
            { title: 'Contraseña', field: 'password' },
            { title: 'Email', field: 'email' },
            { title: 'Nueva contraseña', field: 'newPassword', type: 'boolean' },
            { title: 'ID interno', field: '_id', editable: 'never' },
            { title: 'IDAyun interno', field: 'idAyun', lookup:lookUpAyun(this.props.ayun) },
        ],
        columnsAyun: [
            { title: 'ID interno', field: '_id' },
            { title: 'Nombre', field: 'nombre' },
            { title: 'Localidad', field: 'localidad' },
            { title: 'Provincia', field: 'provincia' },
            { title: 'CP', field: 'cp' },
            { title: 'Cod municipio', field: 'codigoMunicipio' },
            { title: 'CIF', field: 'cif' },
        ],
        severity: "",
        message: "",
        open: false,
    };
    setOpen = (e) => {
        this.setState({ open: e })
    }
    sendSave = (e) => {
        axios.post(Global.url + "admin/savenewuser", e, this.props.user).then(res => {
            this.props.setClient(res.data.users)
            this.setState({ open: true, message: res.data.message, severity: res.data.status })
        })
    }
    sendUpdate = (e) => {
        axios.post(Global.url + "admin/updateuser", e, this.props.user).then(res => {
            this.props.setClient(res.data.users)
            this.setState({ open: true, message: res.data.message, severity: res.data.status })
        })
    }
    sendDelete = (e) => {
        axios.post(Global.url + "admin/deleteuser", e, this.props.user).then(res => {
            this.props.setClient(res.data.users)
            this.setState({ open: true, message: res.data.message, severity: res.data.status })
        })
    }
    render() {
        return (
            <React.Fragment>
                <Grid container
                    spacing={3}
                    direction="column"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} style={{ width: "100%" }}>
                        <Paper >
                            <MaterialTable
                                title="Usuarios"
                                columns={this.state.columns}
                                data={this.props.client}
                                options={{
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100]
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.sendSave(newData)
                                                resolve();
                                            }, 1000)
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                const dataUpdate = [...this.props.client];
                                                const index = oldData.tableData.id;
                                                dataUpdate[index] = newData;
                                                this.sendUpdate(newData)
                                                resolve();
                                            }, 1000)
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                const dataDelete = [...this.props.client];
                                                const index = oldData.tableData.id;
                                                dataDelete.splice(index, 1);
                                                this.sendDelete(oldData)
                                                resolve()
                                            }, 1000)
                                        }),
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item md={12} lg={6}>
                        <Paper >
                            <MaterialTable
                                title="Ayuntamientos"
                                columns={this.state.columnsAyun}
                                data={this.props.ayun}
                                options={{
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100]
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Alert open={this.state.open} setOpen={this.setOpen} message={this.state.message} severity={this.state.severity}></Alert>
            </React.Fragment>
        );
    }

} export default withStyles(useStyles)(TableClub);