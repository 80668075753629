import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import axios from 'axios'
import Global from '../Global'
import Alert from '../components/Alert'

import Tab from './AdminTable'
import moment from 'moment'
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © eLocal'}
            {' 1991 - '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'auto',
    },
    image: {
        width: '100%',
    },
    paper: {
        margin: theme.spacing(4, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    submitt: {
        margin: theme.spacing(0, 0, 2),
    },
    progress: {
        width: '70%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        marginTop: theme.spacing(20),
    },
}));

export default function SignInSide() {
    const classes = useStyles();
    const [step, setStep] = React.useState(true);
    const [user, setUser] = React.useState({})
    const [ayun, setAyun] = React.useState([])
    const [client, setClient] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [received, setReceived] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const [severity, setSeverity] = React.useState("")

    const signIn = () => {
        const user = document.getElementById("dni").value
        const password = document.getElementById("password").value
        axios.post(Global.url + "admin/signin", { user, password }).then((res) => {
            if (res.data.status === "success") {
                if (document.getElementById("remember").checked) {
                    localStorage.setItem("AdminToken", "H " + res.data.token)
                } else {
                    sessionStorage.setItem("AdminToken", "H " + res.data.token)
                }
                setStep(false)
                setUser({
                    headers: {
                        'Authorization': "H " + res.data.token
                    }
                })
                getAyun({ headers: { 'Authorization': "H " + res.data.token } })

            } else {
                setStep(true)
                setUser({})
            }
        })

    }
    React.useEffect(() => {
        if (localStorage.getItem("AdminToken") !== null) {
            axios.get(Global.url + "admin/private", {
                headers: {
                    'Authorization': localStorage.getItem("AdminToken")
                }
            }).then(res => {
                if (res.data.message === "Tienes acceso") {
                    setStep(false)
                    setUser({
                        headers: {
                            'Authorization': localStorage.getItem("AdminToken")
                        }
                    })
                    if (ayun.length === 0) {
                        getAyun({ headers: { 'Authorization': localStorage.getItem("AdminToken") } })
                    }
                } else {
                    setStep(true)
                    setUser({})
                    localStorage.removeItem("AdminToken")
                    sessionStorage.removeItem("AdminToken")
                }
            })
        }

    }, []);
    const getAyun = (e) => {
        axios.get(Global.url + "admin/getayun", e).then(res => {
            if (res.data.status === "error") {
                setOpen(true)
                setMessage(res.data.message)
                setSeverity(res.data.status)
            } else {
                setAyun(res.data.data)
            }
            axios.get(Global.url + "admin/getuser", e).then(ress => {
                if (ress.data.status === "error") {
                    setOpen(true)
                    setMessage(ress.data.message)
                    setSeverity(ress.data.status)
                } else {
                    setClient(ress.data.data)
                }
                axios.get(Global.url + "getztaadmin", e).then(resss => {
                        if (resss.data.status === "error") {
                            setOpen(true)
                            setMessage(resss.data.message)
                            setSeverity(resss.data.status)
                        } else {
                            for (var key in resss.data.data) {
                                localStorage.setItem(key, JSON.stringify(resss.data.data[key]))
                            }
                            localStorage.setItem("ZTAUpdated", moment())
                            setTimeout(() => {
                                setReceived(true)
                            }, 300)
                        }
                    })
            })
        })
    }
    return (
        <Grid container component="main" className={classes.root} justify="center" alignItems="center">
            <CssBaseline />
            <Grid item xs={12} sm={12} md={12} square>
                <div className={classes.paper}>
                    <Grid>
                        <Typography variant="h2" component="h1" id="name">eLocal Nominas Grupo H</Typography>
                    </Grid>
                </div>
            </Grid>
            {step === true &&
                <React.Fragment>
                    <Grid item xs={11} sm={8} md={5}>
                        <div className={classes.paper}>
                            <Avatar className={classes.avatar}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h2" variant="h5">
                                Admin
                            </Typography>
                            <form className={classes.form} noValidate>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="dni"
                                    label="Usuario"
                                    name="dni"
                                    autoComplete="off"
                                    autoFocus
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" id="remember" />}
                                    label="Recordar usuario"
                                />
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={signIn}
                                >
                                    Iniciar sesión
                            </Button>
                                <Box mt={5}>
                                    <Copyright />
                                </Box>
                            </form>
                        </div>
                    </Grid>
                </React.Fragment>
            }
            {step === false &&
                <React.Fragment>
                    {received
                        ? <Tab user={user} ayun={ayun} client={client} setAyun={(e) => setAyun(e)} setClient={(e) => setClient(e)}></Tab>
                        : <div className={classes.progress}>
                            <LinearProgress />
                        </div>
                    }
                </React.Fragment>
            }
            <Alert open={open} setOpen={(e) => setOpen(e)} message={message} severity={severity}></Alert>
        </Grid>
    );
}

