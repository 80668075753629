import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core/';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import db from '../components/idb-keyval-iife'
import Tiempo from './tiempo/App.js'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
export default function Trabajadores(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Hidden only="xs">
                        <Grid item display={{ xs: 'none' }} sm={5} md={3} xl={2}>
                            <Paper className={classes.paper} elevation={0}>
                                <Tiempo city={props.ayun.localidad}></Tiempo>
                            </Paper>
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
        </React.Fragment>
    );
}