import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import RouteChange from './components/RouteChange'

import Login from './components/Login'
import Main from './components/Main'
import Alert from './components/Alert'

import Test from './components/MiComponete'


import axios from 'axios'
import Global from './Global'
import moment from 'moment'

//General
import ConfigurarPuesto from './components/ConfigurarPuesto'

//Admin
import Admin from './admin'

//Inicio
import Inicio from './inicio'

//Ficheros
import Trabajadores from './ficheros/Trabajadores'
import Obras from './ficheros/Obras'
import TS from './ficheros/TablasSalariales'
import Cotizaciones from './ficheros/Cotizaciones'
import ActividadOcupacion from './ficheros/ActividadOcupacion'
import Enfermedad from './ficheros/Enfermedad'
import Calendarios from './ficheros/Calendarios'

//Utilidades
import MiCuenta from './utilidades/MiCuenta'
import ConfigPuesto from './utilidades/ConfigPuesto'
import MisSesiones from './utilidades/MisSesiones'
import MiEntidad from './utilidades/MiEntidad'

//Gestión
import Nominas from "./gestion/Nominas"
import Contratos from "./gestion/Contratos"
import Turnos from "./gestion/Turnos"
import SS from "./gestion/SS"

//EXTRA
import Inventory from './extra/inventory/index'

class ProtectedRoute extends Component {
    render() {
        const { component: Component, ...props } = this.props
        return (
            <Route
                {...props}
                render={props => (
                    this.props.user !== null ?
                        <Component {...props} /> :
                        <React.Fragment>{!this.props.main && <Login {...this.props} />}</React.Fragment>
                )}
            />
        )
    }
}

class Router extends Component {
    state = {
        load: false,
        user: null,
        ayun: null,
        open: false,
        token: null,
        message: "",
        severity: "",
        redirect: false,
        parad: [],
        obra: [],
        calendar: [],
        ts: [],
        bases: [],
        tipos: [],
        enfermedad: [],
        contratos: [],
        turnos: [],
        edit: false,
        edittionRow: {},
    }
    private = (token) => {
        axios.get(Global.url + "private", {
            headers: {
                'Authorization': token
            }
        })
            .then(res => {
                if (res.data.status === "error") {
                    this.deleteData()
                    this.setState({ open: true, message: res.data.message, severity: res.data.status, load: true, user: null })
                } else {
                    sessionStorage.setItem("UserToken", token)
                    axios.get(Global.url + "getdata", {
                        headers: {
                            'Authorization': token
                        }
                    }).then(ress => {
                        this.setState({
                            user: res.data.decoded.user,
                            ayun: res.data.decoded.ayun,
                            token: { headers: { 'Authorization': token } },
                            parad: ress.data.parad,
                            obra: ress.data.obra,
                            calendar: ress.data.calendar,
                            ts: ress.data.ts,
                            bases: ress.data.bases,
                            tipos: ress.data.tipos,
                            enfermedad: ress.data.enfermedad,
                            contratos: ress.data.contratos,
                            turnos: ress.data.turnos,
                            load: true,
                        })
                        window.addEventListener('beforeunload', this.beforeUnload)
                    })
                    axios.post(Global.url + "getzta", { "date": moment(localStorage.getItem("ZTAUpdated")) }
                        , {
                            headers: {
                                'Authorization': token
                            }
                        }).then(ress => {
                            if (ress.data.status !== "ok") {
                                for (var key in ress.data.data) {
                                    localStorage.setItem(key, JSON.stringify(ress.data.data[key]))
                                }
                                localStorage.setItem("ZTAUpdated", moment())
                            }
                        })
                }
            })
    }
    setOpen = (e) => {
        this.setState({ open: e })
    }
    setData = (e) => {
        this.setState(e)
    }
    deleteData = () => {
        sessionStorage.removeItem("UserToken")
        sessionStorage.removeItem("User")
        sessionStorage.removeItem("Entidad")
    }
    beforeUnload = (e) => {
        const confirmationMessage = "¿Seguro que quieres cerrar Nominas GrupoH?";
        e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
        return confirmationMessage;
    }
    componentDidMount() {
        var ss = sessionStorage.getItem("UserToken")
        if (ss !== null) {
            this.private(ss)
        } else {
            this.setState({ load: true })
        }
        var now = moment()
        document.addEventListener("mousemove", () => {
            now = moment()
        })
        document.addEventListener("keypress", () => {
            now = moment()
        })
        setInterval(() => {
            if (moment().diff(now, "minute", true) > 30) {
                if (this.state.token !== null) this.signOut()
            }
        }, 1000)
    }

    signOut = () => {
        window.removeEventListener("beforeunload", this.beforeUnload)
        this.deleteData()
        this.setState({
            user: null,
            ayun: null,
            token: null,
            open: false,
            message: "",
            severity: "",
        })
    }
    render() {
        return (
            <BrowserRouter>
                {this.state.load ?
                    <React.Fragment>
                        {this.state.user !== null &&
                            <ConfigurarPuesto private={this.private} token={this.state.token} user={this.state.user} ayun={this.state.ayun} setState={this.setData} />
                        }

                        <ProtectedRoute user={this.state.user} private={this.private} main={true} component={() => <Main private={this.private} obra={this.state.obra} calendar={this.state.calendar} setState={this.setData} user={this.state.user} ayun={this.state.ayun} token={this.state.token} signOut={this.signOut} parad={this.state.parad} />} />
                        <Switch>
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/" component={() => <Inicio ayun={this.state.ayun} />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/ficheros" component={() => <Redirect to="/ficheros/trabajadores" />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/ficheros/trabajadores" component={() =>
                                <Trabajadores
                                    private={this.private}
                                    obra={this.state.obra}
                                    ts={this.state.ts}
                                    calendar={this.state.calendar}
                                    setState={this.setData}
                                    user={this.state.user}
                                    ayun={this.state.ayun}
                                    token={this.state.token}
                                    signOut={this.signOut}
                                    parad={this.state.parad}
                                    contratos={this.state.contratos}
                                    edit={this.state.edit}
                                    edittionRow={this.state.edittionRow}
                                />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/ficheros/obras" component={() =>
                                <Obras
                                    private={this.private}
                                    obra={this.state.obra}
                                    contratos={this.state.contratos}
                                    ts={this.state.ts}
                                    edit={this.state.edit}
                                    edittionRow={this.state.edittionRow}
                                    calendar={this.state.calendar}
                                    setState={this.setData}
                                    user={this.state.user}
                                    ayun={this.state.ayun}
                                    token={this.state.token}
                                    signOut={this.signOut}
                                    parad={this.state.parad}
                                />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/ficheros/tablas-salarios" component={() => <TS private={this.private} setState={this.setData} user={this.state.user} ayun={this.state.ayun} token={this.state.token} ts={this.state.ts} enfermedad={this.state.enfermedad} />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/ficheros/cotizaciones" component={() => <Cotizaciones private={this.private} setState={this.setData} user={this.state.user} ayun={this.state.ayun} token={this.state.token} bases={this.state.bases} tipos={this.state.tipos} />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/ficheros/actividad-ocupacion" component={() => <ActividadOcupacion token={this.state.token} />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/ficheros/tablas-enfermedad" component={() => <Enfermedad private={this.private} obra={this.state.obra} enfermedad={this.state.enfermedad} setState={this.setData} user={this.state.user} ayun={this.state.ayun} token={this.state.token} signOut={this.signOut} parad={this.state.parad} />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/ficheros/calendarios" component={() => <Calendarios private={this.private} obra={this.state.obra} calendar={this.state.calendar} setState={this.setData} user={this.state.user} ayun={this.state.ayun} token={this.state.token} signOut={this.signOut} parad={this.state.parad} />} />

                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/utilidades" component={() => <Redirect to="/utilidades/mi-cuenta" />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/utilidades/mi-cuenta" component={() => <MiCuenta private={this.private} setState={this.setData} user={this.state.user} ayun={this.state.ayun} token={this.state.token} />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/utilidades/mi-entidad" component={() => <MiEntidad setState={this.setData} token={this.state.token} ayun={this.state.ayun} user={this.state.user} private={this.private} />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/utilidades/config-puesto" component={() => <ConfigPuesto setFontSize={this.props.setFontSize} fontSize={this.props.fontSize} token={this.state.token} />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/utilidades/mis-sesiones" component={() => <MisSesiones setState={this.setData} token={this.state.token} />} />

                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/gestion" component={() => <Redirect to="/gestion/contratos" />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/gestion/nominas" component={() =>
                                <Nominas
                                    private={this.private}
                                    token={this.state.token}
                                    edit={this.state.edit}
                                    reload={this.state.reload}
                                    user={this.state.user}
                                />}
                            />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/gestion/contratos" component={() => <Contratos private={this.private} contratos={this.state.contratos} turnos={this.state.turnos} obra={this.state.obra} parad={this.state.parad} ts={this.state.ts} bases={this.state.bases} tipos={this.state.tipos} setState={this.setData} user={this.state.user} ayun={this.state.ayun} token={this.state.token} />} />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/gestion/turnos" component={() =>
                                <Turnos
                                    private={this.private}
                                    contratos={this.state.contratos}
                                    turnos={this.state.turnos}
                                    obra={this.state.obra}
                                    parad={this.state.parad}
                                    ts={this.state.ts}
                                    bases={this.state.bases}
                                    tipos={this.state.tipos}
                                    setState={this.setData}
                                    user={this.state.user}
                                    ayun={this.state.ayun}
                                    token={this.state.token}
                                    edit={this.state.edit}
                                    edittionRow={this.state.edittionRow}
                                    reload={this.state.reload}
                                />}
                            />
                            <ProtectedRoute user={this.state.user} private={this.private} exact path="/gestion/ss" component={() =>
                                <SS
                                    private={this.private}
                                    token={this.state.token}
                                    edit={this.state.edit}
                                    reload={this.state.reload}
                                    user={this.state.user}
                                />}
                            />
                            <Route path={["/inventory","/old/inventory"]} component={() => <Inventory />} />
                            <Route exact path="/admin" component={() => <Admin />} />
                        </Switch>
                    </React.Fragment>
                    :
                    <div className={{ width: '70%', }}>
                        <LinearProgress />
                    </div>

                }
                <Alert open={this.state.open} setOpen={this.setOpen} message={this.state.message} severity={this.state.severity} ></Alert>
                <RouteChange onRouteChange={() => this.setState({ edit: false, edittionRow: {} })}></RouteChange>
            </BrowserRouter>
        );
    }

}
export default Router;