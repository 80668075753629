import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import HeaderHeader from './HeaderHeader';
import HeaderInfo from './HeaderInfo';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    borderWidth: 2,
    borderColor: '#848484',
  },
});

const Header = ({ paradInfo, contratoInfo,ayunInfo }) => (
  <View style={styles.tableContainer}>
    <HeaderHeader />
    <HeaderInfo paradInfo={paradInfo} contratoInfo={contratoInfo} ayunInfo={ayunInfo}/>
  </View>
);

export default Header