import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, TextField, Paper, Button, Box, Tab, Tabs, AppBar, Popper } from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import Alert from '../components/Alert'

import Icons from '../components/Icons'
import axios from 'axios'
import Global from '../Global'
import MaterialTable from 'material-table'
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: "150px auto",
        height: "auto",
    },
    row: {
        padding: "1rem 0rem"
    },
    labelContainer: {
        width: '100%',
        textAlign: "end",
    },
    label: {
        height: '1.1876em',
        fontSize: '1rem',
        padding: '4px 40px 7px 0px',
    },
    inputAutocomplete: {
        fontSize: "0.8rem",
    },
}));
function findRegimen(string, array) {
    if (typeof string === 'string') {
        for (var i in array) {
            if (array[i].CODS === string) {
                return array[i]
            }
        }
    } else {
        return { "CODS": string, "DESS": string }
    }
}
function findActividad(string, array) {
    if (typeof string === 'string') {
        for (var i in array) {
            if (array[i].CODCNA === string) {
                return array[i]
            }
        }
    } else {
        return { "CODCNA": string, "TITCNA": "Seleccione una opción válida" }
    }
}
function findMutua(string, array) {
    if (typeof string === 'string') {
        for (var i in array) {
            if (array[i].CODMUT === string) {
                return array[i]
            }
        }
    } else {
        return { "CODMUT": "", "NOMMUT": "Seleccione una opción válida" }
    }
}
function findBIC(string, array) {
    if (typeof string === 'string') {
        for (var i in array) {
            if (array[i].BICEBA === string) {
                return array[i]
            }
        }
    } else {
        return { "BICEBA": "", "BANEBA": "Seleccione una opción válida" }
    }
}

export default function Trabajadores(props) {
    const fileUploader = React.useRef(null);
    const fileUploaderr = React.useRef(null);
    const classes = useStyles();
    const [values, setValues] = React.useState(props.ayun)
    const [value, setValue] = React.useState(0);
    const [email, setEmail] = React.useState(props.ayun.email)
    const [IBAN, setIBAN] = React.useState("")
    const [errorE, setErrorE] = React.useState(false)
    const [errorTextE, setErrorTextE] = React.useState("")
    const [ibanError, setIbanError] = React.useState(false);
    const [ibanErrorText, setIbanErrorText] = React.useState("");
    const [users, setUsers] = React.useState([]);
    const [state, setState] = React.useState({ open: false, message: "", status: "" })
    const [urlHeader, setUrlHeader] = React.useState([`${props.ayun.imgHeader}`, {}])
    const [urlDoc, setUrlDoc] = React.useState([`${props.ayun.imgDoc}`, {}])
    const setOpen = (e) => setState({ ...state, ["open"]: e })
    const handleChangeV = (event, newValue) => {
        setValue(newValue);
    };
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleChangeSalarial = (prop) => (event) => {
        setValues({ ...values, ['salarial']: { ...values.salarial, [prop]: event.target.value } });
    };
    const handleChangeEmail = (event) => {
        if (Global.validateEmail(event.target.value).status) {
            setErrorE(true)
            setErrorTextE(Global.validateEmail(event.target.value).message)
        } else {
            setErrorE(false)
            setErrorTextE("")
            setValues({ ...values, ["email"]: event.target.value });
        }
    };
    const handleChangeIBAN = () => {
        const res = Global.checkIBAN(IBAN)
        if (res) {
            setIbanError(false)
            setIbanErrorText("")
            setValues({ ...values, ["IBAN"]: IBAN });
        } else {
            setIbanError(true)
            setIbanErrorText("IBAN no válido, revíselo")
        }
    }
    const sendData = () => {
        if (props.user.rol >= 2) {
            axios.post(Global.url + "updateayun", values, props.token).then(res => {
                if (res.data.status === "error") {
                } else {
                    axios.get(Global.url + "refreshtoken", props.token).then(ress => {
                        props.private("H " + ress.data.token)
                        props.setState({ open: true, message: res.data.message, severity: res.data.status })
                    })
                }
            })
        }
    }
    React.useEffect(() => {
        if (props.user.rol >= 2) axios.get(`${Global.url}getusers`, props.token).then(res => setUsers(res.data.users))
        setIBAN(props.ayun.IBAN)
    }, [])
    const saveUser = async (newData) => {
        if (props.user.rol >= 2) {
            if (newData.dni && newData.apellidos && newData.nombre && newData.rol && newData.email && newData.password) {
                const validateDNI = Global.validateDNI(newData.dni)
                if (!validateDNI.status) {
                    if (!users.find(a => a.dni === newData.dni.toUpperCase())) {
                        const res = await axios.post(`${Global.url}save-user`, newData, props.token)
                        if (res.data.status === "error") {
                            setState({ open: true, message: res.data.message, severity: "error" })
                            return { status: false }
                        } else {
                            setState({ open: true, message: res.data.message, severity: "success" })
                            setUsers(res.data.users)
                            return { status: true }
                        }
                    } else {
                        setState({ open: true, message: "Ya existe un usuario con el mismo DNI", severity: "error" })
                        return { status: false }
                    }
                } else {
                    setState({ open: true, message: validateDNI.message, severity: "error" })
                    return { status: false }
                }
            } else {
                setState({ open: true, message: "Rellene todos los campos, usuario no guardado", severity: "error" })
                return { status: false }
            }
        } else {
            return { status: true }
        }
    }
    const updateUser = async (newData) => {
        if (props.user.rol >= 2) {
            if (newData.dni && newData.apellidos && newData.nombre && newData.rol && newData.email) {
                const validateDNI = Global.validateDNI(newData.dni)
                if (!validateDNI.status) {
                    if (!users.find(a => a._id !== newData._id && a.dni === newData.dni.toUpperCase())) {
                        const res = await axios.post(`${Global.url}update-user`, newData, props.token)
                        if (res.data.status === "error") {
                            setState({ open: true, message: res.data.message, severity: "error" })
                            return { status: false }
                        } else {
                            setState({ open: true, message: res.data.message, severity: "success" })
                            setUsers(res.data.users)
                            return { status: true }
                        }
                    } else {
                        setState({ open: true, message: "Ya existe un usuario con el mismo DNI", severity: "error" })
                        return { status: false }
                    }
                } else {
                    setState({ open: true, message: validateDNI.message, severity: "error" })
                    return { status: false }
                }
            } else {
                setState({ open: true, message: "Rellene todos los campos (Único no obligatorio: Contraseña, permanecerá la anterior)", severity: "error" })
                return { status: false }
            }
        } else {
            return { status: true }
        }
    }
    const deleteUser = async (oldData) => {
        if (props.user.rol >= 2) {
            const res = await axios.post(`${Global.url}delete-user`, oldData, props.token)
            if (res.data.status === "error") {
                setState({ open: true, message: res.data.message, severity: "error" })
                return { status: false }
            } else {
                setState({ open: true, message: res.data.message, severity: "success" })
                setUsers(res.data.users)
                return { status: true }
            }
        } else {
            return { status: true }
        }
    }
    const saveImageTemp = (event) => {
        if (event.target.files[0]) {
            setUrlHeader([URL.createObjectURL(event.target.files[0]), event.target.files[0]])
        } else {
            setUrlHeader([`${props.ayun.imgHeader}`, {}])
        }
    }
    const saveImageDocTemp = (event) => {
        if (event.target.files[0]) {
            setUrlDoc([URL.createObjectURL(event.target.files[0]), event.target.files[0]])
        } else {
            setUrlDoc([`${props.ayun.imgDoc}`, {}])
        }
    }
    const sendDataHeader = () => {
        if (props.user.rol >= 2) {
            var formData = new FormData();
            formData.append('file0', urlHeader[1], urlHeader[1].name)
            axios.post(Global.url + 'updateimageheader/', formData, props.token).then(res => {
                if (res.data.status === "error") setState({ open: true, message: res.data.message, severity: "error" })
                else {
                    setState({ open: true, message: res.data.message, severity: res.data.status })
                    axios.get(Global.url + "refreshtoken", props.token).then(ress => {
                        props.private("H " + ress.data.token)
                    })
                }
            })
        }
    }
    const sendDataDoc = () => {
        if (props.user.rol >= 2) {
            var formData = new FormData();
            formData.append('file0', urlDoc[1], urlDoc[1].name)
            axios.post(Global.url + 'updateimagedoc/', formData, props.token).then(res => {
                if (res.data.status === "error") setState({ open: true, message: res.data.message, severity: "error" })
                else {
                    setState({ open: true, message: res.data.message, severity: res.data.status })
                    axios.get(Global.url + "refreshtoken", props.token).then(ress => {
                        props.private("H " + ress.data.token)
                    })
                }
            })
        }
    }
    return (
        <React.Fragment>
            <div className={classes.root} style={{ maxWidth: value === 1 ? "1200px" : "800px" }}>
                <Paper>
                    <AppBar position="static" color="default" component="div">
                        <Tabs
                            value={value}
                            onChange={handleChangeV}
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="scrollable auto tabs example"
                            centered
                        >
                            <Tab label="Datos" {...a11yProps(0)} />
                            {props.user.rol >= 2 &&
                                <Tab label="Usuarios" {...a11yProps(1)} />
                            }
                            {props.user.rol >= 2 &&
                                <Tab label="Multimedia" {...a11yProps(2)} />
                            }
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Grid container style={{ backgroundColor: "#fff", padding: "0rem 2rem", paddingBottom: "1rem" }}>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h2" style={{ textAlign: "center", marginBottom: "2rem" }}>Mi entidad</Typography>
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Nombre:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <TextField
                                    value={values.nombre}
                                    onChange={handleChange('nombre')}
                                    defaultValue={props.ayun.nombre}
                                    fullWidth
                                    autoComplete="something-new"
                                />
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Dirección:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <TextField
                                    value={values.direccion}
                                    onChange={handleChange('direccion')}
                                    defaultValue={props.ayun.direccion}
                                    fullWidth
                                    autoComplete="something-new"
                                />
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Código postal:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <TextField
                                    value={values.cp}
                                    onChange={handleChange('cp')}
                                    defaultValue={props.ayun.cp}
                                    fullWidth
                                    autoComplete="something-new"
                                />
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Localidad:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <div className={classes.label}>{props.ayun.localidad}</div>
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Provincia:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <div className={classes.label}>{Global.getAuxiliarTableValue('ZTA_CodProvincia', 'CODDEL', props.ayun.provincia) ? Global.getAuxiliarTableValue('ZTA_CodProvincia', 'CODDEL', props.ayun.provincia)["NOMDEL"] : ''}</div>
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Tipo empresa:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <div className={classes.label}>{JSON.parse(localStorage.getItem('ZTA_tipoEmpresario')).find(a => a.CODTP === props.ayun.tipoEmpresa)["DESTP"]}</div>
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>CIF:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <div className={classes.label}>{props.ayun.cif}</div>
                            </Grid>
                            <Grid item xs={3} sm={4} className={`${classes.row} ${classes.labelContainer}`} style={{ padding: '2rem 0' }}>
                                <div className={classes.label}>Número SS:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <Grid container>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Autocomplete
                                            options={JSON.parse(localStorage.getItem("ZTA_SS_Regimen"))}
                                            autoHighlight
                                            classes={{
                                                option: classes.option,
                                                input: classes.inputAutocomplete
                                            }}
                                            value={findRegimen(values.CCCreg, JSON.parse(localStorage.getItem("ZTA_SS_Regimen")))}
                                            onChange={(event, value) => {
                                                if (value && props.user.rol >= 2) setValues({ ...values, ["CCCreg"]: value.CODS })

                                            }}
                                            getOptionLabel={(option) => option.CODS + " - " + option.DESS}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    <span>{option.CODS}&nbsp;-&nbsp;</span>{option.DESS}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Régimen"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password',
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            )}
                                            PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2}>
                                        <TextField
                                            value={values.CCCprovincia}
                                            onChange={handleChange('CCCprovincia')}
                                            defaultValue={props.ayun.CCCprovincia}
                                            inputProps={{ style: { padding: "6px 0", fontSize: "0.85rem" } }}
                                            label="Provincia"
                                            InputProps={{
                                                inputComponent: Global.formatProvinciaSS
                                            }}
                                            fullWidth
                                            autoComplete="something-new"
                                        />
                                    </Grid>
                                    <Grid item xs={8} sm={6}>
                                        <TextField
                                            value={values.CCCnum}
                                            onChange={handleChange('CCCnum')}
                                            defaultValue={props.ayun.CCCnum}
                                            inputProps={{ style: { padding: "6px 0", fontSize: "0.85rem" } }}
                                            label="Número"
                                            InputProps={{
                                                inputComponent: Global.formatNumeroSS
                                            }}
                                            fullWidth
                                            autoComplete="something-new"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} sm={4} className={`${classes.row} ${classes.labelContainer}`} style={{ padding: '2rem 0' }}>
                                <div className={classes.label}>Representante:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <Grid container>
                                    <Grid item xs={4} sm={5}>
                                        <TextField
                                            value={values.representanteNombre}
                                            onChange={handleChange('representanteNombre')}
                                            defaultValue={props.ayun.representanteNombre}
                                            inputProps={{ style: { padding: "6px 0", fontSize: "0.85rem" } }}
                                            label="Nombre"
                                            fullWidth
                                            autoComplete="something-new"
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={5}>
                                        <TextField
                                            value={values.representanteApellido1}
                                            onChange={handleChange('representanteApellido1')}
                                            defaultValue={props.ayun.representanteApellido1}
                                            inputProps={{ style: { padding: "6px 0", fontSize: "0.85rem" } }}
                                            label="Primer apellido"
                                            fullWidth
                                            autoComplete="something-new"
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={5}>
                                        <TextField
                                            value={values.representanteApellido2}
                                            onChange={handleChange('representanteApellido2')}
                                            defaultValue={props.ayun.representanteApellido2}
                                            inputProps={{ style: { padding: "6px 0", fontSize: "0.85rem" } }}
                                            label="Segundo apellido"
                                            fullWidth
                                            autoComplete="something-new"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            value={values.representanteDNI}
                                            onChange={handleChange('representanteDNI')}
                                            defaultValue={props.ayun.representanteDNI}
                                            error={Global.validateDNI(values.representanteDNI).status}
                                            inputProps={{ style: { padding: "6px 0", fontSize: "0.85rem" } }}
                                            label="DNI"
                                            fullWidth
                                            autoComplete="something-new"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <TextField
                                            value={values.representanteCargo}
                                            onChange={handleChange('representanteCargo')}
                                            defaultValue={props.ayun.representanteCargo}
                                            inputProps={{ style: { padding: "6px 0", fontSize: "0.85rem" } }}
                                            label="Cargo"
                                            fullWidth
                                            autoComplete="something-new"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Obs. Certificado Empresa:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <TextField
                                    value={values.observacionesCertificadoEmpresa}
                                    onChange={handleChange('observacionesCertificadoEmpresa')}
                                    defaultValue={props.ayun.observacionesCertificadoEmpresa}
                                    fullWidth
                                    autoComplete="something-new"
                                />
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Código de ordenate:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <TextField
                                    value={values.codigoOrdenate}
                                    onChange={handleChange('codigoOrdenate')}
                                    defaultValue={props.ayun.codigoOrdenate}
                                    fullWidth
                                    autoComplete="something-new"
                                />
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>IBAN:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <TextField
                                    value={IBAN}
                                    onChange={(e) => { const res = e.target.value.replace(/ /g, "").replace(/_/g, ""); setIBAN(res) }}
                                    onBlur={(e) => { handleChangeIBAN(e) }}
                                    error={ibanError}
                                    helperText={ibanErrorText}
                                    defaultValue={props.ayun.IBAN}
                                    fullWidth
                                    InputProps={{
                                        className: classes.input,
                                        inputComponent: Global.formatIBAN
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    autoComplete="something-new"
                                />
                            </Grid>
                            <Grid xs={4} className={`${classes.row} ${classes.labelContainer}`} style={{ padding: '2rem 0' }}>
                                <div className={classes.label}>BIC:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <Autocomplete
                                    options={JSON.parse(localStorage.getItem("ZTA_Banco"))}
                                    autoHighlight
                                    classes={{
                                        option: classes.option,
                                        input: classes.inputAutocomplete
                                    }}
                                    value={findBIC(values.BIC, JSON.parse(localStorage.getItem("ZTA_Banco")))}
                                    onChange={(event, value) => {
                                        if (value && props.user.rol >= 2) setValues({ ...values, ["BIC"]: value.BICEBA })

                                    }}
                                    getOptionLabel={(option) => option.BICEBA + " - " + option.BANEBA}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span>{option.BICEBA}&nbsp;-&nbsp;</span>{option.BANEBA}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label=" "
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                    PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                                />
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>E-mail:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <TextField
                                    value={email}
                                    onChange={(e, email) => setEmail(email)}
                                    onBlur={handleChangeEmail}
                                    error={errorE}
                                    helperText={errorTextE}
                                    defaultValue={props.ayun.email}
                                    fullWidth
                                    autoComplete="something-new"
                                />
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Teléfono:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <TextField
                                    value={values.telf}
                                    onChange={handleChange('telf')}
                                    defaultValue={props.ayun.telf}
                                    fullWidth
                                    autoComplete="something-new"
                                />
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Autorización RED:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <TextField
                                    value={values.red}
                                    onChange={(e) => { const res = e.target.value.replace(/ /g, "").replace(/_/g, ""); if (props.user.rol > 1) setValues({ ...values, ["red"]: res }) }}
                                    defaultValue={props.ayun.red}
                                    fullWidth
                                    InputProps={{
                                        className: classes.input,
                                        inputComponent: Global.formatClaveRed
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    autoComplete="something-new"
                                />
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Fecha RED:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}> {/* FECHA NACIMIENTO */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                    <KeyboardDatePicker
                                        format="dd/MM/yyyy"
                                        value={values.redFechaAutorizacion}
                                        onChange={(date) => { if (props.user.rol >= 2) setValues({ ...values, ["redFechaAutorizacion"]: date }) }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        style={{ margin: "0px" }}
                                        InputAdornmentProps={{
                                            root: {
                                                padding: 0,
                                                marginBottom: "10px"
                                            }
                                        }}
                                        InputProps={{
                                            className: classes.input
                                        }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        fullWidth
                                        keyboardIcon={<CalendarTodayIcon style={{ fontSize: 15, marginBottom: "10px" }}></CalendarTodayIcon>}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid xs={4} className={`${classes.row} ${classes.labelContainer}`} style={{ padding: '2rem 0' }}>
                                <div className={classes.label}>Aseguradora:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <Autocomplete
                                    options={JSON.parse(localStorage.getItem("ZTA_Mutua"))}
                                    autoHighlight
                                    classes={{
                                        option: classes.option,
                                        input: classes.inputAutocomplete
                                    }}
                                    value={findMutua(values.entidadATEP, JSON.parse(localStorage.getItem("ZTA_Mutua")))}
                                    onChange={(event, value) => {
                                        if (value && props.user.rol >= 2) setValues({ ...values, ["entidadATEP"]: value.CODMUT })

                                    }}
                                    getOptionLabel={(option) => option.CODMUT + " - " + option.NOMMUT}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span>{option.CODMUT}&nbsp;-&nbsp;</span>{option.NOMMUT}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label=" "
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                    PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' component="h3" style={{ textAlign: "center", marginTop: "2rem" }}>Datos por defecto Tablas Salariales</Typography>
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Código de convenio:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <TextField
                                    value={values.salarial.codigoConvenio}
                                    onChange={handleChangeSalarial('codigoConvenio')}
                                    defaultValue={props.ayun.salarial.codigoConvenio}
                                    fullWidth
                                    autoComplete="something-new"
                                />
                            </Grid>
                            <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                                <div className={classes.label}>Nombre convenio:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <TextField
                                    value={values.salarial.nombreConvenio}
                                    onChange={handleChangeSalarial('nombreConvenio')}
                                    defaultValue={props.ayun.salarial.nombreConvenio}
                                    fullWidth
                                    autoComplete="something-new"
                                />
                            </Grid>
                            <Grid xs={4} className={`${classes.row} ${classes.labelContainer}`} style={{ padding: '2rem 0' }}>
                                <div className={classes.label}>Actividad:</div>
                            </Grid>
                            <Grid item xs={8} className={classes.row}>
                                <Autocomplete
                                    options={JSON.parse(localStorage.getItem("ZTA_SS_Actividad"))}
                                    autoHighlight
                                    classes={{
                                        option: classes.option,
                                        input: classes.inputAutocomplete
                                    }}
                                    value={findActividad(values.salarial.actividad, JSON.parse(localStorage.getItem("ZTA_SS_Actividad")))}
                                    onChange={(event, value) => {
                                        if (value && props.user.rol >= 2) setValues({ ...values, ["salarial"]: { ...values.salarial, ["actividad"]: value.CODCNA } })

                                    }}
                                    getOptionLabel={(option) => option.CODCNA + " - " + option.TITCNA}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span>{option.CODCNA}&nbsp;-&nbsp;</span>{option.TITCNA}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label=" "
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                    PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                                />
                            </Grid>

                            {props.user.rol >= 2 &&
                                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                    <div style={{ position: "absolute", transform: "translateX(-50%)", left: '50%', margin: 0 }}>
                                        <Button variant="outlined" color="primary" onClick={() => sendData()} >
                                            Guardar
                                        </Button>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Grid container style={{ backgroundColor: "#fff", paddingBottom: "1rem" }}>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h2" style={{ textAlign: "center", marginBottom: "2rem" }}>Mi entidad</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <MaterialTable
                                    title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Usuarios</Typography>}
                                    columns={[
                                        {
                                            title: 'DNI',
                                            field: 'dni',
                                            align: "center",
                                        },
                                        {
                                            title: 'Apellidos',
                                            field: 'apellidos',
                                            align: "center",
                                        },
                                        {
                                            title: 'Nombre',
                                            field: 'nombre',
                                            align: "center",
                                        },
                                        { title: 'Rol', field: 'rol', type: 'numeric', lookup: { 0: 'Consultor', 1: 'Editor', 2: "Administrador" }, },
                                        { title: 'E-mail', field: 'email' },
                                        { title: 'Contraseña', field: 'password' },
                                    ]}
                                    icons={Icons}
                                    data={users.filter(e => e.rol < 3)}
                                    editable={{
                                        onRowAdd: (newData) =>
                                            new Promise(async (resolve, reject) => {
                                                const { status } = await saveUser(newData)
                                                if (status) {
                                                    resolve()
                                                } else {
                                                    reject()
                                                }
                                            }),
                                        onRowUpdate: (newData) =>
                                            new Promise(async (resolve, reject) => {
                                                await updateUser(newData)
                                                const { status } = await updateUser(newData)
                                                if (status) {
                                                    resolve()
                                                } else {
                                                    reject()
                                                }
                                            }),
                                        onRowDelete: (oldData) =>
                                            new Promise(async (resolve, reject) => {
                                                const { status } = await deleteUser(oldData)
                                                if (status) {
                                                    resolve()
                                                } else {
                                                    reject()
                                                }
                                            }),
                                    }}
                                    options={{
                                        pageSize: 5,
                                        pageSizeOptions: [5, 10, 15, 20]
                                    }}
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} de {count}',
                                            labelRowsSelect: 'filas'
                                        },
                                        toolbar: {
                                            nRowsSelected: '{0} row(s) seleccionada',
                                            searchPlaceholder: 'Buscar',
                                            searchTooltip: 'Buscar',
                                        },
                                        header: {
                                            actions: 'Acciones'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Sin datos',
                                            filterRow: {
                                                filterTooltip: 'Filtrar'
                                            },
                                            addTooltip: "Nuevo usuario",
                                            deleteTooltip: "Eliminar usuario",
                                            editTooltip: "Editar usuario",
                                            editRow: {
                                                deleteText: "¿Estás seguro de eliminar el usuario?",
                                                cancelTooltip: "Cancelar",
                                                saveTooltip: "Guardar"
                                            },
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Grid container style={{ backgroundColor: "#fff", padding: "0rem 2rem", paddingBottom: "1rem" }}>
                            <Grid item xs={12}>
                                <Typography variant="h5" component="h2" style={{ textAlign: "center", marginBottom: "2rem" }}>Mi entidad</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6" component="h3" style={{ textAlign: "center", marginBottom: "1rem" }}>Imagen aplicación</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption" component="h4" style={{ textAlign: "center", marginBottom: "0rem" }}>Actual</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption" component="h4" style={{ textAlign: "center", marginBottom: "0.5rem" }}>Nueva (230px*70px)</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "1.5rem" }}>
                                <img src={`${props.ayun.imgHeader}`} alt={props.ayun.nombre} style={{ width: "230px", height: "70px", border: "1px solid #eee" }} />
                            </Grid>
                            <Grid item xs={6} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "1.5rem" }}>
                                <input
                                    accept="image/*"
                                    type="file"
                                    id="file"
                                    ref={fileUploader}
                                    onChange={saveImageTemp}
                                    style={{ display: "none" }} />
                                <img src={urlHeader[0]} alt={props.ayun.nombre} style={{ width: "230px", height: "70px", border: "1px solid #eee" }} onClick={() => fileUploader.current.click()} />
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "50px" }}>
                                <div style={{ position: "absolute", transform: "translateX(-50%)", left: '50%', margin: 0 }}>
                                    <Button variant="outlined" color="primary" onClick={() => sendDataHeader()} >
                                        Guardar
                                    </Button>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" component="h3" style={{ textAlign: "center", marginBottom: "1rem" }}>Imagen documentos</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption" component="h4" style={{ textAlign: "center", marginBottom: "0rem" }}>Actual</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="caption" component="h4" style={{ textAlign: "center", marginBottom: "0.5rem" }}>Nueva (160px*190px)</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "1.5rem" }}>
                                <img src={`${props.ayun.imgDoc}`} alt={props.ayun.nombre} style={{ width: "160px", height: "190px", border: "1px solid #eee" }} />
                            </Grid>
                            <Grid item xs={6} style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "1.5rem" }}>
                                <input
                                    accept="image/*"
                                    type="file"
                                    id="file"
                                    ref={fileUploaderr}
                                    onChange={saveImageDocTemp}
                                    style={{ display: "none" }} />
                                <img src={urlDoc[0]} alt={props.ayun.nombre} style={{ width: "160px", height: "190px", border: "1px solid #eee" }} onClick={() => fileUploaderr.current.click()} />
                            </Grid>
                            {props.user.rol >= 2 &&
                                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                    <div style={{ position: "absolute", transform: "translateX(-50%)", left: '50%', margin: 0 }}>
                                        <Button variant="outlined" color="primary" onClick={() => sendDataDoc()} >
                                            Guardar
                                        </Button>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </TabPanel>
                </Paper>
            </div>
            <Alert open={state.open} setOpen={setOpen} message={state.message} severity={state.severity} ></Alert>
        </React.Fragment>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
