import React from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import swal from 'sweetalert';
import axios from 'axios'
import Global from '../Global'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSignature, faUserInjured } from '@fortawesome/free-solid-svg-icons'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import _ from 'underscore';
import esLocale from "date-fns/locale/es";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import GetAppIcon from '@material-ui/icons/GetApp';
import fs from '../components/fs';
import MaterialTable from 'material-table';
import Icons from '../components/Icons'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale';
import ContratosConceptos from './ContratosConceptos';
import { incapacidad, contrato } from '../services'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
function findName(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { nombre: "", primerApellido: "Selecciona una opción válida", segundoApellido: "" }
  }
}
function findDNI(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { dni: "Selecciona una opción válida" }
  }
}
function findExp(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { exped: "Selecciona una opción válida" }
  }
}
function findBaja(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODCAB === string) {
        return array[i]
      }
    }
  } else {
    return { CODCAB: "Selecciona una opción válida" }
  }
}
function findCese(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODCC === string) {
        return array[i]
      }
    }
  } else {
    return { CODCC: "Selecciona una opción válida" }
  }
}
function findDenom(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { denom: "Selecciona una opción válida" }
  }
}
function findCod(string, array) {
  if (typeof string === 'string') {
    for (const i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { codigoConvenio: "Selecciona una opción válida" }
  }
}
function findNameConvenio(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { nombreConvenio: "Selecciona una opción válida" }
  }
}
function findClaveContrato(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODCC === string) {
        return array[i]
      }
    }
  } else {
    return { CODCC: "Selecciona una opción válida", DESCC: "" }
  }
}
const useStyles = makeStyles((theme) => ({
  rootTwo: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  option: {
    fontSize: "0.8rem",
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputSelect: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputAutocomplete: {
    height: 3,
    fontSize: "0.8rem",
  },
  inputAutocompleteTable: {
    height: 17,
    fontSize: "13px",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tab: {
    height: "30px",
    marginTop: "0px",
    fontSize: "0.7rem",
  },
  tabs: {
    height: "48px"
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    padding: "0",
    zIndex: "30"
  },
  buttonDelete: {
    position: "absolute",
    bottom: "10px",
    right: "120px",
    padding: "0",
    zIndex: "30"
  },
  buttonGenerete: {
    position: "absolute",
    bottom: "10px",
    right: "220px",
    padding: "0",
    zIndex: "30"
  },
  tabButton: {
    '&:hover': {
      backgroundColor: 'initial !important'
    },
    fontSize: 'inherit',
  },
}));
export default function InputAdornments(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState(props.data);
  const [value, setValue] = React.useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (prop) => (value) => {
    if (props.user.rol >= 1) {
      setValues({ ...values, [prop]: value });
    }
  };
  const setParad = (value) => {
    const info = Global.validateWorker(value)
    if (!values._id) {
      if (info.obligatorios.length === 0) {
        setValues({ ...values, ["idParad"]: value._id })
      } else {
        swal({
          title: "Faltan campos obligatorios por rellenar del trabajador",
          text: `Antes de continuar tienes que rellenar todos los campos necesarios del trabajador. \n Faltan: ${info.obligatorios.join(', ')}`,
          icon: "warning",
          buttons: {
            ok: {
              text: "Vale",
              className: 'popup-button',
              value: true,
            },
          },
        })
        setValues({ ...values, ["idParad"]: "" })
      }
    }
  }
  const setObra = (value) => {
    const info = Global.validateObra(value)
    if (info.obligatorios.length === 0) {
      setValues({ ...values, ["idObra"]: value._id })
    } else {
      swal({
        html: true,
        title: "Faltan campos obligatorios por rellenar de la obra",
        text: `Antes de continuar tienes que rellenar todos los campos necesarios de la obra. \n Faltan: ${info.obligatorios.join(', ')}`,
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
      setValues({ ...values, ["idObra"]: "", ["denom"]: "" })
    }
  }
  const setTS = (value) => {
    const info = Global.validateTS(value)
    if (info.obligatorios.length === 0) {
      setValues({ ...values, ["idTs"]: value._id })
    } else {
      swal({
        html: true,
        title: "Faltan campos obligatorios por rellenar de la tabla salarial",
        text: `Antes de continuar tienes que rellenar todos los campos necesarios de la tabla salarial. \n Faltan: ${info.obligatorios.join(', ')}`,
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
      setValues({ ...values, ["idTs"]: "" })
    }
  }
  const saveAndClose = async () => {
    const infoParad = Global.validateWorker(props.parad.find(element => element._id === values.idParad)).obligatorios
    const infoObra = Global.validateObra(props.obra.find(element => element._id === values.idObra)).obligatorios
    const infoTS = Global.validateTS(props.ts.find(element => element._id === values.idTs)).obligatorios
    if (infoParad.length === 0 && infoObra.length === 0 && infoTS.length === 0) {
      sessionStorage.setItem("contratosFecha", values.fechaAlta)
      sessionStorage.setItem("contratosCod", values.codOfertaEmpleo)
      const isValidDateRes = isValidDate();
      if (values._id) {
        const nominaValRes = await contrato.validaContrato(values, props.token,false);
        if(!nominaValRes.valid){
          swal({
            title: nominaValRes.message,
            text: nominaValRes.longMessage,
            icon: "error",
            buttons: {
              ok: {
                text: "Vale",
                className: 'popup-button',
                value: true,
              },
            },
          });
          return;
        }
        if (moment(values.fechaAlta).isSame(moment(props.data.fechaAlta), "day") && moment(values.fechaBaja).isSame(moment(props.data.fechaBaja), "day")) {
          axios.post(`${Global.url}updatecontratos`, values, props.token).then(res => {
            if (res.data.status === "error") alert(res.data.message)
            else { props.setState({ open: true, message: res.data.message, severity: res.data.status, contratos: res.data.contratos, edit: false, edittionRow: {} }) }
          })
        } else {
          if (isValidDateRes) {
            axios.post(`${Global.url}updatecontratos`, values, props.token).then(res => {
              if (res.data.status === "error") alert(res.data.message)
              else { props.setState({ open: true, message: res.data.message, severity: res.data.status, contratos: res.data.contratos, edit: false, edittionRow: {} }) }
            })
          }
        }
      } else {
        if (isValidDateRes) {
          axios.post(`${Global.url}updatecontratos`, values, props.token).then(res => {
            if (res.data.status === "error") alert(res.data.message)
            else { props.setState({ open: true, message: res.data.message, severity: res.data.status, contratos: res.data.contratos, edit: false, edittionRow: {} }) }
          })
        }
      }
    } else {
      swal({
        title: "Faltan campos obligatorios por rellenar",
        text: "Trabajador: " + infoParad.obligatorios.join(', ') + ". \n Obra: " + infoObra.obligatorios.join(', ') + ". \n Tabla salarial: " + infoTS.obligatorios.join(', ') + ". \n",
        icon: "warning",
        buttons: {
          salir: {
            text: "Salir sin guardar",
            value: false,
            className: 'popup-button-red',
          },
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      }).then(res => {
        if (!res) props.close()
      })
    }
  }
  const save = () => {
    const info = Global.validateContrato(values)
    if (info.obligatorios.length === 0) {
      saveAndClose()
    } else {
      swal({
        title: "Faltan campos obligatorios por rellenar",
        text: "Faltan: " + info.obligatorios.join(', '),
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
    }
  }
  const close = () => {
    if (props.user.rol >= 1) {
      const info = Global.validateContrato(values, props.data)
      if (info.obligatorios.length > 0) {
        swal({
          title: "Faltan campos obligatorios por rellenar",
          text: `Faltan: ${info.obligatorios.join(', ')}.`,
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Vale",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (!res && info.equal) props.close()
          else if (!res && !info.equal && values.idAyun) {
            props.close()
          } else if (!values.idAyun && !res) {
            props.close()
          }
        })
      } else if (info.obligatorios.length === 0 && !info.equal) {
        swal({
          title: "¿Desea salir sin guardar?",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Guardar",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (res) saveAndClose()
          else props.close()
        })
      } else {
        props.close()
      }
    } else {
      props.close()
    }
  }
  const isValidDate = () => {
    const contratos =
      props.allData
        .filter(element =>
          element._id !== values._id && element.idParad === values.idParad)
        .sort((a, b) =>
          moment(a.fechaAlta) - moment(b.fechaAlta))

    const sinFechaDeBaja = contratos.find(element => !element.fechaBaja)
    if (sinFechaDeBaja) {
      swal({
        title: "Error",
        text: `Existe un contrato abierto del trabajador. Para seguir editando este contrato debes cerrar el otro contrato del trabajador`,
        icon: "error",
        buttons: {
          salir: {
            text: "Vale",
            value: false,
            className: 'popup-button-red',
          },
          ok: {
            text: "Ver contrato",
            className: 'popup-button',
            value: true,
          },
        },
      }).then(res => {
        if (res) setValues(sinFechaDeBaja)
      })
      return false
    }
    const igualFechaAlta = contratos.find(element => moment(element.fechaAlta).isSame(moment(values.fechaAlta), "day"))
    if (igualFechaAlta) {
      swal({
        title: "Error",
        text: `Existe un contrato con la misma fecha de alta del trabajador`,
        icon: "error",
        buttons: {
          salir: {
            text: "Vale",
            value: false,
            className: 'popup-button-red',
          },
          ok: {
            text: "Ver contrato",
            className: 'popup-button',
            value: true,
          },
        },
      }).then(res => {
        if (res) setValues(sinFechaDeBaja)
      })
      return false
    }
    const posteriorFechaAlta = contratos.find(element => !values._id && moment(element.fechaAlta).isAfter(moment(values.fechaAlta)))
    let bajaCambiada = undefined;
    if (posteriorFechaAlta) {
      if (!values.fechaBaja) bajaCambiada = moment(posteriorFechaAlta.fechaAlta).add(-1, "day")
      else {
        if (moment(values.fechaBaja).isSameOrAfter(moment(posteriorFechaAlta.fechaAlta), "day")) bajaCambiada = moment(posteriorFechaAlta.fechaAlta).add(-1, "day")
      }
    }
    if (bajaCambiada) {
      swal({
        title: "Advertencia",
        text: `La fecha de baja ha sido modificada`,
        icon: "warning",
        buttons: {
          salir: {
            text: "Vale",
            className: 'popup-button',
          },
        },
      })
      setValues({ ...values, ["fechaBaja"]: bajaCambiada })
      return false
    }
    const isBetweenAlta = contratos.find(element => {
      return element.fechaBaja ? (moment(values.fechaAlta).isBetween(element.fechaAlta, element.fechaBaja, "day", "[]")) : false;
    })
    if (isBetweenAlta) {
      swal({
        title: "Error",
        text: `Existe un contrato en las mismas fechas`,
        icon: "error",
        buttons: {
          salir: {
            text: "Vale",
            value: false,
            className: 'popup-button-red',
          },
          ok: {
            text: "Ver contrato",
            className: 'popup-button',
            value: true,
          },
        },
      }).then(res => {
        if (res) setValues(isBetweenAlta)
      })
      return false
    }
    const fechaBajaValida = Boolean(values.fechaBaja) && moment(values.fechaBaja).isBefore(moment(values.fechaAlta), "day")
    if (fechaBajaValida) {
      swal({
        title: "Error",
        text: `Fecha de baja inferior a fecha de alta`,
        icon: "error",
        buttons: {
          salir: {
            text: "Vale",
            className: 'popup-button',
          },
        },
      })
      return false
    }
    return true
  }
  const getContrato = () => {
    axios.post(Global.url + 'getpdfcontrato', values, { ...props.token, ["responseType"]: 'arraybuffer' }).then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' })
      const parad = props.parad.find(element => element._id === values.idParad)
      const fileName = `${moment().format("YYYY-MM-DD HHmmss")} CONTRATO ${moment(values.fechaAlta).format("YYYY-MM-DD")} ${parad.dni} ${parad.nombre} ${parad.primerApellido} ${parad.segundoApellido}.pdf`.toUpperCase()
      fs.set(file, "OTROS", fileName)
    })
  }
  React.useEffect(() => {
    if (values._id === undefined) {
      if (moment(sessionStorage.getItem("contratosFecha")).isValid()) setValues({ ...values, ["fechaAlta"]: moment(sessionStorage.getItem("contratosFecha")), ["codOfertaEmpleo"]: sessionStorage.getItem("contratosCod") })
      else handleChange("fechaAlta")(moment())
    }
  }, [])
  const deleteContrato = () => {
    axios.post(Global.url + 'deletecontrato', values, props.token).then(res => {
      if (res.data.status === "error") alert(res.data.message)
      else { props.setState({ open: true, message: res.data.message, severity: res.data.status, contratos: res.data.contratos, edit: false, edittionRow: {} }) }
    })
  }
  React.useEffect(() => {
    let incap = values.incapacidades
    let incapSC = incap.filter(e => e.color === undefined)
    if (incapSC.length > 0) {
      incapSC.forEach((x, i) => {
        let res = incap[incap.indexOf(incapSC[i])]
        if (res.causa !== undefined) {
          let color = "#fff"
          if (res.causa === "EC" || res.causa === "ANL") color = "#e83f40"
          if (res.causa === "EP" || res.causa === "AL") color = "#7b0b07"
          if (res.causa === "IJ" || res.causa === "INJ") color = "#6f0ad3"
          if (res.causa === "MA" || res.causa === "PA") color = "#110f8c"
          if (res.causa === "V") color = "#c96b44"
          if (res.causa === "H") color = "#235284"
          incap[incap.indexOf(incapSC[i])] = { ...res, color }
        }
      })
      setValues({ ...values, incapacidades: incap })
    }
  }, [values])
  return (
    <div className='popup'>
      <div className="popup-container">
        <IconButton style={{ position: "absolute", top: "10px", right: "20px", padding: "0", zIndex: "30" }} color="primary" onClick={close}>
          <CloseIcon color="primary" fontSize="large" />
        </IconButton>

        <div className="popup-inner">
          <div className="popup-full-height"></div>
          <div className={classes.root}>
            <AppBar position="static" component="div" elevation={0} style={{ height: "48px", backgroundColor: "#eee", float: "left", flexDirection: "row", justifyContent: "flex-start" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                    Datos generales
                  </Button>
                }
                  {...a11yProps(0)} className={classes.tab} />
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faUserInjured} style={{ marginRight: "7px" }} />
                    Incapacidades o ausencias
                  </Button>
                }
                  {...a11yProps(1)} className={classes.tab} />
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faUserInjured} style={{ marginRight: "7px" }} />
                    Conceptos
                  </Button>
                }
                  {...a11yProps(2)} className={classes.tab} />
              </Tabs>

            </AppBar>
            <TabPanel value={value} index={0} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>
                  <Grid item xs={12}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Typography variant="body1" component="h2">Datos trabajador</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8} md={4}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Autocomplete
                        options={props.parad}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findName(values.idParad, props.parad)}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setParad(value)
                        }}
                        getOptionLabel={(option) => `${option.primerApellido} ${option.segundoApellido}, ${option.nombre}`}
                        renderOption={(option) => (
                          <React.Fragment>{`${option.primerApellido} ${option.segundoApellido}, ${option.nombre}`}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Nombre"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={8} sm={4} md={2}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Autocomplete
                        options={props.parad}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findDNI(values.idParad, props.parad)}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setParad(value)
                        }}
                        getOptionLabel={(option) => `${option.dni}`}
                        renderOption={(option) => (
                          <React.Fragment>{`${option.dni}`}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="DNI"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item>
                    <Paper className={classes.paper}>
                      <TextField
                        label="Edad"
                        value={props.parad.find(element => element._id === values.idParad) ? (Math.trunc(moment().diff(moment(props.parad.find(element => element._id === values.idParad).fechaNacimiento), 'years', true))) + " años" : ""}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Typography variant="body1" component="h2">Alta/Baja</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* FECHA ALTA */}
                    <Paper className={classes.paper}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <KeyboardDatePicker

                          label="Alta S.S."
                          format="dd/MM/yyyy"
                          value={values.fechaAlta}
                          onChange={handleChange('fechaAlta')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          style={{ margin: "0px" }}
                          InputAdornmentProps={{
                            root: {
                              padding: 0,
                              marginBottom: "10px"
                            }
                          }}
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          keyboardIcon={<CalendarTodayIcon style={{ fontSize: 15, marginBottom: "10px" }}></CalendarTodayIcon>}
                        />
                      </MuiPickersUtilsProvider>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* FECHA BAJA */}
                    <Paper className={classes.paper}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <KeyboardDatePicker

                          label="Baja S.S."
                          format="dd/MM/yyyy"
                          value={values.fechaBaja ? values.fechaBaja : null}
                          onChange={handleChange('fechaBaja')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          style={{ margin: "0px" }}
                          InputAdornmentProps={{
                            root: {
                              padding: 0,
                              marginBottom: "10px"
                            }
                          }}
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          keyboardIcon={<CalendarTodayIcon style={{ fontSize: 15, marginBottom: "10px" }}></CalendarTodayIcon>}
                        />
                      </MuiPickersUtilsProvider>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* FECHA FIN DE VACACIONES */}
                    <Paper className={classes.paper}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <KeyboardDatePicker
                          label="Fecha fin vacaciones"
                          format="dd/MM/yyyy"
                          value={values.fechaVND ? values.fechaVND : null}
                          onChange={handleChange('fechaVND')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          style={{ margin: "0px" }}
                          InputAdornmentProps={{
                            root: {
                              padding: 0,
                              marginBottom: "10px"
                            }
                          }}
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          keyboardIcon={<CalendarTodayIcon style={{ fontSize: 15, marginBottom: "10px" }}></CalendarTodayIcon>}
                        />
                      </MuiPickersUtilsProvider>
                    </Paper>
                  </Grid>
                  <Grid item xs={0} sm={12}></Grid>
                  {values.fechaBaja !== null && moment(values.fechaBaja).isValid() &&
                    <Grid item xs={12} sm={8} md={5}>
                      <Paper className={classes.paper} style={{ textAlign: "left" }}>
                        <Autocomplete
                          options={JSON.parse(localStorage.getItem("ZTA_SS_causaAltaBaja")).filter(element => element.CODCAB !== "1")}
                          autoHighlight
                          classes={{
                            option: classes.option,
                            input: classes.inputAutocomplete
                          }}
                          value={findBaja(values.causaBajaSS, JSON.parse(localStorage.getItem("ZTA_SS_causaAltaBaja")).filter(element => element.CODCAB !== "1"))}
                          onChange={(event, value) => {
                            if (value && props.user.rol >= 1) setValues({ ...values, ["causaBajaSS"]: value.CODCAB })
                          }}
                          getOptionLabel={(option) => `${option.CODCAB} - ${option.DESCAB}`}
                          renderOption={(option) => (
                            <React.Fragment>{`${option.CODCAB} - ${option.DESCAB}`}</React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Causa baja S.S."
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          )}
                          PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                        />
                      </Paper>
                    </Grid>
                  }
                  {values.fechaBaja !== null && moment(values.fechaBaja).isValid() &&
                    <Grid item xs={12} sm={8} md={5}>
                      <Paper className={classes.paper} style={{ textAlign: "left" }}>
                        <Autocomplete
                          options={JSON.parse(localStorage.getItem("ZTA_SEPE_causaCese"))}
                          autoHighlight
                          classes={{
                            option: classes.option,
                            input: classes.inputAutocomplete
                          }}
                          value={findCese(values.causaCeseSEPE, JSON.parse(localStorage.getItem("ZTA_SEPE_causaCese")))}
                          onChange={(event, value) => {
                            if (value && props.user.rol >= 1) setValues({ ...values, ["causaCeseSEPE"]: value.CODCC })
                          }}
                          getOptionLabel={(option) => `${option.CODCC} - ${option.DESCC}`}
                          renderOption={(option) => (
                            <React.Fragment>{`${option.CODCC} - ${option.DESCC}`}</React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Causa cese SEPE"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            />
                          )}
                          PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                        />
                      </Paper>
                    </Grid>
                  }

                  <Grid item xs={12}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Typography variant="body1" component="h2">Datos obra</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Autocomplete
                        options={props.obra}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findDenom(values.idObra, props.obra)}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setObra(value)
                        }}
                        getOptionLabel={(option) => `${option.denom}`}
                        renderOption={(option) => (
                          <React.Fragment>{`${option.denom}`}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Denominación"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Autocomplete
                        options={props.obra}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findExp(values.idObra, props.obra)}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setObra(value)
                        }}
                        getOptionLabel={(option) => `${option.exped}`}
                        renderOption={(option) => (
                          <React.Fragment>{`${option.exped}`}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cod. Expediente"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Typography variant="body1" component="h2">Tabla salarial asociada al contrato</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Autocomplete
                        options={props.ts}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findNameConvenio(values.idTs, props.ts)}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setTS(value)
                        }}
                        getOptionLabel={(option) => `${option.nombreConvenio}`}
                        renderOption={(option) => (
                          <React.Fragment>{`${option.nombreConvenio}`}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Convenio"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Autocomplete
                        options={props.ts}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findCod(values.idTs, props.ts)}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) handleChange("idTs")(value._id)
                        }}
                        getOptionLabel={(option) => `${option.codigoConvenio}`}
                        renderOption={(option) => (
                          <React.Fragment>{`${option.codigoConvenio}`}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Código"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Typography variant="body1" component="h2">Contrato</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Paper className={classes.paper} style={{ textAlign: "left" }}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_claveContrato"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findClaveContrato(values.claveContrato, JSON.parse(localStorage.getItem("ZTA_claveContrato")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) handleChange("claveContrato")(value.CODCC)
                        }}
                        getOptionLabel={(option) => `${option.CODCC} - ${option.DESCC}`}
                        renderOption={(option) => (
                          <React.Fragment><span style={{ fontSize: "110%", whiteSpace: "nowrap" }}>{`${option.CODCC} -`}</span> {`${option.DESCC}`}</React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Clave contrato"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={5} md={3}>
                    <Paper className={classes.paper}>
                      <TextField
                        label="Cod. Oferta de Empleo"
                        value={values.codOfertaEmpleo}
                        fullWidth
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatCodOfertaEmpleo
                        }}
                        onChange={(e) => handleChange('codOfertaEmpleo')(e.target.value)}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>
                  <Grid xs={12} sm={12} md={8}>
                    <Paper className={classes.paper} style={{ textAlign: "left", marginTop: "20px" }}>
                      <MaterialTable
                        title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Incapacidades/Ausencias</Typography>}
                        icons={Icons}
                        columns={[
                          {
                            title: 'Causa',
                            field: 'causa',
                            lookup: Global.ausencias,
                            render: rowData => <>
                              <svg height="8" width="8" style={{ marginRight: "5px" }}>
                                <circle cx="4" cy="4" r="4" fill={rowData.color} />
                              </svg>
                              <span>{Global.ausencias[rowData.causa]}</span>
                            </>
                          },
                          {
                            title: 'Inicio',
                            field: 'inicio',
                            type: "date",
                            align: "center",
                            defaultSort: 'desc',
                            customSort: (a, b) => moment(a.inicio).diff(moment(b.inicio)),
                            render: rowData => <span >{moment(rowData.inicio).format("DD/MM/YYYY")}</span>,
                            editComponent: props => (
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableFuture={true}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  value={props.value}
                                  onChange={(date) => props.onChange(date)}
                                  InputAdornmentProps={{
                                    style: { display: "none" }
                                  }}
                                  InputProps={{
                                    className: classes.input,
                                    style: { height: "30px" },
                                  }}
                                  maxDateMessage="Futuros solo permitidos en ausencias"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  fullWidth
                                  disableFuture={props.rowData.causa !== "IJ" && props.rowData.causa !== "INJ"}
                                />
                              </MuiPickersUtilsProvider>
                            )
                          },
                          {
                            title: 'Final',
                            field: 'final',
                            type: "date",
                            align: "center",
                            initialEditValue: null,
                            customSort: (a, b) => moment(a.final).diff(moment(b.final)),
                            render: rowData => <span >{moment(rowData.final).isValid() ? moment(rowData.final).format("DD/MM/YYYY") : "Sin establecer"}</span>,
                            editComponent: props => (
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableFuture={true}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  value={props.value}
                                  onChange={(date) => props.onChange(date)}
                                  InputAdornmentProps={{
                                    style: { display: "none" }
                                  }}
                                  InputProps={{
                                    className: classes.input,
                                    style: { height: "30px" }
                                  }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  fullWidth
                                  disableFuture={false}
                                />
                              </MuiPickersUtilsProvider>
                            )
                          },
                          {
                            title: 'Descontar', field: 'diasCompletos',
                            align: "left", initialEditValue: true,
                            sorting: false,
                            lookup: { true: "Días completos", false: "Porcentaje" },
                            editComponent: props => {
                              if ((props.rowData.causa === "IJ" || props.rowData.causa === "INJ") && moment(props.rowData.inicio).isValid() && moment(props.rowData.final).isValid() && moment(props.rowData.inicio).isSame(props.rowData.final, 'day')) return (
                                <FormControl>
                                  <Select
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                    className={classes.input}
                                    style={{ height: "30px" }}
                                  >
                                    <MenuItem value={true}>Días completos</MenuItem>
                                    <MenuItem value={false}>Porcentaje</MenuItem>
                                  </Select>
                                </FormControl>)
                              else {
                                if (props.value !== true && props.value !== "true") props.onChange(true)
                                return (<span>Días completos</span>)
                              }
                            },
                          },
                          {
                            title: '% Jornada', field: 'horas',
                            align: "left", type: "numeric",
                            sorting: false,
                            render: rowData => (rowData.diasCompletos !== "false" && rowData.diasCompletos !== false) ? "" : rowData.horas,
                            editComponent: props =>
                              <TextField
                                value={props.value}
                                onChange={(e) => props.onChange(e.target.value)}
                                InputProps={{
                                  className: classes.input,
                                  inputComponent: Global.formatPor,
                                  style: { height: "30px" }
                                }}
                                disabled={props.rowData.diasCompletos !== "false" && props.rowData.diasCompletos !== false}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true
                                }}
                                autoComplete="something-new"
                              />
                            ,
                            initialEditValue: 0
                          },
                          {
                            title: 'Recaida', field: 'recaida',
                            align: "left", lookup: { true: "Sí", false: "No" }, initialEditValue: false,
                            sorting: false,
                            render: rowData => (rowData.causa === "EC" || rowData.causa === "EP" || rowData.causa === "AL" || rowData.causa === "ANL")
                              ? (rowData.recaida === true || rowData.recaida === "true" ? "Sí" : "No")
                              : "",
                            editComponent: props => {
                              if (props.rowData.causa === "EC" || props.rowData.causa === "EP" || props.rowData.causa === "AL" || props.rowData.causa === "ANL") return (
                                <FormControl>
                                  <Select
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                    className={classes.input}
                                    style={{ height: "30px" }}
                                  >
                                    <MenuItem value={true}>Sí</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                  </Select>
                                </FormControl>)
                              else {
                                if (props.value !== false && props.value !== "false") props.onChange(false)
                                return (<span>No</span>)
                              }
                            },
                          },
                          {
                            title: 'Periodo anterior', field: 'periodoAnteriorRecaida',
                            align: "left", type: "numeric", initialEditValue: 0,
                            sorting: false,
                            render: rowData => (rowData.recaida === true || rowData.recaida === "true")
                              ? rowData.periodoAnteriorRecaida
                              : "",
                            editComponent: props => {
                              if (!(props.rowData.causa === "EC" || props.rowData.causa === "EP" || props.rowData.causa === "AL" || props.rowData.causa === "ANL") || (props.rowData.recaida !== true && props.rowData.recaida !== "true")) {
                                if (props.value !== 0 && props.value !== "0") props.onChange(0)
                              }
                              return (
                                <TextField
                                  value={props.value}
                                  onChange={(e) => props.onChange(e.target.value)}
                                  InputProps={{
                                    className: classes.input,
                                    inputComponent: Global.formatNumberInt,
                                    style: { height: "30px" }
                                  }}
                                  disabled={!(props.rowData.causa === "EC" || props.rowData.causa === "EP" || props.rowData.causa === "AL" || props.rowData.causa === "ANL") || (props.rowData.recaida !== true && props.rowData.recaida !== "true")}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  autoComplete="something-new"
                                />)
                            },
                          },
                          {
                            title: 'Carencia', field: 'carencia',
                            align: "left", lookup: { true: "Sí", false: "No" }, initialEditValue: true,
                            sorting: false,
                            render: rowData => (rowData.causa === "EC")
                              ? (rowData.carencia === true || rowData.carencia === "true" ? "Sí" : "No")
                              : "",
                            editComponent: props => {
                              if (props.rowData.causa === "EC") return (
                                <FormControl>
                                  <Select
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                    className={classes.input}
                                    style={{ height: "30px" }}
                                  >
                                    <MenuItem value={true}>Sí</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                  </Select>
                                </FormControl>)
                              else {
                                if (props.value !== false && props.value !== "false") props.onChange(false)
                                return (<span>No</span>)
                              }
                            },
                          },
                          {
                            title: "Observaciones", field: "observaciones",
                            sorting: false,
                            editComponent: props => (
                              <TextField
                                value={props.value}
                                onChange={(e) => props.onChange(e.target.value)}
                                InputProps={{
                                  className: classes.input,
                                  style: { height: "30px" }
                                }}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true
                                }}
                                autoComplete="something-new"
                              />)
                          },
                        ]}
                        data={(query) => new Promise((resolve, reject) => {
                          let res = values.incapacidades;
                          if (query.orderBy) {
                            const field = _.get(query, ['orderBy', 'field']);
                            const customSort = _.get(query, ['orderBy', 'customSort']);
                            if (field) {
                              if (typeof customSort === "function") {
                                res.sort(customSort);
                              } else {
                                res.sort((a, b) => {
                                  const aVal = _.get(a, field.split("."), "");
                                  const bVal = _.get(b, field.split("."), "");
                                  if (aVal > bVal) {
                                    return 1;
                                  }
                                  if (aVal < bVal) {
                                    return -1;
                                  }
                                  return 0;
                                });
                              }
                            }
                            const isDesc = _.get(query, ['orderDirection']) === "desc";
                            if (isDesc) res.reverse();
                          }
                          res = res.slice(query.pageSize * query.page)
                          resolve({
                            data: res,
                            page: query.page,
                            totalCount: values.incapacidades.length,
                          })
                        })
                        }
                        editable={{
                          onRowAdd: (newData) =>
                            new Promise(async (resolve, reject) => {
                              if (!value || props.user.rol < 1) {
                                resolve();
                                return;
                              }
                              const response = await incapacidad.validaIncapacidad(newData, values._id, props.token);
                              if (!response.valid) {
                                swal({
                                  title: response.message,
                                  text: response.longMessage,
                                  icon: "error",
                                  buttons: {
                                    ok: {
                                      text: "Vale",
                                      className: 'popup-button',
                                      value: true,
                                    },
                                  },
                                });
                                reject();
                              } else {
                                setValues(prevState => ({ ...prevState, incapacidades: [...prevState.incapacidades, newData] }));
                                resolve();
                              }
                            }),
                          onRowUpdate: (newData, oldData) =>
                            new Promise(async (resolve, reject) => {
                              if (!value || props.user.rol < 1) {
                                resolve();
                                return;
                              }
                              const response = await incapacidad.validaIncapacidad(newData, values._id, props.token);
                              if (!response.valid) {
                                swal({
                                  title: response.message,
                                  text: response.longMessage,
                                  icon: "error",
                                  buttons: {
                                    ok: {
                                      text: "Vale",
                                      className: 'popup-button',
                                      value: true,
                                    },
                                  },
                                });
                                reject();
                              } else {
                                setValues(prevState => {
                                  const incapacidades = [...prevState.incapacidades];
                                  incapacidades[incapacidades.indexOf(oldData)] = newData;
                                  return { ...prevState, incapacidades }
                                });
                                resolve();
                              }
                            }),
                          onRowDelete: (oldData) =>
                            new Promise(async (resolve, reject) => {
                              if (!(value && props.user.rol >= 1)) {
                                return resolve();
                              }
                              const response = await incapacidad.validaIncapacidad(oldData, values._id, props.token, true);
                              if (!response.valid) {
                                swal({
                                  title: response.message,
                                  text: response.longMessage,
                                  icon: "error",
                                  buttons: {
                                    ok: {
                                      text: "Vale",
                                      className: 'popup-button',
                                      value: true,
                                    },
                                  },
                                });
                                return reject();
                              }
                              setValues(prevState => {
                                const data = [...prevState.incapacidades];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, ["incapacidades"]: data };
                              });
                              resolve();
                            }),
                        }}
                        options={{
                          pageSize: 8,
                          search: false,
                          pageSizeOptions: [8, 15, 20],
                          headerStyle: {
                            color: 'rgba(0, 0, 0, 0.54)'
                          },
                          rowStyle: {
                            color: 'rgba(0, 0, 0, 0.54)'
                          },
                        }}
                        localization={{
                          pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas'
                          },
                          toolbar: {
                            nRowsSelected: '{0} row(s) seleccionada',
                            searchPlaceholder: 'Buscar',
                            searchTooltip: 'Buscar',
                          },
                          header: {
                            actions: 'Acciones'
                          },
                          body: {
                            emptyDataSourceMessage: 'Sin datos',
                            filterRow: {
                              filterTooltip: 'Filtrar'
                            },
                            addTooltip: "Nueva fila",
                            deleteTooltip: "Eliminar fila",
                            editTooltip: "Editar fila",
                            editRow: {
                              deleteText: "¿Estás seguro de eliminar la fila?",
                              cancelTooltip: "Cancelar",
                              saveTooltip: "Guardar"
                            },
                          },

                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid xs={12} sm={0} md={4}>
                    <Paper className={classes.paper} style={{ textAlign: "center", marginTop: "20px" }}>
                      <DateRange
                        onChange={() => { }}
                        showSelectionPreview={false}
                        moveRangeOnFirstSelection={false}
                        editableDateInputs={false}
                        dragSelectionEnabled={false}
                        shownDate={moment().toDate()}
                        showDateDisplay={false}
                        showPreview={false}
                        months={1}
                        rangeColors={Array.from(values.incapacidades, x => x.color)}
                        scroll={{ enabled: false }}
                        endDatePlaceholder="Sin establecer"
                        locale={es}
                        ranges={Array.from(values.incapacidades, x => { return { startDate: moment(x.inicio).toDate(), endDate: moment(x.final).toDate(), key: Global.ausencias[x.causa] } })}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2} component={'div'}>
              <ContratosConceptos contrato={values} setContrato={setValues} user={props.user} />
            </TabPanel>
          </div>
          {props.user.rol >= 0 && values._id &&
            <IconButton style={{ display: "flex", flexDirection: "column", padding: "0" }} color="secondary" className={classes.buttonGenerete}
              onClick={() => {
                getContrato()
              }}>
              <GetAppIcon color="inherit" />
              <div style={{ fontSize: "1rem" }}>
                Generar contrato
              </div>
            </IconButton>
          }
          {props.user.rol >= 1 && values._id &&
            <IconButton style={{ display: "flex", flexDirection: "column", padding: "0", color: "red" }} className={classes.buttonDelete} onClick={() => {
              swal({
                title: "¿Desea eliminar el contrato?",
                text: "Los cambios son definitivos y no lo podrás recuperar",
                icon: "warning",
                buttons: {
                  salir: {
                    text: "Cancelar",
                    className: 'popup-button',
                    value: false,
                  },
                  ok: {
                    text: "Eliminar",
                    value: true,
                    className: 'popup-button-red',
                  },
                },
              }).then(res => {
                if (res) deleteContrato()
              })
            }}>
              <DeleteIcon color="inherit" />
              <div style={{ fontSize: "1rem" }}>
                Eliminar
              </div>
            </IconButton>
          }
          {props.user.rol >= 1 &&
            <IconButton style={{ display: "flex", flexDirection: "column", padding: "0" }} className={classes.buttonSave} color="primary" onClick={save}>
              <SaveIcon color="primary" />
              <div style={{ fontSize: "1rem" }}>
                Guardar
              </div>
            </IconButton>
          }
        </div>
      </div>
    </div >
  );
}