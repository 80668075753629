import db from './idb-keyval-iife';

var fs = (function (exports) {
  'use strict';
  function isComp() {
    return 'showDirectoryPicker' in window;
  };
  async function set(data, directory, fileName, resolve) {
    const fileHandle = await db.get(directory)
    var permisos = await verifyPermission(fileHandle)
    if (!isComp() || fileHandle === undefined || !permisos) {
      var a = document.createElement('a')
      a.href = URL.createObjectURL(data)
      a.download = fileName
      a.click()
      a.remove()
      if (resolve) resolve()
    } else {
      const doc = await fileHandle.getFileHandle(fileName, { create: true })
      const writable = await doc.createWritable();
      await writable.write(data);
      await writable.close();
      if (resolve) resolve(directory)
      else alert(`Archivos descargados en ${directory}`)
    }
  };
  async function verifyPermission(fileHandle, readWrite = true) {
    if (fileHandle === undefined) return false
    const opts = {};
    if (readWrite) {
      opts.mode = 'readwrite';
    }
    // Check if permission was already granted. If so, return true.
    if ((await fileHandle.queryPermission(opts)) === 'granted') {
      return true;
    }
    // Request permission. If the user grants permission, return true.
    if ((await fileHandle.requestPermission(opts)) === 'granted') {
      return true;
    }
    // The user didn't grant permission, so return false.
    return false;
  }
  function defaultDirs() {
    return {
      SILTRA: {
        BC: ["XECR"],
        AFI: ['RED', 'VIPTC2', 'AFI'],
        CRA: ['RED', 'VIPTC2', 'CRA'],
        OTROS: ['GRUPOH']
      },

    };
  };
  function get() {
    return {
      SILTRA: {
        BC: ["XECR"],
        AFI: ['RED', 'VIPTC2', 'AFI'],
        CRA: ['RED', 'VIPTC2', 'CRA'],
        OTROS: ['GRUPOH']
      },

    };
  };

  exports.isComp = isComp;
  exports.verifyPermission = verifyPermission;
  exports.defaultDirs = defaultDirs;
  exports.get = get;
  exports.set = set;

  return exports;

}({}));
export default fs
