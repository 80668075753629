import React from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import swal from 'sweetalert';
import axios from 'axios'
import Global from '../Global'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDisease, faUserInjured } from '@fortawesome/free-solid-svg-icons'
import Icons from '../components/Icons'
import Typography from '@material-ui/core/Typography'
import MaterialTable from 'material-table'
import { DragHandleOutlined } from '@material-ui/icons';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  rootTwo: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  option: {
    fontSize: "0.8rem",
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputSelect: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputAutocomplete: {
    height: 3,
    fontSize: "0.8rem",
  },
  inputAutocompleteTable: {
    height: 17,
    fontSize: "13px",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tab: {
    height: "30px",
    marginTop: "0px",
    fontSize: "0.7rem",
  },
  tabs: {
    height: "48px"
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    padding: "0",
    zIndex: "30"
  },
  tabButton: {
    '&:hover': {
      backgroundColor: 'initial !important'
    },
    fontSize: 'inherit',
  },
}));
export default function InputAdornments(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState(props.data);
  const [value, setValue] = React.useState(0);
  const [errorID, setErrorID] = React.useState(false);
  const [errorIDText, setErrorIDText] = React.useState("");
  const columns = [
    {
      title: 'Periodo nº',
      field: 'id',
      type: 'numeric',
      defaultSort: 'asc',
      align: "center",
      editComponent: (props) =>
        <TextField
          label=" "
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{
            className: classes.input,
            inputComponent: Global.formatNumberInt
          }}
          placeholder={props.columnDef.title}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          autoComplete="something-new"
        />
    },
    {
      title: 'Nº de días del periodo',
      field: 'periodo',
      align: "center",
      editComponent: (props) =>
        <TextField
          label=" "
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{
            className: classes.input,
            inputComponent: Global.formatNumberInt
          }}
          placeholder={props.columnDef.title}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          autoComplete="something-new"
        />
    },
    {
      title: '% Pagado por la empresa',
      field: 'empresa',
      align: "center",
      render: rowData => Global.roundPor(rowData.empresa),
      editComponent: (props) =>
        <TextField
          label=" "
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{
            className: classes.input,
            inputComponent: Global.formatPor
          }}
          placeholder={props.columnDef.title}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          autoComplete="something-new"
        />
    },
    {
      title: '% Pagado por la SS',
      field: 'ss',
      align: "center",
      render: rowData => Global.roundPor(rowData.ss),
      editComponent: (props) =>
        <TextField
          label=" "
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{
            className: classes.input,
            inputComponent: Global.formatPor
          }}
          placeholder={props.columnDef.title}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          autoComplete="something-new"
        />
    },
    {
      title: 'Completar hasta',
      field: 'completar',
      align: "center",
      render: rowData => Global.roundPor(rowData.completar),
      editComponent: (props) =>
        <TextField
          label=" "
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{
            className: classes.input,
            inputComponent: Global.formatPor
          }}
          placeholder={props.columnDef.title}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          autoComplete="something-new"
        />
    },
  ]
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (prop) => (value) => {
    if (props.user.rol >= 1) {
      setValues({ ...values, [prop]: value });
    }
  };
  const saveAndClose = () => {
    axios.post(Global.url + 'updateenfermedad', values, props.token).then(res => {
      if (res.data.status === "error") alert(res.data.message)
      else { props.setState({ open: true, message: res.data.message, severity: res.data.status, enfermedad: res.data.enfermedad }) }
    })
  }
  const save = () => {
    const info = Global.validateTF(values)
    if (info.obligatorios.length === 0 && !errorID) {
      saveAndClose()
    } else {
      swal({
        title: "Faltan campos obligatorios por rellenar",
        text: "Faltan: " + info.obligatorios.join(', '),
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
    }
  }
  const close = () => {
    if (props.user.rol >= 1) {
      const info = Global.validateTF(values, props.data)
      if (info.obligatorios.length > 0) {
        swal({
          title: "Faltan campos obligatorios por rellenar",
          text: "Faltan: " + info.obligatorios.join(', ') + ".",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Vale",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (!res && info.equal) props.close()
          else if (!res && !info.equal && values.idAyun && !errorID) {
            swal({
              title: "¿Desea salir sin guardar?",
              icon: "warning",
              buttons: {
                salir: {
                  text: "Salir",
                  value: false,
                  className: 'popup-button-red',
                },
                ok: {
                  text: "Guardar",
                  className: 'popup-button',
                  value: true,
                },
              },
            }).then(res => {
              if (res) saveAndClose()
              else props.close()
            })
          } else if (!values.idAyun && !res) {
            props.close()
          }
        })
      } else if (info.obligatorios.length === 0 && !info.equal && !errorID) {
        swal({
          title: "¿Desea salir sin guardar?",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Guardar",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (res) saveAndClose()
          else props.close()
        })
      } else {
        props.close()
      }
    } else {
      props.close()
    }
  }
  const handleID = () => {
    const query = props.allData.sort((a, b) => b.id - a.id)
    var nextID = 1;
    if (query.length > 0) {
      nextID = query[0].id + 1
    }
    if (values.id) {
      if (props.allData.filter(element => Number(element.id) === Number(values.id) && element._id !== values._id).length > 0) return { valid: false, nextID }
      else return { valid: true, nextID }
    } else {
      return { valid: true, nextID }
    }
  }
  React.useEffect(() => {
    if (handleID().valid) {
      setErrorID(false)
      setErrorIDText("")
    } else {
      setErrorID(true)
      setErrorIDText("ID ya existente")
    }
  }, [values, props.allData])
  React.useEffect(() => {
    if (!values.id) {
      handleChange("id")(handleID().nextID)
    } else {
      if (handleID().valid) {
        setErrorID(false)
        setErrorIDText("")
      } else {
        setErrorID(true)
        setErrorIDText("ID ya existente")
      }
    }
  }, [])
  return (
    <div className='popup'>
      <div className="popup-container">
        <IconButton style={{ position: "absolute", top: "10px", right: "20px", padding: "0", zIndex: "30" }} color="primary" onClick={close}>
          <CloseIcon color="primary" fontSize="large" />
        </IconButton>

        <div className="popup-inner">
          <div className="popup-full-height"></div>
          <div className={classes.root}>
            <AppBar position="static" component="div" elevation={0} style={{ height: "48px", backgroundColor: "#eee", float: "left", flexDirection: "row", justifyContent: "flex-start" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faUserInjured} style={{ marginRight: "7px" }} />
                        Accidente
                      </Button>
                }
                  {...a11yProps(0)} className={classes.tab} />
                <Tab
                  label={
                    <Button color='inherit' className={classes.tabButton}>
                      <FontAwesomeIcon icon={faDisease} style={{ marginRight: "7px" }} />
                        Enfermedad
                  </Button>
                  }
                  {...a11yProps(1)} className={classes.tab} />
              </Tabs>

            </AppBar>
            <TabPanel value={value} index={0} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>
                  <Grid item xs={6} sm={3} md={1}> {/* id */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="ID"
                        value={values.id}
                        error={errorID}
                        helperText={errorIDText}
                        onChange={(e) => handleChange('id')(e.target.value)}
                        defaultValue={props.data.nombre}
                        fullWidth
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatNumberInt
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* nombre */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Descripción"
                        id="nombre"
                        value={values.nombre}
                        onChange={(e)=>handleChange('nombre')(e.target.value)}
                        defaultValue={props.data.nombre}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}> {/* accidenteIndemnizacion */}
                    <Paper className={classes.paper}>
                      <MaterialTable
                        title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Accidente</Typography>}
                        icons={Icons}
                        columns={columns}
                        data={values.accidenteIndemnizacion}
                        editable={{
                          onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                if (String(newData.periodo) && String(newData.empresa) && String(newData.ss) && String(newData.completar)) {
                                  if (props.user.rol >= 1) {
                                    var array = values.accidenteIndemnizacion
                                    var nextID = 1
                                    if (array.length > 0) nextID = array.sort((a, b) => b.id - a.id)[0]["id"] + 1
                                    var mayor = array.filter(element => Number(element.id) >= Number(newData.id))
                                    if (mayor.length > 0) {
                                      mayor.forEach(element => {
                                        array[array.indexOf(element)] = { ...array[array.indexOf(element)], ["id"]: Number(array[array.indexOf(element)].id) + 1 }
                                      });
                                      if(Number(newData.id) === 0){
                                        array.push({ ...newData, ["id"]:1 })
                                      }else{
                                        array.push({ ...newData})
                                      }
                                      handleChange("accidenteIndemnizacion")(array)
                                      resolve()
                                    } else {
                                      array.push({ ...newData, ["id"]: nextID })
                                      handleChange("accidenteIndemnizacion")(array)
                                      resolve()
                                    }
                                  }
                                  else resolve();
                                } else {
                                  reject()
                                  swal({
                                    title: "Rellene todos los campos",
                                    icon: "warning",
                                    buttons: {
                                      ok: {
                                        text: "Vale",
                                        className: 'popup-button',
                                        value: true,
                                      },
                                    },
                                  })
                                }
                              }, 600);
                            }),
                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                resolve()
                                if (String(newData.periodo) && String(newData.empresa) && String(newData.ss) && String(newData.completar)) {
                                  if (props.user.rol >= 1) {
                                    var array = values.accidenteIndemnizacion
                                    array.splice(array.indexOf(oldData),1)
                                    var nextID = 1
                                    if (array.length > 0) nextID = array.sort((a, b) => b.id - a.id)[0]["id"] + 1
                                    var mayor = array.filter(element => Number(element.id) >= Number(newData.id))
                                    if (mayor.length > 0) {
                                      mayor.forEach(element => {
                                        array[array.indexOf(element)] = { ...array[array.indexOf(element)], ["id"]: Number(array[array.indexOf(element)].id) + 1 }
                                      });
                                      if(Number(newData.id) === 0){
                                        array.push({ ...newData, ["id"]:1 })
                                      }else{
                                        array.push({ ...newData})
                                      }
                                      handleChange("accidenteIndemnizacion")(array)
                                      resolve()
                                    } else {
                                      array.push({ ...newData, ["id"]: nextID })
                                      handleChange("accidenteIndemnizacion")(array)
                                      resolve()
                                    }
                                  }
                                  else resolve();
                                } else {
                                  reject()
                                  swal({
                                    title: "Rellene todos los campos",
                                    icon: "warning",
                                    buttons: {
                                      ok: {
                                        text: "Vale",
                                        className: 'popup-button',
                                        value: true,
                                      },
                                    },
                                  })
                                }
                              }, 600);
                            }),
                          onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                resolve()
                                if (props.user.rol >= 1) {
                                  var array = values.accidenteIndemnizacion
                                  array.splice(array.indexOf(oldData),1)
                                  var mayor = array.filter(element => Number(element.id) >= Number(oldData.id))
                                  if (mayor.length > 0) {
                                    mayor.forEach(element => {
                                      array[array.indexOf(element)] = { ...array[array.indexOf(element)], ["id"]: Number(array[array.indexOf(element)].id) - 1 }
                                    });
                                    handleChange("accidenteIndemnizacion")(array)
                                    resolve()
                                  } else {
                                    handleChange("accidenteIndemnizacion")(array)
                                    resolve()
                                  }
                                }
                                else resolve();
                              }, 600);
                            }),
                        }}
                        options={{
                          pageSize: 5,
                          search: false,
                          pageSizeOptions: [5, 10, 15, 20],
                          headerStyle: {
                            color: 'rgba(0, 0, 0, 0.54)'
                          },
                          rowStyle: {
                            color: 'rgba(0, 0, 0, 0.54)'
                          },
                        }}
                        localization={{
                          pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas'
                          },
                          toolbar: {
                            nRowsSelected: '{0} row(s) seleccionada',
                            searchPlaceholder: 'Buscar',
                            searchTooltip: 'Buscar',
                          },
                          header: {
                            actions: 'Acciones'
                          },
                          body: {
                            emptyDataSourceMessage: 'Sin datos',
                            filterRow: {
                              filterTooltip: 'Filtrar'
                            },
                            addTooltip: "Nueva fila",
                            deleteTooltip: "Eliminar fila",
                            editTooltip: "Editar fila",
                            editRow: {
                              deleteText: "¿Estás seguro de eliminar la fila?",
                              cancelTooltip: "Cancelar",
                              saveTooltip: "Guardar"
                            },
                          },

                        }}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>
                <Grid item xs={12}> {/* enfermedadIndemnizacion */}
                    <Paper className={classes.paper}>
                      <MaterialTable
                        title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Enfermedad</Typography>}
                        icons={Icons}
                        columns={columns}
                        data={values.enfermedadIndemnizacion}
                        editable={{
                          onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                if (String(newData.periodo) && String(newData.empresa) && String(newData.ss) && String(newData.completar)) {
                                  if (props.user.rol >= 1) {
                                    var array = values.enfermedadIndemnizacion
                                    var nextID = 1
                                    if (array.length > 0) nextID = array.sort((a, b) => b.id - a.id)[0]["id"] + 1
                                    var mayor = array.filter(element => Number(element.id) >= Number(newData.id))
                                    if (mayor.length > 0) {
                                      mayor.forEach(element => {
                                        array[array.indexOf(element)] = { ...array[array.indexOf(element)], ["id"]: Number(array[array.indexOf(element)].id) + 1 }
                                      });
                                      if(Number(newData.id) === 0){
                                        array.push({ ...newData, ["id"]:1 })
                                      }else{
                                        array.push({ ...newData})
                                      }
                                      handleChange("enfermedadIndemnizacion")(array)
                                      resolve()
                                    } else {
                                      array.push({ ...newData, ["id"]: nextID })
                                      handleChange("enfermedadIndemnizacion")(array)
                                      resolve()
                                    }
                                  }
                                  else resolve();
                                } else {
                                  reject()
                                  swal({
                                    title: "Rellene todos los campos",
                                    icon: "warning",
                                    buttons: {
                                      ok: {
                                        text: "Vale",
                                        className: 'popup-button',
                                        value: true,
                                      },
                                    },
                                  })
                                }
                              }, 600);
                            }),
                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                resolve()
                                if (String(newData.periodo) && String(newData.empresa) && String(newData.ss) && String(newData.completar)) {
                                  if (props.user.rol >= 1) {
                                    var array = values.enfermedadIndemnizacion
                                    array.splice(array.indexOf(oldData),1)
                                    var nextID = 1
                                    if (array.length > 0) nextID = array.sort((a, b) => b.id - a.id)[0]["id"] + 1
                                    var mayor = array.filter(element => Number(element.id) >= Number(newData.id))
                                    if (mayor.length > 0) {
                                      mayor.forEach(element => {
                                        array[array.indexOf(element)] = { ...array[array.indexOf(element)], ["id"]: Number(array[array.indexOf(element)].id) + 1 }
                                      });
                                      if(Number(newData.id) === 0){
                                        array.push({ ...newData, ["id"]:1 })
                                      }else{
                                        array.push({ ...newData})
                                      }
                                      handleChange("enfermedadIndemnizacion")(array)
                                      resolve()
                                    } else {
                                      array.push({ ...newData, ["id"]: nextID })
                                      handleChange("enfermedadIndemnizacion")(array)
                                      resolve()
                                    }
                                  }
                                  else resolve();
                                } else {
                                  reject()
                                  swal({
                                    title: "Rellene todos los campos",
                                    icon: "warning",
                                    buttons: {
                                      ok: {
                                        text: "Vale",
                                        className: 'popup-button',
                                        value: true,
                                      },
                                    },
                                  })
                                }
                              }, 600);
                            }),
                          onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                resolve()
                                if (props.user.rol >= 1) {
                                  var array = values.enfermedadIndemnizacion
                                  array.splice(array.indexOf(oldData),1)
                                  var mayor = array.filter(element => Number(element.id) >= Number(oldData.id))
                                  if (mayor.length > 0) {
                                    mayor.forEach(element => {
                                      array[array.indexOf(element)] = { ...array[array.indexOf(element)], ["id"]: Number(array[array.indexOf(element)].id) - 1 }
                                    });
                                    handleChange("enfermedadIndemnizacion")(array)
                                    resolve()
                                  } else {
                                    handleChange("enfermedadIndemnizacion")(array)
                                    resolve()
                                  }
                                }
                                else resolve();
                              }, 600);
                            }),
                        }}
                        options={{
                          pageSize: 5,
                          search: false,
                          pageSizeOptions: [5, 10, 15, 20],
                          headerStyle: {
                            color: 'rgba(0, 0, 0, 0.54)'
                          },
                          rowStyle: {
                            color: 'rgba(0, 0, 0, 0.54)'
                          },
                        }}
                        localization={{
                          pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas'
                          },
                          toolbar: {
                            nRowsSelected: '{0} row(s) seleccionada',
                            searchPlaceholder: 'Buscar',
                            searchTooltip: 'Buscar',
                          },
                          header: {
                            actions: 'Acciones'
                          },
                          body: {
                            emptyDataSourceMessage: 'Sin datos',
                            filterRow: {
                              filterTooltip: 'Filtrar'
                            },
                            addTooltip: "Nueva fila",
                            deleteTooltip: "Eliminar fila",
                            editTooltip: "Editar fila",
                            editRow: {
                              deleteText: "¿Estás seguro de eliminar la fila?",
                              cancelTooltip: "Cancelar",
                              saveTooltip: "Guardar"
                            },
                          },

                        }}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
          </div>
          {props.user.rol >= 1 &&
            <IconButton disabled={errorID} style={{ display: "flex", flexDirection: "column", padding: "0" }} className={classes.buttonSave} color="primary" onClick={save}>
              <SaveIcon color="primary" />
              <div style={{ fontSize: "1rem" }}>
          {errorID ?<span style={{ color: "red" }}>ID duplicado</span>:"Guardar"}
              </div>
            </IconButton>
          }
        </div>
      </div>
    </div >
  );
}