import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MaterialTable from '../components/MaterialTablePrimary'
import MaterialTableMenu from '../components/MaterialTableMenu'
import moment from 'moment'

import Edit from './TrabajadoresEdit'
import Contratos from '../gestion/ContratosEdit'
import axios from 'axios'
import Global from '../Global'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Icons from '../components/Icons'
import Typography from '@material-ui/core/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function Trabajadores(props) {
    const classes = useStyles();
    const [columns, setColumnss] = React.useState([
        { title: 'DNI', field: 'dni', hiddenByColumnsButton: true, hidden: false },
        { title: '1º Apellido', field: 'primerApellido', defaultSort: "asc", hiddenByColumnsButton: true, hidden: false },
        { title: '2º Apellido', field: 'segundoApellido', defaultSort: "asc", hiddenByColumnsButton: true, hidden: false },
        { title: 'Nombre', field: 'nombre', defaultSort: "asc", hiddenByColumnsButton: true, hidden: false },
        { title: 'Email', field: 'email', hiddenByColumnsButton: true, hidden: false, ocultar: true },
        { title: 'IBAN', field: 'IBAN', hiddenByColumnsButton: true, hidden: false, ocultar: true },
        { title: 'Teléfono', field: 'telf', hiddenByColumnsButton: true, hidden: false },
        {
            title: 'Fecha nacimiento',
            field: 'fechaNacimiento',
            render: rowData => <span style={{ width: 50, borderRadius: '50%' }}>{moment(rowData.fechaNacimiento).format("DD/MM/YYYY") + " (" + (Math.trunc(moment().diff(moment(rowData.fechaNacimiento), 'years', true))) + " años)"}</span>,
            hiddenByColumnsButton: true,
            hidden: false
        },
        { title: 'Dirección', field: 'domicilio', render: rowData => `${rowData.domsig ? rowData.domsig + "/ " : ""}${rowData.domicilio}${!rowData.domicilio.includes(",") ? ", " + rowData.domnum : ""} ${rowData.domblo ? rowData.domblo : ""} ${rowData.domesc ? rowData.domesc : ""} ${rowData.pis ? rowData.pis : ""} ${rowData.pue ? rowData.pue : ""}`, hiddenByColumnsButton: true, hidden: false, ocultar: true },
    ])
    const [data, setData] = React.useState([])
    const [edit, setEdit] = React.useState(false)
    const [contratos, setContratos] = React.useState(props.edit)
    const [filtering, setFiltering] = React.useState(true)
    const [edittionRow, setEdittionRow] = React.useState()
    const defaultData = {
        postal: props.ayun.cp,
        ciudad: props.ayun.localidad,
        municipio: props.ayun.codigoMunicipio,
        provincia: props.ayun.provincia,
        claveIPF: "1",
        idenimen: "D",
        sitFamilia: "3",
        nacional: "724",
        paisResidencia: "724",
        domsig: "CL",
        nivelForm: "12",
        discapacidad: 0,
        movilidadReducida: false,
        tipoRelacion: "2",
        subclirpf: "A00",
        claveIRPF: "A",
        rirpf: 2.00,
        active: true
    }
    const filter = (data) => {
        if (filtering) return data.filter(element => element.active === true)
        else return data
    }
    React.useEffect(() => {
        setData(filter(props.parad))
    }, [])
    const refreshData = () => {
        setData([])
        axios.get(Global.url + "getworkers", props.token).then(res => {
            props.setState({ parad: res.data.parad, edit:false, edittionRow:{} })
        })
    }
    React.useEffect(() => {
        setData(filter(props.parad))
    }, [filtering])
    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<div color='inherit' style={{ display: "flex", alignItems: "baseline" }}>
                                    <FontAwesomeIcon icon={faUsers} style={{ marginRight: "7px" }} />
                                    <Typography variant="h6">Trabajadores</Typography>
                                </div>}
                                columns={columns}
                                data={data}
                                options={{
                                    pageSize: 25,
                                    pageSizeOptions: [25, 50, 100, 200],
                                }}
                                icons={Icons}
                                actions={[
                                    {
                                        icon: Icons.Add,
                                        isFreeAction: true,
                                        onClick: (event) => {
                                            setEdit(true)
                                            setEdittionRow(defaultData)
                                        }
                                    },
                                    {
                                        icon: Icons.Refresh,
                                        isFreeAction: true,
                                        onClick: () => refreshData(),
                                    },
                                ]}
                                filtrar={true}
                                filtrarOptions={[<FormControlLabel
                                    control={<Checkbox size="small" style={{ padding: 0, paddingRight: "5px" }} checked={filtering} onChange={() => setFiltering(!filtering)} name="checkedA" />}
                                    label={<Typography style={{ fontSize: "0.8rem" }}>Mostrar solo activos</Typography>}
                                    style={{ marginLeft: 0 }}
                                />]}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'filas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} row(s) seleccionada',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        }
                                    }
                                }}
                                onRowClick={(event, rowData) => {
                                    setEdittionRow(rowData)
                                    setEdit(true)
                                }}
                            />

                        </Paper>
                    </Grid>
                </Grid>
            </div>
            {edit &&
                <Edit
                    close={() => setEdit(false)}
                    user={props.user}
                    data={edittionRow}
                    token={props.token}
                    setOpen={props.setOpen}
                    setState={props.setState}
                    ayun={props.ayun}
                    contratos={props.contratos}
                ></Edit>
            }
            {contratos &&
                <Contratos
                    close={() => {
                        setContratos(false)
                        setEdittionRow(data.find(element => element._id === props.edittionRow.idParad))
                        setEdit(true)
                    }}
                    user={props.user}
                    data={props.edittionRow}
                    allData={props.contratos}
                    parad={props.parad}
                    ts={props.ts}
                    obra={props.obra}
                    token={props.token}
                    setOpen={props.setOpen}
                    setState={props.setState}
                    ayun={props.ayun}
                />
            }
        </React.Fragment>
    );
}