import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MaterialTable from '../components/MaterialTablePrimary'

import moment from 'moment'

import Edit from './TurnosEdit'
import Add from './TurnosAdd'
import axios from 'axios'
import Global from '../Global'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper'

import Icons from '../components/Icons'
import Typography from '@material-ui/core/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));
function findDenom(string, array) {
    if (typeof string === 'string') {
        for (var i in array) {
            if (array[i]._id === string) {
                return array[i]
            }
        }
    } else {
        return { denom: "Sin filtro seleccionado" }
    }
}

export default function Trabajadores(props) {
    const classes = useStyles();
    const [columns, setColumns] = React.useState([
        {
            title: 'Código',
            field: "id",
            cellStyle: { width: "10%" },
            hiddenByColumnsButton: true, 
            hidden: false,
        },
        { title: 'Denominación', field: 'denom', render: rowData => props.obra.find(element => element._id === rowData.idObra).denom, hiddenByColumnsButton: true, hidden: false, cellStyle: { width: "30%" } },
        { 
            title: 'Trabajadores', 
        hiddenByColumnsButton: 
        true, hidden: false, 
        cellStyle: { width: "5%" },
    render: rowData => props.contratos.filter(element => element.idTurno === rowData._id).length},
        { title: 'Fecha alta', field: 'fechaAlta', defaultSort: 'desc', render: rowData => <span style={{ width: 50, borderRadius: '50%' }}>{moment(rowData.fechaAlta).format("DD/MM/YYYY")}</span>, hiddenByColumnsButton: true, hidden: false, },
        { title: 'Fecha baja', field: 'fechaBaja', render: rowData => <span style={{ width: 50, borderRadius: '50%' }}>{moment(rowData.fechaBaja).isValid() ? moment(rowData.fechaBaja).format("DD/MM/YYYY") : "No consta"}</span>, hiddenByColumnsButton: true, hidden: false, },
        { title: 'Oferta de empleo', field: 'codOfertaEmpleo', hiddenByColumnsButton: true, hidden: false, ocultar: true },
        {
            title: 'Tipo', field: 'claveContrato',
            render: rowData =>
                JSON.parse(localStorage.getItem("ZTA_claveContrato")).find(element => element.CODCC === rowData.claveContrato) ? JSON.parse(localStorage.getItem("ZTA_claveContrato")).find(element => element.CODCC === rowData.claveContrato).DESCC : "Sin definir",
            hiddenByColumnsButton: true, hidden: false, ocultar: true,
            cellStyle: { width: "30%", fontSize: "0.8rem" }
        },

    ])
    const [data, setData] = React.useState([])
    const [edittionRow, setEdittionRow] = React.useState()
    const [edit, setEdit] = React.useState(false)
    const [add, setAdd] = React.useState(false)
    const [filtering, setFiltering] = React.useState(false)
    const [obraFilter, setObraFilter] = React.useState(null)
    const defaultData = {
        codOfertaEmpleo: "",
    }
    const filter = (data) => {
        var res = data
        if (filtering) res = res.filter(element => element.fechaBaja === null || moment(element.fechaBaja).isAfter(moment()))
        if (obraFilter) res = res.filter(element => element.idObra === obraFilter)
        return res
    }
    React.useEffect(() => {
        setData(filter(props.turnos))
    }, [])
    const refreshData = () => {
        setData([])
        axios.get(Global.url + "getturnos", props.token).then(res => {
            props.setState({ turnos: res.data.turnos, edit:false, edittionRow:{} })
            setData(filter(res.data.turnos))
        })
    }
    React.useEffect(() => {
        setData(filter(props.turnos))
    }, [filtering])
    React.useEffect(() => {
        setData(filter(props.turnos))
    }, [obraFilter])
    React.useEffect(() => {
        if(props.edittionRow){
            if(Object.keys(props.edittionRow).length > 0){
                setEdittionRow(props.edittionRow)
                setEdit(true)
            }
        }
    }, [props.edittionRow])
    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<div color='inherit' style={{ display: "flex", alignItems: "baseline" }}>
                                    <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                                    <Typography variant="h6">Turnos</Typography>
                                </div>}
                                columns={columns}
                                data={data}
                                options={{
                                    pageSize: 25,
                                    pageSizeOptions: [25, 50, 100, 200],
                                }}
                                icons={Icons}
                                actions={[
                                    {
                                        icon: Icons.Add,
                                        isFreeAction: true,
                                        onClick: (event) => {
                                            setAdd(true)
                                            setEdittionRow(defaultData)
                                        }
                                    },
                                    {
                                        icon: Icons.Refresh,
                                        isFreeAction: true,
                                        onClick: () => refreshData(),
                                    },
                                ]}
                                onRowClick={(event, rowData, togglePanel) => {
                                    setEdittionRow(rowData)
                                    setEdit(true)
                                }}
                                filtrar={true}
                                filtrarOptions={[<FormControlLabel
                                    control={<Checkbox size="small" style={{ padding: 0, paddingRight: "5px" }} checked={filtering} onChange={() => setFiltering(!filtering)} name="checkedA" />}
                                    label={<Typography style={{ fontSize: "0.8rem" }}>Mostrar sólo turnos en alta</Typography>}
                                    style={{ marginLeft: 0 }}
                                />,
                                <Autocomplete
                                    options={props.obra}
                                    autoHighlight
                                    fullWidth
                                    value={findDenom(obraFilter, props.obra)}
                                    onChange={(event, value) => {
                                        if (value) setObraFilter(value._id)
                                        else setObraFilter(null)
                                    }}
                                    getOptionLabel={(option) => `${option.denom}`}
                                    renderOption={(option) => (
                                        <React.Fragment>{`${option.denom}`}</React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Denominación"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                    PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                                />]}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'filas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} row(s) seleccionada',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        }
                                    }
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            {edit &&
                <Edit close={() => setEdit(false)} user={props.user} data={edittionRow} turnos={data} allData={props.contratos} parad={props.parad} ts={props.ts} obra={props.obra} token={props.token} setOpen={props.setOpen} setState={props.setState} ayun={props.ayun}></Edit>
            }
            {add &&
                <Add close={() => setAdd(false)} setEdit={(e) => setEdit(e)} user={props.user} data={edittionRow} turnos={data} allData={props.contratos} setEdittionRow={(e) => setEdittionRow(e)} parad={props.parad} ts={props.ts} obra={props.obra} token={props.token} setOpen={props.setOpen} setState={props.setState} ayun={props.ayun}></Add>
            }
        </React.Fragment>
    );
}