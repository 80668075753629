import React from "react";
import { Paper, Grid, Typography, Popper, TextField } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import Icons from '../components/Icons';
import swal from 'sweetalert';
import Global from "../Global";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: 'none',
    }
}));

function findCRA(string, array) {
    if (typeof string === 'string') {
        for (var i in array) {
            if (array[i].COD === string) {
                return array[i]
            }
        }
    } else {
        return { "COD": "", "DES": "Seleccione una opción válida" }
    }
}

export default function ContratosConceptos({ contrato, setContrato, user }) {
    const classes = useStyles();
    return (<Grid container>
        <Grid item xs={12}> {/* conceptos */}
            <Paper className={classes.paper}>
                <MaterialTable
                    title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Conceptos</Typography>}
                    icons={Icons}
                    columns={[
                        {
                            title: 'Nombre',
                            field: 'nombre',
                            align: "center",
                        },
                        {
                            title: 'CRA',
                            field: 'codigoCRA',
                            align: "center",
                            editComponent: (props) =>
                                <Autocomplete
                                    options={JSON.parse(localStorage.getItem("ZTA_CRA"))}
                                    autoHighlight
                                    classes={{
                                        option: classes.option,
                                        input: classes.inputAutocompleteTable
                                    }}
                                    value={findCRA(props.value, JSON.parse(localStorage.getItem("ZTA_CRA")))}
                                    onChange={(event, value) => {
                                        if (value) props.onChange(value.COD)
                                    }}
                                    getOptionLabel={(option) => option.COD + " - " + option.DES}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            <span>{option.COD}&nbsp;-&nbsp;</span>{option.DES}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    )}
                                    PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                                />
                        },
                        { title: 'SS', field: 'SS', align: "center", lookup: { true: "Sí", false: "No" } },
                        { title: 'IRPF', field: 'IRPF', align: "center", lookup: { true: "Sí", false: "No" } },
                        { title: 'Indemnización', field: 'indemnizacion', align: "center", lookup: { true: "Sí", false: "No" } },
                        { title: 'Devengo', field: 'devengo', align: "center", lookup: Global.devengo },
                        {
                            title: 'Importe',
                            field: 'importe',
                            type: 'numeric',
                            align: "center",
                            render: rowData => Global.roundEUR(rowData.importe),
                            editComponent: (props) =>
                                <TextField
                                    label=" "
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                    InputProps={{
                                        className: classes.input,
                                        inputComponent: Global.formatEUR
                                    }}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    autoComplete="something-new"
                                />
                        },
                    ]}
                    data={contrato.conceptos}
                    editable={{
                        onRowAdd: (newData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if (newData.nombre && newData.codigoCRA && Boolean(newData.SS.toString()) && Boolean(newData.IRPF.toString()) && Boolean(newData.indemnizacion.toString()) && newData.devengo && newData.importe) {
                                        if (user.rol >= 1) setContrato(prevState => {
                                            var newD = newData
                                            if (typeof newD.SS === 'string') newD.SS = Boolean(newData.SS.replace("false", ""))
                                            if (typeof newD.IRPF === 'string') newD.IRPF = Boolean(newData.IRPF.replace("false", ""))
                                            if (typeof newD.indemnizacion === 'string') newD.indemnizacion = Boolean(newData.indemnizacion.replace("false", ""))
                                            const table = JSON.parse(localStorage.getItem("ZTA_CRA")).find(element => element.COD === newD.codigoCRA)
                                            if (newD.SS) {
                                                if (table.BBCC === "SI") {
                                                    const data = [...prevState.conceptos];
                                                    data.push(newD);
                                                    resolve()
                                                    return { ...prevState, ["conceptos"]: data };
                                                } else {
                                                    swal({
                                                        title: "SS y CRA incompatibles",
                                                        icon: "error",
                                                        buttons: {
                                                            ok: {
                                                                text: "Vale",
                                                                className: 'popup-button',
                                                                value: true,
                                                            },
                                                        },
                                                    })
                                                    reject()
                                                    return { ...prevState }
                                                }
                                            } else {
                                                if (table.BBCC4 === "SI") {
                                                    const data = [...prevState.conceptos];
                                                    data.push(newD);
                                                    resolve()
                                                    return { ...prevState, ["conceptos"]: data };
                                                } else {
                                                    swal({
                                                        title: "SS y CRA incompatibles",
                                                        icon: "error",
                                                        buttons: {
                                                            ok: {
                                                                text: "Vale",
                                                                className: 'popup-button',
                                                                value: true,
                                                            },
                                                        },
                                                    })
                                                    reject()
                                                    return { ...prevState }
                                                }
                                            }
                                        })
                                        else resolve();
                                    } else {
                                        reject()
                                        swal({
                                            title: "Rellene todos los campos",
                                            icon: "warning",
                                            buttons: {
                                                ok: {
                                                    text: "Vale",
                                                    className: 'popup-button',
                                                    value: true,
                                                },
                                            },
                                        })
                                    }
                                }, 600);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    resolve();
                                    if (newData.nombre && newData.codigoCRA && Boolean(newData.SS.toString()) && Boolean(newData.IRPF.toString()) && Boolean(newData.indemnizacion.toString()) && newData.devengo && newData.importe) {
                                        if (oldData) {
                                            if (user.rol >= 1) setContrato(prevState => {
                                                var newD = newData
                                                if (typeof newD.SS === 'string') newD.SS = Boolean(newData.SS.replace("false", ""))
                                                if (typeof newD.IRPF === 'string') newD.IRPF = Boolean(newData.IRPF.replace("false", ""))
                                                if (typeof newD.indemnizacion === 'string') newD.indemnizacion = Boolean(newData.indemnizacion.replace("false", ""))
                                                const table = JSON.parse(localStorage.getItem("ZTA_CRA")).find(element => element.COD === newD.codigoCRA)
                                                if (newD.SS) {
                                                    if (table.BBCC === "SI") {
                                                        const data = [...prevState.conceptos];
                                                        data[data.indexOf(oldData)] = newD;
                                                        resolve()
                                                        return { ...prevState, ["conceptos"]: data };
                                                    } else {
                                                        swal({
                                                            title: "SS y CRA incompatibles",
                                                            icon: "error",
                                                            buttons: {
                                                                ok: {
                                                                    text: "Vale",
                                                                    className: 'popup-button',
                                                                    value: true,
                                                                },
                                                            },
                                                        })
                                                        reject()
                                                        return { ...prevState }
                                                    }
                                                } else {
                                                    if (table.BBCC4 === "SI") {
                                                        const data = [...prevState.conceptos];
                                                        data[data.indexOf(oldData)] = newD;
                                                        resolve()
                                                        return { ...prevState, ["conceptos"]: data };
                                                    } else {
                                                        swal({
                                                            title: "SS y CRA incompatibles",
                                                            icon: "error",
                                                            buttons: {
                                                                ok: {
                                                                    text: "Vale",
                                                                    className: 'popup-button',
                                                                    value: true,
                                                                },
                                                            },
                                                        })
                                                        reject()
                                                        return { ...prevState }
                                                    }
                                                }
                                            });
                                        }
                                        else resolve()
                                    } else {
                                        reject()
                                        swal({
                                            title: "Rellene todos los campo",
                                            icon: "warning",
                                            buttons: {
                                                ok: {
                                                    text: "Vale",
                                                    className: 'popup-button',
                                                    value: true,
                                                },
                                            },
                                        })
                                    }
                                }, 600);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    if (user.rol >= 1) setContrato(prevState => {
                                        const data = [...prevState.conceptos];
                                        data.splice(data.indexOf(oldData), 1);
                                        return { ...prevState, ["conceptos"]: data };
                                    });
                                }, 600);
                            }),
                    }}
                    options={{
                        pageSize: 5,
                        search: false,
                        pageSizeOptions: [5, 10, 15, 20],
                        headerStyle: {
                            color: 'rgba(0, 0, 0, 0.54)'
                        },
                        rowStyle: {
                            color: 'rgba(0, 0, 0, 0.54)'
                        },
                    }}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas'
                        },
                        toolbar: {
                            nRowsSelected: '{0} row(s) seleccionada',
                            searchPlaceholder: 'Buscar',
                            searchTooltip: 'Buscar',
                        },
                        header: {
                            actions: 'Acciones'
                        },
                        body: {
                            emptyDataSourceMessage: 'Sin datos',
                            filterRow: {
                                filterTooltip: 'Filtrar'
                            },
                            addTooltip: "Nueva fila",
                            deleteTooltip: "Eliminar fila",
                            editTooltip: "Editar fila",
                            editRow: {
                                deleteText: "¿Estás seguro de eliminar la fila?",
                                cancelTooltip: "Cancelar",
                                saveTooltip: "Guardar"
                            },
                        },

                    }}
                />
            </Paper>
        </Grid>
    </Grid>);
}