import React from 'react';
import {Text, View } from '@react-pdf/renderer';
import commonStyles from './../commonStyles';


const styles = commonStyles({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#ccc',
        backgroundColor: '#fcfcfc',
        borderBottomWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 18,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    }
  });

  const NominaTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.concepto}>CONCEPTO</Text>
        <Text style={styles.dd}>DD</Text>
        <Text style={styles.base}>BASE</Text>
        <Text style={styles.coef}>COEF</Text>
        <Text style={styles.devengos}>DEVENGOS</Text>
        <Text style={styles.descuentos}>DESCUENTOS</Text>
    </View>
  );
  
  export default NominaTableHeader