import React from "react";

import AppLayout from "./AppLayout";

import * as weatherIcons from "./icons";

export default function Weather(props) {
  const { city, currentWeather, forecast, onCityChange, error } = props;
  if (currentWeather && forecast) {
    const prefix = "wi wi-";
    const icon = prefix + weatherIcons.default[currentWeather.icon_id].icon;

    return (
      <AppLayout
        currentWeather={currentWeather}
        forecast={forecast}
        icon={icon}
      />
    );
  }
}
