import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Grid, TextField, Button, Dialog } from '@material-ui/core/'
import Global from '../Global'
import axios from 'axios'
import fs from './fs'
import AyudaChrome from './configPuesto/AyudaChrome'
import ConfigPuestoFS from './configPuesto/ConfigPuestoFS'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "block",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        boxShadow: 'none',
        borderRadius: 0,
    },
    container: {
        width: "70%",
        margin: "0 auto",
        paddingTop: "20px",
        paddingBottom: "20px",
        background: "white",
        minWidth: "305px",
        maxWidth: "500px",
        height: "100%"
    }
}));
export default function InputAdornments(props) {
    const classes = useStyles();
    const [passwords, setPasswords] = React.useState(["", ""]);
    const [error, setError] = React.useState(true)
    const [open, setOpen] = React.useState(true)
    const [errorText, setErrorText] = React.useState("")
    const [pass, setPass] = React.useState(undefined)
    const [step, setStep] = React.useState(0)
    const validatePasswords = () => {
        const passOne = passwords[0];
        const passTwo = passwords[1];
        if (passOne === passTwo) {
            if (passOne.length >= 8) {
                var mayuscula = false;
                var minuscula = false;
                var numero = false;
                var caracter_raro = false;
                for (var i = 0; i < passOne.length; i++) {
                    if (passOne.charCodeAt(i) >= 65 && passOne.charCodeAt(i) <= 90) {
                        mayuscula = true;
                    }
                    else if (passOne.charCodeAt(i) >= 97 && passOne.charCodeAt(i) <= 122) {
                        minuscula = true;
                    }
                    else if (passOne.charCodeAt(i) >= 48 && passOne.charCodeAt(i) <= 57) {
                        numero = true;
                    }
                    else {
                        caracter_raro = true;
                    }
                }
                if (mayuscula === true && minuscula === true && caracter_raro === true && numero === true) {
                    setErrorText("")
                    setError(false)
                    setPass(passOne)
                } else {
                    setErrorText("La contraseña debe al menos una mayúscula, minúscula, número y carácter especial. ")
                    setError(true)
                    setPass(undefined)
                }
            } else {
                setErrorText("La contraseña debe tener ocho caractéres como mínimo. ")
                setError(true)
                setPass(undefined)
            }
        } else {
            setErrorText("Las contraseñas no coinciden. ")
            setError(true)
            setPass(undefined)
        }
    }
    const sendNewPassword = () => {
        axios.post(Global.url + "updateuser", { pass }, props.token).then(res => {
            if (res.data.status === "error") {
                setErrorText(res.data.message)
                setError(true)
            } else {
                axios.post(Global.url + "signin", { cif: props.ayun.cif, dni: props.user.dni, password: pass }).then(ress => {
                    sessionStorage.setItem("UserToken", "H " + ress.data.token)
                    sessionStorage.setItem("Entidad", JSON.stringify(ress.data.ayun))
                    sessionStorage.setItem("User", JSON.stringify(ress.data.user))
                    props.private("H " + ress.data.token);
                    if (Global.getChromeVersion() === undefined || Global.getChromeVersion().major < 86  || !fs.isComp()) setStep(1);
                    else if (Global.getChromeVersion().major >= 86) setStep(2);
                })
            }
        })
    }
    React.useEffect(() => {
        if (props.user.newPassword) setStep(0)
        else if (!Boolean(localStorage.getItem("showed")) && (Global.getChromeVersion() === undefined || Global.getChromeVersion().major < 86  || !fs.isComp())) setStep(1)
        else if (!Boolean(localStorage.getItem("showed")) && Global.getChromeVersion().major >= 86) setStep(2)
        else setOpen(false)
    },[])
    return (
        <Dialog fullScreen open={open && step !== 4}>
            {step === 0 &&
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.container}
                >
                    <div style={{ width: "100%", maxWidth: "300px" }}>
                        <Paper className={classes.paper}>
                            <Typography variant="h5" component="h2">Cambiar contraseña</Typography>
                        </Paper>
                        <Paper className={classes.paper}>
                            <TextField
                                label="Nueva contraseña"
                                type="password"
                                onChange={(e)=>{
                                  setPasswords(prev=>{
                                    prev[0] = e.target.value
                                    return prev;
                                  })
                                  validatePasswords();
                                }}
                                error={error}
                                fullWidth
                                autoComplete="new-password"
                            />
                        </Paper>
                        <Paper className={classes.paper} >
                            <TextField
                                label="Repetir contraseña"
                                type="password"
                                onChange={(e)=>{
                                  setPasswords(prev=>{
                                    prev[1] = e.target.value
                                    return prev;
                                  })
                                  validatePasswords();
                                }}
                                helperText={errorText}
                                error={error}
                                fullWidth
                                autoComplete="new-password"
                            />
                        </Paper>
                        <Paper className={classes.paper}>
                            <Button variant="outlined" color="primary" disabled={error} onClick={sendNewPassword}>Cambiar</Button>
                        </Paper>
                    </div>
                </Grid>
            }
            {step === 1 &&
                <AyudaChrome value={Global.getChromeVersion() === undefined ?1:0} setStep={(e)=>setStep(e)}/>
            }
            {step === 2 &&
                <ConfigPuestoFS setStep={(e)=>setStep(e)}/>
            }
        </Dialog>
    );
}
