import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MaterialTable from '../components/MaterialTablePrimary'
import Icons from '../components/Icons'

import Edit from './ObrasEdit'
import Contratos from '../gestion/ContratosEdit'
import axios from 'axios'
import Global from '../Global'
import Typography from '@material-ui/core/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSnowplow } from '@fortawesome/free-solid-svg-icons'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function Trabajadores(props) {
    const classes = useStyles();
    const [columns, setColumns] = React.useState([
        { title: 'ID', field: 'id', hiddenByColumnsButton: true, hidden: false, cellStyle: { width: "5%" } },
        { title: 'Denominación', field: 'denom', hiddenByColumnsButton: true, hidden: false, cellStyle: { width: "35%" } },
        { title: 'Lugar', field: 'lugar', hiddenByColumnsButton: true, hidden: false },
        { title: 'Ejercicio P.', field: 'ejerpres', hiddenByColumnsButton: true, hidden: false },
        { title: 'Cod. Expediente', field: 'exped', hiddenByColumnsButton: true, hidden: false },
        { title: 'Presupuesto mano de obra', render: rowData => Global.roundEUR(rowData.manoObra), field: 'manoObra', hiddenByColumnsButton: true, hidden: false, ocultar: true },
        { title: 'Disponible', render: rowData => Global.roundEUR(rowData.manoObra - rowData.manoInve - rowData.manoAuto), hiddenByColumnsButton: true, hidden: false, ocultar: true },
    ])
    const [data, setData] = React.useState([])
    const [edit, setEdit] = React.useState(false)
    const [contratos, setContratos] = React.useState(props.edit)
    const [edittionRow, setEdittionRow] = React.useState()
    const [defaultData, setDefaultData] = React.useState({
        CCCreg: props.ayun.CCCreg,
        CCCprovincia: props.ayun.CCCprovincia,
        CCCnum: props.ayun.CCCnum,
        manoObra: 0,
        manoAuto: 0,
        manoInve: 0,
        material: 0,
        materialAuto: 0,
        materialDisp: 0,
        imagenes: [],
        codPais:"724",
        codMunicipio:props.ayun.codigoMunicipio
    })
    React.useEffect(() => {
        getNextId()
    }, [data])
    React.useEffect(() => {
        setData(props.obra)
    }, [props.obra])
    const getNextId = () => {
        if (data !== undefined) {
            if (data.length > 0) {
                data.sort((a, b) => b.id - a.id)
                setDefaultData({ ...defaultData, ["id"]: (data[0].id + 1) })
            }
        } else {
            setDefaultData({ ...defaultData, ["id"]: 1 })
        }
    }
    const refreshData = () => {
        setData([])
        axios.get(Global.url + "getobras", props.token).then(res => {
            props.setState({ obra: res.data.obra })
            setData(res.data.obra)
        })
    }
    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<div color='inherit' style={{ display: "flex", alignItems: "baseline" }}>
                                    <FontAwesomeIcon icon={faSnowplow} style={{ marginRight: "7px" }} />
                                    <Typography variant="h6">Obras</Typography>
                                </div>}
                                filtrar={true}
                                columns={columns}
                                data={data}
                                options={{
                                    pageSize: 25,
                                    pageSizeOptions: [25, 50, 100, 200]
                                }}
                                actions={[
                                    {
                                        icon: Icons.Add,
                                        isFreeAction: true,
                                        onClick: (event) => {
                                            setEdit(true)
                                            setEdittionRow(defaultData)
                                        }
                                    },
                                    {
                                        icon: Icons.Refresh,
                                        isFreeAction: true,
                                        onClick: () => refreshData(),
                                    },
                                ]}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'filas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} row(s) seleccionada',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        }
                                    }
                                }}
                                onRowClick={(event, rowData, togglePanel) => {
                                    setEdittionRow(rowData)
                                    setEdit(true)
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            {edit &&
                <Edit
                    close={() => setEdit(false)}
                    calendar={props.calendar}
                    user={props.user}
                    allData={data}
                    data={edittionRow}
                    token={props.token}
                    setOpen={props.setOpen}
                    setState={props.setState}
                    contratos={props.contratos}
                    ayun={props.ayun}
                ></Edit>
            }
            {contratos &&
                <Contratos
                    close={() => {
                        setContratos(false)
                        setEdittionRow(data.find(element => element._id === props.edittionRow.idObra))
                        setEdit(true)
                    }}
                    user={props.user}
                    data={props.edittionRow}
                    allData={props.contratos}
                    parad={props.parad}
                    ts={props.ts}
                    obra={props.obra}
                    token={props.token}
                    setOpen={props.setOpen}
                    setState={props.setState}
                    ayun={props.ayun}
                />
            }
        </React.Fragment>
    );
}