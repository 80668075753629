import React, { Component } from 'react';
import MaterialTable from 'material-table';

import axios from 'axios'
import Global from '../Global'

import Alert from '../components/Alert'

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'

const useStyles = theme => ({
    root: {
        width: 500,
        margin: "0px auto",
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    formControl: {
        margin: "0px auto",
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    displayClub: {
        width: "calc(50% - 40px)",
        height: 300,
        background: 'white',
        display: "flex",
        alignItems: "center",
        margin: "20px",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        float: "left"
    },
    displayMainClub: {
        width: "30%",
        height: 200,
        background: 'white',
        display: "flex",
        margin: "0 auto",
        alignItems: "center",
        marginTop: "20px",
        marginBottom: "20px",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
    },
    input: {
        display: 'none',
    },
});
const lookUpTP = (data) => {
    var res = {}
    JSON.parse(localStorage.getItem("ZTA_tipoEmpresario")).forEach(tp => {
        res[tp.CODTP] = tp.DESTP
    });
    return res
}
class TableClub extends Component {

    state = {
        columns: [
            { title: 'Nombre', field: 'nombre' },
            { title: 'Dirección', field: 'direccion' },
            { title: 'Localidad', field: 'localidad' },
            { title: 'Provincia', field: 'provincia' },
            { title: 'CP', field: 'cp' },
            { title: 'Email', field: 'email' },
            { title: 'Telefono', field: 'telf' },
            { title: 'Tipo empresa', field: 'tipoEmpresa', lookup:lookUpTP() },
            { title: 'Cod municipio', field: 'codigoMunicipio' },
            { title: 'RégimenCCC', field: 'CCCreg' },
            { title: 'ProvinciaCCC', field: 'CCCprovincia' },
            { title: 'NúmeroCCC', field: 'CCCnum' },
            { title: 'CIF', field: 'cif' },
            { title: 'Cod ordenate', field: 'codigoOrdenate' },
            { title: 'IBAN', field: 'IBAN' },
            { title: 'Activo', field: 'active', type: 'boolean' },
            { title: 'ID interno', field: '_id', editable:"never" },
        ],
        severity: "",
        message: "",
        open: false,
    };
    setOpen = (e) => {
        this.setState({ open: e })
    }
    sendSave = (e) => {
        axios.post(Global.url + "admin/savenewayun", e, this.props.user).then(res => {
            this.props.setAyun(res.data.ayuns)
            this.setState({ open: true, message: res.data.message, severity: res.data.status })
        })
    }
    sendUpdate = (e) => {
        axios.post(Global.url + "admin/updateayun", e, this.props.user).then(res => {
            this.props.setAyun(res.data.ayuns)
            this.setState({ open: true, message: res.data.message, severity: res.data.status })
        })
    }
    sendDB = (e) => {
        const fileDB = {
            name: e.target.files[0].name,
            file: e.target.files[0],
            idAyun: document.getElementById("idayun").value
        }
        var formData = new FormData();
        formData.append('file0', fileDB.file, fileDB.name)
        formData.append('id', fileDB.idAyun)
        axios.post(Global.url + "admin/setDB", formData, this.props.user).then((res) => {
            this.setState({ open: true, message: res.data.message, severity: res.data.status })
        })
    }
    render() {
        return (
            <React.Fragment>
                <Grid container
                    spacing={3}
                    direction="column"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12}>
                        <Paper >
                            <MaterialTable
                                title="Ayuntamientos"
                                columns={this.state.columns}
                                data={this.props.ayun}
                                options={{
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100]
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.sendSave(newData)
                                                resolve();
                                            }, 1000)
                                        }),
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                const dataUpdate = [...this.props.ayun];
                                                const index = oldData.tableData.id;
                                                dataUpdate[index] = newData;
                                                this.sendUpdate(newData)

                                                resolve();
                                            }, 1000)
                                        }),
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper >
                            <div>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    id="idayun"
                                    label="ID AYUN"
                                    name="id"
                                />
                                <input
                                    id="contained-button-file"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={this.sendDB}
                                />
                                <label htmlFor="contained-button-file">
                                    <Button variant="contained" color="primary" component="span">
                                        Upload
                                </Button>
                                </label>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>

                <Alert open={this.state.open} setOpen={this.setOpen} message={this.state.message} severity={this.state.severity}></Alert>
            </React.Fragment>
        );
    }

} export default withStyles(useStyles)(TableClub);