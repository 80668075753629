import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, TextField, Paper, Button } from '@material-ui/core/';

import axios from 'axios'
import Global from '../Global'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: "800px",
        margin: "150px auto"
    },
    row: {
        padding: "2rem 0rem"
    },
    labelContainer: {
        width: '100%',
        textAlign: "end",
    },
    label: {
        height: '1.1876em',
        fontSize: '1rem',
        padding: '4px 40px 7px 0px',
    }
}));

export default function Trabajadores(props) {
    const classes = useStyles();
    const [values, setValues] = React.useState(props.user)
    const [email, setEmail] = React.useState(props.user.email)
    const [pass, setPass] = React.useState(undefined)
    const [error, setError] = React.useState(false)
    const [errorText, setErrorText] = React.useState("")
    const [errorE, setErrorE] = React.useState(false)
    const [errorTextE, setErrorTextE] = React.useState("")
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleChangeEmail = (event) => {
        if (Global.validateEmail(event.target.value).status) {
            setErrorE(true)
            setErrorTextE(Global.validateEmail(event.target.value).message)
        } else {
            setErrorE(false)
            setErrorTextE("")
            setValues({ ...values, ["email"]: event.target.value });
        }
    };
    const handleChangePassword = () => {
        const passOne = document.getElementById("password").value
        const passTwo = document.getElementById("passwordTwo").value
        if (passOne === passTwo) {
            if (passOne.length >= 8) {
                var mayuscula = false;
                var minuscula = false;
                var numero = false;
                var caracter_raro = false;
                for (var i = 0; i < passOne.length; i++) {
                    if (passOne.charCodeAt(i) >= 65 && passOne.charCodeAt(i) <= 90) {
                        mayuscula = true;
                    }
                    else if (passOne.charCodeAt(i) >= 97 && passOne.charCodeAt(i) <= 122) {
                        minuscula = true;
                    }
                    else if (passOne.charCodeAt(i) >= 48 && passOne.charCodeAt(i) <= 57) {
                        numero = true;
                    }
                    else {
                        caracter_raro = true;
                    }
                }
                if (mayuscula === true && minuscula === true && caracter_raro === true && numero === true) {
                    setErrorText("")
                    setError(false)
                    setPass(passOne)
                } else {
                    setErrorText("La contraseña debe al menos una mayúscula, minúscula, número y carácter especial. ")
                    setError(true)
                    setPass(undefined)
                }
            } else {
                setErrorText("La contraseña debe tener ocho caractéres como mínimo. ")
                setError(true)
                setPass(undefined)
            }
        } else {
            setErrorText("Las contraseñas no coinciden. ")
            setError(true)
            setPass(undefined)
        }
    }
    const sendData = () => {
        axios.post(Global.url + "updateuser", { apellidos: values.apellidos, nombre: values.nombre, email: values.email, pass }, props.token).then(res => {
            if (res.data.status === "error") {
                props.setState({ open: true, message: res.data.message, severity: res.data.status })
            } else {
                axios.get(Global.url + "refreshtoken", props.token).then(ress => {
                    props.private("H " + ress.data.token)
                })
                props.setState({ open: true, message: res.data.message, severity: res.data.status })
            }
        })
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Paper>
                    <Grid container style={{ backgroundColor: "#fff", padding: "2rem" }}>
                        <Grid item xs={12}>
                            <Typography variant="h5" component="h2" style={{ textAlign: "center", marginBottom: "2rem" }}>Mi cuenta</Typography>
                        </Grid>
                        <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                            <div className={classes.label}>Nombre:</div>
                        </Grid>
                        <Grid item xs={8} className={classes.row}>
                            <TextField
                                value={values.nombre}
                                onChange={handleChange('nombre')}
                                defaultValue={props.user.nombre}
                                fullWidth
                                autoComplete="something-new"
                            />
                        </Grid>
                        <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                            <div className={classes.label}>Apellidos:</div>
                        </Grid>
                        <Grid item xs={8} className={classes.row}>
                            <TextField
                                value={values.apellidos}
                                onChange={handleChange('apellidos')}
                                defaultValue={props.user.apellidos}
                                fullWidth
                                autoComplete="something-new"
                            />
                        </Grid>
                        <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                            <div className={classes.label}>DNI:</div>
                        </Grid>
                        <Grid item xs={8} className={classes.row}>
                            <div className={classes.label}>{props.user.dni}</div>
                        </Grid>

                        <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                            <div className={classes.label}>Cambiar contraseña:</div>
                        </Grid>
                        <Grid item xs={8} className={classes.row}>
                            <TextField
                                id="password"
                                type="password"
                                error={error}
                                fullWidth
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                            <div className={classes.label}>Confirmar nueva contraseña:</div>
                        </Grid>
                        <Grid item xs={8} className={classes.row}>
                            <TextField
                                id="passwordTwo"
                                type="password"
                                onBlur={handleChangePassword}
                                helperText={errorText}
                                error={error}
                                fullWidth
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                            <div className={classes.label}>E-mail:</div>
                        </Grid>
                        <Grid item xs={8} className={classes.row}>
                            <TextField
                                value={email}
                                onChange={(e,email)=>setEmail(email)}
                                onBlur={handleChangeEmail}
                                error={errorE}
                                helperText={errorTextE}
                                defaultValue={props.user.email}
                                fullWidth
                                autoComplete="something-new"
                            />
                        </Grid>
                        <Grid item xs={4} className={`${classes.row} ${classes.labelContainer}`}>
                            <div className={classes.label}>Rol:</div>
                        </Grid>
                        <Grid item xs={8} className={classes.row}>
                            <div className={classes.label}>{Global.rol[props.user.rol]}</div>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "20px" }}>
                            <div style={{ position: "absolute", transform: "translateX(-50%)", left: '50%', margin: 0 }}>
                                <Button variant="outlined" color="primary" onClick={() => sendData()} >
                                    Guardar
                                </Button>
                            </div>
                        </Grid>

                    </Grid>
                </Paper>
            </div>
        </React.Fragment>
    );
}