import React from 'react'
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer'
import moment from 'moment'
import 'moment/locale/es'
const PrintDoc = ({ rowData, ayun, parad, obra }) => {
  var imgs = undefined
  if (Array.isArray(rowData)) {
    var imgs = rowData
    imgs.sort((a, b) => moment(a.date) - moment(b.date))
  }
  return (
    <Document>
      <Page style={styles.page} size="A4" wrap>
        <View style={styles.table} fixed>
          <View style={styles.row}>
            <View style={styles.headerImage} >
              <Image
                style={styles.image}
                src={`${ayun.imgDoc}`}
                fixed
              />
            </View>
            <View style={styles.headerText} >
              <Text>DOSIER FOTOGRÁFICO</Text>
              <Text>{ayun.nombre.toUpperCase()}</Text>
            </View>
          </View>
        </View>
        <View style={[styles.container, styles.row]}>
          <Text style={styles.columnTitle}>Denominación:</Text>
          <Text style={styles.columnDataDos}>{obra.denom}</Text>
          <Text style={styles.columnTitle}>Expediente:</Text>
          <Text style={styles.columnData}>{obra.exped}</Text>

        </View>
        {imgs.length > 0 &&
          <>
            <View style={{ width: "100%", height: "300px", marginTop: "100px" }}>
              <Image
                style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain" }}
                src={
                  typeof imgs[0].id === 'string'
                    ?
                    `${imgs[0].id}`
                    :
                    imgs[0].id[0]
                }
              />
            </View>
            <View style={{ width: "100%", textAlign: "center", fontSize: 10 }}><Text>{moment(imgs[0].date).format("DD/MM/YYYY")}{(imgs[0].description !== "" || imgs[0].description !== undefined) ? " - " + imgs[0].description : ""}</Text></View>
          </>
        }




        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      {imgs &&
        <>
          {imgs.length - 1 > 0 &&
          <>
          {imgs.map((x,i)=>{
            if(i !== 0 && i % 2 === 1){
              return(
                <Page style={styles.page} size="A4" wrap>
              <View style={styles.table} fixed>
                <View style={styles.row}>
                  <View style={styles.headerImage} >
                    <Image
                      style={styles.image}
                      src={`${ayun.imgDoc}`}
                      fixed
                    />
                  </View>
                  <View style={styles.headerText} >
                    <Text>DOSIER FOTOGRÁFICO</Text>
                    <Text>{ayun.nombre.toUpperCase()}</Text>
                  </View>
                </View>
              </View>
              {imgs &&
                <>
                  <View style={{ width: "100%", height: "300px", marginTop: "20px" }}>
                    <Image
                      style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain" }}
                      src={
                        typeof imgs[i].id === 'string'
                          ?
                          `${imgs[i].id}`
                          :
                          imgs[i].id[0]
                      }
                    />
                  </View>
                  <View style={{ width: "100%", textAlign: "center", fontSize: 10 }}><Text>{moment(imgs[i].date).format("DD/MM/YYYY")}{(imgs[i].description !== "" || imgs[i].description !== undefined) ? " - " + imgs[i].description : ""}</Text></View>
                </>
              }
              {imgs && imgs[i+1] !== undefined &&
                <>
                  <View style={{ width: "100%", height: "300px", marginTop: "20px" }}>
                    <Image
                      style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain" }}
                      src={
                        typeof imgs[i+1].id === 'string'
                          ?
                          `${imgs[i+1].id}`
                          :
                          imgs[i+1].id[0]
                      }
                    />
                  </View>
                  <View style={{ width: "100%", textAlign: "center", fontSize: 10 }}><Text>{moment(imgs[i+1].date).format("DD/MM/YYYY")}{(imgs[i+1].description !== "" || imgs[i+1].description !== undefined) ? " - " + imgs[i+1].description : ""}</Text></View>
                </>
              }
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                `${pageNumber} / ${totalPages}`
              )} fixed />
            </Page>
              )
            }
          })}
            </>
          }
        </>
      }



    </Document>
  )
  
};

export default PrintDoc;

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',

  },
  headerText: {
    width: '60%',
    fontSize: 11,
  },
  headerImage: {
    width: '40%',
    fontSize: 11,
  },
  image: {
    width: "35%"
  },
  columnTitle: {
    width: '15%',
    paddingLeft: 5,
    marginTop: -1,
    marginLeft: -1,
    paddingTop: 2,
    border: '1 solid black'
  },
  columnData: {
    width: '20%',
    paddingLeft: 5,
    marginTop: -1,
    marginLeft: -1,
    paddingTop: 2,
    border: '1 solid black'
  },
  columnDataDos: {
    width: '50%',
    paddingLeft: 7,
    marginTop: -1,
    marginLeft: -2,
    marginRigth: -20,
    paddingTop: 2,
    border: '1 solid black',
    borderLeft: '0 solid black'
  },
  text: {
    marginTop: 10,
    fontSize: 10,
    textAlign: "justify",
  },
  textDos: {
    paddingLeft: 10,
    marginTop: 5,
    textAlign: "justify",
  },
  container: {
    marginTop: 20,
    fontSize: 10
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  page: { padding: 40 },

});