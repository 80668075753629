import React from 'react';
import NumberFormat from "react-number-format";
import MaskedInput from 'react-text-mask';
import moment from 'moment'

var Global = {
    url: process.env.NODE_ENV === 'development' ? 'http://localhost:3400/api/' : 'https://api.elocal.es/api/',
    tipoIndemnizacion: [{ COD: "P", DES: "Porcentaje" }/* , { COD: "D", DES: "Días por año" } */],
    rol: { 0: "Consultor", 1: "Editor", 2: "Administrador" },
    tipos: {
        "comunes": "Comunes",
        "hhExtFM": "Horas ext. de fuerza mayor",
        "hhExtRest": "Resto de horas extraordinarias",
        // "mayores6567": "Mayores de 65 años (38,5 años cotizados) o mayores de 67 (37 años cotizados)",
        // "mayores60": "Mayores de 60 años",
        "general": "Tipo general",
        "CTC": "Contratos temporales a tiempo completo",
        "CTP": "Contratos temporales a tiempo parcial",
        "garantiaSalarial": "Fondo de garantía salarial",
        "fp": "Formación profesional",
        "CF": "Trabajadores fijos",
        "CT": "Tipo general (eventuales)",
        "especiales": "Prácticas, relevo, interinidad, discapacitados",
        "menor30dias": "Adicional < 30 días",
    },
    devengo: { 
        AT: "Devengo todos los días en alta", 
        A: "Devengo todos los días en alta, salvo vacaciones", 
        T: "Devengo solo los días realmente trabajados", 
        V: "Devengo días de vacaciones", 
        VND: "Devengo días de vacaciones no disfrutadas" 
    },
    ausencias: {
        EC: "Enfermedad común",
        EP: "Enfermedad profesional",
        AL: "Accidente laboral",
        ANL: "Accidente no laboral",
        IJ: "Ausencia justificada",
        INJ: "Ausencia no justificada",
        V: "Vacaciones",
        MA: "Maternidad",
        PA: "Paternidad",/* 
        H: "Huelga",
        AP:"Asuntos propios",
        M:"Matrimonio",
        NH:"Nacimiento hijo",
        F:"Fallecimiento",
        TD:"Translado de domicilio",
        OBFP:"Observación de la enfermedad profesional",
        DCPP:"Deber inexcusable de carácter público y personal",
        S:"Sanción",
        RE:"Riesgo durante el embarazo o lactancia." */
    },
    validateWorker: (data, originalData) => {
        const validate = (obj) => {
            var ress = ""
            if (typeof obj === 'string') {
                ress = !obj.replace(/\s/g, '').length
            } else {
                ress = obj === undefined || obj === null
            }
            return ress
        }
        const obligatorios = {
            primerApellido: "Apellido 1",
            nombre: "Nombre",
            domicilio: "Domicilio",
            postal: "CP",
            ciudad: "Ciudad",
            dni: "Documento",
            numss: "NºSS",
            fechaNacimiento: "Fecha nacimento",
            rirpf: "Retención IRPF",
            agricola: "Agrícola",
            hombre: "Sexo",
            provincia: "Provincia",
            claveIRPF: "Clave IRPF",
            claveIPF: "IPF",
            subclirpf: "Subclave IRPF",
            tipoRelacion: "Tipo relación",
            discapacidad: "% Discapacidad",
            movilidadReducida: "Movilidad reducida",
            sitFamilia: "Situación familiar",
            idenimen: "IPF-SEPE",
            nacional: "Nacionalidad",
            municipio: "Cod. Municipio",
            paisResidencia: "País",
            nivelForm: "Nivel formativo",
        }
        var res = []
        if (typeof data['numss'] === 'string') {
            if (data['numss'].length !== 12) {
                res.push('Número de la Seguridad Social no válido')
            }
        }
        for (var key in obligatorios) {
            if (validate(data[key])) res.push(obligatorios[key])
        }
        if (originalData) {
            return {
                obligatorios: res,
                equal: originalData === data
            }
        } else {
            return {
                obligatorios: res
            }
        }
    },
    validateObra: (data, originalData) => {
        const validate = (obj) => {
            var ress = ""
            if (typeof obj === 'string') {
                ress = !obj.replace(/\s/g, '').length
            } else {
                ress = obj === undefined || obj === null
            }
            return ress
        }
        const obligatorios = {
            manoObra: "Presupuesto mano de obra",
            material: "Presupuesto material",
            id: "ID",
            exped: "Expediente",
            denom: "Denominación",
            calend: "Calendario",
            progempl: "Cod. programa",
            entidad: "Cod. entidad",
            actuacion: "Cod. actuación",
            CCCreg: "Régimen",
            CCCprovincia: "Provincia",
            CCCnum: "Número",
            codMunicipio: "CT municipio",
            codPais: "CT país"

        }
        var res = []
        if (typeof data['CCCprovincia'] === 'string') {
            if (data['CCCprovincia'].length !== 2) res.push('Provincia Seguridad Social no válido')
        }
        if (typeof data['CCCnum'] === 'string') {
            if (data['CCCnum'].length !== 9) res.push('Número Seguridad Social no válido')
        }
        for (var key in obligatorios) {
            if (validate(data[key])) res.push(obligatorios[key])
        }
        if (originalData) {
            return {
                obligatorios: res,
                equal: originalData === data
            }
        } else {
            return {
                obligatorios: res
            }
        }
    },
    validateBases: (data, originalData) => {
        const validate = (obj) => {
            var ress = ""
            if (typeof obj === 'string') {
                ress = !obj.replace(/\s/g, '').length
            } else {
                ress = obj === undefined || obj === null || obj === 0
            }
            return ress
        }
        const obligatorios = {
            nombre: "Nombre",
        }
        var res = []
        if (!moment(data['vigencia']).isValid()) res.push('Vigencia no válida')
        for (var key in obligatorios) {
            if (validate(data[key])) res.push(obligatorios[key])
        }
        if (originalData) {
            return {
                obligatorios: res,
                equal: originalData === data
            }
        } else {
            return {
                obligatorios: res
            }
        }
    },
    validateTS: (data, originalData) => {
        const validate = (obj) => {
            var ress = ""
            if (typeof obj === 'string') {
                ress = !obj.replace(/\s/g, '').length
            } else {
                ress = obj === undefined || obj === null
            }
            return ress
        }
        const obligatorios = {
            idEnfermedad: "Tabla enfermedad",
            tipoIndemnizacion: "Tipo indemnización",
            indemnizacion: "Valor indemnización",
            codigoConvenio: "Código convenio",
            nombreConvenio: "Nombre convenio",
            tarifaSS: "Grupo cotización",
            actividad: "Actividad",
            codNacionalOcupacion: "CNO"
        }
        var res = []
        for (var key in obligatorios) {
            if (validate(data[key])) res.push(obligatorios[key])
        }
        if (originalData) {
            return {
                obligatorios: res,
                equal: originalData === data
            }
        } else {
            return {
                obligatorios: res
            }
        }
    },
    validateTF: (data, originalData) => {
        const validate = (obj) => {
            var ress = ""
            if (typeof obj === 'string') {
                ress = !obj.replace(/\s/g, '').length
            } else if (Array.isArray(obj)) {
                ress = !obj.length
            } else {
                ress = obj === undefined || obj === null
            }
            return ress
        }
        const obligatorios = {
            nombre: "Descripción",
            id: "ID",
            accidenteIndemnizacion: "Accidente",
            enfermedadIndemnizacion: "Enfermedad",
        }
        var res = []
        for (var key in obligatorios) {
            if (validate(data[key])) res.push(obligatorios[key])
        }
        if (originalData) {
            return {
                obligatorios: res,
                equal: originalData === data
            }
        } else {
            return {
                obligatorios: res
            }
        }
    },
    validateContrato: (data, originalData) => {
        const validate = (obj) => {
            var ress = ""
            if (typeof obj === 'string') {
                ress = !obj.replace(/\s/g, '').length
            } else if (Array.isArray(obj)) {
                ress = !obj.length
            } else {
                ress = obj === undefined || obj === null
            }
            return ress
        }
        const obligatorios = {
            idObra: "Obra",
            idParad: "Trabajador",
            idTs: "Tabla salarial",
            claveContrato: "Clave contrato",
        }
        var res = []
        for (var key in obligatorios) {
            if (validate(data[key])) res.push(obligatorios[key])
        }
        if (data.fechaAlta) {
            if (!moment(data.fechaAlta).isValid()) res.push("Alta S.S. inválida")
        } else {
            res.push("Alta S.S.")
        }
        if (data.fechaBaja) {
            if (!moment(data.fechaBaja).isValid()) res.push("Baja S.S. inválida");
            else if (moment(data.fechaAlta).isAfter(moment(data.fechaBaja))) res.push("Alta/Baja no procede");
        }
        if(data.fechaVND){
            if(!moment(data.fechaBaja).isValid() && moment(data.fechaVND).isValid()) res.push('No puedes rellenar fecha fin de vacaciones sin fecha de baja.');
            else if(moment(data.fechaBaja).isValid() && moment(data.fechaVND).isValid() && moment(data.fechaBaja).isAfter(data.fechaVND,'days')) res.push("Fecha fin de vacaciones no puede ser anterior a fecha de baja");
        }
        if (data.codOfertaEmpleo) {
            if (data.codOfertaEmpleo.length !== 12) res.push("Código oferta de empleo inválida")
        }
        if (originalData) {
            return {
                obligatorios: res,
                equal: originalData === data
            }
        } else {
            return {
                obligatorios: res
            }
        }
    },
    validateTurno: (data, originalData) => {
        const validate = (obj) => {
            var ress = ""
            if (typeof obj === 'string') {
                ress = !obj.replace(/\s/g, '').length
            } else if (Array.isArray(obj)) {
                ress = !obj.length
            } else {
                ress = obj === undefined || obj === null
            }
            return ress
        }
        const obligatorios = {
            idObra: "Obra",
            idTs: "Tabla salarial",
            claveContrato: "Clave contrato",
        }
        var res = []
        for (var key in obligatorios) {
            if (validate(data[key])) res.push(obligatorios[key])
        }
        if (data.fechaAlta) {
            if (!moment(data.fechaAlta).isValid()) res.push("Alta S.S. inválida")
        } else {
            res.push("Alta S.S.")
        }
        if (data.fechaBaja) {
            if (!moment(data.fechaBaja).isValid()) res.push("Baja S.S. inválida")
            else if (moment(data.fechaAlta).isAfter(moment(data.fechaBaja))) res.push("Alta/Baja no procede")
        }
        if (data.codOfertaEmpleo) {
            if (data.codOfertaEmpleo.length !== 12) res.push("Código oferta de empleo inválida")
        }
        if (originalData) {
            return {
                obligatorios: res,
                equal: originalData === data
            }
        } else {
            return {
                obligatorios: res
            }
        }
    },
    validateDNI: (dni) => {
        var numero
        var letr
        var letra
        var expresion_regular_dni

        expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

        if (expresion_regular_dni.test(dni) == true) {
            numero = dni.substr(0, dni.length - 1);
            letr = dni.substr(dni.length - 1, 1);
            numero = numero % 23;
            letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
            letra = letra.substring(numero, numero + 1);
            if (letra != letr.toUpperCase()) {
                return { "status": true, "message": "DNI incorrecto" }
            } else {
                return { "status": false, "message": "" }
            }
        } else {
            return { "status": true, "message": "DNI erróneo, formato no válido" }
        }
    },
    validateEmail: (email) => {
        var emailexpresion = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        if (emailexpresion.test(email) == true) {
            return { "status": false, "message": "" }
        } else {
            return { "status": true, "message": "Email erróneo, formato no válido" }
        }
    },
    roundEUR: (number) => {
        const res = new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(number)
        return res
    },
    roundPor: (number) => {
        return (
            <NumberFormat
                value={number}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString
                suffix=" %"
                displayType="text"
            />
        );
    },
    roundFloat: (number) => {
        return (
            <NumberFormat
                value={number}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString
                displayType="text"
            />
        );
    },
    formatEUR: (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString
                suffix=" €"
            />
        );
    },
    formatPor: (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString
                suffix=" %"
            />
        );
    },
    formatNumberInt: (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }}
                type="tel"
                decimalScale={0}
                fixedDecimalScale={true}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString
            />
        );
    },
    formatNumberFloat: (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalSeparator=","
                thousandSeparator="."
                isNumericString
                suffix={props.suffix && " " + props.suffix}
            />
        );
    },
    formatIBAN: (props) => {
        const { inputRef, ...other } = props;

        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={[/[A-Z]/, /[A-Z]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                placeholderChar={'_'}
                showMask
            />
        );
    },
    formatYear: (props) => {
        const { inputRef, ...other } = props;

        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={[/\d/, /\d/, /\d/, /\d/]}
                placeholderChar={'_'}
                showMask
            />
        );
    },
    formatProvinciaSS: (props) => {
        const { inputRef, ...other } = props;

        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={[/\d/, /\d/]}
                placeholderChar={'_'}
                showMask
            />
        );
    },
    formatNumeroSS: (props) => {
        const { inputRef, ...other } = props;

        return (
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                placeholderChar={'_'}
                showMask
            />
        );
    },
    formatClaveRed: (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }}
                format="########"
                mask={"_"}
                allowEmptyFormatting
                isNumericString
            />
        );
    },
    formatSS: (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            value: values.value
                        }
                    });
                }}
                format="##/##########"
                allowEmptyFormatting
                mask="_"
                isNumericString
            />
        );
    },
    formatMonth: (props) => {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    props.onBlur({
                        target: {
                            value: values.value
                        }
                    });
                }}
                format="##/####"
                allowEmptyFormatting
                mask={["M", "M", "Y", "Y", "Y", "Y"]}
                isNumericString
            />
        );
    },
    checkIBAN: (iban) => {
        if (iban.length === 24) {
            var digitoControl = getCodigoControl_IBAN(iban.substr(0, 2).toUpperCase(), iban.substr(4));
            if (digitoControl === Number(iban.substr(2, 2))) {
                return true;
            } else {
                return false
            }
        } else {
            return false;
        }
        function getCodigoControl_IBAN(codigoPais, cc) {
            // cada letra de pais tiene un valor
            var valoresPaises = {
                'A': '10',
                'B': '11',
                'C': '12',
                'D': '13',
                'E': '14',
                'F': '15',
                'G': '16',
                'H': '17',
                'I': '18',
                'J': '19',
                'K': '20',
                'L': '21',
                'M': '22',
                'N': '23',
                'O': '24',
                'P': '25',
                'Q': '26',
                'R': '27',
                'S': '28',
                'T': '29',
                'U': '30',
                'V': '31',
                'W': '32',
                'X': '33',
                'Y': '34',
                'Z': '35'
            };

            // reemplazamos cada letra por su valor numerico y ponemos los valores mas dos ceros al final de la cuenta
            var dividendo = cc + valoresPaises[codigoPais.substr(0, 1)] + valoresPaises[codigoPais.substr(1, 1)] + '00';

            // Calculamos el modulo 97 sobre el valor numerico y lo restamos al valor 98
            var digitoControl = 98 - modulo(dividendo, 97);

            // Si el digito de control es un solo numero, añadimos un cero al delante
            if (digitoControl.length === 1) {
                digitoControl = '0' + digitoControl;
            }
            return digitoControl;
        }
        function modulo(valor, divisor) {
            var resto = 0;
            var dividendo = 0;
            for (var i = 0; i < valor.length; i += 10) {
                dividendo = resto + "" + valor.substr(i, 10);
                resto = dividendo % divisor;
            }
            return resto;
        }
    },
    getChromeVersion: () => {
        var pieces = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/);
        if (pieces == null || pieces.length != 5) {
            return undefined;
        }
        pieces = pieces.map(piece => parseInt(piece, 10));
        return {
            major: pieces[1],
            minor: pieces[2],
            build: pieces[3],
            patch: pieces[4]
        };
    },
    getAuxiliarTableValue: (table, keyToFind, valueToFind) => {
        const arr = JSON.parse(localStorage.getItem(table));
        if (Array.isArray(arr)) {
            return arr.find(a => a[keyToFind] === valueToFind)
        }
        return undefined;
    }
};

export default Global;
