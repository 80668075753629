import React from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 17
    },
    footer: {
        textAlign: "center",
        padding: 4
    },
    footerWorker: {
        borderColor: '#848484',
        borderWidth: 1,
        width: "39%",
        minHeight: 80,
    },
    footerCompany: {
        fontSize: 8,
        width: "59%",
    }
});

const Header = ({ ayunInfo }) => (
    <View style={styles.tableContainer}>
        <View style={[styles.footer, styles.footerWorker]}>
            <Text>{"Recibí,\nEl trabajador"}</Text>
        </View>
        <View style={[styles.footer, styles.footerCompany]}>
            <Text>{ayunInfo.footer}</Text>
        </View>
    </View>
);

export default Header