import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper'

import fs from '../fs'
import db from '../idb-keyval-iife'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  image: {
    width: '100%',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    display: "none"
  },
  label: {
    height: "100%",
    display: "flex",
    fontSize: "14px",
    fontWeight: "400",
    alignItems: "center"
  },
  textfield: {
    height: "40px",
  },
  row: {
    marginTop: "20px"
  }
}));
function getSteps() {
  return ['Método de instalación', 'Seleccionar directorio', 'Revisar'];
}

export default (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [tipoInstalacion, setTipoInstalacion] = React.useState(1)
  const [dirs, setDirs] = React.useState({})

  const handleClose = (h) => {
    if (h) {
      localStorage.setItem("showed", "true");
      props.setStep(4)
    }
    setOpen(false);
  };
  var getBrowserInfo = function () {
    var ua = navigator.userAgent, tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (<StepOne value={tipoInstalacion} setValue={(e) => setTipoInstalacion(e)}></StepOne>);
      case 1:
        return (<StepTwo tipoInstalacion={tipoInstalacion} dirs={dirs} setDirs={setDirs}></StepTwo>);
      case 2:
        return (<StepThree tipoInstalacion={tipoInstalacion} dirs={dirs}></StepThree>);
      case 3:
        return (<StepFour tipoInstalacion={tipoInstalacion} dirs={dirs} handleClose={handleClose}></StepFour>);
      default:
        return 'Unknown step';
    }
  }

  return (
    <>
      {fs.isComp()
        ?
        <div className={classes.root}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div style={{ margin: "40px" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              {getStepContent(activeStep)}
              <div style={{ marginTop: "30px" }}>

                <Button
                  onClick={() => handleClose(true)}
                  className={classes.button}
                >
                  {'No configurar ahora'}
                </Button>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                  Volver
              </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  disabled={activeStep === 1 && ((tipoInstalacion === 1 && !dirs[0]) || (tipoInstalacion === 2 && !dirs[1] && !dirs[2] && !dirs[3] && !dirs[4]))}
                >
                  {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
                </Button>
              </div>
            </div>
          </div>
        </div>
        :
        <div className={classes.root}>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{"Navegador no compatible"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Su navegador no es compatible con todas las funciones, pudiendo afectar al correcto funcionamiento de la aplicación.
                                <br />Además que todos los archivos generados por la aplicación irán a la carpeta predeterminada de descargas configurada en su actual navegador en vez de facilitar su trabajo y ubicarse en la C:/SILTRA.
                                <br /> Recomendamos usar Chrome 86 o superior
                                <br /> Navegador actual: {getBrowserInfo()}
              </DialogContentText>
              <DialogContentText>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose(true)} color="primary">
                Seguir con mi navegador
                            </Button>
              <Button onClick={handleClose} color="primary" component="a" target="_blank" href="https://www.google.com/intl/es_es/chrome/">
                Descargar Chrome
                            </Button>
            </DialogActions>
          </Dialog>
          {!open &&
            <div style={{ margin: "30px" }}>
              <Typography color="primary" variant="h5">Una vez descargada la aplicación, abre Google Chrome y dirígete a www.elocal.es</Typography>
            </div>
          }
        </div>
      }
    </>
  );
}

const StepOne = (props) => {

  const handleChange = (event) => {
    props.setValue(Number(event.target.value));
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Tipo de instalación</FormLabel>
      <RadioGroup aria-label="gender" name="gender1" value={props.value} onChange={handleChange}>
        <FormControlLabel value={1} control={<Radio />} label="Instalación recomendada" />
        <FormControlLabel value={2} control={<Radio />} label="Intalación avanzada" />
      </RadioGroup>
    </FormControl>
  );
}
const StepTwo = (props) => {
  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root} style={{ maxWidth: "500px" }} justify="center" alignItems="center">

      <CssBaseline />
      {props.tipoInstalacion === 1 &&
        <>
          <Grid item xs={8} className={classes.row}>
            <TextField
              variant="filled"
              size="small"
              label="Carpeta SILTRA"
              value={props.dirs["0"] ? props.dirs["0"].name : "Seleccione una opción válida"}
              fullWidth
              InputProps={{
                className: classes.textfield
              }}
              helperText="Normalmente ubicada en disco C:/ (C:/SILTRA)"
            />

          </Grid>
          <Grid item xs={4} >
            <Button variant="contained" color="primary" component="span" style={{ height: "100%", fontWeight: "500", float: "left" }}
              onClick={async () => {
                const dirHandle = await window.showDirectoryPicker();
                props.setDirs({ ...props.dirs, "0": dirHandle });
              }}>
              Seleccionar
              </Button>
          </Grid>
        </>
      }
      {props.tipoInstalacion === 2 &&
        <>
          {[
            {
              name: "Bases de cotización",
              key: "BC"
            },
            {
              name: "Ficheros de afiliación",
              key: "AFI"
            },
            {
              name: "CRA",
              key: "CRA"
            },
            {
              name: "Otros (PDFs, Contrat@, Certific@...)",
              key: "OTROS"
            },
          ].map((x, i) => (
            <React.Fragment key={i}>
              <Grid item xs={8} className={classes.row}>
                <TextField
                  variant="filled"
                  size="small"
                  label={x.name}
                  value={props.dirs[i + 1] ? props.dirs[i + 1].name : "Seleccione una opción válida"}
                  fullWidth
                  InputProps={{
                    className: classes.textfield
                  }}
                  helperText="Normalmente ubicada en disco C:/ (C:/SILTRA)"
                />

              </Grid>
              <Grid item xs={4} >
                <Button variant="contained" color="primary" component="span" style={{ height: "100%", fontWeight: "500", float: "left" }}
                  onClick={async () => {
                    const dirHandle = await window.showDirectoryPicker();
                    props.setDirs({ ...props.dirs, [i + 1]: dirHandle });
                  }}>
                  Seleccionar
              </Button>
              </Grid>
            </React.Fragment>
          ))}
        </>
      }
    </Grid>

  );
}
const StepThree = (props) => {
  const classes = useStyles();
  var names = []
  if (props.tipoInstalacion === 1) {
    names = [
      {
        name: "Bases de cotización",
        key: props.dirs["0"].name + "/" + fs.defaultDirs().SILTRA.BC.join("/")
      },
      {
        name: "Ficheros de afiliación",
        key: props.dirs["0"].name + "/" + fs.defaultDirs().SILTRA.AFI.join("/")
      },
      {
        name: "CRA",
        key: props.dirs["0"].name + "/" + fs.defaultDirs().SILTRA.CRA.join("/")
      },
      {
        name: "Otros (PDFs, Contrat@, Certific@...)",
        key: props.dirs["0"].name + "/" + fs.defaultDirs().SILTRA.OTROS.join("/")
      },
    ]
  }
  if (props.tipoInstalacion === 2) {
    names = [
      {
        name: "Bases de cotización",
        key: props.dirs["1"].name
      },
      {
        name: "Ficheros de afiliación",
        key: props.dirs["2"].name
      },
      {
        name: "CRA",
        key: props.dirs["3"].name
      },
      {
        name: "Otros (PDFs, Contrat@, Certific@...)",
        key: props.dirs["4"].name
      },
    ]
  }
  return (
    <Grid container component="main" className={classes.root} style={{ maxWidth: "500px" }} justify="center" alignItems="center">

      <CssBaseline />
      {names.map((x, i) => (

        <Grid item xs={8} className={classes.row} key={i}>
          <TextField
            variant="filled"
            size="small"
            label={x.name}
            value={x.key}
            fullWidth
            InputProps={{
              className: classes.textfield
            }}
          />
        </Grid>

      ))}
    </Grid>

  );
}
const StepFour = (props) => {
  const classes = useStyles();
  var names = []
  if (props.tipoInstalacion === 1) {
    names = [
      {
        name: "BC",
        key: props.dirs["0"]
      },
      {
        name: "AFI",
        key: props.dirs["0"]
      },
      {
        name: "CRA",
        key: props.dirs["0"]
      },
      {
        name: "OTROS",
        key: props.dirs["0"]
      },
    ]
  }
  if (props.tipoInstalacion === 2) {
    names = [
      {
        name: "BC",
        key: props.dirs["1"]
      },
      {
        name: "AFI",
        key: props.dirs["2"]
      },
      {
        name: "CRA",
        key: props.dirs["3"]
      },
      {
        name: "OTROS",
        key: props.dirs["4"]
      },
    ]
  }
  React.useEffect(() => {
    if (props.tipoInstalacion === 2) {
      var res = []
      names.forEach((x) => {
        res.push( new Promise((resolve) => {
        db.set(x.name, x.key).then(_=>resolve())
        }))
      })
      Promise.all(res).then(_=>props.handleClose(true))
    }
    else if (props.tipoInstalacion === 1) {
      var defaultDirs = fs.defaultDirs()
      var res = []
      names.forEach((x) => {
        res.push( new Promise((resolve) => {
          defaultDirs.SILTRA[x.name].reduce((prev, current) => {
            return prev.then((dir) => {
              return dir.getDirectoryHandle(current, {
                create: true,
              });
            });
          }, Promise.resolve(x.key)).then(res => {
            db.set(x.name, res).then(_=>resolve())
          })
        }))
      })
      Promise.all(res).then(_=>props.handleClose(true))
    }
  }, [])

  return (
    <Grid container component="main" className={classes.root} style={{ maxWidth: "500px" }} justify="center" alignItems="center">
    </Grid>

  );
}