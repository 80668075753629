import { Button, Grid, TextField } from '@material-ui/core';
import React from 'react';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import axios from 'axios';
import Global from '../../Global';
import moment from 'moment';
import { useHistory } from 'react-router';

export default function CreateInventory(props) {
    const history = useHistory()
    const [inventory, setInventory] = React.useState({
        date: Date.now(),
        local: "O'clock",
        name: 'Inventario ' + moment().format('DD/MM/YYYY'),
        password: '',
        passwordConfirm: ''
    });

    const saveInventory = () => {
        axios.post(Global.url + 'inventory', inventory).then(res => {
            history.push(`/inventory/${res.data._id}`, { password: `Bearer ${inventory.password}` })
        })
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ marginTop: '2rem' }}
        >
            <Grid item>
                <TextField label="Local" fullWidth={true} value={inventory.local} onChange={(e) => setInventory({ ...inventory, local: e.target.value })} />
            </Grid>
            <Grid item>
                <TextField label="Nombre" fullWidth={true} value={inventory.name} onChange={(e) => setInventory({ ...inventory, name: e.target.value })} />
            </Grid>
            <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                    <KeyboardDatePicker
                        label="Fecha"
                        format="dd/MM/yyyy"
                        value={inventory.date}
                        onChange={(e) => setInventory({ ...inventory, date: e })}
                        TextFieldComponent={(props) => <TextField {...props} fullWidth={true} />}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
                <TextField
                    label="Contraseña"
                    fullWidth={true}
                    value={inventory.password}
                    onChange={(e) => setInventory({ ...inventory, password: e.target.value })}
                    type="password"
                />
            </Grid>
            <Grid item>
                <TextField
                    label="Confirmar contraseña"
                    fullWidth={true}
                    value={inventory.passwordConfirm}
                    onChange={(e) => setInventory({ ...inventory, passwordConfirm: e.target.value })}
                    type="password"
                />
            </Grid>
            <Grid item>
                <Button disabled={!inventory.local || !inventory.name || !inventory.date ||
                    inventory.password !== inventory.passwordConfirm || inventory.passwordConfirm === '' || inventory.password.length < 4
                } onClick={() => saveInventory()} color="primary" variant="contained">Crear</Button>
            </Grid>
            <Grid item style={{ color: "red", fontWeight: "bold" }}>
                {inventory.password !== inventory.passwordConfirm
                    ? <span>Las contraseñas no coinciden</span>
                    : inventory.password.length < 4 && <span>La contraseña debe tener al menos 4 caracteres</span>
                }
            </Grid>
        </Grid>
    );
}