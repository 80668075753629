import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography, Checkbox, Accordion, AccordionSummary, AccordionDetails, FormControl, FormControlLabel, Grid, Paper, Select, MenuItem, InputLabel, CircularProgress, IconButton, Icon } from '@material-ui/core';
import MaterialTable from '../components/MaterialTablePrimary';
import moment from 'moment'

import axios from 'axios'
import Global from '../Global'

import Icons from '../components/Icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert';
import NominasEdit from './NominasEdit';
import fs from '../components/fs';
import _ from 'underscore';
import { Lock } from '@material-ui/icons';

String.prototype.contaN = function (str) {
    const fn = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim().replace(/\s{2,}/g, ' ');
    }
    const orgStr = fn(this);
    const str2 = fn(str);
    return orgStr.includes(str2);
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperDos: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    filterGroup: {
        textAlign: "left",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "600",
    },
    input: {
        height: 15,
        fontSize: "0.775rem",
    },
    inputAutocomplete: {
        height: 3,
        fontSize: "0.775rem",
    },
    inputAutocompleteLabel: {
        fontSize: "0.9rem",
    }
}));

export default function SS(props) {
    const classes = useStyles();
    const tableRef = React.useRef(null);
    const [edit, setEdit] = React.useState(false);
    const [selectedMonth, setSelectedMonth] = React.useState();
    const [selectedYear, setSelectedYear] = React.useState();
    const [edittionRow, setEdittionRow] = React.useState(null);
    const [actions, setActions] = React.useState({
        downloadTramos: false,
        downloadSolConf: false
    });
    const [columns, setColumns] = React.useState([
        {
            title: 'Estado',
            field: 'isClosed',
            type: 'boolean',
            hiddenByColumnsButton: true,
            hidden: false,
            render: rowData => (
                <Icon color="primary">
                    {rowData.isClosed ? <Lock /> : <></>}
                </Icon>
            ),
            cellStyle: { width: "3%" }
        },
        {
            title: 'Apellidos, Nombre',
            field: 'paradInfo.nombreCompleto',
        },
        {
            title: 'DNI',
            field: 'paradInfo.dni',
            hiddenByColumnsButton: true,
            hidden: false,
            cellStyle: { width: "10%" }
        },
        {
            title: 'D. Obra',
            field: 'obraInfo.denom',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true
        },
        {
            title: 'Plan Obra',
            field: 'obraInfo.plan',
            hiddenByColumnsButton: true,
            hidden: false,
        },
        {
            title: 'Turno',
            field: 'contratoInfo.turno',
            hiddenByColumnsButton: true,
            hidden: false,
        },
        {
            title: 'Clave contrato',
            field: 'contratoInfo.claveContrato',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true
        },
        {
            title: 'Fecha',
            field: 'strDate',
            hiddenByColumnsButton: true,
            hidden: false,
            defaultSort: 'asc',
            customSort: (a, b) => moment(b.fechaInicio).diff(moment(a.fechaInicio))
        },
        {
            title: 'Fecha inicio',
            field: 'fechaInicio',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true,
            type: 'date'
        },
        {
            title: 'Fecha fin',
            field: 'fechaFin',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true,
            type: 'date'
        },
        {
            title: 'Fecha creación',
            field: 'createdAt',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true,
            render: rowData => moment(rowData.createdAt).format('DD/MM/YYYY HH:mm:ss'),
            customSort: (a, b) => moment(b.createdAt).diff(moment(a.createdAt))
        },
        {
            title: 'Fecha modificación',
            field: 'updatedAt',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true,
            render: rowData => moment(rowData.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
            customSort: (a, b) => moment(b.updatedAt).diff(moment(a.updatedAt))
        }

    ])
    const [data, setData] = React.useState([]);
    const refreshData = () => {
        setData([])
        axios.get(Global.url + "nomina", props.token).then((res) => {
            setData(res.data.data.nominas)
        })
    }

    const submitShortcut = () => {
        let date;
        try {
            date = moment(`${Number(selectedMonth)+1}-${selectedYear}`, "MM-YYYY");
            if(!date.isValid()){
                throw "";
            }
        } catch (error) {
            swal("Fecha inválida", "Introduce una fecha válida", "error");
            return;
        }
        tableRef.current.state.originalData = tableRef.current.state.originalData.map(data => {
            data.tableData.checked = date.isSame(data.fechaInicio, 'month');
            return data;
        });
        tableRef.current.onQueryChange()
    }

    const sendActions = () => {
        const actionsParams = new URLSearchParams();
        const nominas = tableRef.current.state.originalData.filter(element => element.tableData.checked === true);
        actionsParams.append('nominas', JSON.stringify(nominas.map(element => element._id)));
        Object.keys(actions).forEach(key => {
            if (actions[key]) {
                axios.get(`${Global.url}nomina/actions/${key}?${actionsParams.toString()}`, { ...props.token, ["responseType"]: 'arraybuffer' }).then(response => {
                    const file = new Blob([response.data], { type: 'application/*' });
                    //Get file name from response header
                    let fileName;
                    try {
                        fileName = response.headers['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '');
                        fs.set(file, "BC", fileName);
                    } catch (error) {
                        console.log("ERROR AL OBTENER EL NOMBRE DEL FICHERO", error);
                    }
                }).catch(async error => {
                    try {
                        const blob = new Blob([error.response.data], { type: 'application/*' });
                        const errorJSON = JSON.parse(await blob.text());
                        swal({
                            title: "Error al generar los tramos",
                            text: errorJSON.message,
                            icon: "error",
                            button: "Vale"
                        })
                    } catch (error) {
                        console.log("ERROR DESCONOCIDO", error);
                    }
                });
            }
        })
    }

    React.useEffect(() => {
        tableRef.current.onQueryChange()
    }, [data]);
    React.useEffect(() => {
        refreshData()
    }, []);

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={9}>
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<div color='inherit' style={{ display: "flex", alignItems: "baseline" }}>
                                    <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                                    <Typography variant="h6">Nominas</Typography>
                                </div>}
                                tableRef={tableRef}
                                columns={columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        let res = data;
                                        if (query.search) {
                                            res = res.filter(row => {
                                                return columns.map(column => {
                                                    const field = _.get(column, "field", "");
                                                    return String(_.get(row, field.split("."), "")).contaN(query.search)
                                                }).some(result => result === true);
                                            });
                                        }
                                        if (query.orderBy) {
                                            const field = _.get(query, ['orderBy', 'field']);
                                            const customSort = _.get(query, ['orderBy', 'customSort']);
                                            if (field) {
                                                if (typeof customSort === "function") {
                                                    res.sort(customSort);
                                                } else {
                                                    res.sort((a, b) => {
                                                        const aVal = _.get(a, field.split("."), "");
                                                        const bVal = _.get(b, field.split("."), "");
                                                        if (aVal > bVal) {
                                                            return 1;
                                                        }
                                                        if (aVal < bVal) {
                                                            return -1;
                                                        }
                                                        return 0;
                                                    });
                                                }
                                            }
                                            const isDesc = _.get(query, ['orderDirection']) === "desc";
                                            if (isDesc) res.reverse();
                                        }
                                        res = res.slice(query.pageSize * query.page)
                                        resolve({
                                            data: res,
                                            page: query.page,
                                            totalCount: data.length,
                                        })
                                    })}
                                options={{
                                    selection: true,
                                    pageSize: 10,
                                    debounceInterval: 500,
                                    pageSizeOptions: [10, 25, 50, 100],
                                }}
                                icons={Icons}
                                actions={[
                                    {
                                        icon: Icons.Refresh,
                                        isFreeAction: true,
                                        onClick: () => refreshData(),
                                    },
                                ]}
                                filtrar={true}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'nominas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} nomina(s) seleccionado',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        },
                                        deleteTooltip: 'Eliminar',
                                        editRow: {
                                            deleteText: '¿Segura(o) que quiere eliminar?',
                                            cancelTooltip: 'Cancelar',
                                            saveTooltip: 'Guardar',
                                        },
                                    },
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Paper className={classes.paper}>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading} variant="body1" component="h3" color="primary">Atajos</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="body2" component="h4" className={classes.filterGroup}>Actualizar nominas abiertas</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Paper className={classes.paperDos} elevation={0}>
                                                    <FormControl style={{minWidth:"150px"}}>
                                                        <InputLabel id="month-selector-label">Mes</InputLabel>
                                                        <Select
                                                            labelId="month-selector-label"
                                                            id="month-selector"
                                                            value={selectedMonth}
                                                            label="Mes"
                                                            onChange={(e) => setSelectedMonth(e.target.value)}
                                                        >
                                                            <MenuItem value={0}>Enero</MenuItem>
                                                            <MenuItem value={1}>Febrero</MenuItem>
                                                            <MenuItem value={2}>Marzo</MenuItem>
                                                            <MenuItem value={3}>Abril</MenuItem>
                                                            <MenuItem value={4}>Mayo</MenuItem>
                                                            <MenuItem value={5}>Junio</MenuItem>
                                                            <MenuItem value={6}>Julio</MenuItem>
                                                            <MenuItem value={7}>Agosto</MenuItem>
                                                            <MenuItem value={8}>Septiembre</MenuItem>
                                                            <MenuItem value={9}>Octubre</MenuItem>
                                                            <MenuItem value={10}>Noviembre</MenuItem>
                                                            <MenuItem value={11}>Diciembre</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {/* Input to type a year with mask */}
                                                    <TextField
                                                        label="Año"
                                                        value={selectedYear}
                                                        onChange={(e) => setSelectedYear(e.target.value)}
                                                        style={{ maxWidth: "110px" }}
                                                        InputProps={{
                                                            inputComponent: Global.formatYear
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        autoComplete="something-new"

                                                    />
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={submitShortcut}
                                                    disabled={typeof selectedMonth === "undefined" || typeof selectedYear === "undefined"}
                                                >Aplicar atajos</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading} variant="body1" component="h3" color="primary">Acciones</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" component="h4" className={classes.filterGroup}>Acciones</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <FormControlLabel
                                                    control={<Checkbox style={{ padding: 0, paddingRight: "5px" }} checked={actions.downloadTramos} onChange={(event) => { setActions({ ...actions, ["downloadTramos"]: event.target.checked }) }} />}
                                                    label={<Typography style={{ fontSize: "0.8rem" }}>Descargar tramos</Typography>}
                                                    style={{ marginLeft: 0 }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <FormControlLabel
                                                    control={<Checkbox style={{ padding: 0, paddingRight: "5px" }} checked={actions.downloadSolConf} onChange={(event) => { setActions({ ...actions, ["downloadSolConf"]: event.target.checked }) }} />}
                                                    label={<Typography style={{ fontSize: "0.8rem" }}>Descargar Solicitud de confirmación</Typography>}
                                                    style={{ marginLeft: 0 }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => sendActions(false)}
                                                disabled={!actions.downloadTramos && !actions.downloadSolConf}
                                            >Aplicar acciones</Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            {(edit && edittionRow) && <NominasEdit edittionRow={edittionRow} setEdit={setEdit} setEdittionRow={setEdittionRow} token={props.token} user={props.user} />}
        </React.Fragment>
    );
}