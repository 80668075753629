import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { NavLink } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
    tab: {
        height: "30px",
        marginTop: "0px",
        fontSize: "0.6rem",
        width: "auto",
        minWidth: "40px"
    },
    tabs: {
        height: "48px"
    }
}));

export default function FullWidthTabs(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static" component="div" elevation={0} style={{ height: "48px", backgroundColor: "#eee", float: "left", flexDirection: "row", justifyContent: "flex-end" }}>
                <Tabs
                    variant="scrollable"
                    textColor="primary"
                    scrollButtons="auto"
                    className={classes.tabs}
                >
                    <Tab label="Nominas" to="/gestion/nominas" component={NavLink} activeClassName={"selected"} className={classes.tab} />
                    <Tab label="Contratos" to="/gestion/contratos" component={NavLink} activeClassName={"selected"} className={classes.tab} />
                    <Tab label="Turnos" to="/gestion/turnos" component={NavLink} activeClassName={"selected"} className={classes.tab} />
                    <Tab label="S.S." to="/gestion/ss" component={NavLink} activeClassName={"selected"} className={classes.tab} />
                </Tabs>

            </AppBar>

        </div>
    );
}