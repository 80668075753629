import React from "react";
import ProductSelector from './ProductSelector';
import Grid from '@material-ui/core/Grid';
import { useHistory } from "react-router-dom";
import axios from "axios";
import Global from "../../Global";
import { useParams } from "react-router";
import MaterialTable from "material-table";
import swal from "sweetalert";
import { Button } from "@material-ui/core";

export default function Inventory() {
    const { id } = useParams();
    const [products, setProducts] = React.useState([]);
    const [inventory, setInventory] = React.useState(null);
    const history = useHistory();
    const [tableData, setTableData] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [headers, setHeaders] = React.useState();
    const [isShowSummary, setIsShowSummary] = React.useState(false);
    const [isOnlySummary, setIsOnlySummary] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const updateData = (data) => {
        setProducts(data.products);
        setInventory(data.inventory);
        setLoading(false);
    };

    const saveItem = (item, isNewProduct = false) => {
        const isOlderProduct = !isNewProduct;
        setLoading(true);
        axios.post(`${Global.url}inventory/${id}/product/${isOlderProduct ? item.product_id : ''}`, item, headers).then(res => {
            updateData(res.data);
        }).catch(handleRequestError);
    };

    const deleteItem = (item) => {
        // Alert to confirm the delete
        swal({
            title: `¿Estás seguro de eliminar ${item.name}?`,
            text: "No podrás revertir esto!",
            icon: "warning",
            buttons: {
                salir: {
                    text: "Borrar",
                    value: true,
                    className: 'popup-button-red',
                },
                ok: {
                    text: "Cancelar",
                    className: 'popup-button',
                    value: false,
                },
            },
        }).then((willDelete) => {
            if (willDelete) {
                setLoading(true);
                axios.delete(`${Global.url}inventory/${id}/item/${item._id}`, headers).then(res => {
                    updateData(res.data);
                }).catch(handleRequestError);
            }
        });
    };

    const getSummary = () => {
        setLoading(true);
        if (isOnlySummary) return introducePassword();
        if (isShowSummary) {
            getInventory(headers);
        } else {
            axios.get(`${Global.url}inventory/${id}/summary`, headers).then(res => {
                setTableData(res.data);
                setIsShowSummary(true);
                setLoading(false);
            });
        }
    };

    const introducePassword = async () => {
        const pass = await swal({
            title: 'Contraseña',
            content: {
                element: "input",
                attributes: {
                    placeholder: "Ingresa la contraseña del inventario",
                    type: "password"
                },
            }
        });
        history.push(`/old/inventory/${id}`, { password: pass ? `Bearer ${pass}` : undefined });
    };

    const handleRequestError = (err) => {
        if (err.response.status === 401) {
            swal({
                title: "Contraseña incorrecta o no proporcionada",
                text: "Por favor ingresa la contraseña del inventario si quieres editarlo.\n\nSi no deseas editarlo, puedes continuar sin contraseña.",
                icon: "warning",
                buttons: {
                    salir: {
                        text: "Cancelar",
                        value: false,
                        className: 'popup-button',
                    },
                    ok: {
                        text: "Ingresar",
                        value: true,
                        className: 'popup-button-red',
                    },
                },
            }).then(async (willDelete) => {
                if (willDelete) {
                    introducePassword();
                } else {
                    setIsOnlySummary(true);
                    setIsShowSummary(true);
                    getSummary();
                }
            });
        }
    }

    const getInventory = (headers) => axios.get(`${Global.url}inventory/${id}`, headers).then(res => {
        setIsShowSummary(false);
        updateData(res.data);
        return Promise.resolve();
    })

    React.useEffect(() => {
        let token;
        if (history.location.state?.password) {
            token = history.location.state.password;
            localStorage.setItem(`token-${id}`, token);
        } else {
            const localStorageToken = localStorage.getItem(`token-${id}`);
            if (localStorageToken) {
                token = localStorageToken;
            }
        }
        const newHeaders = {
            headers: {
                Authorization: token
            }
        }
        getInventory(newHeaders).then(_ => {
            setHeaders(newHeaders);
        }).catch(handleRequestError);
    }, []);

    React.useEffect(() => {
        if (inventory) {
            if (isShowSummary) setIsShowSummary(false);
            setTableData(inventory.items);
        }
    }, [inventory]);

    React.useEffect(() => {
        setCategories([...new Set(products.map(product => product.category))]);
    }, [products]);

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ marginTop: '2rem' }}
        >
            {loading
                ? <Grid item>
                    <h1>Cargando...</h1>
                </Grid>
                : <>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={getSummary}>{isShowSummary ? 'Editar' : 'Ver resumen'}</Button>
                    </Grid>
                    {!isShowSummary && (<Grid item>
                        <ProductSelector products={products} onSave={saveItem} categories={categories} />
                    </Grid>)}
                    <Grid item style={{ width: '100%', padding: '0px' }}>
                        <ItemList items={tableData} onDeleteItem={deleteItem} isShowSummary={isShowSummary} />
                    </Grid>
                </>
            }

        </Grid>
    );
}

/** 
 * Item
 * {
 * product_id: Id of the related product,
 * name: Name of the related product,
 * quantityMajor: Quantity of the product in the inventory,
 * quantityMinor: Quantity of the product in the inventory,
 * createdAt: Date of creation
 * }
 *
 * */
// Create a Item table to show the items in the inventory with material-table
function ItemList(props) {
    const { items, onDeleteItem, isShowSummary } = props;
    const columns = [
        { title: 'Nombre', field: 'name' },
        { title: 'Cajas', field: 'quantityMajor', cellStyle: { fontWeight: 'bold' } },
        { title: 'Unidades', field: 'quantityMinor', cellStyle: { fontWeight: 'bold' } },
    ];
    if (isShowSummary) columns.push({ title: 'Categoria', field: 'category', defaultGroupOrder: 0 });
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ marginTop: '2rem', width: '100%' }}
        >
            <Grid item
                //delete padding
                style={{ width: '100%', padding: '10px' }}
            >
                <MaterialTable
                    title=""
                    columns={columns}
                    data={items.reverse()}
                    //Create actions to delete rows
                    actions={isShowSummary ? [] : [
                        {
                            icon: 'delete',
                            tooltip: 'Delete Item',
                            onClick: (event, rowData) => {
                                onDeleteItem(rowData);
                            }
                        }
                    ]}
                    options={{
                        pageSize: 25,
                        pageSizeOptions: [25, 50, 100, 200],
                    }}
                />
            </Grid>
        </Grid>
    );
}


