import React from "react";
import moment from 'moment'

export default function WeatherCardSubHeader(props) {
  const { currentWeather } = props;
  const date = moment().isValid(currentWeather.date) ? currentWeather.date : "";
  const description = currentWeather.description
    ? currentWeather.description
    : "";

  return (
    <>
      <span>
        {moment(date).format("dddd").charAt(0).toUpperCase()+moment(date).format("dddd").substr(1).toLowerCase()}, {moment(date).format("h:mm")}{" "}
        {moment(date).format("A").charAt(0).toUpperCase()+moment(date).format("A").substr(1).toLowerCase()},{" "}
        {description.replace(/\w\S*/g, txt => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })}
      </span>
    </>
  );
}
