import Ayun from './AdminAyunTable'
import User from './AdminUserTable'
import Cotizaciones from './Cotizaciones'

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
}));

export default function FullWidthTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Usuarios" {...a11yProps(0)} />
                    <Tab label="Ayuntamientos" {...a11yProps(1)} />
                    <Tab label="Cotizaciones" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
                <TabPanel value={value} index={0} component={'span'}>
                    <User user={props.user} ayun={props.ayun} client={props.client} setClient={props.setClient} setAyun={props.setAyun}></User>
                </TabPanel>
                <TabPanel value={value} index={1} component={'span'}>
                    <Ayun user={props.user} ayun={props.ayun} client={props.client} setClient={props.setClient} setAyun={props.setAyun}></Ayun>
                </TabPanel>
                <TabPanel value={value} index={2} component={'span'}>
                    <Cotizaciones token={props.user}></Cotizaciones>
                </TabPanel>
        </div>
    );
}