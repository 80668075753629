import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import NominaTableHeader from './NominaTableHeader'
import NominaTableRow from './NominaTableRow'
import NominaTableBlankSpace from './NominaTableBlankSpace'
import commonStyles from '../commonStyles';

const tableRowsCount = 20;

const styles = commonStyles({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 17,
    borderWidth: 2,
    borderColor: '#848484',
  },
  container: {
    flexDirection: 'row',
    borderBottomColor: '#848484',
    borderBottomWidth: 2,
    backgroundColor: '#fbfbfb',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 4,
    height: 16,
    fontSize: 8,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  }
});

const NominaItemsTable = ({ conceptos, totales }) => (
  <View style={styles.tableContainer}>
    <View style={styles.container}>
      <Text style={styles.header}>DESGLOSE DE LA NÓMINA</Text>
    </View>
    <NominaTableHeader />
    <NominaTableRow
      conceptos={conceptos}
      BlankSpaces={<NominaTableBlankSpace rowsCount={Math.max(tableRowsCount, conceptos.length) - conceptos.length}/>}
      totales={totales}
    />
  </View>
);

export default NominaItemsTable