import React, { Component } from 'react';
import './assets/css/App.css';

import Router from './Router';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';



function App() {
  var theme = createMuiTheme({
    palette: {
      primary: {
        main: '#262087'
      },
      secondary: {
        main: '#1D6069'
      },
      white: {
        main: '#ffffff'
      },
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "0.75rem",
          backgroundColor: "rgba(10, 10, 10, 0.4)"
        }
      },
      MuiTableCell: {
        root: {
          paddingTop: 5,
          paddingBottom: 5,
          fontSize: '0.8rem !important'
        },
        footer: {
          backgroundColor: "#fff"
        }
      },
      MuiTableRow: {
        root: {
          transition:"all 0ms !important"
        },
        hover: {
          "&:hover": {
            backgroundColor: '#262087e6 !important',
            color:"#fff"
          },
        }
      },
    },
    spacing: factor => `${Number(value) / 200 * factor}rem`
  })
  const [value, setValue] = React.useState(localStorage.getItem('fontSize') || "100")
  React.useEffect(() => {
    document.getElementById('html').style.fontSize = `${value}%`
    localStorage.setItem('fontSize', value)
  }, [value])
  return (

    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Router setFontSize={setValue} fontSize={value} />

      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
