import RobotoRegular from "./Roboto-Regular.ttf";
import RobotoBold from "./Roboto-Bold.ttf";
import RobotoItalic from "./Roboto-Italic.ttf";

const Roboto = {
    regular: { src: RobotoRegular }, // font-style: normal, font-weight: normal
    italic: { src: RobotoItalic, fontStyle: 'italic' },
    bold: { src: RobotoBold, fontStyle: 'bold', fontWeight: 700 },
}

export default Roboto;