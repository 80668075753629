import axios from "axios";
import Global from "../Global";

export default {
    validaIncapacidad: (incapacidad, idContrato, token, toDelete) => new Promise((resolve, reject) => {
        const lastParams = incapacidad._id ? `/${incapacidad._id}${toDelete?"/delete":""}` : "";
        axios.put(
            `${Global.url}contrato/${idContrato}/incapacidad${lastParams}/validate`,
            incapacidad,
            token
        ).then(({ data }) => {
            resolve({ valid: true, data:data.data });
        }).catch(err => {
            resolve({ valid: false, message: err.response.data.message, longMessage: err.response.data.longMessage });
        });
    })
}