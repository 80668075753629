import React from "react";
import { Page, Document, StyleSheet, Font } from "@react-pdf/renderer";
import Roboto from "../../assets/fonts/Roboto";

import Header from "./Header";
import Conceptos from "./Conceptos";
import Info from "./Info";
import Footer from "./Footer";

Font.register({
  family: 'Roboto', fonts: [
    Roboto.regular,
    Roboto.bold,
    Roboto.italic
  ]
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontSize: 7,
    padding: "1cm 2cm",
    lineHeight: 1.5,
    flexDirection: "column"
  },
  logo: {
    width: 74,
    height: 66,
    marginLeft: "auto",
    marginRight: "auto"
  }
});

const Nomina = ({ values }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Header paradInfo={values.paradInfo} contratoInfo={values.contratoInfo} ayunInfo={values.ayunInfo} />
      <Conceptos
        conceptos={values.conceptos}
        totales={{
          devengos: values.determinacionData.totalBruto,
          descuentos: values.determinacionData.totalDeducciones,
          neto: values.determinacionData.totalLiquido
        }} />
      <Info data={values.determinacionData} />
      <Footer ayunInfo={values.ayunInfo} />
    </Page>
  </Document>
);

export default Nomina;
