import React, { Component } from 'react';

class MiComponete extends Component {

    render() {
        return (
            <h1>Componente</h1>
        );
    }

}
export default MiComponete;