import { StyleSheet } from '@react-pdf/renderer';

const borderColor = '#ccc'


const commonStyles = {
    row: {
        flexDirection: 'row',
        borderBottomColor: '#f7f7f7',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 12
    },
    header:{
        width:'100%'
    },
    headerRow:{
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20
    },
    headerColumn:{
        flexDirection: 'column',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    headerLabel:{
        fontStyle: 'italic',
        fontSize: 6,
        marginLeft: 1
    },
    headerValue:{
        fontStyle: 'bold',
        marginLeft: 4
    },
    concepto: {
        width: '35%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    dd: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    base: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    coef: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    devengos: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1
    },
    descuentos: {
        width: '15%'
    },
    totalRow:{
        flexDirection: 'row',
        borderTopColor: '#848484',
        borderTopWidth: 1,
        alignItems: 'center',
        height: 14
    },
    totalLabel: {
        width: '70%',
        fontStyle: 'bold',
        fontSize: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    determinacionRow:{
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: 11
    },
    determinacionItem:{
        fontSize: 7,
        flexDirection: 'row',
        alignItems: 'center'
    }
};

export default (specialStyles = {}) => {
    const styles = Object.assign({}, commonStyles, specialStyles);
    return StyleSheet.create(styles);
}