import React, { forwardRef } from 'react';
import { Add, Refresh, Edit, Delete, GetApp, Settings } from '@material-ui/icons/';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    column: {
        display: "flex",
        flexDirection: "column",
    }
});
export default {
    Add: forwardRef((props, ref) => <IconButton {...props} ref={ref} style={{ padding: "0" }} color="primary">
        <Add />
        <div style={{ fontSize: "1rem" }}>
            Añadir
</div>
    </IconButton>),
    Refresh: forwardRef((props, ref) => <IconButton {...props} ref={ref} style={{ padding: "0" }} color="primary">
        <Refresh />
        <div style={{ fontSize: "1rem" }}>
            Actualizar
    </div>
    </IconButton>),
    Settings: forwardRef((props, ref) => <IconButton {...props} ref={ref} style={{ padding: "0" }} color="primary">
    <Settings />
    <div style={{ fontSize: "1rem" }}>
        Opciones
</div>
</IconButton>),
    Edit: forwardRef((props, ref) => <IconButton {...props} ref={ref} style={{ display: "flex", flexDirection: "row", padding: "0" }} classes={{
        label: useStyles().column
    }} color="primary" >
        <Edit fontSize="small" />
        <div style={{ fontSize: "0.6rem" }}>
            Editar
</div>
    </IconButton >),
    Delete: forwardRef((props, ref) => <IconButton {...props} ref={ref} style={{ display: "flex", flexDirection: "row", padding: "0" }} classes={{
        label: useStyles().column
    }} color="primary" >
        <Delete fontSize="small" />
        <div style={{ fontSize: "0.6rem" }}>
            Borrar
</div>
    </IconButton >),
    Download: forwardRef((props, ref) => <IconButton {...props} ref={ref} style={{ padding: "0" }} color="primary">
        <GetApp />
        <div style={{ fontSize: "1rem" }}>
            Generar Doc.
</div>
    </IconButton>),
}