import React from 'react'
import { Grid, Paper, Typography, Button } from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Icons from '../components/Icons'
const useStyles = makeStyles((theme) => ({
    rootTwo: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: 'none',
    },
    input: {
        height: 15,
        fontSize: "0.8rem",
    },
    disableButton: {
        display: "none"
    }
}));
export default function EpiSalud(props) {
    const classes = useStyles();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} lg={12}> 
                <Paper className={classes.paper}>
                    <MaterialTable
                        title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Contratos</Typography>}
                        columns={props.localization === "P"?[
                            { title: 'Denominación', field: 'denom', hiddenByColumnsButton: true, hidden: false, cellStyle:{width:"30%"} },
                            { title: 'Fecha alta', field: 'fechaAlta', defaultSort: 'desc', render: rowData => <span style={{ width: 50, borderRadius: '50%' }}>{moment(rowData.fechaAlta).format("DD/MM/YYYY")}</span>, hiddenByColumnsButton: true, hidden: false, },
                            { title: 'Fecha baja', field: 'fechaBaja', render: rowData => <span style={{ width: 50, borderRadius: '50%' }}>{moment(rowData.fechaBaja).isValid() ? moment(rowData.fechaBaja).format("DD/MM/YYYY") : "No consta"}</span>, hiddenByColumnsButton: true, hidden: false, },
                            { title: 'Oferta de empleo', field: 'codOfertaEmpleo', hiddenByColumnsButton: true, hidden: false, ocultar: true },
                            {
                                title: 'Tipo', field: 'claveContrato',
                                render: rowData =>
                                    JSON.parse(localStorage.getItem("ZTA_claveContrato")).find(element => element.CODCC === rowData.claveContrato) ?JSON.parse(localStorage.getItem("ZTA_claveContrato")).find(element => element.CODCC === rowData.claveContrato).DESCC :"Sin definir",
                                hiddenByColumnsButton:true, hidden: false, ocultar: true, 
                                cellStyle:{width:"30%", fontSize:"0.8rem"}
                            },
                    
                        ]:[
                            {
                                title: 'Apellidos, Nombre',
                                render: rowData => <span>{`${rowData.primerApellido} ${rowData.segundoApellido}, ${rowData.nombre}`}</span>,
                                customFilterAndSearch: (term, rowData) => (rowData.primerApellido + ' ' + rowData.segundoApellido + ', ' + rowData.nombre).toUpperCase().indexOf(term.toUpperCase()) !== -1,
                                hiddenByColumnsButton: true, hidden: false,
                            },
                            { title: 'DNI', field: 'dni', hiddenByColumnsButton:true, hidden:false, cellStyle:{width:"10%"} },
                            { title: 'Fecha alta', field: 'fechaAlta', defaultSort: 'desc', render: rowData => <span style={{ width: 50, borderRadius: '50%' }}>{moment(rowData.fechaAlta).format("DD/MM/YYYY")}</span>, hiddenByColumnsButton: true, hidden: false, },
                            { title: 'Fecha baja', field: 'fechaBaja', render: rowData => <span style={{ width: 50, borderRadius: '50%' }}>{moment(rowData.fechaBaja).isValid() ? moment(rowData.fechaBaja).format("DD/MM/YYYY") : "No consta"}</span>, hiddenByColumnsButton: true, hidden: false, },
                            { title: 'Oferta de empleo', field: 'codOfertaEmpleo', hiddenByColumnsButton: true, hidden: false, ocultar: true },
                            {
                                title: 'Tipo', field: 'claveContrato',
                                render: rowData =>
                                    JSON.parse(localStorage.getItem("ZTA_claveContrato")).find(element => element.CODCC === rowData.claveContrato) ?JSON.parse(localStorage.getItem("ZTA_claveContrato")).find(element => element.CODCC === rowData.claveContrato).DESCC :"Sin definir",
                                hiddenByColumnsButton:true, hidden: false, ocultar: true, 
                                cellStyle:{width:"30%", fontSize:"0.8rem"}
                            },
                    
                        ]}
                        icons={Icons}
                        data={props.contratos}
                        options={{
                            search: false,
                            pageSize: 5,
                            pageSizeOptions: [5, 10, 15, 20],
                            headerStyle: {
                                color: 'rgba(0, 0, 0, 0.54)'
                            },
                            rowStyle: {
                                color: 'rgba(0, 0, 0, 0.54)'
                            },
                        }}

                        localization={{
                            pagination: {
                                labelDisplayedRows: '{from}-{to} de {count}',
                                labelRowsSelect: 'filas'
                            },
                            toolbar: {
                                nRowsSelected: '{0} Fila(s) seleccionada(s)',
                                searchPlaceholder: 'Buscar',
                                searchTooltip: 'Buscar',
                            },
                            header: {
                                actions: 'Acciones'
                            },
                            body: {
                                emptyDataSourceMessage: 'Sin datos',
                                filterRow: {
                                    filterTooltip: 'Filtrar'
                                },
                                addTooltip: "Nuevo EPI",
                                deleteTooltip: "Eliminar EPI",
                                editTooltip: "Editar EPI",
                                editRow: {
                                    deleteText: "¿Estás seguro de eliminar la fila?",
                                    cancelTooltip: "Cancelar",
                                    saveTooltip: "Guardar"
                                },
                            },

                        }}
                        onRowClick={(event, rowData) => {
                            props.setState({
                                edittionRow: rowData, 
                                edit: true,
                               })
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}

