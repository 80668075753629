import React from 'react';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import axios from 'axios'
import Global from '../Global'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'
import { pdf } from '@react-pdf/renderer';
import Nomina from '../pdf/Nomina/Nomina'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import GetApp from '@material-ui/icons/GetApp';
import moment from 'moment';
import fs from '../components/fs';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  rootTwo: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  option: {
    fontSize: "0.8rem",
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputSelect: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputAutocomplete: {
    height: 3,
    fontSize: "0.8rem",
  },
  inputAutocompleteTable: {
    height: 17,
    fontSize: "13px",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tab: {
    height: "30px",
    marginTop: "0px",
    fontSize: "0.7rem",
  },
  tabs: {
    height: "48px"
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    padding: "0",
    zIndex: "30"
  },
  buttonDelete: {
    position: "absolute",
    bottom: "10px",
    right: "120px",
    padding: "0",
    zIndex: "30"
  },
  buttonGenerete: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    padding: "0",
    zIndex: "30"
  },
  tabButton: {
    '&:hover': {
      backgroundColor: 'initial !important'
    },
    fontSize: 'inherit',
  },
  table: {
    marginTop: '10px',
    border: '2px solid #848484'
  },
  cell: {
    padding: '5px',
    border: '1px solid #ccc'
  },
  columnTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  label: {
    fontSize: '75%',
    fontStyle: 'italic',
  },
  value: {
    marginLeft: '10px',
    fontWeight: '600',
  },
  row: {
    whiteSpace: 'pre',
    padding: '3px 10px',
    borderTop: '1px solid #f7f7f7',
    borderBottom: '1px solid #f7f7f7',
    borderRight: '1px solid #ccc',
    borderLeft: '1px solid #ccc'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  right: {
    textAlign: 'right'
  },
  limitWidth: {
    width: '60px'
  }
}));
export default function NominasEdit(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState(null);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const close = () => {
    props.setEdittionRow(null);
    props.setEdit(false);
  }
  const getNomina = async () => {
    const blob = await pdf(<Nomina values={values} />).toBlob()
    fs.set(blob, "OTROS", `${moment().format("YYYY-MM-DD HHmmss")} NOMINA ${values.paradInfo.nombreApellidos}.pdf`)
  }
  React.useEffect(() => {
    axios.get(`${Global.url}nomina/${props.edittionRow._id}`, props.token).then(res => {
      setValues(res.data.data.nomina);
    })

  }, []);
  if (values !== null) return (
    <div className='popup'>
      <div className="popup-container">
        <IconButton style={{ position: "absolute", top: "10px", right: "20px", padding: "0", zIndex: "30" }} color="primary" onClick={close}>
          <CloseIcon color="primary" fontSize="large" />
        </IconButton>

        <div className="popup-inner">
          <div className="popup-full-height"></div>
          <div className={classes.root}>
            <AppBar position="static" component="div" elevation={0} style={{ height: "48px", backgroundColor: "#eee", float: "left", flexDirection: "row", justifyContent: "flex-start" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                    Datos generales
                  </Button>
                }
                  {...a11yProps(0)} className={classes.tab} />
              </Tabs>

            </AppBar>
            <TabPanel value={value} index={0}>
              <div className={classes.rootTwo}>
                {/* Create a table with the info of the employee */}
                <Grid container className={classes.table} style={{ marginTop: '40px' }}>
                  <Grid item xs={8} className={classes.cell}>
                    <div className={classes.label}>
                      Apellidos y nombre del trabajador
                    </div>
                    <div className={classes.value}>
                      {values.paradInfo.nombreCompleto}
                    </div>
                  </Grid>
                  <Grid item xs={4} className={classes.cell}>
                    <div className={classes.label}>
                      Número de afiliación S.S.
                    </div>
                    <div className={classes.value}>
                      {values.paradInfo.numss}
                    </div>
                  </Grid>
                  <Grid item xs={2} className={classes.cell}>
                    <div className={classes.label}>
                      Grupo de Tarifa
                    </div>
                    <div className={classes.value}>
                      {values.contratoInfo.tarifaSS}
                    </div>
                  </Grid>
                  <Grid item xs={6} className={classes.cell}>
                    <div className={classes.label}>
                      Nivel/Categoría
                    </div>
                    <div className={classes.value}>
                      {`${values.contratoInfo.idCategoria} ${values.contratoInfo.nombreCategoria}`}
                    </div>
                  </Grid>
                  <Grid item xs={4} className={classes.cell}>
                    <div className={classes.label}>
                      N.I.F.
                    </div>
                    <div className={classes.value}>
                      {values.paradInfo.dni}
                    </div>
                  </Grid>
                  <Grid item xs={8} className={classes.cell}>
                    <div className={classes.label}>
                      Periodo de liquidación
                    </div>
                    <div className={classes.value}>
                      {values.contratoInfo.strDate}
                    </div>
                  </Grid>
                  <Grid item xs={4} className={classes.cell}>
                    <div className={classes.label}>
                      Días del periodo
                    </div>
                    <div className={classes.value}>
                      {values.contratoInfo.nDias}
                    </div>
                  </Grid>
                </Grid>
                <Grid container className={classes.table}>
                  <Grid item xs={4} className={[classes.cell, classes.columnTitle]}>
                    Concepto
                  </Grid>
                  <Grid item xs={1} className={[classes.cell, classes.columnTitle]}>
                    DD
                  </Grid>
                  <Grid item xs={2} className={[classes.cell, classes.columnTitle]}>
                    Base
                  </Grid>
                  <Grid item xs={1} className={[classes.cell, classes.columnTitle]}>
                    Coef.
                  </Grid>
                  <Grid item xs={2} className={[classes.cell, classes.columnTitle]}>
                    Devengos
                  </Grid>
                  <Grid item xs={2} className={[classes.cell, classes.columnTitle]}>
                    Descuentos
                  </Grid>
                  <Grid container>
                    {values.conceptos.map((concepto, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={4} className={classes.row}>
                          {concepto.nombre}
                        </Grid>
                        <Grid item xs={1} className={[classes.row, classes.center]}>
                          {concepto.dias || ""}
                        </Grid>
                        <Grid item xs={2} className={[classes.row, classes.center]}>
                          <Box className={[classes.right, classes.limitWidth]}>
                            {Global.roundFloat(concepto.importe)}
                          </Box>
                        </Grid>
                        <Grid item xs={1} className={[classes.row, classes.center]}>
                          <Box className={[classes.right, classes.limitWidth]}>
                            {concepto.coef === 1 ? "" : Global.roundPor(concepto.coef * 100)}
                          </Box>
                        </Grid>
                        <Grid item xs={2} className={[classes.row, classes.center]}>
                          <Box className={[classes.right, classes.limitWidth]}>
                            {Global.roundFloat(concepto.subtotal)}
                          </Box>
                        </Grid>
                        <Grid item xs={2} className={[classes.row, classes.center]}>
                          <Box className={[classes.right, classes.limitWidth]}>
                            {Global.roundFloat(concepto.descuento)}
                          </Box>
                        </Grid>
                      </React.Fragment>)
                    )}
                  </Grid>
                  {props.user.rol >= 0 &&
                    <IconButton style={{ display: "flex", flexDirection: "column", padding: "0" }} color="secondary" className={classes.buttonGenerete}
                      onClick={() => {
                        getNomina()
                      }}>
                      <GetApp color="inherit" />
                      <div style={{ fontSize: "1rem" }}>
                        Generar nómina
                      </div>
                    </IconButton>
                  }
                </Grid>
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    </div >
  );
  else return null;
}