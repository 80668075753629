import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Settings } from '@material-ui/icons/';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles((theme) => ({
    menuItem: {
    }
}));
export default function SimpleMenu(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const setColumns = async (x) => {
        props.onColumnsChanged(x, !x.hidden)
    }
    React.useEffect(() => {
        var res = props.columns.filter(element => element.ocultar === true)
        if (res.length > 0) setTimeout(() => {
            res.map((x, i) => props.onColumnsChanged(x, true))
        }, 10)
    }, []);
    return (
        <div>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} color="primary">
                <Settings />
                <div style={{ fontSize: "1rem" }}>Opciones</div>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {props.options &&
                    props.options.map((x) => (<MenuItem className={classes.menuItem}>{x}</MenuItem>))

                }
                {props.columns.filter(x=>!x.disallowHidden).map((x, i) => (
                    <MenuItem key={i}>
                        <FormControlLabel
                            key={i}
                            control={<Checkbox
                                size="small"
                                style={{ padding: 0, paddingRight: "5px" }}
                                checked={!x.hidden}
                                color="primary"
                                onChange={() => setColumns(x)}
                                name="checkedA" />}
                            label={<Typography style={{ fontSize: "0.8rem" }}>{x.title}</Typography>}
                            style={{ marginLeft: 0 }}
                        />
                    </MenuItem>
                ))}

            </Menu>
        </div>
    );
}