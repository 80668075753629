import React from 'react'
import { Grid, Paper, Typography, Button } from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment'
import fs from '../components/fs'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import Global from '../Global'
import EpiPDF from '../pdf/EPI'
import { pdf } from '@react-pdf/renderer'
import Icons from '../components/Icons'
const useStyles = makeStyles((theme) => ({
    rootTwo: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: 'none',
    },
    input: {
        height: 15,
        fontSize: "0.8rem",
    },
    disableButton: {
        display: "none"
    }
}));
export default function EpiSalud(props) {
    const classes = useStyles();
    const [epi, setEpi] = React.useState([]);
    const [salud, setSalud] = React.useState([]);
    React.useEffect(() => {
        if (props.parad._id && epi.length === 0) axios.get(`${Global.url}epi-parad/${props.parad._id}`, props.token).then(res => {
            setEpi(res.data.epi)
        })
        if (props.parad._id && salud.length === 0) axios.get(`${Global.url}salud-parad/${props.parad._id}`, props.token).then(res => {
            setSalud(res.data.salud)
        })
    }, [])
    const pdff = async (rowData)=>{
        if(rowData){
            const blob = await pdf(<EpiPDF rowData={rowData} ayun={props.ayun} parad={props.parad}/>).toBlob()
            fs.set(blob, "OTROS", `${moment().format("YYYY-MM-DD HHmmss")} EPI ${props.parad.nombre} ${props.parad.primerApellido+" "+props.parad.segundoApellido}.pdf`)
        }
    }
    return (
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={8}> {/* EPIS */}
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>EPIs</Typography>}
                                columns={[
                                    {
                                        title: 'Fecha',
                                        field: 'date',
                                        type: "date",
                                        align: "center",
                                        defaultSort: 'desc',
                                        render: rowData => <span >{moment(rowData.date).format("DD/MM/YYYY")}</span>,
                                        editComponent: props => (
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableFuture={true}
                                                    variant="inline"
                                                    format="dd/MM/yyyy"
                                                    label="Fecha"
                                                    value={props.value}
                                                    onChange={(date) => props.onChange(date)}
                                                    InputAdornmentProps={{
                                                        className: classes.disableButton
                                                    }}
                                                    InputProps={{
                                                        className: classes.input
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    fullWidth
                                                    disableFuture={{ style: { display: "none" } }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        )
                                    },
                                    { title: 'Cantidad', field: 'quantity', type: "numeric", align: "center" },
                                    { title: 'Tipo', field: 'type' },
                                    { title: 'Detalles', field: 'typeTwo' },
                                ]}
                                icons={Icons}
                                data={epi}
                                editable={{
                                    onRowAdd: (newData) =>
                                        new Promise(async (resolve) => {
                                            if (props.user.rol >= 1) {
                                                await axios.post(`${Global.url}save-epi`, { ...newData, ["idParad"]: props.parad._id }, props.token).then(res => {
                                                    setEpi(res.data.epis)
                                                })
                                            }
                                            resolve()
                                        }),
                                    onRowUpdate: (newData) =>
                                        new Promise(async (resolve) => {
                                            if (props.user.rol >= 1) {
                                                await axios.post(`${Global.url}save-epi`, { ...newData, ["idParad"]: props.parad._id }, props.token).then(res => {
                                                    setEpi(res.data.epis)
                                                })
                                            }
                                            resolve()
                                        }),
                                }}
                                actions={[
                                    {
                                        icon: Icons.Download,
                                        tooltip: 'Generar resguardo',
                                        onClick: (evt, rowData) => { pdff(rowData); }
                                    }
                                ]}
                                options={{
                                    selection: true,
                                    search: false,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 15, 20],
                                    headerStyle: {
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    },
                                    rowStyle: {
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    },
                                }}

                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'filas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} Fila(s) seleccionada(s)',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        },
                                        addTooltip: "Nuevo EPI",
                                        deleteTooltip: "Eliminar EPI",
                                        editTooltip: "Editar EPI",
                                        editRow: {
                                            deleteText: "¿Estás seguro de eliminar la fila?",
                                            cancelTooltip: "Cancelar",
                                            saveTooltip: "Guardar"
                                        },
                                    },

                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} lg={4}> {/* SALUD */}
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Salud</Typography>}
                                columns={[
                                    {
                                        title: 'Fecha',
                                        field: 'date',
                                        type: "date",
                                        align: "center",
                                        defaultSort: 'desc',
                                        render: rowData => <span >{moment(rowData.date).format("DD/MM/YYYY")}</span>,
                                        editComponent: props => (
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableFuture={true}
                                                    variant="inline"
                                                    format="dd/MM/yyyy"
                                                    label="Fecha"
                                                    value={props.value}
                                                    onChange={(date) => props.onChange(date)}
                                                    InputAdornmentProps={{
                                                        className: classes.disableButton
                                                    }}
                                                    InputProps={{
                                                        className: classes.input
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    fullWidth
                                                    disableFuture={{ style: { display: "none" } }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        )
                                    },
                                    { title: 'Apto', field: 'favorable', lookup: { true: "Sí", false: "No" }, initialEditValue: true },
                                ]}
                                data={salud}
                                icons={Icons}
                                editable={{
                                    onRowAdd: (newData) =>
                                        new Promise(async (resolve) => {
                                            if (props.user.rol >= 1) {
                                                await axios.post(`${Global.url}save-salud`, { ...newData, ["idParad"]: props.parad._id }, props.token).then(res => {
                                                    setSalud(res.data.saluds)
                                                })
                                            }
                                            resolve()
                                        }),
                                    onRowUpdate: (newData) =>
                                        new Promise(async (resolve) => {
                                            if (props.user.rol >= 1) {
                                                await axios.post(`${Global.url}save-salud`, { ...newData, ["idParad"]: props.parad._id }, props.token).then(res => {
                                                    setSalud(res.data.saluds)
                                                })
                                            }
                                            resolve()
                                        }),
                                }}
                                options={{
                                    pageSize: 5,
                                    search: false,
                                    pageSizeOptions: [5, 10, 15, 20],
                                    headerStyle: {
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    },
                                    rowStyle: {
                                        color: 'rgba(0, 0, 0, 0.54)'
                                    },
                                }}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'filas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} row(s) seleccionada',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        },
                                        addTooltip: "Nuevo EPI",
                                        deleteTooltip: "Eliminar EPI",
                                        editTooltip: "Editar EPI",
                                        editRow: {
                                            deleteText: "¿Estás seguro de eliminar la fila?",
                                            cancelTooltip: "Cancelar",
                                            saveTooltip: "Guardar"
                                        },
                                    },

                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
    )
}

