import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MaterialTable from '../components/MaterialTablePrimary'
import Icons from '../components/Icons'

import Edit from './TablasSalarialesEdit'
import axios from 'axios'
import Global from '../Global'
import Typography from '@material-ui/core/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEuroSign } from '@fortawesome/free-solid-svg-icons'
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function Trabajadores(props) {
    const classes = useStyles();
    const [columns, setColumns] = React.useState([
        { title: 'Convenio', field: 'codigoConvenio', hiddenByColumnsButton:true, hidden:false },
        { title: 'Denominación', field: 'nombreConvenio', hiddenByColumnsButton:true, hidden:false },
        { title: 'Categoría', field: 'idCategoria', hiddenByColumnsButton:true, hidden:false },
        { title: 'Denominación', field: 'nombreCategoria', hiddenByColumnsButton:true, hidden:false },
        { title: 'Grupo', 
        field: 'tarifaSS', 
        render: rowData => 
            JSON.parse(localStorage.getItem("ZTA_SS_grupoCotizacion")).find(element => element.CODGP === rowData.tarifaSS).DESGP,
        hiddenByColumnsButton:true, 
        hidden:false, 
        ocultar:true },

    ])
    const [data, setData] = React.useState([])
    const [edit, setEdit] = React.useState(false)
    const [edittionRow, setEdittionRow] = React.useState()
    const defaultData = {
        actividad: props.ayun.salarial.actividad,
        codigoConvenio: props.ayun.salarial.codigoConvenio,
        nombreConvenio: props.ayun.salarial.nombreConvenio,
        conceptos: [],
    }
    React.useEffect(() => {
        setData(props.ts)
    }, [props.ts])
    const refreshData = () => {
        setData([])
        axios.get(Global.url + "getts", props.token).then(res => {
            props.setState({ ts: res.data.ts })
            setData(res.data.ts)
        })
    }
    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<div color='inherit' style={{ display: "flex", alignItems: "baseline" }}>
                                    <FontAwesomeIcon icon={faEuroSign} style={{ marginRight: "7px" }} />
                                    <Typography variant="h6">Tablas salarios</Typography>
                                </div>}
                                columns={columns}
                                data={data}
                                options={{
                                    pageSize: 10,
                                    pageSizeOptions: [10, 15, 20, 25]
                                }}
                                filtrar={true}
                                actions={[
                                    {
                                        icon: Icons.Add,
                                        isFreeAction: true,
                                        onClick: (event) => {
                                            setEdit(true)
                                            setEdittionRow(defaultData)
                                        }
                                    },
                                    {
                                        icon: Icons.Refresh,
                                        isFreeAction: true,
                                        onClick: () => refreshData(),
                                    },
                                ]}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'filas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} row(s) seleccionada',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        }
                                    }
                                }}
                                onRowClick={(event, rowData, togglePanel) => {
                                    setEdittionRow(rowData)
                                    setEdit(true)
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            {edit &&
                <Edit close={() => setEdit(false)} data={edittionRow} token={props.token} user={props.user} setOpen={props.setOpen} setState={props.setState} enfermedad={props.enfermedad}></Edit>
            }
        </React.Fragment>
    );
}