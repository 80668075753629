import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CreateInventory from './CreateInventory';
import Inventories from './Inventories';
import Inventory from './Inventory';
import { useHistory } from 'react-router-dom';

export default function InventoryRouter() {
    return (
        <Switch>
            <Route exact path="/old/inventory" component={() => <Inventories />} />
            <Route exact path="/old/inventory/add" component={() => <CreateInventory />} />
            <Route exact path="/old/inventory/:id" component={() => <Inventory />} />
            <Route path="/inventory" component={() => <NewInventory />} />
        </Switch>
    );
}

const NewInventory = () => {
    const history = useHistory();
    React.useEffect(() => {
        window.location.href = `https://inventory.elocal.es${history.location.pathname.replace('/inventory', '')}`;
    }, [])
    // 
    return null;
}