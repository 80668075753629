import React, { useEffect, useState } from 'react' 

import { useHistory } from 'react-router-dom' 

function cuantasVecesAparece(cadena, caracter){
    var indices = [];
    for(var i = 0; i < cadena.length; i++) {
      if (cadena[i].toLowerCase() === caracter) indices.push(i);
    }
      return indices.length;
  }
const RouteChange = (props) => { 
   const history = useHistory() 
   useEffect(() => {
      return history.listen((location, action) => { 
            
            if(action === "PUSH" && cuantasVecesAparece(location.pathname,"/") > 1) {
                props.onRouteChange()
            }
            
      }) 
   },[history])
   return(<React.Fragment></React.Fragment>)
}
export default RouteChange