import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { List, ListItem, ListItemIcon, ListItemText, Divider, Grid, Paper, Typography, TextField, Button } from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';

import axios from 'axios'
import Global from '../Global'
import Calendar, { DateUtils } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment';
import moment from 'moment'
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    }
}));

export default function Calendarios(props) {
    const classes = useStyles();
    const [selectedDays, setSelectedDays] = React.useState([])
    const [selectedDayWeekday, setSelectedDayWeekday] = React.useState([])
    const [selected, setSelected] = React.useState()
    const [errorID, setErrorID] = React.useState(false)
    const [errorIDText, setErrorIDText] = React.useState("")
    const [fechaInicial, setFechaInicial] = React.useState(moment("01/01/" + moment().year() + "T06:00", "DD/MM/YYYY[T]HH:mm"))
    const [fechaFinal, setFechaFinal] = React.useState(moment("31/12/" + moment().year() + "T23:00", "DD/MM/YYYY[T]HH:mm"))
    const weekdays = [
        { title: "Lunes", value: 1 },
        { title: "Martes", value: 2 },
        { title: "Miercoles", value: 3 },
        { title: "Jueves", value: 4 },
        { title: "Viernes", value: 5 },
        { title: "Sábado", value: 6 },
        { title: "Domingo", value: 7 },
    ]
    const handleDayClick = (day, { selected }) => {
        if (props.user.rol >= 1) {
            if (selected) {
                const selectedIndex = selectedDays.findIndex(selectedDay =>
                    DateUtils.isSameDay(selectedDay, day)
                );
                selectedDays.splice(selectedIndex, 1);
            } else {
                selectedDays.push(day);
            }
            selectedDays.sort((a, b) => a - b)
            setSelectedDays([...selectedDays]);
        }
    }
    const setSelectedDaysByWeekdays = (value) => {
        if (props.user.rol >= 1) {
            var remove = selectedDayWeekday.filter(elemento => value.indexOf(elemento) === -1)
            var add = value.filter(elemento => selectedDayWeekday.indexOf(elemento) === -1)
            var compareDate = []
            selectedDays.forEach((day) => {
                var momentDay = moment(day).format('L')
                compareDate.push(momentDay)
            })
            if (add.length > 0) {
                add = add[0]
                var fecha = moment(fechaInicial).isoWeekday(add.value)
                while (fecha.isBefore(fechaFinal)) {
                    if (!compareDate.includes(fecha.format('L'))) {
                        selectedDays.push(moment(fecha).toDate())
                    }
                    fecha = fecha.add(1, 'week')
                }
                selectedDays.sort((a, b) => a - b)
                setSelectedDays([...selectedDays])
            }
            if (remove.length > 0) {
                remove = remove[0]
                var res = selectedDays.filter(fecha => moment(fecha).isoWeekday() !== remove.value)
                setSelectedDays([...res])
            }
        }
    }
    const searchSelectedDaysByWeekdays = () => {
        var res = []
        var compareDate = []
        selectedDays.forEach((day) => {
            var momentDay = moment(day).format('L')
            compareDate.push(momentDay)
        })
        for (var i = 1; i <= 7; i++) {
            var fecha = moment(fechaInicial).isoWeekday(i)
            while (fecha.isBefore(fechaFinal)) {
                if (compareDate.includes(fecha.format('L'))) {
                    fecha = fecha.add(1, 'week')
                } else {
                    break
                }
                if (fecha.add(1, 'week').isAfter(fechaFinal)) {
                    res.push(weekdays.find(weekday => weekday.value === i))
                }
                fecha = fecha.add(1, 'week')
            }
        }
        setSelectedDayWeekday(res)
    }
    const saveCalendar = (isNew) => {
        if (isNew && !errorID) {
            const id = document.getElementById("id").value
            const _id = undefined
            const description = document.getElementById("description").value
            const date = selectedDays
            axios.post(Global.url + "savecalendar", { isNew, id, date, _id, description }, props.token).then(res => {
                props.setState({ calendar: res.data.calendar })
            })

        }
        if (!isNew) {
            const id = props.calendar[selected].id
            const _id = props.calendar[selected]._id
            const description = props.calendar[selected].description
            const date = selectedDays
            axios.post(Global.url + "savecalendar", { isNew, id, date, _id, description }, props.token).then(res => {
                props.setState({ calendar: res.data.calendar })
            })
        }

    }
    const handleID = (value) => {
        if (props.calendar.filter(e => e.id === Number(value)).length > 0 || value === undefined || value === "") {
            setErrorID(true)
            setErrorIDText("El ID especificado ya existe o no procede")
        } else {
            setErrorID(false)
            setErrorIDText("")
        }
    }
    const handleYearMonthChange = (date) => {
        setFechaInicial(moment("01/01/" + moment(date).year() + "T06:00", "DD/MM/YYYY[T]HH:mm"))
        setFechaFinal(moment("31/12/" + moment(date).year() + "T23:00", "DD/MM/YYYY[T]HH:mm"))
    }
    React.useEffect(() => {
        searchSelectedDaysByWeekdays()
    }, [selectedDays, fechaInicial, fechaFinal])
    React.useEffect(() => {
        if (selected !== -1 && selected !== undefined) {
            var res = []
            props.calendar[selected].date.forEach((day) => {
                res.push(moment(day).toDate())
            })
            setSelectedDays(res)
        } else {
            setSelectedDays([])
        }
    }, [selected])
    React.useEffect(() => {
        if (props.calendar.length === 0) {
            setSelected(-1)
        } else {
            setSelected(0)
        }
    }, [props.calendar])
    React.useEffect(() => {
        handleID(document.getElementById("id").value)
    }, [])
    return (
        <div className={classes.root}>
            <Grid container spacing={0} style={{ backgroundColor: "#fff" }}>
                <Grid item xs={12} sm={12} md={3} xl={2}> {/* Menu */}
                    <Paper style={{ width: "95%" }}>
                        <List component="nav">
                            {props.calendar.map((cal, i) => {
                                return (
                                    <ListItem key={i} button onClick={() => setSelected(i)}>
                                        <ListItemIcon>
                                        </ListItemIcon>
                                        <ListItemText primary={cal.description} secondary={"ID: " + cal.id} />
                                    </ListItem>
                                )
                            })}
                        </List>
                        {props.user.rol >= 1 &&
                            <React.Fragment>
                                <Divider />
                                <List component="nav">
                                    <ListItem button onClick={() => setSelected(-1)}>
                                        <ListItemIcon>
                                            <AddIcon></AddIcon>
                                        </ListItemIcon>
                                        <ListItemText primary="Nuevo calendario" />
                                    </ListItem>
                                </List>
                            </React.Fragment>
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={6}> {/* Calendario */}
                    <Paper style={{ boxShadow: "none", outline: "none" }}>
                        <Calendar
                            month={new Date(moment().year(), 0)}
                            fromMonth={new Date(moment().year(), 0)}
                            toMonth={new Date(moment().add(1, "years").year(), 12)}
                            localeUtils={MomentLocaleUtils}
                            locale={"es"}
                            numberOfMonths={12}
                            selectedDays={selectedDays}
                            onDayClick={handleDayClick}
                            pagedNavigation
                            fixedWeeks
                            onMonthChange={(date) => {
                                handleYearMonthChange(date)
                            }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={4}> {/* Data */}
                    <Paper style={{ boxShadow: "none", padding: "20px" }}>
                        {selected === -1 || selected === undefined
                            ? <React.Fragment>
                                <TextField
                                    label="Descripción"
                                    id="description"
                                    fullWidth
                                    autoComplete="something-new"
                                />
                                <TextField
                                    label="ID"
                                    id="id"
                                    style={{ marginTop: "5px", marginBottom: "16px" }}
                                    type="number"
                                    onChange={(e) => handleID(e.target.value)}
                                    error={errorID}
                                    helperText={errorIDText}
                                    fullWidth
                                    autoComplete="something-new"
                                />
                                <Button variant="outlined" color="primary" onClick={() => saveCalendar(true)}>
                                    Guardar
                                </Button>
                            </React.Fragment>
                            : <React.Fragment>
                                <Typography variant="h2" style={{ fontSize: "2rem" }}>{props.calendar[selected].description}</Typography>
                                <Typography variant="h4" style={{ fontSize: "1.8rem" }}>{'ID: ' + props.calendar[selected].id}</Typography>
                                {props.user.rol >= 1 &&
                                    <Button variant="outlined" color="primary" onClick={() => saveCalendar(false)}>
                                        Guardar cambios
                                     </Button>
                                }
                            </React.Fragment>
                        }
                        <br />
                        <Autocomplete
                            multiple
                            options={weekdays}
                            getOptionLabel={(option) => option.title}
                            value={selectedDayWeekday}
                            onChange={(event, value) => setSelectedDaysByWeekdays(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Seleccionar todos los..."
                                />
                            )}
                        />
                    </Paper>
                </Grid>

            </Grid>
        </div>
    );
}