import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import commonStyles from './../commonStyles';

const styles = commonStyles();

const parseToFloat = (value, symbol = "") => {
    if (value === undefined || value === null || value === "") {
        return "";
    }
    return parseFloat(value).toFixed(2).replace(/\./g, ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + symbol;
}


const GenerateText = ({ text, style, center }) => {
    let width = 20;
    if (typeof style.width === 'string') {
        width = parseInt((style.width.replace('%', '') / 2) ** 1.65);
    }
    if (typeof text !== "string" || text === "") {
        return <Text style={[style, { color: "white" }]}>-</Text>
    } else if (!center) {
        return <Text style={style}>&nbsp;&nbsp;{text}</Text>
    } else {
        return <View style={[style, { alignItems: "center" }]}><Text style={{ textAlign: "right", width: width }}>{text}</Text></View>
    }
}

const NominaTableRow = ({ conceptos, BlankSpaces, totales }) => {
    const rows = conceptos.map((concepto, i) =>
        <View style={styles.row} key={i}>
            <GenerateText text={concepto.nombre} style={styles.concepto} />
            <GenerateText text={String(concepto.dias || "")} style={styles.dd} center={true} />
            <GenerateText text={parseToFloat(concepto.importe)} style={styles.base} center={true} />
            <GenerateText text={(concepto.coef === 1 || typeof concepto.coef !== 'number') ? "" : parseToFloat(concepto.coef * 100, "%")} style={styles.coef} center={true} />
            <GenerateText text={parseToFloat(concepto.subtotal)} style={styles.devengos} center={true} />
            <GenerateText text={parseToFloat(concepto.descuento)} style={styles.descuentos} center={true} />
        </View>
    );
    return (<Fragment>
        {rows}
        {BlankSpaces}
        <View style={styles.totalRow}>
            <GenerateText text="TOTALES" style={styles.totalLabel} />
            <GenerateText text={parseToFloat(totales.devengos)} style={styles.devengos} center={true} />
            <GenerateText text={parseToFloat(totales.descuentos)} style={styles.descuentos} center={true} />
        </View>
        <View style={styles.totalRow}>
            <GenerateText text="TOTAL LÍQUIDO" style={styles.totalLabel} />
            <GenerateText text={parseToFloat(totales.neto)} style={styles.devengos} center={true} />
            <GenerateText text={"-"} style={[styles.descuentos, { color: "white" }]} />
        </View>
    </Fragment>)
};

export default NominaTableRow