import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import commonStyles from '../commonStyles';


const styles = commonStyles({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#848484',
    borderBottomWidth: 2,
    backgroundColor: '#fbfbfb',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 4,
    height: 18,
    fontSize: 9,
    textAlign: 'center',
    fontStyle: 'bold',
    flexGrow: 1,
  }
});

const HeaderHeader = () => (
  <View style={styles.container}>
    <Text style={styles.header}>RECIBO DE SALARIOS</Text>
  </View>
);

export default HeaderHeader