import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tabb from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import HomeIcon from '@material-ui/icons/HomeTwoTone';
import DialpadIcon from '@material-ui/icons/DialpadTwoTone';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileTwoTone';
import StorageIcon from '@material-ui/icons/StorageTwoTone';
import SettingsIcon from '@material-ui/icons/SettingsTwoTone';

import Ficheros from '../ficheros'
import Gestion from '../gestion'
import Utilidades from '../utilidades'
import { NavLink, Link, useHistory } from 'react-router-dom'
import Global from '../Global'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {(value === index && props.human) && (
                <Box p={3} style={{ padding: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
    tabs: {
        minWidth: "40%",
    },
    tab: {
        height: "60px",
        minWidth: "calc(40% / 4)",
        marginTop: "20px"
    },
    logo: {
        position: "absolute",
        top: 0,
        left: 0,
    },
    wrapper: {
        height: "80px",
        display: "flex",
        justify: "center",
        alignItems: "center",
        marginLeft: "10px",
        maxWidth: "228px",

    },
    img: {
        margin: "0 auto",
        width: "80%",
    },
    menu: {
        transform: "translate3d(0px, 80px, 0px) !important",
        zIndex: 15,
    },
    userData: {
        position: "absolute",
        right: "8px",
        top: "0",
    },
    userInfo: {
        paddingRight: '0.8rem',
        float: "rigth",
        fontSize: "0.8rem",
        textTransform: "uppercase",
        fontWeight: "bold",
        color: "white"
    },
    userWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: "0",
    },
}));


export default function FullWidthTabs(props) {
    const history = useHistory()
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [human, setHuman] = React.useState(false)
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const theme = useTheme();
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const selected = (number) => {
        if (number === 0) {
            return history.location.pathname === "/"
        }
    }
    const Tab = withStyles({
        root: {
            background: theme.palette.primary.main,
            border: 0,
            color: 'white',
            padding: '0 30px',
            '&:first-child': {
                marginLeft: "10px"
            }
        },
        selected: {
            background: 'white',
            borderRadius: '15px 15px 0 0',
            color: theme.palette.primary.main,
        },
        label: {
            textTransform: 'capitalize',
        },
    })(Tabb);
    useEffect(() => {
        if (history.location.pathname === "/") {
            setValue(0)
        }
        if (history.location.pathname.includes("/ficheros")) {
            setValue(1)
        }
        if (history.location.pathname.includes("/gestion")) {
            setValue(2)
        }
        if (history.location.pathname.includes("/listados")) {
            setValue(3)
        }
        if (history.location.pathname.includes("/utilidades")) {
            setValue(4)
        }
    }, [history.location])
    return (
        <React.Fragment>
            <div className={classes.root}>
                <AppBar position="static" color="default" style={{ height: "80px", backgroundColor: theme.palette.primary.main, float: "left", flexDirection: "row", justifyContent: "flex-end" }}>
                    {useMediaQuery('(min-width:900px)') ?
                        <React.Fragment>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                            <div style={{ width: "100%", backgroundColor: "#fff", height: "100%", borderBottomRightRadius: "50px", borderRight: "5px solid " + theme.palette.primary.main }}></div>
                            <div style={{ width: "100%", borderBottom: "10px solid " + theme.palette.primary.main }}></div>
                        </div>
                            <React.Fragment>
                                <div className={classes.logo}>
                                    <div className={classes.wrapper} id="logo-ayun">
                                        <img src={`${props.ayun.imgHeader}`} className={classes.img} alt={props.ayun.nombre} onError={(e) => {
                                            e.target.style.display = "none"
                                            document.getElementById("logo-ayun").innerHTML = `<h2 style="font-weight: bold; font-size:0.8rem; color:#262087;">${props.ayun.nombre}</h2>`
                                        }}></img>
                                    </div>
                                </div>
                                <Tabs
                                    value={value}
                                    textColor="primary"
                                    onChange={handleChange}
                                    variant="fullWidth"
                                    TabIndicatorProps={{ style: { display: "none" } }}
                                    className={classes.tabs}
                                >
                                    <Tab icon={<HomeIcon fontSize="small" classes={{ wrapper: classes.tabWrapper }} style={{ marginBottom: 0 }} />} {...a11yProps(0)} label="Inicio" exact to="/" component={NavLink} className={selected(0) ? "selected" : ""} className={classes.tab} />
                                    <Tab icon={<StorageIcon fontSize="small" style={{ marginBottom: 0 }} />} label="Ficheros" {...a11yProps(1)} onClick={() => setHuman(true)} className={classes.tab} />
                                    <Tab icon={<DialpadIcon fontSize="small" style={{ marginBottom: 0 }} />} label="Gestión" {...a11yProps(2)} onClick={() => setHuman(true)} className={classes.tab} />
                                    <Tab icon={<InsertDriveFileIcon fontSize="small" style={{ marginBottom: 0 }} />} label="Listados" {...a11yProps(3)} onClick={() => setHuman(true)} className={classes.tab} />
                                    <Tab icon={<SettingsIcon fontSize="small" style={{ marginBottom: 0 }} />} label="Utilidades" {...a11yProps(4)} onClick={() => setHuman(true)} className={classes.tab} />
                                </Tabs>
                                <Link to="/utilidades/mi-cuenta" style={{ all: 'unset' }}>
                                    <div className={classes.userData}>
                                        <div className={classes.userWrapper}>
                                            <div className={classes.userInfo}>
                                                <span>{props.user.apellidos + ", " + props.user.nombre}</span>
                                            </div>
                                            <IconButton style={{ display: "flex", flexDirection: "column", padding: "0", color: "white" }} onClick={props.signOut}>
                                                <PowerSettingsNewIcon style={{ color: "white" }} />
                                                <div style={{ fontSize: "1rem" }}>
                                                    Salir
                                        </div>
                                            </IconButton>

                                        </div>
                                    </div>
                                </Link>
                            </React.Fragment>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <IconButton
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}>
                                <MenuIcon />
                            </IconButton>
                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} className={classes.menu} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}

                                    >
                                        <Paper style={{ borderRadius: "0px" }}>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={open} id="menu-list-grow" >
                                                    <MenuItem exact to="/" component={NavLink} >Inicio</MenuItem>
                                                    <MenuItem onClick={() => { setValue(1); setHuman(true); setOpen(false); }} >Ficheros</MenuItem>
                                                    <MenuItem onClick={() => { setValue(2); setHuman(true); setOpen(false); }}>Gestión</MenuItem>
                                                    <MenuItem onClick={() => { setValue(3); setHuman(true); setOpen(false); }}>Listados</MenuItem>
                                                    <MenuItem onClick={() => { setValue(4); setHuman(true); setOpen(false); }}>Utilidades</MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>

                            <div className={classes.wrapper} id="logo-ayun-m" style={{backgroundColor:"inherit"}}>
                                <img src={`${props.ayun.imgHeader}`} className={classes.img} style={{mixBlendMode:"screen", filter:"invert(100%)", WebkitFilter:"invert(100%)", msFilter:"invert(100%)"}} alt={props.ayun.nombre} onError={(e) => {
                                    e.target.style.display = "none"
                                    document.getElementById("logo-ayun-m").innerHTML = `<h2 style="font-weight: bold; font-size:0.8rem; color:white;">${props.ayun.nombre}</h2>`
                                }}></img>
                            </div>
                            <div className={classes.userWrapper}>
                                <IconButton style={{ display: "flex", flexDirection: "column", padding: "0", color: "white" }} onClick={props.signOut}>
                                    <PowerSettingsNewIcon style={{ color: "white" }} />
                                    <div style={{ fontSize: "1rem" }}>
                                        Salir
                                            </div>
                                </IconButton>

                            </div>
                        </React.Fragment>
                    }

                </AppBar>
                <TabPanel value={value} index={1} human={human} component={'span'}>
                    <Ficheros ></Ficheros>
                </TabPanel>
                <TabPanel value={value} index={2} human={human} component={'span'}>
                    <Gestion ></Gestion>
                </TabPanel>
                <TabPanel value={value} index={3} human={human} component={'span'}>
                </TabPanel>
                <TabPanel value={value} index={4} human={human} component={'span'}>
                    <Utilidades ></Utilidades>
                </TabPanel>
            </div>

        </React.Fragment>
    );
}