import React from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import swal from 'sweetalert';
import axios from 'axios'
import Global from '../Global'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer, faTractor, faCopy } from '@fortawesome/free-solid-svg-icons'
import { InputBase } from '@material-ui/core/';
import MaterialTable from 'material-table'

import moment from 'moment'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  rootTwo: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  option: {
    fontSize: "0.8rem",
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputSelect: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputAutocomplete: {
    height: 3,
    fontSize: "0.8rem",
  },
  inputAutocompleteTable: {
    height: 17,
    fontSize: "13px",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tab: {
    height: "30px",
    marginTop: "0px",
    fontSize: "0.7rem",
  },
  tabs: {
    height: "48px"
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    padding: "0",
    zIndex: "30"
  },
  tabButton: {
    '&:hover': {
      backgroundColor: 'initial !important'
    },
    fontSize: 'inherit',
  },
  imitUnderline: {
    textAlign: 'center',
    width: "75%",
    padding: "3px 0 3px",
    fontSize: "0.8rem",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    "&:hover": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.87)"
    },
    "&:focus": {
      transition: "all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderBottom: "1px solid #228333"
    }
  },
  error: {
    textAlign: 'center',
    width: "75%",
    padding: "3px 0 3px",
    fontSize: "0.8rem",
    borderBottom: "1px solid red",
  }
}));
export default function InputAdornments(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState(props.data);
  const [value, setValue] = React.useState(0);
  const [error, setError] = React.useState(false)

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (prop) => (value) => {
    setValues({ ...values, [prop]: value });
  };
  const saveAndClose = () => {
    if (props.user.rol > 0) {
      axios.post(Global.url + '/admin/updatetipos', values, props.token).then(res => {
        if (res.data.status === "error") alert(res.data.message)
        else {
          props.setTipos(res.data.tip)
          props.close()
        }
      })
    } else {
      props.close()
    }
  }
  const save = () => {
    const info = Global.validateBases(values)
    if (info.obligatorios.length === 0) {
      saveAndClose()
    } else {
      swal({
        title: "Faltan campos obligatorios por rellenar",
        text: "Faltan: " + info.obligatorios.join(', '),
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
    }
  }
  const close = () => {
    const info = Global.validateBases(values, props.data)
    if (info.obligatorios.length > 0) {
      swal({
        title: "Faltan campos obligatorios por rellenar",
        text: "Faltan: " + info.obligatorios.join(', ') + ".",
        icon: "warning",
        buttons: {
          salir: {
            text: "Salir",
            value: false,
            className: 'popup-button-red',
          },
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      }).then(res => {
        if (!res && info.equal) props.close()
        else if (!res && !info.equal && values.idAyun) {
          if (error) {
            swal({
              title: "¿Desea salir sin guardar?",
              icon: "warning",
              buttons: {
                salir: {
                  text: "Salir",
                  value: false,
                  className: 'popup-button-red',
                },
                ok: {
                  text: "Guardar",
                  className: 'popup-button',
                  value: true,
                },
              },
            }).then(res => {
              if (res) saveAndClose()
              else props.close()
            })
          } else {
            props.close()
          }
        } else if (!values.idAyun && !res) {
          props.close()
        }
      })
    } else if (info.obligatorios.length === 0 && !info.equal && error) {
      swal({
        title: "¿Desea salir sin guardar?",
        icon: "warning",
        buttons: {
          salir: {
            text: "Salir",
            value: false,
            className: 'popup-button-red',
          },
          ok: {
            text: "Guardar",
            className: 'popup-button',
            value: true,
          },
        },
      }).then(res => {
        if (res) saveAndClose()
        else props.close()
      })
    } else {
      props.close()
    }
  }
  const QError = () => {
    return (props.allData.filter(element => moment(element.vigencia).format("MM/YYYY") === moment(values.vigencia).format("MM/YYYY") && values._id !== element._id).length === 0)
  }
  React.useEffect(() => {
    setError(QError())
  }, [values, props.allData])
  React.useEffect(() => {
    setError(QError())
  }, [])
  return (
    <div className='popup'>
      <div className="popup-container">
        <IconButton style={{ position: "absolute", top: "10px", right: "20px", padding: "0", zIndex: "30" }} color="primary" onClick={close}>
          <CloseIcon color="primary" fontSize="large" />
        </IconButton>

        <div className="popup-inner">
          <div className="popup-full-height"></div>
          <div className={classes.root}>
            <AppBar position="static" component="div" elevation={0} style={{ height: "48px", backgroundColor: "#eee", float: "left", flexDirection: "row", justifyContent: "flex-start" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faServer} style={{ marginRight: "7px" }} />
                        General
                      </Button>
                }
                  {...a11yProps(0)} className={classes.tab} />
                <Tab
                  label={
                    <Button color='inherit' className={classes.tabButton}>
                      <FontAwesomeIcon icon={faTractor} style={{ marginRight: "7px" }} />
                        S.E.A.
                    </Button>
                  }
                  {...a11yProps(1)} className={classes.tab} />
              </Tabs>

            </AppBar>
            <TabPanel value={value} index={0} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>General:</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} md={8}> {/* Datos generales */}
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem" }}>
                            <tr>
                              <th>Descripción</th>
                              <th>Vigente desde</th>
                              <td></td>
                            </tr>
                            <tr>
                              <td>
                                <InputBase
                                  style={{ justifyContent: "center" }}
                                  value={values.nombre}
                                  onChange={(e) => handleChange("nombre")(e.target.value)}
                                  inputProps={{
                                    className: classes.imitUnderline,
                                    style: { width: "100%" }
                                  }}
                                />
                              </td>
                              <td>
                                <InputBase
                                  style={{ justifyContent: "center" }}
                                  inputProps={{
                                    className: moment(values.vigencia).isValid() || !error ? classes.imitUnderline : classes.error,
                                    style: { width: "100%" }
                                  }}
                                  defaultValue={moment(values.vigencia).format("MMYYYY")}
                                  onBlur={(e) => handleChange('vigencia')(moment("01/" + e.target.value, "DD/MM/YYYY").add(12, "hour"))}
                                  inputComponent={Global.formatMonth}
                                />
                              </td>
                              <td>{!error && <span style={{ color: "red" }}>&larr; Tipo existente con el mismo mes</span>}</td>
                            </tr>
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}> {/* Contingencias */}
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>Contingencias:</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem", margin: "0 auto" }}>
                            <tr>
                              <td></td>
                              <th>Empresa</th>
                              <th>Trabajador</th>
                              <th>Total</th>
                            </tr>
                            {["comunes", "hhExtFM", "hhExtRest", "mayores6567", "mayores60"].map((x, i) => (
                              <tr key={i}>
                                <th style={{ textAlign: "left", fontSize: "0.6rem", maxWidth: "200px" }}>{Global.tipos[x] + ":"}</th>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.general.contingencias[x].empresa}
                                    onChange={(e) => {
                                      var res = values.general
                                      res.contingencias[x].empresa = Number(e.target.value)
                                      handleChange("general")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.general.contingencias[x].trabajador}
                                    onChange={(e) => {
                                      var res = values.general
                                      res.contingencias[x].trabajador = Number(e.target.value)
                                      handleChange("general")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.general.contingencias[x].trabajador + values.general.contingencias[x].empresa}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                    disabled={true}
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}> {/* Desempleo */}
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>Desempleo:</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem", margin: "0 auto" }}>
                            <tr>
                              <td></td>
                              <th>Empresa</th>
                              <th>Trabajador</th>
                              <th>Total</th>
                            </tr>
                            {["general", "CTC", "CTP"].map((x, i) => (
                              <tr key={i}>
                                <th style={{ textAlign: "left", fontSize: "0.6rem", maxWidth: "200px" }}>{Global.tipos[x] + ":"}</th>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.general.desempleo[x].empresa}
                                    onChange={(e) => {
                                      var res = values.general
                                      res.desempleo[x].empresa = Number(e.target.value)
                                      handleChange("general")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.general.desempleo[x].trabajador}
                                    onChange={(e) => {
                                      var res = values.general
                                      res.desempleo[x].trabajador = Number(e.target.value)
                                      handleChange("general")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.general.desempleo[x].trabajador + values.general.desempleo[x].empresa}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                    disabled={true}
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}> {/* Otros conceptos */}
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>Otros conceptos:</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem", margin: "0 auto" }}>
                            <tr>
                              <td></td>
                              <th>Empresa</th>
                              <th>Trabajador</th>
                              <th>Total</th>
                            </tr>
                            {["garantiaSalarial", "fp"].map((x, i) => (
                              <tr key={i}>
                                <th style={{ textAlign: "left", fontSize: "0.6rem", maxWidth: "200px" }}>{Global.tipos[x] + ":"}</th>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.general.conceptos[x].empresa}
                                    onChange={(e) => {
                                      var res = values.general
                                      res.conceptos[x].empresa = Number(e.target.value)
                                      handleChange("general")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.general.conceptos[x].trabajador}
                                    onChange={(e) => {
                                      var res = values.general
                                      res.conceptos[x].trabajador = Number(e.target.value)
                                      handleChange("general")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.general.conceptos[x].trabajador + values.general.conceptos[x].empresa}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                    disabled={true}
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} component={'div'}> {/* S.E.A. */}
              <div className={classes.rootTwo}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}> {/* Contingencias */}
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>Contingencias:</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem", margin: "0 auto" }}>
                            <tr>
                              <td></td>
                              <th>Empresa</th>
                              <th>Trabajador</th>
                              <th>Total</th>
                            </tr>
                            {["comunes"].map((x, i) => (
                              <tr key={i}>
                                <th style={{ textAlign: "left", fontSize: "0.6rem", maxWidth: "200px" }}>{Global.tipos[x] + ":"}</th>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.contingencias[x].empresa}
                                    onChange={(e) => {
                                      var res = values.SEA
                                      res.contingencias[x].empresa = Number(e.target.value)
                                      handleChange("SEA")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.contingencias[x].trabajador}
                                    onChange={(e) => {
                                      var res = values.SEA
                                      res.contingencias[x].trabajador = Number(e.target.value)
                                      handleChange("SEA")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.contingencias[x].trabajador + values.SEA.contingencias[x].empresa}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                    disabled={true}
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}> {/* Desempleo */}
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>Desempleo:</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem", margin: "0 auto" }}>
                            <tr>
                              <td></td>
                              <th>Empresa</th>
                              <th>Trabajador</th>
                              <th>Total</th>
                            </tr>
                            {["CF", "CT", "especiales"].map((x, i) => (
                              <tr key={i}>
                                <th style={{ textAlign: "left", fontSize: "0.6rem", maxWidth: "200px" }}>{Global.tipos[x] + ":"}</th>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.desempleo[x].empresa}
                                    onChange={(e) => {
                                      var res = values.SEA
                                      res.desempleo[x].empresa = Number(e.target.value)
                                      handleChange("SEA")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.desempleo[x].trabajador}
                                    onChange={(e) => {
                                      var res = values.SEA
                                      res.desempleo[x].trabajador = Number(e.target.value)
                                      handleChange("SEA")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.desempleo[x].trabajador + values.SEA.desempleo[x].empresa}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                    disabled={true}
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}> {/* Otros conceptos */}
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <strong>Otros conceptos:</strong>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Paper className={classes.paper}>
                          <table style={{ fontSize: "0.8rem", margin: "0 auto" }}>
                            <tr>
                              <td></td>
                              <th>Empresa</th>
                              <th>Trabajador</th>
                              <th>Total</th>
                            </tr>
                            {["garantiaSalarial", "fp"].map((x, i) => (
                              <tr key={i}>
                                <th style={{ textAlign: "left", fontSize: "0.6rem", maxWidth: "200px" }}>{Global.tipos[x] + ":"}</th>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.conceptos[x].empresa}
                                    onChange={(e) => {
                                      var res = values.SEA
                                      res.conceptos[x].empresa = Number(e.target.value)
                                      handleChange("SEA")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.conceptos[x].trabajador}
                                    onChange={(e) => {
                                      var res = values.SEA
                                      res.conceptos[x].trabajador = Number(e.target.value)
                                      handleChange("SEA")(res)
                                    }}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                  />
                                </td>
                                <td>
                                  <InputBase
                                    style={{ justifyContent: "center" }}
                                    value={values.SEA.conceptos[x].trabajador + values.SEA.conceptos[x].empresa}
                                    inputComponent={Global.formatPor}
                                    inputProps={{
                                      className: classes.imitUnderline
                                    }}
                                    disabled={true}
                                  />
                                </td>
                              </tr>
                            ))}
                          </table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
          </div>
          {props.user.rol > 0 &&
            <IconButton disabled={!error} style={{ display: "flex", flexDirection: "column", padding: "0" }} className={classes.buttonSave} color="primary" onClick={save}>
              <SaveIcon color="primary" />
              <div style={{ fontSize: "1rem" }}>
                {error ? "Guardar" : <span style={{ color: "red" }}>Tipo duplicada</span>}
              </div>
            </IconButton>
          }
        </div>
      </div>
    </div >
  );
}