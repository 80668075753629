import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core/';

import axios from 'axios'
import Global from '../Global'
import MaterialTable from 'material-table'
import moment from 'moment'
import Icons from '../components/Icons'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "800px",
    margin: "150px auto"
  },
  row: {
    padding: "2rem 0rem"
  },
}));

export default function Trabajadores(props) {
  const [data, setData] = React.useState([])
  const columns = [
    { title: 'Nombre del equipo', field: 'pcName' },
    {
      title: 'En línea', field: 'online', lookup: {
        true:
          <svg height="15" width="70">
            <circle cx="6" cy="6" r="4" stroke="black" stroke-width="0.51" fill="green" /><text x="15" y="10" fill="green">Online</text>
          </svg>,
        false:
          <svg height="15" width="70">
            <circle cx="6" cy="6" r="4" stroke="black" stroke-width="0.51" fill="red" /><text x="15" y="10" fill="red">Offline</text>
          </svg>
      }, defaultSort: "des"
    },
    { title: 'Primer incio de sesión', field: 'dateC', render: rowData => <span style={{ width: 50, borderRadius: '50%' }}>{moment(rowData.fechaNacimiento).format("DD/MM/YYYY")}</span> },
  ]
  const classes = useStyles();
  React.useEffect(() => {
    axios.get(Global.url + 'getpcuser', props.token).then(res => setData(res.data.pc))
  }, [])
  const refreshData = () => {
    axios.get(Global.url + 'getpcuser', props.token).then(res => setData(res.data.pc))
  }
  const deleteAccount = (e) => {
    axios.post(Global.url + 'deletepcuser', e ,props.token).then(res=>{
      setData(res.data.pc)
      props.setState({ open: true, message: res.data.message, severity: res.data.status })
    })
  }
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Paper>
          <Grid container style={{ backgroundColor: "#fff", padding: "2rem" }}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" style={{ textAlign: "center", marginBottom: "2rem" }}>Mis sesiones</Typography>
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <MaterialTable
                title={"Trabajadores"}
                columns={columns}
                data={data}
                options={{
                  toolbar: false,
                  pageSize: 5,
                }}
                actions={[
                  {
                    icon: Icons.Refresh,
                    onClick: () => refreshData(),
                  },
                  {
                    icon: Icons.Delete,
                    tooltip: 'Cerrar sesión',
                    onClick: (e, rowData) => deleteAccount(rowData)
                  }
                ]}
                localization={{
                  pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'filas'
                  },
                  header: {
                    actions: 'Acciones'
                  },
                  body: {
                    emptyDataSourceMessage: 'Sin datos',
                    filterRow: {
                      filterTooltip: 'Filtrar'
                    }
                  }
                }}
              />
            </Grid>

          </Grid>
        </Paper>
      </div>
    </React.Fragment>
  );
}