import React from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import MaterialTableMenu from '../components/MaterialTableMenu'
import { useTheme } from '@material-ui/styles/';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiTableRow-root td:first-child": {
            textAlign: "center",
        },
        "& .MuiTableRow-root th:first-child": {
            textAlign: "center",
        }
    }
}));
function MaterialTablePrimary(props) {
    const theme = useTheme();
    const classes = useStyles(theme);
    return (
        <div className={classes.root}>
            <MaterialTable
                {...props}
                components={{
                    Toolbar: propss => {
                        if (props.filtrar) propss.actions.push({ icon: () => <MaterialTableMenu options={props.filtrarOptions} columns={propss.columns} onColumnsChanged={propss.onColumnsChanged}></MaterialTableMenu>, isFreeAction: true, position: "toolbar" })
                        return (
                            <div style={{
                                color: theme.palette.primary.main,
                                backgroundColor: 'white'
                            }}>
                                <MTableToolbar  {...propss} classes={{ spacer: "spacer", title: "title", searchField: "searchField", root: "root-Table" }} />
                            </div>
                        )
                    },
                }}
                options={{
                    ...props.options,
                    ['headerStyle']: { backgroundColor: 'white' },
                    ['exportButton']: true,
                    ['exportAllData']: true,
                    ['exportFileName']: props.title.props.children[1].props.children,
                }}
                localization={{
                    ...props.localization,
                    toolbar: {
                        ...props.localization.toolbar,
                        exportTitle: "",
                        exportAriaLabel: "Exportar",
                        exportCSVName: "Exportar CSV",
                        exportPDFName: "Exportar PDF",
                    }
                }}

            />
        </div>
    )
}
export default MaterialTablePrimary