import axios from "axios";
import Global from "../Global";

export default {
    validaContrato: (contrato, token, toDelete) => new Promise((resolve, reject) => {
        axios.put(
            `${Global.url}contrato/${contrato._id}${toDelete?"/delete":""}/validate`,
            contrato,
            token
        ).then(({ data }) => {
            resolve({ valid: true, data:data.data });
        }).catch(err => {
            resolve({ valid: false, message: err.response.data.message, longMessage: err.response.data.longMessage });
        });
    })
}