import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Global from '../../Global';
import { Grid } from '@material-ui/core';

const filter = createFilterOptions();

export default function ProductSelector({ products, onSave, categories }) {
    const [value, setValue] = React.useState(null);
    const [open, toggleOpen] = React.useState(false);
    const [dialogValue, setDialogValue] = React.useState(null);
    const [isNewProduct, setIsNewProduct] = React.useState(false);

    const itemsStyles = { padding: '10px' };

    const handleClose = () => {
        setDialogValue(null);
        toggleOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setValue({ ...dialogValue });
        setIsNewProduct(true);
        handleClose();
    };

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '2rem', height: '100%' }}
        >
            <Grid item style={itemsStyles}>
                <Autocomplete
                    value={value}
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                            // timeout to avoid instant validation of the dialog's form.
                            setTimeout(() => {
                                toggleOpen(true);
                                setDialogValue({
                                    name: newValue
                                });
                            });
                        } else if (newValue && newValue.inputValue) {
                            toggleOpen(true);
                            setDialogValue({
                                name: newValue.inputValue,
                                ratio: 24
                            });
                        } else {
                            const id = newValue._id;
                            delete newValue._id;
                            setValue((prev) => ({ ...prev, ...newValue, product_id: id }));
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // if (params.inputValue !== '') {
                        //     filtered.push({
                        //         inputValue: params.inputValue,
                        //         name: `Añadir "${params.inputValue}"`,
                        //     });
                        // }

                        return filtered;
                    }}
                    groupBy={(option) => option.category}
                    id="product-selector"
                    options={products.sort((a, b) => -b.category.localeCompare(a.category))}
                    getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        return option.name;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    fullWidth
                    renderOption={(option) => option.name}
                    style={{ width: 300 }}
                    freeSolo
                    renderInput={(params) => (
                        <TextField {...params} label="Producto" />
                    )}
                />
            </Grid>
            {!!value && (
                <>
                    <Grid item style={itemsStyles}>
                        <TextField
                            label="Cajas"
                            value={value.quantityMajor}
                            onChange={(e) => setValue((prev) => ({ ...prev, quantityMajor: e.target.value }))}
                            InputProps={{
                                inputComponent: Global.formatNumberInt
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            autoComplete="something-new"
                        />
                    </Grid>
                    <Grid item style={itemsStyles}>
                        <TextField
                            label="Unidades"
                            value={value.quantityMinor}
                            onChange={(e) => setValue((prev) => ({ ...prev, quantityMinor: e.target.value }))}
                            InputProps={{
                                inputComponent: Global.formatNumberInt
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}

                            autoComplete="something-new"
                        />
                    </Grid>
                </>
            )}
            <Button disabled={!value || (!value.quantityMajor && !value.quantityMinor)} onClick={() => onSave(value, isNewProduct)}>
                Añadir
            </Button>
            {dialogValue && <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">Añadir un nuevo producto</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Rellene los datos del producto
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={dialogValue.name}
                            onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
                            label="Nombre"
                            placeholder="P. ej.: Coca-Cola Zero"
                            type="text"
                        />
                        <Autocomplete
                            freeSolo
                            id="category"
                            disableClearable
                            options={categories}
                            value={dialogValue.category}
                            onChange={(event, newInputValue) => 
                                setDialogValue({ ...dialogValue, category: newInputValue })
                            }
                            onInputChange={(event, newInputValue) => 
                                setDialogValue({ ...dialogValue, category: newInputValue })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Categoria"
                                    margin="dense"
                                    InputProps={{ ...params.InputProps, type: 'search' }}
                                />
                            )}
                        />
                        <TextField
                            label="Relacion Unidad/Caja"
                            value={dialogValue.ratio}
                            onChange={(e) => setDialogValue((prev) => ({ ...prev, ratio: e.target.value }))}
                            InputProps={{
                                inputComponent: Global.formatNumberInt
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            helperText="P. ej.: Una caja que contiene 24 unidades su relacion seria 24"
                            autoComplete="something-new"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button disabled={
                            !Number(dialogValue.ratio)
                            ||
                            (typeof dialogValue.category !== 'string' || !dialogValue.category.length)
                            ||
                            (typeof dialogValue.name !== 'string' || !dialogValue.name.length)
                        }
                            type="submit" color="primary"
                        >
                            Añadir
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>}
        </Grid>
    );
}