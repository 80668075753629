import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import commonStyles from './../commonStyles';

const styles = commonStyles();

const GenerateText = ({ text, label, width }) => {
    return (
        <View style={[styles.headerColumn, { width }]}>
            <Text style={styles.headerLabel}>{label}</Text>
            <Text style={styles.headerValue}>{text}</Text>
        </View>
    )
}
const HeaderInfo = ({ paradInfo, contratoInfo, ayunInfo }) => {
    return (<Fragment>
        <View style={styles.headerRow}>
            <GenerateText text={ayunInfo.nombre} label={"Nombre o Razón social de la Empresa"} width={"65%"} />
            <GenerateText text={ayunInfo.numss} label={"Número Patronal S.S."} width={"35%"} />
        </View>
        <View style={[styles.headerRow,{borderBottomColor: '#848484',borderBottomWidth: 2}]}>
            <GenerateText text={ayunInfo.dom} label={"Domicilio"} width={"65%"} />
            <GenerateText text={ayunInfo.cif} label={"C.I.F."} width={"35%"} />
        </View>
        <View style={styles.headerRow}>
            <GenerateText text={paradInfo.nombreCompleto} label={"Apellidos y nombre del trabajador"} width={"65%"} />
            <GenerateText text={paradInfo.numss} label={"Número de afiliación S.S."} width={"35%"} />
        </View>
        <View style={styles.headerRow}>
            <GenerateText text={contratoInfo.tarifaSS} label={"Grupo de Tarifa"} width={"25%"} />
            <GenerateText text={`${contratoInfo.idCategoria} ${contratoInfo.nombreCategoria}`} label={"Nivel/Categoría"} width={"50%"} />
            <GenerateText text={paradInfo.dni} label={"N.I.F."} width={"25%"} />
        </View>
        <View style={styles.headerRow}>
            <GenerateText text={contratoInfo.strDate} label={"Periodo de liquidación"} width={"65%"} />
            <GenerateText text={contratoInfo.nDias} label={"Días del periodo"} width={"35%"} />
        </View>
    </Fragment>);
};

export default HeaderInfo