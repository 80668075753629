import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography, Checkbox, Accordion, AccordionSummary, AccordionDetails, FormControl, FormControlLabel, Grid, Paper, Select, MenuItem, InputLabel, CircularProgress, IconButton, Icon } from '@material-ui/core';
import MaterialTable from '../components/MaterialTablePrimary';
import moment from 'moment'

import axios from 'axios'
import Global from '../Global'

import Icons from '../components/Icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'
import swal from 'sweetalert';
import NominasEdit from './NominasEdit';
import { pdf } from '@react-pdf/renderer';
import fs from '../components/fs';
import Nomina from '../pdf/Nomina/Nomina';
import _ from 'underscore';
import { Lock } from '@material-ui/icons';

String.prototype.contaN = function (str) {
    const fn = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().trim().replace(/\s{2,}/g, ' ');
    }
    const orgStr = fn(this);
    const str2 = fn(str);
    return orgStr.includes(str2);
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperDos: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    filterGroup: {
        textAlign: "left",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "600",
    },
    input: {
        height: 15,
        fontSize: "0.775rem",
    },
    inputAutocomplete: {
        height: 3,
        fontSize: "0.775rem",
    },
    inputAutocompleteLabel: {
        fontSize: "0.9rem",
    }
}));

export default function Nominas(props) {
    const classes = useStyles();
    const tableRef = React.useRef(null)
    const [selectedMonth, setSelectedMonth] = React.useState(moment().month());
    const [selectedYear, setSelectedYear] = React.useState(moment().year());
    const [edit, setEdit] = React.useState(false);
    const [edittionRow, setEdittionRow] = React.useState(null);
    const [isDisabled, setIsDisabled] = React.useState(false);
    const [actions, setActions] = React.useState({
        close: false,
        download: false,
        transferencias: false,
    });
    const [columns, setColumns] = React.useState([
        {
            title: 'Acciones',
            sorting: false,
            render: rowData => {
                return (
                    <IconButton
                        color="primary"
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteNomina(rowData);
                        }}
                    >
                        <Icons.Delete />
                    </IconButton>
                )
            },
            disallowHidden: true,
            hiddenByColumnsButton: false,
            hidden: props.user.rol < 1 ? true : false,
            cellStyle: { width: "3%" }
        },
        {
            title: 'Estado',
            field: 'isClosed',
            type: 'boolean',
            hiddenByColumnsButton: true,
            hidden: false,
            render: rowData => (
                <Icon color="primary">
                    {rowData.isClosed ? <Lock /> : <></>}
                </Icon>
            ),
            cellStyle: { width: "3%" }
        },
        {
            title: 'Apellidos, Nombre',
            field: 'paradInfo.nombreCompleto',
        },
        {
            title: 'DNI',
            field: 'paradInfo.dni',
            hiddenByColumnsButton: true,
            hidden: false,
            cellStyle: { width: "10%" }
        },
        {
            title: 'D. Obra',
            field: 'obraInfo.denom',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true
        },
        {
            title: 'Plan Obra',
            field: 'obraInfo.plan',
            hiddenByColumnsButton: true,
            hidden: false,
        },
        {
            title: 'Turno',
            field: 'contratoInfo.turno',
            hiddenByColumnsButton: true,
            hidden: false,
        },
        {
            title: 'Clave contrato',
            field: 'contratoInfo.claveContrato',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true
        },
        {
            title: 'Fecha',
            field: 'strDate',
            hiddenByColumnsButton: true,
            hidden: false,
            defaultSort: 'asc',
            customSort: (a, b) => moment(b.fechaInicio).diff(moment(a.fechaInicio))
        },
        {
            title: 'Fecha inicio',
            field: 'fechaInicio',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true,
            type: 'date'
        },
        {
            title: 'Fecha fin',
            field: 'fechaFin',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true,
            type: 'date'
        },
        {
            title: 'Fecha creación',
            field: 'createdAt',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true,
            render: rowData => moment(rowData.createdAt).format('DD/MM/YYYY HH:mm:ss'),
            customSort: (a, b) => moment(b.createdAt).diff(moment(a.createdAt))
        },
        {
            title: 'Fecha modificación',
            field: 'updatedAt',
            hiddenByColumnsButton: true,
            hidden: false,
            ocultar: true,
            render: rowData => moment(rowData.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
            customSort: (a, b) => moment(b.updatedAt).diff(moment(a.updatedAt))
        }

    ])
    const [data, setData] = React.useState([]);
    const refreshData = () => {
        setData([])
        axios.get(Global.url + "nomina", props.token).then((res) => {
            setData(res.data.data.nominas)
        })
    }

    const updateMonth = () => {
        const date = moment().month(selectedMonth).year(selectedYear);
        if (!date.isValid()) {
            return;
        }
        setIsDisabled(true);
        axios.post(Global.url + "nomina", { date }, props.token).then((res) => {
            setData(res.data.data.nominas);
            setIsDisabled(false);
        }).catch((err) => {
            swal("Fallo al generar las nominas", err.response.data.message, "error");
            setIsDisabled(false);
        });
    }

    const sendActions = async () => {
        let promises = [];
        const nominas = tableRef.current.state.originalData.filter(element => element.tableData.checked === true);
        if (nominas.length === 0) {
            swal("No seleccionó ninguna nomina", "", "error");
            return;
        }
        if (actions.transferencias) {
            const idsNominas = nominas.map(nomina => nomina._id);
            const urlParams = new URLSearchParams();
            urlParams.append('nominas', JSON.stringify(idsNominas));
            const selectDate = (force = false) => {
                return swal({
                    title: `Seleccione una fecha de transferencia${force ? " válida." : "."}`,
                    content: {
                        element: "input",
                        attributes: {
                            type: "date",
                            value: moment().format('YYYY-MM-DD'),
                            min: moment().format('YYYY-MM-DD'),
                            max: moment().add(2, 'month').format('YYYY-MM-DD')
                        }
                    },
                    buttons: ["Cancelar", "Aceptar"]
                });
            }
            //Generate invalid date
            const firstDate = await selectDate();
            let result = (firstDate === null) ? null : moment(firstDate, "YYYY-MM-DD");
            if (typeof firstDate === "string" && !result.isValid()) {
                result = moment();
            }
            while (result !== null && !(result.isValid() && moment().isSameOrBefore(result, "day"))) {
                const date = await selectDate(true);
                result = (date === null) ? null : moment(date, "YYYY-MM-DD");
            }
            urlParams.append('date', result.format('YYYY-MM-DD'));
            axios.get(
                `${Global.url}nomina/actions/downloadTransferencias?${urlParams.toString()}`,
                { ...props.token, ["responseType"]: 'arraybuffer' }
            ).then(response => {
                const file = new Blob([response.data], { type: 'application/*' });
                //Get file name from response header
                let fileName;
                try {
                    fileName = response.headers['content-disposition'].split(';')[1].split('=')[1].replace(/"/g, '');
                    fs.set(file, "BC", fileName);
                } catch (error) {
                    console.log("ERROR AL OBTENER EL NOMBRE DEL FICHERO", error);
                }
            }).catch(async error => {
                try {
                    const blob = new Blob([error.response.data], { type: 'application/*' });
                    const errorJSON = JSON.parse(await blob.text());
                    swal({
                        title: "Error al generar los tramos",
                        text: errorJSON.message,
                        icon: "error",
                        button: "Vale"
                    })
                } catch (error) {
                    console.log("ERROR DESCONOCIDO", error);
                }
            });
        }

        if (actions.download) {
            promises[0] = nominas.reduce((promise, nomina) => {
                return promise.then(async (arr) => new Promise((resolve, reject) => {
                    try {
                        axios.get(`${Global.url}nomina/${nomina._id}`, props.token).then(async res => {
                            const nomina = res.data.data.nomina;
                            const blob = await pdf(<Nomina values={nomina} />).toBlob();
                            await fs.set(blob, "OTROS", `${moment().format("YYYY-MM-DD HHmmss")} NOMINA ${moment(nomina.fechaInicio).format("MMMM").toUpperCase()} ${nomina.paradInfo.nombreApellidos}.pdf`);
                            resolve([...arr, "OK"]);
                        });
                    } catch (error) {
                        resolve([...arr, `KO: ${error} ${typeof nomina === "object" ? nomina._id : "NO DATA"}`]);
                    }
                }));
            }, Promise.resolve([]));
        }

        if (actions.close) {
            nominas.sort((a, b) => moment(a.fechaInicio).diff(moment(b.fechaInicio)));
            const result = nominas.reduce((promise, nomina) => {
                const fn = (arr) => new Promise((resolve) => {
                    axios.put(`${Global.url}nomina/${nomina._id}/close`, {}, props.token).then(_ => {
                        resolve([...arr, "OK"]);
                    }).catch(err => {
                        resolve([...arr, `${(typeof nomina === "object" && typeof nomina.paradInfo === "object") ? nomina.paradInfo.nombreCompleto : "NO DATA"}: ${err.response.data.message}`]);
                    });
                });
                return promise.then((arr) => fn(arr));
            }, Promise.resolve([]));

            promises[1] = result;

        }

        if (promises[0]) {
            promises[0].then((holap) => {
                refreshData();
                swal("Nominas generadas", "Las nominas se han generado correctamente", "success");
            }).catch(err => {
                swal("Error al generar las nominas", err, "error");
            });
        }
        if (promises[1]) {
            const finalPromise = (arr) => {
                const kos = [...new Set(arr.filter(result => result !== "OK"))];
                if (kos.length > 0) {
                    swal("Error al cerrar las nominas", kos.join("\n"), "error");
                } else {
                    refreshData();
                    swal("Nominas cerradas", "Las nominas se han cerrado correctamente", "success");
                }
            }
            promises[1].then(finalPromise).catch(finalPromise);
        }
    }

    const deleteNomina = async (nomina) => {
        if (nomina.isClosed) {
            const toOpen = await swal({
                title: "Nómina cerrada",
                text: "La nómina ya está cerrada, intentaremos reabrirla si no tiene nóminas cerradas posteriores, ¿desea continuar?",
                icon: "warning",
                buttons: {
                    salir: {
                        text: "Salir",
                        value: false,
                        className: 'popup-button-red',
                    },
                    ok: {
                        text: "Vale",
                        className: 'popup-button',
                        value: true,
                    },
                },
            });
            if (toOpen) {
                axios.put(`${Global.url}nomina/${nomina._id}/open`, {}, props.token).then(res => {
                    refreshData();
                    swal({
                        title: "Nómina abierta",
                        icon: "success"
                    });
                }).catch(err => {
                    swal("Error", err.response.data.message, "error");
                });
            }
        } else {
            const toDelete = await swal({
                title: "¿Estás seguro?",
                text: "Una vez eliminada la nómina no podrás recuperarla",
                icon: "warning",
                buttons: {
                    salir: {
                        text: "Salir",
                        value: false,
                        className: 'popup-button-red',
                    },
                    ok: {
                        text: "Vale",
                        className: 'popup-button',
                        value: true,
                    },
                },
            });
            if (toDelete) {
                axios.delete(`${Global.url}nomina/${nomina._id}`, props.token).then(res => {
                    refreshData();
                    swal({
                        title: "Nómina eliminada",
                        icon: "success",
                        buttons: {
                            salir: {
                                text: "Salir",
                                value: false,
                                className: 'popup-button-red',
                            },
                            ok: {
                                text: "Vale",
                                className: 'popup-button',
                                value: true,
                            },
                        },
                    });
                }).catch(err => {
                    swal({
                        title: "Error al eliminar la nomina",
                        text: err.response.data.message,
                        icon: "error",
                        buttons: {
                            salir: {
                                text: "Salir",
                                value: false,
                                className: 'popup-button-red',
                            },
                            ok: {
                                text: "Vale",
                                className: 'popup-button',
                                value: true,
                            },
                        },
                    });
                });
            }

        }
    }

    React.useEffect(() => {
        tableRef.current.onQueryChange()
    }, [data]);
    React.useEffect(() => {
        refreshData()
    }, []);

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={9}>
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<div color='inherit' style={{ display: "flex", alignItems: "baseline" }}>
                                    <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                                    <Typography variant="h6">Nominas</Typography>
                                </div>}
                                tableRef={tableRef}
                                columns={columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        let res = data;
                                        if (query.search) {
                                            res = res.filter(row => {
                                                return columns.map(column => {
                                                    const field = _.get(column, "field", "");
                                                    return String(_.get(row, field.split("."), "")).contaN(query.search)
                                                }).some(result => result === true);
                                            });
                                        }
                                        if (query.orderBy) {
                                            const field = _.get(query, ['orderBy', 'field']);
                                            const customSort = _.get(query, ['orderBy', 'customSort']);
                                            if (field) {
                                                if (typeof customSort === "function") {
                                                    res.sort(customSort);
                                                } else {
                                                    res.sort((a, b) => {
                                                        const aVal = _.get(a, field.split("."), "");
                                                        const bVal = _.get(b, field.split("."), "");
                                                        if (aVal > bVal) {
                                                            return 1;
                                                        }
                                                        if (aVal < bVal) {
                                                            return -1;
                                                        }
                                                        return 0;
                                                    });
                                                }
                                            }
                                            const isDesc = _.get(query, ['orderDirection']) === "desc";
                                            if (isDesc) res.reverse();
                                        }
                                        res = res.slice(query.pageSize * query.page)
                                        resolve({
                                            data: res,
                                            page: query.page,
                                            totalCount: data.length,
                                        })
                                    })}
                                options={{
                                    selection: true,
                                    pageSize: 10,
                                    debounceInterval: 500,
                                    pageSizeOptions: [10, 25, 50, 100],
                                }}
                                icons={Icons}
                                actions={[
                                    {
                                        icon: Icons.Refresh,
                                        isFreeAction: true,
                                        onClick: () => refreshData(),
                                    },
                                ]}
                                onRowClick={(event, rowData, togglePanel) => {
                                    setEdittionRow(rowData)
                                    setEdit(true)
                                }}
                                filtrar={true}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'nominas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} nomina(s) seleccionado',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        },
                                        deleteTooltip: 'Eliminar',
                                        editRow: {
                                            deleteText: '¿Segura(o) que quiere eliminar?',
                                            cancelTooltip: 'Cancelar',
                                            saveTooltip: 'Guardar',
                                        },
                                    },
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Paper className={classes.paper}>
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading} variant="body1" component="h3" color="primary">Acciones</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" component="h4" className={classes.filterGroup}>Actualizar nominas abiertas</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <FormControl>
                                                    <InputLabel id="month-selector-label">Mes</InputLabel>
                                                    <Select
                                                        labelId="month-selector-label"
                                                        id="month-selector"
                                                        value={selectedMonth}
                                                        label="Mes"
                                                        onChange={(e) => setSelectedMonth(e.target.value)}
                                                    >
                                                        <MenuItem value={0}>Enero</MenuItem>
                                                        <MenuItem value={1}>Febrero</MenuItem>
                                                        <MenuItem value={2}>Marzo</MenuItem>
                                                        <MenuItem value={3}>Abril</MenuItem>
                                                        <MenuItem value={4}>Mayo</MenuItem>
                                                        <MenuItem value={5}>Junio</MenuItem>
                                                        <MenuItem value={6}>Julio</MenuItem>
                                                        <MenuItem value={7}>Agosto</MenuItem>
                                                        <MenuItem value={8}>Septiembre</MenuItem>
                                                        <MenuItem value={9}>Octubre</MenuItem>
                                                        <MenuItem value={10}>Noviembre</MenuItem>
                                                        <MenuItem value={11}>Diciembre</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                {/* Input to type a year with mask */}
                                                <TextField
                                                    label="Año"
                                                    value={selectedYear}
                                                    onChange={(e) => setSelectedYear(e.target.value)}
                                                    style={{ maxWidth: "110px" }}
                                                    InputProps={{
                                                        inputComponent: Global.formatYear
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    autoComplete="something-new"

                                                />

                                                <Button
                                                    onClick={() => updateMonth()}
                                                    disabled={isDisabled}
                                                    variant="contained"
                                                    color="primary"
                                                >Actualizar mes</Button>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" component="h4" className={classes.filterGroup}>Acciones</Typography>
                                        </Grid>
                                        {
                                            props.user.rol > 0 &&
                                            <Grid item>
                                                <Paper className={classes.paperDos} elevation={0}>
                                                    <FormControlLabel
                                                        control={<Checkbox style={{ padding: 0, paddingRight: "5px" }} checked={actions.close} onChange={(event) => { setActions({ ...actions, ["close"]: event.target.checked }) }} />}
                                                        label={<Typography style={{ fontSize: "0.8rem" }}>Cerrar nomina/s</Typography>}
                                                        style={{ marginLeft: 0 }}
                                                    />
                                                </Paper>
                                            </Grid>
                                        }
                                        <Grid item>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <FormControlLabel
                                                    control={<Checkbox style={{ padding: 0, paddingRight: "5px" }} checked={actions.download} onChange={(event) => { setActions({ ...actions, ["download"]: event.target.checked }) }} />}
                                                    label={<Typography style={{ fontSize: "0.8rem" }}>Descargar nomina/s</Typography>}
                                                    style={{ marginLeft: 0 }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <FormControlLabel
                                                    control={<Checkbox style={{ padding: 0, paddingRight: "5px" }} checked={actions.transferencias} onChange={(event) => { setActions({ ...actions, ["transferencias"]: event.target.checked }) }} />}
                                                    label={<Typography style={{ fontSize: "0.8rem" }}>Descargar fichero transferencias</Typography>}
                                                    style={{ marginLeft: 0 }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={sendActions}
                                                disabled={!actions.close && !actions.download && !actions.transferencias}
                                            >Aplicar acciones</Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            {(edit && edittionRow) && <NominasEdit edittionRow={edittionRow} setEdit={setEdit} setEdittionRow={setEdittionRow} token={props.token} user={props.user} />}
        </React.Fragment>
    );
}