import React, {Fragment} from 'react';
import {Text, View } from '@react-pdf/renderer';
import commonStyles from './../commonStyles';

const styles = commonStyles();

const NominaTableBlankSpace = ({rowsCount}) => {
    const blankRows = Array(rowsCount).fill(0)
    const rows = blankRows.map( (x, i) => 
        <View style={styles.row} key={`BR${i}`}>
            <Text style={[styles.concepto,{color:"white"}]}>-</Text>
            <Text style={[styles.dd,{color:"white"}]}>-</Text>
            <Text style={[styles.base,{color:"white"}]}>-</Text>
            <Text style={[styles.coef,{color:"white"}]}>-</Text>
            <Text style={[styles.devengos,{color:"white"}]}>-</Text>
            <Text style={[styles.descuentos,{color:"white"}]}>-</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default NominaTableBlankSpace

