import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MaterialTable from '../components/MaterialTablePrimary'

import moment from 'moment'

import Edit from './ContratosEdit'
import axios from 'axios'
import Global from '../Global'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper'

import Icons from '../components/Icons'
import Typography from '@material-ui/core/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'
import swal from 'sweetalert'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import fs from '../components/fs';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paperDos: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    filterGroup: {
        textAlign: "left",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "600",
    },
    input: {
        height: 15,
        fontSize: "0.775rem",
    },
    inputAutocomplete: {
        height: 3,
        fontSize: "0.775rem",
    },
    inputAutocompleteLabel: {
        fontSize: "0.9rem",
    },
}));
function findDenom(string, array) {
    if (typeof string === 'string') {
        for (var i in array) {
            if (array[i]._id === string) {
                return array[i]
            }
        }
    } else {
        return { _id: undefined, denom: "Sin filtro seleccionado" }
    }
}
function findName(string, array) {
    if (typeof string === 'string') {
        for (var i in array) {
            if (array[i]._id === string) {
                return array[i]
            }
        }
    } else {
        return { _id: undefined, nombre: "", primerApellido: "Sin filtro seleccionado", segundoApellido: "" }
    }
}
function findDNI(string, array) {
    if (typeof string === 'string') {
        for (var i in array) {
            if (array[i]._id === string) {
                return array[i]
            }
        }
    } else {
        return { _id: undefined, dni: "Sin filtro seleccionado" }
    }
}
function findTurno(string, array) {
    if (typeof string === 'string') {
        for (var i in array) {
            if (array[i]._id === string) {
                return array[i]
            }
        }
    } else {
        return { _id: undefined, id: "Sin filtro seleccionado" }
    }
}

export default function Trabajadores(props) {
    const classes = useStyles();
    const tableRef = React.useRef(null)
    const [columns, setColumns] = React.useState([
        {
            title: 'Apellidos, Nombre',
            render: rowData => {
                rowData = props.parad.find(element => element._id === rowData.idParad)
                return (<span>{`${rowData.primerApellido} ${rowData.segundoApellido}, ${rowData.nombre}`}</span>)
            },
            customFilterAndSearch: (term, rowData) => {
                rowData = props.parad.find(element => element._id === rowData.idParad)
                return (rowData.primerApellido + ' ' + rowData.segundoApellido + ', ' + rowData.nombre).toUpperCase().indexOf(term.toUpperCase()) !== -1
            },
            hiddenByColumnsButton: true, hidden: false,
        },
        { title: 'DNI', field: 'dni', render: rowData => props.parad.find(element => element._id === rowData.idParad).dni, hiddenByColumnsButton: true, hidden: false, cellStyle: { width: "10%" } },
        { title: 'Denominación', field: 'denom', render: rowData => props.obra.find(element => element._id === rowData.idObra).denom, hiddenByColumnsButton: true, hidden: false, cellStyle: { width: "30%" } },
        { title: 'Fecha alta', field: 'fechaAlta', defaultSort: 'desc', render: rowData => <span style={{ width: 50, borderRadius: '50%' }}>{moment(rowData.fechaAlta).format("DD/MM/YYYY")}</span>, hiddenByColumnsButton: true, hidden: false, },
        { title: 'Fecha baja', field: 'fechaBaja', render: rowData => <span style={{ width: 50, borderRadius: '50%' }}>{moment(rowData.fechaBaja).isValid() ? moment(rowData.fechaBaja).format("DD/MM/YYYY") : "No consta"}</span>, hiddenByColumnsButton: true, hidden: false, },
        { title: 'Oferta de empleo', field: 'codOfertaEmpleo', hiddenByColumnsButton: true, hidden: false, ocultar: true },
        {
            title: 'Tipo', field: 'claveContrato',
            render: rowData =>
                JSON.parse(localStorage.getItem("ZTA_claveContrato")).find(element => element.CODCC === rowData.claveContrato) ? JSON.parse(localStorage.getItem("ZTA_claveContrato")).find(element => element.CODCC === rowData.claveContrato).DESCC : "Sin definir",
            hiddenByColumnsButton: true, hidden: false, ocultar: true,
            cellStyle: { width: "30%", fontSize: "0.8rem" }
        },

    ])
    const [data, setData] = React.useState([])
    const [edit, setEdit] = React.useState(false)
    const [filters, setFilters] = React.useState({})
    const defaultFilters = {
        fechaAltaI: props.contratos.length > 0 ? props.contratos.sort((a, b) => moment(a.fechaAlta) - moment(b.fechaAlta))[0].fechaAlta : moment(),
        fechaAltaF: props.contratos.length > 0 ? props.contratos.sort((a, b) => moment(b.fechaAlta) - moment(a.fechaAlta))[0].fechaAlta : moment(),
        fechaBajaI: props.contratos.length > 0 ? props.contratos.sort((a, b) => {
            if (a.fechaBaja === null || !moment(a.fechaBaja).isValid()) return 1
            if (b.fechaBaja === null || !moment(b.fechaBaja).isValid()) return -1
            else return moment(a.fechaBaja) - moment(b.fechaBaja)
        })[0].fechaBaja : moment(),
        fechaBajaF: props.contratos.length > 0 ? props.contratos.sort((a, b) => {
            if (a.fechaBaja === null || !moment(a.fechaBaja).isValid()) return -1
            if (b.fechaBaja === null || !moment(b.fechaBaja).isValid()) return 1
            else return moment(b.fechaBaja) - moment(a.fechaBaja)
        })[0].fechaBaja : moment(),
        alta: false,
    }
    const [open, setOpen] = React.useState(false);

    const [documents, setDocuments] = React.useState({
        pdf: false,
        asciiA: false,
        asciiB: false,
        contrata: false,
        certifica: false,
    })
    const [pdf, setPdf] = React.useState("")
    const [asciiA, setAsciiA] = React.useState("")
    const [asciiB, setAsciiB] = React.useState("")
    const [contrata, setContrata] = React.useState("")
    const [certifica, setCertifica] = React.useState("")
    const [edittionRow, setEdittionRow] = React.useState()
    const defaultData = {
        codOfertaEmpleo: "",
        causaBajaSS: "93",
        causaCeseSEPE: "11",
        incapacidades: []
    }
    React.useEffect(() => {
        setData(props.contratos)
        setFilters(defaultFilters)
    }, [])
    const refreshData = () => {
        setData([])
        axios.get(Global.url + "getcontratos", props.token).then(res => {
            props.setState({ contratos: res.data.contratos })
            setData(res.data.contratos)
        })
    }
    const filter = (fil) => {
        var contratos = props.contratos
        if (fil.alta) contratos = contratos.filter(element => element.fechaBaja === null || !moment(element.fechaBaja).isValid() || moment(element.fechaBaja).isAfter(moment(), "days"))
        if (fil.fechaAltaI !== null && moment(fil.fechaAltaI).isValid()) contratos = contratos.filter(element => moment(element.fechaAlta).isSameOrAfter(moment(fil.fechaAltaI), "days"))
        if (fil.fechaAltaF !== null && moment(fil.fechaAltaF).isValid()) contratos = contratos.filter(element => moment(element.fechaAlta).isSameOrBefore(moment(fil.fechaAltaF), "days"))
        if (fil.fechaBajaI !== null && moment(fil.fechaBajaI).isValid()) contratos = contratos.filter(element => element.fechaBaja === null || !moment(element.fechaBaja).isValid() || moment(element.fechaBaja).isSameOrAfter(moment(fil.fechaBajaI), "days"))
        if (fil.fechaBajaF !== null && moment(fil.fechaBajaF).isValid()) contratos = contratos.filter(element => moment(element.fechaBaja).isSameOrBefore(moment(fil.fechaBajaF), "days"))
        if (fil.idTurno) contratos = contratos.filter(element => element.idTurno === fil.idTurno)
        if (fil.idObra) contratos = contratos.filter(element => element.idObra === fil.idObra)
        if (fil.idParad) contratos = contratos.filter(element => element.idParad === fil.idParad)
        setData([...contratos])
    }
    React.useEffect(() => {
        filter(filters)
    }, [filters])
    React.useEffect(() => {
        tableRef.current.onQueryChange()
    }, [data])
    const generarDoc = () => {
        var tableData = tableRef.current
        var contratos = tableData.state.originalData
        var checked = contratos.filter(element => element.tableData.checked === true)
        if (checked.length !== 0) {
            contratos = checked
        }
        const ficheros = []
        for (var key in documents) {
            if (documents[key] === true) ficheros.push(key)
        }
        if (ficheros.length === 0) {
            swal({
                title: "Sin documentos seleccionados",
                text: "Seleccione los documentos que desea generar",
                icon: "warning",
                buttons: {
                    salir: {
                        text: "Vale",
                        className: 'popup-button',
                        value: false,
                    },
                },
            })
        } else {
            var str = "Vas a generar: \n"
            if (documents.pdf === true) {
                str += ` - ${contratos.length} contratos PDF. \n`
            }
            if (documents.asciiA === true) {
                str += ` - 1 fichero de afiliación de altas para ${contratos.length} contratos. \n`
            }
            if (documents.asciiB === true) {
                str += ` - 1 fichero de afiliación de bajas para ${contratos.length} contratos. \n`
            }
            if (documents.contrata === true) {
                str += ` - 1 fichero de contrat@ para ${contratos.length} contratos. \n`
            }
            if (documents.certifica === true) {
                str += ` - 1 fichero de certific@ para ${contratos.length} contratos.`
            }
            swal({
                title: "Confirmación de archivos",
                text: str,
                icon: "info",
                buttons: {
                    salir: {
                        text: "Cancelar",
                        className: 'popup-button-red',
                        value: false,
                    },
                    ok: {
                        text: "Vale",
                        className: 'popup-button',
                        value: true,
                    },
                },
            }).then(async res => {
                if (res) {
                    var promises = []
                    const fileName = await new Promise((resolve) => axios.post(`${Global.url}getfilenameayun`, documents, props.token).then(({ data }) => { resolve(data) }))
                    const name = {
                        pdf: { text: "Generar PDF.", state: pdf, setState: setPdf, fileName: `${moment().format("YYYY-MM-DD HHmmss")} Contratos.zip`.toUpperCase(), idb: "OTROS" },
                        asciiA: { text: "Generar fichero de afilición.", state: asciiA, setState: setAsciiA, fileName: fileName.asciiA + ".AFI", idb: "AFI" },
                        asciiB: { text: "Generar fichero de bajas.", state: asciiB, setState: setAsciiB, fileName: fileName.asciiB + ".AFI", idb: "AFI" },
                        contrata: { text: "Generar fichero contrat@.", state: contrata, setState: setContrata, fileName: fileName.contrata + ".XML", idb: "OTROS" },
                        certifica: { text: "Generar fichero certific@.", state: certifica, setState: setCertifica, fileName: fileName.certifica + ".XML", idb: "OTROS" },
                    }
                    setOpen(true)
                    ficheros.map((x, i) => promises.push(new Promise((resolve, reject) => {
                        name[x].setState(name[x].text)
                        axios.post(Global.url + "getdocscontratos", { contratos, documents: { [x]: true, fileName: name[x].fileName } }, { ...props.token, ["responseType"]: 'arraybuffer' }).then(response => {
                            const file = new Blob([response.data], { type: 'application/*' })
                            fs.set(file, name[x].idb, name[x].fileName, (res) => {
                                name[x].setState(name[x].text + " COMPLETADO.")
                                resolve(res)
                            })
                        }).catch(async error => {
                            try {
                                const blob = new Blob([error.response.data], { type: 'application/*' });
                                const errorJSON = JSON.parse(await blob.text());
                                props.setState({ message: errorJSON.message, open: true, severity: errorJSON.status })
                            } catch (error) {
                                console.log("ERROR DESCONOCIDO", error);
                                props.setState({ message: "Se ha producido un error, vuelva a intentarlo mas tarde.", open: true, severity: "error" })
                            }
                            reject();
                        });
                    })))
                    Promise.all(promises).then(dirs => {
                        let dirsStr = []
                        dirs.forEach((x) => {
                            if (x) dirsStr.push(x)
                        })
                        props.setState({ message: `Archivos generados en ${dirs.filter((value, index, self) => self.indexOf(value) === index).join(", ")}.`, open: true, severity: "success" })
                        setTimeout(() => {
                            ficheros.map((x, i) => name[x].setState(""))
                            setOpen(false)
                        }, 3000)
                    });
                }
            })
        }
    }

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={9}>
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<div color='inherit' style={{ display: "flex", alignItems: "baseline" }}>
                                    <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                                    <Typography variant="h6">Contratos</Typography>
                                </div>}
                                tableRef={tableRef}
                                columns={columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        var res = data;
                                        res = res.slice(query.pageSize * query.page)
                                        resolve({
                                            data: res,
                                            page: query.page,
                                            totalCount: data.length,
                                        })
                                    })}
                                options={{
                                    selection: true,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 25, 50, 100, 200],
                                }}
                                icons={Icons}
                                actions={[
                                    {
                                        icon: Icons.Add,
                                        isFreeAction: true,
                                        onClick: (event) => {
                                            setEdit(true)
                                            setEdittionRow(defaultData)
                                        }
                                    },
                                    {
                                        icon: Icons.Refresh,
                                        isFreeAction: true,
                                        onClick: () => refreshData(),
                                    },
                                ]}
                                onRowClick={(event, rowData, togglePanel) => {
                                    setEdittionRow(rowData)
                                    setEdit(true)
                                }}
                                filtrar={true}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'contratos'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} contrato(s) seleccionado',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        }
                                    }
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3}>
                        <Paper className={classes.paper}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading} variant="body1" component="h3" color="primary">Filtros</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" component="h4" className={classes.filterGroup}>Contratos</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <FormControlLabel
                                                    control={<Checkbox style={{ padding: 0, paddingRight: "5px" }} checked={filters.alta} onChange={(event) => { setFilters({ ...filters, ["alta"]: event.target.checked }) }} name="checkedA" />}
                                                    label={<Typography style={{ fontSize: "0.8rem" }}>Sólo contratos en alta</Typography>}
                                                    style={{ marginLeft: 0 }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}></Grid>
                                        <Grid item xs={6}>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                    <KeyboardDatePicker

                                                        label="Alta S.S."
                                                        format="dd/MM/yyyy"
                                                        value={filters.fechaAltaI}
                                                        onChange={(date) => setFilters({ ...filters, ["fechaAltaI"]: date })}
                                                        error={moment(filters.fechaAltaI).isValid() && filters.fechaAltaI !== null && moment(filters.fechaAltaF).isValid() && filters.fechaAltaF !== null ? moment(filters.fechaAltaI).isAfter(moment(filters.fechaAltaF)) : false}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        style={{ margin: "0px" }}
                                                        InputAdornmentProps={{
                                                            style: { display: "none" },
                                                        }}
                                                        InputProps={{
                                                            className: classes.input
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            className: classes.inputAutocompleteLabel
                                                        }}
                                                        fullWidth
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                    <KeyboardDatePicker

                                                        label="Alta S.S."
                                                        format="dd/MM/yyyy"
                                                        value={filters.fechaAltaF}
                                                        onChange={(date) => setFilters({ ...filters, ["fechaAltaF"]: date })}
                                                        error={moment(filters.fechaAltaI).isValid() && filters.fechaAltaI !== null && moment(filters.fechaAltaF).isValid() && filters.fechaAltaF !== null ? moment(filters.fechaAltaI).isAfter(moment(filters.fechaAltaF)) : false}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        style={{ margin: "0px" }}
                                                        InputAdornmentProps={{
                                                            style: { display: "none" },
                                                        }}
                                                        InputProps={{
                                                            className: classes.input
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            className: classes.inputAutocompleteLabel
                                                        }}
                                                        fullWidth
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                    <KeyboardDatePicker

                                                        label="Baja S.S."
                                                        format="dd/MM/yyyy"
                                                        value={filters.fechaBajaI}
                                                        onChange={(date) => setFilters({ ...filters, ["fechaBajaI"]: date })}
                                                        error={moment(filters.fechaBajaI).isValid() && filters.fechaBajaI !== null && moment(filters.fechaBajaF).isValid() && filters.fechaBajaF !== null ? moment(filters.fechaBajaF).isAfter(moment(filters.fechaAltaF)) : false}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        style={{ margin: "0px" }}
                                                        InputAdornmentProps={{
                                                            style: { display: "none" },
                                                        }}
                                                        InputProps={{
                                                            className: classes.input
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            className: classes.inputAutocompleteLabel
                                                        }}
                                                        fullWidth
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                                                    <KeyboardDatePicker

                                                        label="Baja S.S."
                                                        format="dd/MM/yyyy"
                                                        value={filters.fechaBajaF}
                                                        onChange={(date) => setFilters({ ...filters, ["fechaBajaF"]: date })}
                                                        error={moment(filters.fechaBajaI).isValid() && filters.fechaBajaI !== null && moment(filters.fechaBajaF).isValid() && filters.fechaBajaF !== null ? moment(filters.fechaBajaF).isAfter(moment(filters.fechaAltaF)) : false}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                        style={{ margin: "0px" }}
                                                        InputAdornmentProps={{
                                                            style: { display: "none" },
                                                        }}
                                                        InputProps={{
                                                            className: classes.input
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            className: classes.inputAutocompleteLabel
                                                        }}
                                                        fullWidth
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Paper>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <Autocomplete
                                                    options={[...props.turnos]}
                                                    autoHighlight
                                                    fullWidth
                                                    value={findTurno(filters.idTurno, props.turnos)}
                                                    onChange={(event, value) => {
                                                        if (value) setFilters({ ...filters, ["idTurno"]: value._id })
                                                        else setFilters({ ...filters, ["idTurno"]: undefined })
                                                    }}
                                                    getOptionLabel={(option) => `${option.id}`}
                                                    renderOption={(option) => (
                                                        <React.Fragment>{`${option.id}`}</React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Turno"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password',
                                                                className: classes.inputAutocomplete
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                className: classes.inputAutocompleteLabel
                                                            }}
                                                        />
                                                    )}
                                                    PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" component="h4" className={classes.filterGroup}>Obras</Typography>
                                        </Grid>
                                        <Grid item md={12}>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <Autocomplete
                                                    options={[...props.obra]}
                                                    autoHighlight
                                                    fullWidth
                                                    value={findDenom(filters.idObra, props.obra)}
                                                    onChange={(event, value) => {
                                                        if (value) setFilters({ ...filters, ["idObra"]: value._id })
                                                        else setFilters({ ...filters, ["idObra"]: undefined })
                                                    }}
                                                    getOptionLabel={(option) => `${option.denom}`}
                                                    renderOption={(option) => (
                                                        <React.Fragment>{`${option.denom}`}</React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Denominación"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password',
                                                                className: classes.inputAutocomplete
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                className: classes.inputAutocompleteLabel
                                                            }}
                                                        />
                                                    )}
                                                    PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" component="h4" className={classes.filterGroup}>Trabajador</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <Autocomplete
                                                    options={[...props.parad]}
                                                    autoHighlight
                                                    classes={{
                                                        option: classes.option,
                                                        input: classes.inputAutocomplete
                                                    }}
                                                    value={findName(filters.idParad, props.parad)}
                                                    getOptionSelected={(option, value) => {
                                                        return (option._id === value._id)
                                                    }}
                                                    onChange={(event, value) => {
                                                        if (value) setFilters({ ...filters, ["idParad"]: value._id })
                                                        else setFilters({ ...filters, ["idParad"]: undefined })
                                                    }}
                                                    getOptionLabel={(option) => `${option.primerApellido} ${option.segundoApellido}, ${option.nombre}`}
                                                    renderOption={(option) => (
                                                        <React.Fragment>{`${option.primerApellido} ${option.segundoApellido}, ${option.nombre}`}
                                                        </React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Nombre"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password',
                                                                className: classes.inputAutocomplete
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                className: classes.inputAutocompleteLabel
                                                            }}
                                                        />
                                                    )}
                                                    PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <Autocomplete
                                                    options={[...props.parad]}
                                                    autoHighlight
                                                    classes={{
                                                        option: classes.option,
                                                        input: classes.inputAutocomplete
                                                    }}
                                                    value={findDNI(filters.idParad, props.parad)}
                                                    getOptionSelected={(option, value) => {
                                                        return (option._id === value._id)
                                                    }}
                                                    onChange={(event, value) => {
                                                        if (value) setFilters({ ...filters, ["idParad"]: value._id })
                                                        else setFilters({ ...filters, ["idParad"]: undefined })
                                                    }}
                                                    getOptionLabel={(option) => `${option.dni}`}
                                                    renderOption={(option) => (
                                                        <React.Fragment>{`${option.dni}`}</React.Fragment>
                                                    )}

                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="DNI"
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: 'new-password',
                                                                className: classes.inputAutocomplete
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                className: classes.inputAutocompleteLabel
                                                            }}
                                                        />
                                                    )}
                                                    PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" onClick={() => setFilters(defaultFilters)}>Restablecer filtros</Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            {/* Contratos PDF, ficheros de afiliación ASCII(.afi),contrat@,certifica@ */}
                            <Accordion defaultExpanded>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography className={classes.heading} variant="body1" component="h3" color="primary">Acciones</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" component="h4" className={classes.filterGroup}>Generar documentos</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <FormControlLabel
                                                    control={<Checkbox style={{ padding: 0, paddingRight: "5px" }} checked={documents.pdf} onChange={(event) => { setDocuments({ ...documents, ["pdf"]: event.target.checked }) }} />}
                                                    label={<Typography style={{ fontSize: "0.8rem" }}>Contratos (PDF)</Typography>}
                                                    style={{ marginLeft: 0 }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <FormControlLabel
                                                    control={<Checkbox style={{ padding: 0, paddingRight: "5px" }} checked={documents.asciiA} onChange={(event) => { setDocuments({ ...documents, ["asciiA"]: event.target.checked }) }} />}
                                                    label={<Typography style={{ fontSize: "0.8rem" }}>Alta fichero de afiliación</Typography>}
                                                    style={{ marginLeft: 0 }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <FormControlLabel
                                                    control={<Checkbox style={{ padding: 0, paddingRight: "5px" }} checked={documents.asciiB} onChange={(event) => { setDocuments({ ...documents, ["asciiB"]: event.target.checked }) }} />}
                                                    label={<Typography style={{ fontSize: "0.8rem" }}>Baja fichero de afiliación</Typography>}
                                                    style={{ marginLeft: 0 }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <FormControlLabel
                                                    control={<Checkbox style={{ padding: 0, paddingRight: "5px" }} checked={documents.contrata} onChange={(event) => { setDocuments({ ...documents, ["contrata"]: event.target.checked }) }} />}
                                                    label={<Typography style={{ fontSize: "0.8rem" }}>Contrat@</Typography>}
                                                    style={{ marginLeft: 0 }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item>
                                            <Paper className={classes.paperDos} elevation={0}>
                                                <FormControlLabel
                                                    control={<Checkbox style={{ padding: 0, paddingRight: "5px" }} checked={documents.certifica} onChange={(event) => { setDocuments({ ...documents, ["certifica"]: event.target.checked }) }} />}
                                                    label={<Typography style={{ fontSize: "0.8rem" }}>Certific@</Typography>}
                                                    style={{ marginLeft: 0 }}
                                                />
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button variant="contained" color="primary" onClick={generarDoc}>Generar documentos</Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Paper>

                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                disableBackdropClick
                disableEscapeKeyDown
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <CircularProgress style={{ padding: "30px", margin: "0 auto" }} size={60} />
                <DialogTitle id="alert-dialog-slide-title" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>{"Generando documentos"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" >
                        {pdf !== "" && <p>{pdf}</p>}
                        {asciiA !== "" && <p>{asciiA}</p>}
                        {asciiB !== "" && <p>{asciiB}</p>}
                        {contrata !== "" && <p>{contrata}</p>}
                        {certifica !== "" && <p>{certifica}</p>}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {edit &&
                <Edit close={() => setEdit(false)} user={props.user} data={edittionRow} allData={props.contratos} parad={props.parad} ts={props.ts} obra={props.obra} token={props.token} setOpen={props.setOpen} setState={props.setState} ayun={props.ayun}></Edit>
            }
        </React.Fragment>
    );
}