import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import Header from './Header';
import DataInfo from './DataInfo';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 17,
    borderWidth: 2,
    borderColor: '#848484',
  },
});

const Info = ({ data }) => (
  <View style={styles.tableContainer}>
    <Header />
    <DataInfo data={data} />
  </View>
);

export default Info