import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import commonStyles from '../commonStyles';

const styles = commonStyles();

//Mock number beetwen 0 and 3000 with 2 decimals
const MOCK_NUMBER = () => {
    return (Math.random() * 3000).toFixed(2);
};

const stylesNumberCC = {
    width: "30%",
    textAlign: "right"
}

const stylesNumberBTE = {
    width: "60%",
    textAlign: "right"
}

const parseToFloat = (value, symbol = "") => {
    if (value === undefined || value === null || value === "") {
        return "";
    }
    return parseFloat(value).toFixed(2).replace(/\./g, ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + symbol;
}

const GenerateText = ({ text, width, style, align }) => {
    if (text === undefined || text === null || text === "") {
        return (
            <View style={[styles.determinacionItem, { width, justifyContent: align || "center" }]}>
                <Text style={[style, { color: "white" }]}>-</Text>
            </View>
        );
    } else {
        return (
            <View style={[styles.determinacionItem, { width, justifyContent: align || "center" }]}>
                <Text style={style}>{text}</Text>
            </View>
        )
    }
}
const DataInfo = ({ data }) => {
    const AT_EP_COEF = data.tipo.it.coef + data.tipo.ims.coef;
    const AT_EP_SUBTOTAL = data.tipo.it.subtotal + data.tipo.ims.subtotal;
    return (<Fragment>
        <View style={[styles.determinacionRow, { marginTop: 4 }]}>
            <GenerateText text={"1. Base de cotización por contingencias comunes"} width={"100%"} style={{ marginLeft: 6 }} align={"flex-start"} />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText text={"Importe remuneración mensual"} width={"35%"} style={{ marginLeft: 16 }} align={"flex-start"} />
            <GenerateText text={parseToFloat(data.baseSSMes)} width={"35%"} style={stylesNumberCC} />
            <GenerateText text={""} width={"30%"} />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText text={"Prorrata pagas extraordinarias"} width={"35%"} style={{ marginLeft: 16 }} align={"flex-start"} />
            <GenerateText text={parseToFloat(data.baseSSProrrateo)} width={"35%"} style={stylesNumberCC} />
            <GenerateText text={""} width={"30%"} />
            
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText text={"Vacaciones retribuidas"} width={"35%"} style={{ marginLeft: 16 }} align={"flex-start"} />
            <GenerateText text={parseToFloat(data.baseL13)} width={"35%"} style={stylesNumberCC} />
            <GenerateText text={"BASE"} width={"10%"} />
            <GenerateText text={"TIPO"} width={"10%"} />
            <GenerateText text={"EMPRESA"} width={"10%"} />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText text={"Base a cargo de la empresa"} width={"35%"} style={{ marginLeft: 16 }} align={"flex-start"} />
            <GenerateText text={parseToFloat(data.baseSSEmpresa)} width={"35%"} style={stylesNumberCC} />
            <GenerateText text={parseToFloat(data.baseDeduccionesEmpresa)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={parseToFloat(data.tipo.comunes.coef)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={parseToFloat(data.tipo.comunes.subtotal)} width={"10%"} style={stylesNumberBTE} />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText text={"2. Contingencias profesionales y"} width={"40%"} style={{ marginLeft: 6 }} align={"flex-start"} />
            <GenerateText text={"AT y EP"} width={"30%"} align={"flex-start"} />
            <GenerateText text={parseToFloat(data.baseDeduccionesEmpresa)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={parseToFloat(AT_EP_COEF)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={parseToFloat(AT_EP_SUBTOTAL)} width={"10%"} style={stylesNumberBTE} />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText text={"conceptos de recaudación conjunta"} width={"40%"} style={{ marginLeft: 16 }} align={"flex-start"} />
            <GenerateText text={"Desempleo"} width={"30%"} align={"flex-start"} />
            <GenerateText text={parseToFloat(data.baseDeduccionesEmpresa)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={parseToFloat(data.tipo.desempleo.coef)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={parseToFloat(data.tipo.desempleo.subtotal)} width={"10%"} style={stylesNumberBTE} />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText width={"40%"} />
            <GenerateText text={"Formación profesional"} width={"30%"}  align={"flex-start"} />
            <GenerateText text={parseToFloat(data.baseDeduccionesEmpresa)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={parseToFloat(data.tipo.fp.coef)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={parseToFloat(data.tipo.fp.subtotal)} width={"10%"} style={stylesNumberBTE} />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText width={"40%"} />
            <GenerateText text={"Fondo Garantía Salarial"} width={"30%"} align={"flex-start"} />
            <GenerateText text={parseToFloat(data.baseDeduccionesEmpresa)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={parseToFloat(data.tipo.fgs.coef)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={parseToFloat(data.tipo.fgs.subtotal)} width={"10%"} style={stylesNumberBTE} />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText text={"3. Cotización adicional por"} width={"40%"} style={{ marginLeft: 6 }} align={"flex-start"} />
            <GenerateText text={"Fuerza mayor"} width={"30%"} align={"flex-start"} />
            <GenerateText text={parseToFloat(0)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={""} width={"20%"}  />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText text={"horas extraordinarias"} width={"40%"} style={{ marginLeft: 16 }} align={"flex-start"} />
            <GenerateText text={"Resto"} width={"30%"} align={"flex-start"} />
            <GenerateText text={parseToFloat(0)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={""} width={"20%"}  />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText width={"40%"} />
            <GenerateText text={"Cotizacion adicional < 30 días"} width={"30%"} align={"flex-start"} />
            <GenerateText text={""} width={"20%"} />
            <GenerateText text={parseToFloat(data.tipo.menor30dias)} width={"10%"} style={stylesNumberBTE} />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText text={""} width={"40%"} />
            <GenerateText text={"TOTAL APORTACIÓN EMPRESA"} width={"30%"} align={"flex-start"} />
            <GenerateText text={""} width={"20%"}  />
            <GenerateText text={parseToFloat(data.tipo.total)} width={"10%"} style={stylesNumberBTE} />
        </View>
        <View style={styles.determinacionRow}>
            <GenerateText text={"4. Base sujeta a retención del I.R.P.F."} width={"40%"} style={{ marginLeft: 6 }} align={"flex-start"} />
            <GenerateText text={""} width={"30%"} />
            <GenerateText text={parseToFloat(data.baseIRPF)} width={"10%"} style={stylesNumberBTE} />
            <GenerateText text={""} width={"20%"}  />
        </View>
    </Fragment>);
};

export default DataInfo;