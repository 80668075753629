import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import Alert from './Alert'

import axios from 'axios'
import Global from '../Global'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © eLocal'}
            {' '}
            {"1991 - " + new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        width: '100%',
    },
    paper: {
        margin: theme.spacing(4, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignInSide(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const [severity, setSeverity] = React.useState("")

    const signIn = () => {
        const cif = document.getElementById("cif").value
        const dni = document.getElementById("dni").value
        const password = document.getElementById("password").value
        if (cif === "" || dni === "" || password === "") {
            setSeverity("warning")
            setMessage("Rellene todos los campos obligatorios")
            setOpen(true)
        } else {
            axios.post(Global.url + "signin", { cif, dni, password }).then(res => {
                if (res.data.status === "error") {
                    setSeverity("error")
                    setMessage(res.data.message)
                    setOpen(true)
                } else {
                    sessionStorage.setItem("UserToken", "H " + res.data.token)
                    props.private("H " + res.data.token)
                }
            })
        }
    }
    const keyEnterPassword = (e) => {
        if (e.key === "Enter") {
            signIn()
        }
    }
    return (

        <Grid container component="main" className={classes.root} justify="center" alignItems="center">

            <CssBaseline />
            <Grid item xs={11} sm={8} md={8} square>
                <div className={classes.paper} style={{ marginBottom: "1.5rem" }}>
                    <Grid>
                        <Typography variant="h2" component="h1" id="name" color="primary">eLocal Nóminas GrupoH</Typography>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={11} sm={8} md={5}>
                <Paper elevation={3} style={{ paddingTop: '1px', paddingBottom: '1px' }}>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="cif"
                                label="CIF"
                                name="cif"
                                autoFocus
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="dni"
                                label="NIF"
                                name="dni"
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onKeyPress={keyEnterPassword}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={signIn}
                            >
                                Iniciar sesión
                            </Button>
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </Paper>

            </Grid>
            <Alert open={open} setOpen={(e) => setOpen(e)} message={message} severity={severity}></Alert>

        </Grid>
    );
}