import React from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import swal from 'sweetalert';
import Popper from '@material-ui/core/Popper'
import axios from 'axios'
import Global from '../Global'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer } from '@fortawesome/free-solid-svg-icons'
import Icons from '../components/Icons'
import Typography from '@material-ui/core/Typography'
import MaterialTable from 'material-table'

function findGP(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODGP === string) {
        return array[i]
      }
    }
  } else {
    return { "CODGP": "", "DESGP": "Seleccione una opción válida" }
  }
}
function findSSA(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODCNA === string) {
        return array[i]
      }
    }
  } else {
    return { "CODCNA": "", "TITCNA": "Seleccione una opción válida" }
  }
}
function findSSO(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODCOC === string) {
        return array[i]
      }
    }
  } else {
    return { "CODCOC": "", "TITCOC": "Sin ocupación específica" }
  }
}
function findTI(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].COD === string) {
        return array[i]
      }
    }
  } else {
    return { "COD": "", "DES": "Seleccione una opción válida" }
  }
}
function findCRA(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].COD === string) {
        return array[i]
      }
    }
  } else {
    return { "COD": "", "DES": "Seleccione una opción válida" }
  }
}
function findEnfermedad(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
    return {_id:undefined, id:"NaN", nombre:"Selecciona una opción válida"}
  } else {
    return {_id:undefined, id:"NaN", nombre:"Selecciona una opción válida"}
  }
}
function findCNO(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODOCU === string) {
        return array[i]
      }
    }
  } else {
    return { "CODOCU": "", "DESOCU": "Seleccione una opción válida" }
  }
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  rootTwo: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  option: {
    fontSize: "0.8rem",
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputSelect: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputAutocomplete: {
    height: 3,
    fontSize: "0.8rem",
  },
  inputAutocompleteTable: {
    height: 17,
    fontSize: "13px",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tab: {
    height: "30px",
    marginTop: "0px",
    fontSize: "0.7rem",
  },
  tabs: {
    height: "48px"
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    padding: "0",
    zIndex: "30"
  },
  tabButton: {
    '&:hover': {
      backgroundColor: 'initial !important'
    },
    fontSize: 'inherit',
  },
}));
export default function InputAdornments(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState(props.data);
  const [value, setValue] = React.useState(0);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (prop) => (event) => {
    if (props.user.rol >= 1) {
      setValues({ ...values, [prop]: event.target.value });
    }
  };
  const saveAndClose = () => {
    axios.post(Global.url + 'updatesalarial', values, props.token).then(res => {
      if (res.data.status === "error") alert(res.data.message)
      else { props.setState({ open: true, message: res.data.message, severity: res.data.status, ts: res.data.ts }) }
    })
  }
  const save = () => {
    const info = Global.validateTS(values)
    if (info.obligatorios.length === 0) {
      saveAndClose()
    } else {
      swal({
        title: "Faltan campos obligatorios por rellenar",
        text: "Faltan: " + info.obligatorios.join(', '),
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
    }
  }
  const close = () => {
    if (props.user.rol >= 1) {
      const info = Global.validateTS(values, props.data)
      if (info.obligatorios.length > 0) {
        swal({
          title: "Faltan campos obligatorios por rellenar",
          text: "Faltan: " + info.obligatorios.join(', ') + ".",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Vale",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (!res && info.equal) props.close()
          else if (!res && !info.equal && values.idAyun) {
            swal({
              title: "¿Desea salir sin guardar?",
              icon: "warning",
              buttons: {
                salir: {
                  text: "Salir",
                  value: false,
                  className: 'popup-button-red',
                },
                ok: {
                  text: "Guardar",
                  className: 'popup-button',
                  value: true,
                },
              },
            }).then(res => {
              if (res) saveAndClose()
              else props.close()
            })
          } else if (!values.idAyun && !res) {
            props.close()
          }
        })
      } else if (info.obligatorios.length === 0 && !info.equal) {
        swal({
          title: "¿Desea salir sin guardar?",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Guardar",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (res) saveAndClose()
          else props.close()
        })
      } else {
        props.close()
      }
    } else {
      props.close()
    }
  }
  return (
    <div className='popup'>
      <div className="popup-container">
        <IconButton style={{ position: "absolute", top: "10px", right: "20px", padding: "0", zIndex: "30" }} color="primary" onClick={close}>
          <CloseIcon color="primary" fontSize="large" />
        </IconButton>

        <div className="popup-inner">
          <div className="popup-full-height"></div>
          <div className={classes.root}>
            <AppBar position="static" component="div" elevation={0} style={{ height: "48px", backgroundColor: "#eee", float: "left", flexDirection: "row", justifyContent: "flex-start" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faServer} style={{ marginRight: "7px" }} />
                        Datos generales
                      </Button>
                }
                  {...a11yProps(0)} className={classes.tab} />
                <Tab
                  label={
                    <Button color='inherit' className={classes.tabButton}>
                      <FontAwesomeIcon icon={faServer} style={{ marginRight: "7px" }} />
                    Conceptos
                  </Button>
                  }
                  {...a11yProps(1)} className={classes.tab} />
              </Tabs>

            </AppBar>
            <TabPanel value={value} index={0} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>
                  <Grid item xs={12} sm={6} md={3}> {/* codigoConvenio */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Código de convenio"
                        id="codigoConvenio"
                        value={values.codigoConvenio}
                        onChange={handleChange('codigoConvenio')}
                        defaultValue={props.data.codigoConvenio}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* nombreConvenio */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Nombre de convenio"
                        id="nombreConvenio"
                        value={values.nombreConvenio}
                        onChange={handleChange('nombreConvenio')}
                        defaultValue={props.data.nombreConvenio}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={2}> {/* idCategoria */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Categoría"
                        id="idCategoria"
                        value={values.idCategoria}
                        onChange={handleChange('idCategoria')}
                        defaultValue={props.data.idCategoria}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* nombreCategoria */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Denominación de categoría"
                        id="nombreCategoria"
                        value={values.nombreCategoria}
                        onChange={handleChange('nombreCategoria')}
                        defaultValue={props.data.nombreCategoria}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={5}> {/* tarifaSS */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_SS_grupoCotizacion"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findGP(values.tarifaSS, JSON.parse(localStorage.getItem("ZTA_SS_grupoCotizacion")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["tarifaSS"]: value.CODGP })

                        }}
                        getOptionLabel={(option) => option.CODGP + " - " + option.DESGP}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODGP}&nbsp;-&nbsp;</span>{option.DESGP}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Grupo de cotización"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={5}> {/* actividad */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_SS_Actividad"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findSSA(values.actividad, JSON.parse(localStorage.getItem("ZTA_SS_Actividad")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["actividad"]: value.CODCNA })

                        }}
                        getOptionLabel={(option) => option.CODCNA + " - " + option.TITCNA}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODCNA}&nbsp;-&nbsp;</span>{option.TITCNA}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Actividad de la empresa"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}> {/* ocupacion */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_SS_Ocupacion"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findSSO(values.ocupacion, JSON.parse(localStorage.getItem("ZTA_SS_Ocupacion")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["ocupacion"]: value.CODCOC })
                          else if (!value) setValues({ ...values, ["ocupacion"]: "" })

                        }}
                        id="ocupacion"
                        getOptionLabel={(option) => (option.CODCOC + " - " + option.TITCOC)}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODCOC}&nbsp;-&nbsp;</span>{option.TITCOC}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Ocupación del trabajador"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={5}> {/* codNacionalOcupacion */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_CodNacionalOcupacion"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findCNO(values.codNacionalOcupacion, JSON.parse(localStorage.getItem("ZTA_CodNacionalOcupacion")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["codNacionalOcupacion"]: value.CODOCU })

                        }}
                        id="ocupacion"
                        getOptionLabel={(option) => (option.CODOCU + " - " + option.DESOCU)}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODOCU}&nbsp;-&nbsp;</span>{option.DESOCU}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="CNO"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>          
                  <Grid item xs={6} sm={5} md={4}> {/* tipoIndemnizacion */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={Global.tipoIndemnizacion}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findTI(values.tipoIndemnizacion, Global.tipoIndemnizacion)}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["tipoIndemnizacion"]: value.COD })
                        }}
                        getOptionLabel={(option) => (option.COD + " - " + option.DES)}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.COD}&nbsp;-&nbsp;</span>{option.DES}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Indemnización fin de contrato"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={6} sm={5} md={3}> {/* indemnizacion */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Valor indemnización"
                        id="indemnizacion"
                        value={values.indemnizacion}
                        onChange={handleChange('indemnizacion')}
                        defaultValue={props.data.indemnizacion}
                        fullWidth
                        InputProps={values.tipoIndemnizacion === "P" ? {
                          className: classes.input,
                          inputComponent: Global.formatPor
                        }
                          : {
                            className: classes.input,
                            inputComponent: (props) => Global.formatNumberFloat({ ...props, ["suffix"]: "días/año" })
                          }
                        }
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={6} sm={5} md={3}> {/* idEnfermedad */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={props.enfermedad}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findEnfermedad(values.idEnfermedad, props.enfermedad)}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["idEnfermedad"]: value._id })
                        }}
                        getOptionLabel={(option) => {
                          if (option.id !== undefined) return (option.id + " - " + option.nombre)
                          else return ("Selecciona una opción válida")
                        }
                        }
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.id}&nbsp;-&nbsp;</span>{option.nombre}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tabla de enfermedad"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        ></Autocomplete>
                    </Paper>
                  </Grid>
                  </Grid>
              </div>
            </TabPanel>
              <TabPanel value={value} index={1} component={'div'}>
                <div className={classes.rootTwo}>
                  <Grid container>
                    <Grid item xs={12}> {/* conceptos */}
                      <Paper className={classes.paper}>
                        <MaterialTable
                          title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Conceptos</Typography>}
                          icons={Icons}
                          columns={[
                            {
                              title: 'Nombre',
                              field: 'nombre',
                              align: "center",
                            },
                            {
                              title: 'CRA',
                              field: 'codigoCRA',
                              align: "center",
                              editComponent: (props) =>
                                <Autocomplete
                                  options={JSON.parse(localStorage.getItem("ZTA_CRA"))}
                                  autoHighlight
                                  classes={{
                                    option: classes.option,
                                    input: classes.inputAutocompleteTable
                                  }}
                                  value={findCRA(props.value, JSON.parse(localStorage.getItem("ZTA_CRA")))}
                                  onChange={(event, value) => {
                                    if (value) props.onChange(value.COD)
                                  }}
                                  getOptionLabel={(option) => option.COD + " - " + option.DES}
                                  renderOption={(option) => (
                                    <React.Fragment>
                                      <span>{option.COD}&nbsp;-&nbsp;</span>{option.DES}
                                    </React.Fragment>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                      }}
                                      InputLabelProps={{
                                        shrink: true
                                      }}
                                    />
                                  )}
                                  PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                                />
                            },
                            { title: 'SS', field: 'SS', align: "center", lookup: { true: "Sí", false: "No" } },
                            { title: 'IRPF', field: 'IRPF', align: "center", lookup: { true: "Sí", false: "No" } },
                            { title: 'Indemnización', field: 'indemnizacion', align: "center", lookup: { true: "Sí", false: "No" } },
                            { title: 'Devengo', field: 'devengo', align: "center", lookup: Global.devengo },
                            {
                              title: 'Importe',
                              field: 'importe',
                              type: 'numeric',
                              align: "center",
                              render: rowData => Global.roundEUR(rowData.importe),
                              editComponent: (props) =>
                                <TextField
                                  label=" "
                                  value={props.value}
                                  onChange={(e) => props.onChange(e.target.value)}
                                  InputProps={{
                                    className: classes.input,
                                    inputComponent: Global.formatEUR
                                  }}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  autoComplete="something-new"
                                />
                            },
                          ]}
                          data={values.conceptos}
                          editable={{
                            onRowAdd: (newData) =>
                              new Promise((resolve, reject) => {
                                setTimeout(() => {
                                  if (newData.nombre && newData.codigoCRA && Boolean(newData.SS.toString()) && Boolean(newData.IRPF.toString()) && Boolean(newData.indemnizacion.toString()) && newData.devengo && newData.importe) {
                                    if (value && props.user.rol >= 1) setValues(prevState => {
                                      var newD = newData
                                      if (typeof newD.SS === 'string') newD.SS = Boolean(newData.SS.replace("false", ""))
                                      if (typeof newD.IRPF === 'string') newD.IRPF = Boolean(newData.IRPF.replace("false", ""))
                                      if (typeof newD.indemnizacion === 'string') newD.indemnizacion = Boolean(newData.indemnizacion.replace("false", ""))
                                      const table = JSON.parse(localStorage.getItem("ZTA_CRA")).find(element => element.COD === newD.codigoCRA)
                                      if (newD.SS) {
                                        if (table.BBCC === "SI") {
                                          const data = [...prevState.conceptos];
                                          data.push(newD);
                                          resolve()
                                          return { ...prevState, ["conceptos"]: data };
                                        } else {
                                          swal({
                                            title: "SS y CRA incompatibles",
                                            icon: "error",
                                            buttons: {
                                              ok: {
                                                text: "Vale",
                                                className: 'popup-button',
                                                value: true,
                                              },
                                            },
                                          })
                                          reject()
                                          return { ...prevState }
                                        }
                                      } else {
                                        if (table.BBCC4 === "SI") {
                                          const data = [...prevState.conceptos];
                                          data.push(newD);
                                          resolve()
                                          return { ...prevState, ["conceptos"]: data };
                                        } else {
                                          swal({
                                            title: "SS y CRA incompatibles",
                                            icon: "error",
                                            buttons: {
                                              ok: {
                                                text: "Vale",
                                                className: 'popup-button',
                                                value: true,
                                              },
                                            },
                                          })
                                          reject()
                                          return { ...prevState }
                                        }
                                      }
                                    })
                                    else resolve();
                                  } else {
                                    reject()
                                    swal({
                                      title: "Rellene todos los campos",
                                      icon: "warning",
                                      buttons: {
                                        ok: {
                                          text: "Vale",
                                          className: 'popup-button',
                                          value: true,
                                        },
                                      },
                                    })
                                  }
                                }, 600);
                              }),
                            onRowUpdate: (newData, oldData) =>
                              new Promise((resolve, reject) => {
                                setTimeout(() => {
                                  resolve();
                                  if (newData.nombre && newData.codigoCRA && Boolean(newData.SS.toString()) && Boolean(newData.IRPF.toString()) && Boolean(newData.indemnizacion.toString()) && newData.devengo && newData.importe) {
                                    if (oldData) {
                                      if (value && props.user.rol >= 1) setValues(prevState => {
                                        var newD = newData
                                        if (typeof newD.SS === 'string') newD.SS = Boolean(newData.SS.replace("false", ""))
                                        if (typeof newD.IRPF === 'string') newD.IRPF = Boolean(newData.IRPF.replace("false", ""))
                                        if (typeof newD.indemnizacion === 'string') newD.indemnizacion = Boolean(newData.indemnizacion.replace("false", ""))
                                        const table = JSON.parse(localStorage.getItem("ZTA_CRA")).find(element => element.COD === newD.codigoCRA)
                                        if (newD.SS) {
                                          if (table.BBCC === "SI") {
                                            const data = [...prevState.conceptos];
                                            data[data.indexOf(oldData)] = newD;
                                            resolve()
                                            return { ...prevState, ["conceptos"]: data };
                                          } else {
                                            swal({
                                              title: "SS y CRA incompatibles",
                                              icon: "error",
                                              buttons: {
                                                ok: {
                                                  text: "Vale",
                                                  className: 'popup-button',
                                                  value: true,
                                                },
                                              },
                                            })
                                            reject()
                                            return { ...prevState }
                                          }
                                        } else {
                                          if (table.BBCC4 === "SI") {
                                            const data = [...prevState.conceptos];
                                            data[data.indexOf(oldData)] = newD;
                                            resolve()
                                            return { ...prevState, ["conceptos"]: data };
                                          } else {
                                            swal({
                                              title: "SS y CRA incompatibles",
                                              icon: "error",
                                              buttons: {
                                                ok: {
                                                  text: "Vale",
                                                  className: 'popup-button',
                                                  value: true,
                                                },
                                              },
                                            })
                                            reject()
                                            return { ...prevState }
                                          }
                                        }
                                      });
                                    }
                                    else resolve()
                                  } else {
                                    reject()
                                    swal({
                                      title: "Rellene todos los campo",
                                      icon: "warning",
                                      buttons: {
                                        ok: {
                                          text: "Vale",
                                          className: 'popup-button',
                                          value: true,
                                        },
                                      },
                                    })
                                  }
                                }, 600);
                              }),
                            onRowDelete: (oldData) =>
                              new Promise((resolve) => {
                                setTimeout(() => {
                                  resolve();
                                  if (value && props.user.rol >= 1) setValues(prevState => {
                                    const data = [...prevState.conceptos];
                                    data.splice(data.indexOf(oldData), 1);
                                    return { ...prevState, ["conceptos"]: data };
                                  });
                                }, 600);
                              }),
                          }}
                          options={{
                            pageSize: 5,
                            search: false,
                            pageSizeOptions: [5, 10, 15, 20],
                            headerStyle: {
                              color: 'rgba(0, 0, 0, 0.54)'
                            },
                            rowStyle: {
                              color: 'rgba(0, 0, 0, 0.54)'
                            },
                          }}
                          localization={{
                            pagination: {
                              labelDisplayedRows: '{from}-{to} de {count}',
                              labelRowsSelect: 'filas'
                            },
                            toolbar: {
                              nRowsSelected: '{0} row(s) seleccionada',
                              searchPlaceholder: 'Buscar',
                              searchTooltip: 'Buscar',
                            },
                            header: {
                              actions: 'Acciones'
                            },
                            body: {
                              emptyDataSourceMessage: 'Sin datos',
                              filterRow: {
                                filterTooltip: 'Filtrar'
                              },
                              addTooltip: "Nueva fila",
                              deleteTooltip: "Eliminar fila",
                              editTooltip: "Editar fila",
                              editRow: {
                                deleteText: "¿Estás seguro de eliminar la fila?",
                                cancelTooltip: "Cancelar",
                                saveTooltip: "Guardar"
                              },
                            },

                          }}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              </TabPanel>
          </div>
            {props.user.rol >= 1 &&
              <IconButton style={{ display: "flex", flexDirection: "column", padding: "0" }} className={classes.buttonSave} color="primary" onClick={save}>
                <SaveIcon color="primary" />
                <div style={{ fontSize: "1rem" }}>
                  Guardar
              </div>
              </IconButton>
            }
          </div>
        </div>
      </div >
  );
}