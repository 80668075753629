import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Icons from '../components/Icons'
import MaterialTable from '../components/MaterialTablePrimary'

import axios from 'axios'
import Global from '../Global'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import swal from 'sweetalert';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2),
        backgroundColor: "#fff"
    },
    paper: {
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function Trabajadores(props) {
    const classes = useStyles();
    const columnsOcupacion = [
        { title: 'Código', field: 'CODCOC', hidden: false, cellStyle:{width:"5%"} },
        { title: 'Descripción', field: 'TITCOC', hidden: false },
        { title: 'IT', field: 'ITCOC', hidden: false, render: rowData => Global.roundPor(rowData.ITCOC), cellStyle:{width:"20%"} },
        { title: 'IMS', field: 'IMSCOC', hidden: false, render: rowData => Global.roundPor(rowData.IMSCOC), cellStyle:{width:"20%"} },
    ]
    const columnsActividad = [
        { title: 'Código', field: 'CODCNA', hidden: false, cellStyle:{width:"5%"} },
        { title: 'Descripción', field: 'TITCNA', hidden: false },
        { title: 'IT', field: 'ITCNA', hidden: false, render: rowData => Global.roundPor(rowData.ITCNA), cellStyle:{width:"20%"} },
        { title: 'IMS', field: 'IMSCNA', hidden: false, render: rowData => Global.roundPor(rowData.IMSCNA), cellStyle:{width:"20%"} },
    ]
    const [actividad, setActividad] = React.useState(JSON.parse(localStorage.getItem("ZTA_SS_Actividad")))
    const [ocupacion, setOcupacion] = React.useState(JSON.parse(localStorage.getItem("ZTA_SS_Ocupacion")))
    const refresh = () => {
        setActividad([])
        setOcupacion([])
        if (moment().diff(moment(localStorage.getItem("ZTAUpdated")), "hours", true) < 1) {
            setActividad(JSON.parse(localStorage.getItem("ZTA_SS_Actividad")))
            setOcupacion(JSON.parse(localStorage.getItem("ZTA_SS_Ocupacion")))
            swal({
                title: "Tablas actualizadas",
                icon: "success",
                buttons: {
                    ok: {
                        text: "Vale",
                        className: 'popup-button',
                        value: true,
                    },
                },
            })
        } else {
            axios.post(Global.url + "getzta", { date: moment("01/01/1900") }, props.token).then(ress => {
                if (ress.data.status !== "ok") {
                    for (var key in ress.data.data) {
                        localStorage.setItem(key, JSON.stringify(ress.data.data[key]))
                    }
                    localStorage.setItem("ZTAUpdated", moment())
                    setActividad(ress.data.data["ZTA_SS_Actividad"])
                    setOcupacion(ress.data.data["ZTA_SS_Ocupacion"])
                    swal({
                        title: "Tablas actualizadas",
                        icon: "success",
                        buttons: {
                            ok: {
                                text: "Vale",
                                className: 'popup-button',
                                value: true,
                            },
                        },
                    })
                }
            })
        }
    }
    return (
        <React.Fragment>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<div color='inherit' style={{ display: "flex", alignItems: "baseline" }}>
                                    <FontAwesomeIcon style={{ marginRight: "0px" }} />
                                    <Typography variant="h6">Actividad</Typography>
                                </div>}
                                columns={columnsActividad}
                                data={actividad}
                                options={{
                                    search: true,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 15, 20, 25]
                                }}
                                actions={[
                                    {
                                        icon: Icons.Refresh,
                                        isFreeAction: true,
                                        onClick: () => refresh(),
                                    },
                                ]}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'filas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} row(s) seleccionada',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        }
                                    }
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Paper className={classes.paper}>
                            <MaterialTable
                                title={<div color='inherit' style={{ display: "flex", alignItems: "baseline" }}>
                                    <FontAwesomeIcon style={{ marginRight: "0px" }} />
                                    <Typography variant="h6">Ocupación</Typography>
                                </div>}
                                columns={columnsOcupacion}
                                data={ocupacion}
                                options={{
                                    search: false,
                                    pageSize: 10,
                                    pageSizeOptions: [10, 15, 20, 25]
                                }}
                                actions={[
                                    {
                                        icon: Icons.Refresh,
                                        isFreeAction: true,
                                        onClick: () => refresh(),
                                    },
                                ]}
                                localization={{
                                    pagination: {
                                        labelDisplayedRows: '{from}-{to} de {count}',
                                        labelRowsSelect: 'filas'
                                    },
                                    toolbar: {
                                        nRowsSelected: '{0} row(s) seleccionada',
                                        searchPlaceholder: 'Buscar',
                                        searchTooltip: 'Buscar',
                                    },
                                    header: {
                                        actions: 'Acciones'
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Sin datos',
                                        filterRow: {
                                            filterTooltip: 'Filtrar'
                                        }
                                    }
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
}