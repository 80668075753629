import React, {forwardRef} from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import { TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import swal from 'sweetalert';
import Popper from '@material-ui/core/Popper'
import axios from 'axios'
import Global from '../Global'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faServer, faFileSignature, faImages } from '@fortawesome/free-solid-svg-icons'
import Contratos from './Contratos'
import MaterialTable from 'material-table'
import moment from 'moment'
import { GetApp } from '@material-ui/icons/';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Icons from '../components/Icons'
import DosierPDF from '../pdf/Dosier'
import { pdf } from '@react-pdf/renderer'
import fs from '../components/fs';

function findRegimen(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODS === string) {
        return array[i]
      }
    }
  } else {
    return { "CODS": string, "DESS": string }
  }
}
function findPE(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODPE === string) {
        return array[i]
      }
    }
  } else {
    return {}
  }
}
function findE(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODE === string) {
        return array[i]
      }
    }
  } else {
    return {}
  }
}
function findA(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODA === string) {
        return array[i]
      }
    }
  } else {
    return {}
  }
}
function findCalendar(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return {}
  }
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
function findCM(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODMUN === string) {
        return array[i]
      }
    }
  } else {
    return { "CODMUN": string, "NOMMUN": string }
  }
}
function findNacional(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODPAI === string) {
        return array[i]
      }
    }
  } else {
    return { "CODPAI": string, "NOMPAI": string }
  }
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  rootTwo: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  option: {
    fontSize: "0.8rem",
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputSelect: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputAutocomplete: {
    height: 3,
    fontSize: "0.8rem",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tab: {
    height: "30px",
    marginTop: "0px",
    fontSize: "0.7rem",
  },
  tabs: {
    height: "48px"
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    padding: "0",
    zIndex: "30"
  },
  tabButton: {
    '&:hover': {
      backgroundColor: 'initial !important'
    },
    fontSize: 'inherit',
  },
  disableButton: {
    display: "none"
  },
  columnsIcons: {
    display: "flex",
    flexDirection: "column",
}
}));
export default function InputAdornments(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState(props.data);
  const [value, setValue] = React.useState(0);
  const [newImages, setNewImages] = React.useState([])
  const [deleteImages, setDeleteImages] = React.useState([])
  const [rowData, setRowData] = React.useState();

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (prop) => (event) => {
    if (props.user.rol >= 1) {
      if (prop === 'id') {
        setValues({ ...values, [prop]: event.target.value });
      } else {
        setValues({ ...values, [prop]: event.target.value });
      }
    }
  };
  const handleBlurID = (prop) => (event) => {
    if (props.allData.filter(p => p.id === Number(event.target.value) && p.id !== props.data.id).length === 0) setValues({ ...values, [prop]: event.target.value })
    else {
      setValues({ ...values, [prop]: props.data.id })
      swal({
        title: "ID ya existente",
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
    }
  }
  const saveAndClose = () => {
    axios.post(Global.url + 'updateobra', values, props.token).then(res => {
      if (res.data.status === "error") alert(res.data.message)
      else {
        if (newImages.length > 0 || deleteImages.length > 0) {
          var deleteImagesSend = []
          var addImages = new FormData()
          deleteImages.forEach((x, i) => deleteImagesSend.push(x.id))
          addImages.append("delete", deleteImagesSend.join(","))
          newImages.forEach((x, i) => addImages.append(`file`, x.id[1], `${x.description},34n5,${moment(x.date).format("DDMMYYYY")},34n5,${x.id[1].name}`))
          axios.post(`${Global.url}updateimageobra/${values._id}`, addImages, props.token).then(ress => {
            if (ress.data.status === "error") {
              alert(ress.data.message)
            } else {
              props.setState({ open: true, message: res.data.message, severity: res.data.status, obra: ress.data.obra })
            }
          })
        } else {
          props.setState({ open: true, message: res.data.message, severity: res.data.status, obra: res.data.obra })
        }
      }
    })
  }
  const save = () => {
    const info = Global.validateObra(values)
    if (info.obligatorios.length === 0) {
      saveAndClose()
    } else {
      swal({
        title: "Faltan campos obligatorios por rellenar",
        text: "Faltan: " + info.obligatorios.join(', '),
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
    }
  }
  const close = () => {
    if (props.user.rol >= 1) {
      const info = Global.validateObra(values, props.data)
      if (info.obligatorios.length > 0) {
        swal({
          title: "Faltan campos obligatorios por rellenar",
          text: "Faltan: " + info.obligatorios.join(', ') + ".",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Vale",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (!res && info.equal) props.close()
          else if (!res && !info.equal && values.idAyun) {
            swal({
              title: "¿Desea salir sin guardar?",
              icon: "warning",
              buttons: {
                salir: {
                  text: "Salir",
                  value: false,
                  className: 'popup-button-red',
                },
                ok: {
                  text: "Guardar",
                  className: 'popup-button',
                  value: true,
                },
              },
            }).then(res => {
              if (res) saveAndClose()
              else props.close()
            })
          } else if (!values.idAyun && !res) {
            props.close()
          }
        })
      } else if (info.obligatorios.length === 0 && !info.equal) {
        swal({
          title: "¿Desea salir sin guardar?",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Guardar",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (res) saveAndClose()
          else props.close()
        })
      } else {
        props.close()
      }
    } else {
      props.close()
    }
  }
  const pdff = async (rowData)=>{
      if(rowData){
          const blob = await pdf(<DosierPDF rowData={rowData} ayun={props.ayun} parad={props.parad} obra={values}/>).toBlob()
          fs.set(blob, "OTROS", `${moment().format("YYYY-MM-DD HHmmss")} Dosier ${values.exped}.pdf`.toUpperCase())
      }
  }
  return (
    <div className='popup'>
      <div className="popup-container">
        <IconButton style={{ position: "absolute", top: "10px", right: "20px", padding: "0", zIndex: "30" }} color="primary" onClick={close}>
          <CloseIcon color="primary" fontSize="large" />
        </IconButton>

        <div className="popup-inner">
          <div className="popup-full-height"></div>
          <div className={classes.root}>
            <AppBar position="static" component="div" elevation={0} style={{ height: "48px", backgroundColor: "#eee", float: "left", flexDirection: "row", justifyContent: "flex-start" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faServer} style={{ marginRight: "7px" }} />
                        Datos generales
                      </Button>
                }
                  {...a11yProps(0)} className={classes.tab} />
                <Tab
                  style={{ pointerEvents: "auto" }}
                  label={
                    values._id !== undefined ?
                      <Button color='inherit' className={classes.tabButton}>
                        <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                        Contratos
                      </Button>
                      :
                      <Tooltip title="Primero debes guardar la obra">
                        <Button color='inherit' className={classes.tabButton}>
                          <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                          Contratos
                        </Button>
                      </Tooltip>
                  }
                  disabled={values._id !== undefined ? false : true}
                  {...a11yProps(1)} className={classes.tab} />
                <Tab
                  style={{ pointerEvents: "auto" }}
                  label={
                    values._id !== undefined ?
                      <Button color='inherit' className={classes.tabButton}>
                        <FontAwesomeIcon icon={faImages} style={{ marginRight: "7px" }} />
                        Dosier
                      </Button>
                      :
                      <Tooltip title="Primero debes guardar la obra">
                        <Button color='inherit' className={classes.tabButton}>
                          <FontAwesomeIcon icon={faImages} style={{ marginRight: "7px" }} />
                          Dosier
                        </Button>
                      </Tooltip>
                  }
                  disabled={values._id !== undefined ? false : true}
                  {...a11yProps(2)} className={classes.tab} />
              </Tabs>

            </AppBar>
            <TabPanel value={value} index={0} component={'span'}>
              <div className={classes.rootTwo}>
                <Grid container>
                  <Grid item xs={4} sm={2} md={1} > {/* ID */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="ID"
                        value={values.id}
                        type="number"
                        onBlur={handleBlurID('id')}
                        onChange={handleChange('id')}
                        defaultValue={props.data.id}
                        InputProps={{
                          className: classes.input
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}> {/* DENOMINACIÓN OBLIGATORIO */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Denominación"
                        id="denom"
                        value={values.denom}
                        onChange={handleChange('denom')}
                        defaultValue={props.data.denom}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}> {/* PLAN/PROYECTO */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Plan/Proyecto"
                        id="plan"
                        value={values.plan}
                        onChange={handleChange('plan')}
                        defaultValue={props.data.plan}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}> {/* COMIENZO */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Comienzo"
                        value={!!values.fechaInicio ? moment(values.fechaInicio).format("DD/MM/YYYY") : "Sin establecer"}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}> {/* FINAL */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Final"
                        value={!!values.fechaFin ? moment(values.fechaFin).format("DD/MM/YYYY") : "Sin establecer"}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={3} sm={2} md={1}> {/* CCCreg OBLIGATORIO */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_SS_Regimen"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findRegimen(values.CCCreg, JSON.parse(localStorage.getItem("ZTA_SS_Regimen")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["CCCreg"]: value.CODS })

                        }}
                        getOptionLabel={(option) => option.CODS + " - " + option.DESS}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODS}&nbsp;-&nbsp;</span>{option.DESS}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Régimen"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4} sm={3} md={2}> {/* CCCprovincia OBLIGATORIO */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Provincia"
                        id="CCCprovincia"
                        value={values.CCCprovincia}
                        onChange={handleChange('CCCprovincia')}
                        defaultValue={props.data.CCCprovincia}
                        fullWidth
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatProvinciaSS
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={5} sm={5} md={4}> {/* CCCnum OBLIGATORIO */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Número"
                        id="CCCnum"
                        value={values.CCCnum}
                        onChange={handleChange('CCCnum')}
                        defaultValue={props.data.CCCnum}
                        fullWidth
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatNumeroSS
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={4} > {/* Cod exp Obra OBLIGATORIO */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Expediente"
                        value={values.exped}
                        id="exped"
                        onChange={handleChange('exped')}
                        defaultValue={props.data.exped}
                        InputProps={{
                          className: classes.input
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={4} > {/* LUGAR OBLIGATORIO */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Emplaz. obra"
                        value={values.lugar}
                        id="lugar"
                        onChange={handleChange('lugar')}
                        defaultValue={props.data.lugar}
                        InputProps={{
                          className: classes.input
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={7} sm={6} md={4} xl={2}> {/* CODIGO MUNICIPIO */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_CodMunicipio"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findCM(values.codMunicipio, JSON.parse(localStorage.getItem("ZTA_CodMunicipio")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) {
                            setValues({ ...values, ["codMunicipio"]: value.CODMUN })
                          }
                        }}
                        getOptionLabel={(option) => option.CODMUN + " - " + option.NOMMUN}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODMUN}&nbsp;-&nbsp;</span>{option.NOMMUN}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="CT. Municipio"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8} md={3} xl={2}> {/* PAIS RESIDENCIA */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        options={JSON.parse(localStorage.getItem("ZTA_Pais"))}
                        autoHighlight
                        value={findNacional(values.codPais, JSON.parse(localStorage.getItem("ZTA_Pais")))}
                        onChange={(event, value) => { if (value && props.user.rol >= 1) setValues({ ...values, ["codPais"]: value.CODPAI }) }}
                        getOptionLabel={(option) => option.CODPAI + " - " + option.NOMPAI}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODPAI}&nbsp;-&nbsp;</span>{option.NOMPAI}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="CT. País"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={4} > {/* CALENDARIO */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={props.calendar}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findCalendar(values.calend, props.calendar)}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["calend"]: value._id })
                        }}
                        getOptionLabel={(option) => option.id + " - " + option.description
                        }
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.id}&nbsp;-&nbsp;</span>{option.description}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Calendario"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* progempl */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_Obras_ProgramaDeEmpleo"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findPE(values.progempl, JSON.parse(localStorage.getItem("ZTA_Obras_ProgramaDeEmpleo")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["progempl"]: value.CODPE })

                        }}
                        getOptionLabel={(option) => {
                          if (option.CODPE !== undefined) return (option.CODPE + " - " + option.DESPE)
                          else return ("Selecciona una opción válida")
                        }}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODPE}&nbsp;-&nbsp;</span>{option.DESPE}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cod. Programa"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* entidad */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_Obras_Entidad"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findE(values.entidad, JSON.parse(localStorage.getItem("ZTA_Obras_Entidad")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["entidad"]: value.CODE })

                        }}
                        getOptionLabel={(option) => {
                          if (option.CODE !== undefined) return (option.CODE + " - " + option.DESE)
                          else return ("Selecciona una opción válida")
                        }}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODE}&nbsp;-&nbsp;</span>{option.DESE}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cod. Entidad"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* actuacion */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_Obras_Actuacion"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findA(values.actuacion, JSON.parse(localStorage.getItem("ZTA_Obras_Actuacion")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["actuacion"]: value.CODA })

                        }}
                        getOptionLabel={(option) => {
                          if (option.CODA !== undefined) return (option.CODA + " - " + option.DESA)
                          else return ("Selecciona una opción válida")
                        }}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODA}&nbsp;-&nbsp;</span>{option.DESA}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cod. Actuación"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* ejerpres */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Ejercicio"
                        value={values.ejerpres}
                        id="ejerpres"
                        onChange={handleChange('ejerpres')}
                        defaultValue={props.data.ejerpres}
                        InputProps={{
                          className: classes.input
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={4}> {/* manoObra */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Presupuesto mano de obra"
                        value={values.manoObra}
                        id="manoObra"
                        onChange={handleChange('manoObra')}
                        defaultValue={props.data.manoObra}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* manoInve */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Gasto"
                        value={values.manoInve}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* calculadoInve */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Disponible"
                        value={(values.manoObra - values.manoInve)}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* manoAuto */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Nóminas sin cerrar"
                        value={values.manoAuto}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* calculadoMano */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Disponible - N.S.C."
                        value={(values.manoObra - values.manoInve - values.manoAuto)}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={4}> {/* material */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Presupuesto materiales"
                        value={values.material}
                        id="material"
                        onChange={handleChange('material')}
                        defaultValue={props.data.material}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* materialDisp */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Gasto"
                        value={values.materialDisp}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* calculadoMaterial */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Disponible"
                        value={(values.material - values.materialDisp)}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* materialAuto */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Facturas sin consolidar"
                        value={values.materialAuto}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* calculadoMaterial */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Disponible - F.S.C."
                        value={(values.material - values.materialDisp - values.materialAuto)}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={0} sm={0} md={3}></Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* disponibleTotal */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Total disponible"
                        value={(values.material - values.materialDisp) + (values.manoObra - values.manoInve)}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={0} sm={0} md={3}></Grid>
                  <Grid item xs={12} sm={6} md={3}> {/* disponibleTotal */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Total disponible - N.S.C - F.S.C"
                        value={(values.material - values.materialDisp - values.materialAuto) + (values.manoObra - values.manoInve - values.manoAuto)}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatEUR
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>

            <TabPanel value={value} index={1} component={'span'}>
              <div className={classes.rootTwo}>
                <Contratos
                  localization={"O"}
                  contratos={props.contratos.filter(element => element.idObra === values._id)}
                  parad={values}
                  setState={props.setState}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={2} component={'span'}>
              <div className={classes.rootTwo}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={12}>
                    <Paper className={classes.paper}>
                      <MaterialTable
                        title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Imágenes</Typography>}
                        columns={[
                          {
                            title: 'IMG',
                            field: 'id',
                            align: "center",
                            render: rowData =>
                              <div style={{ height: "150px", width: "100%" }}>
                                <img
                                  src={
                                    typeof rowData.id === 'string'
                                      ?
                                      `${rowData.id}`
                                      :
                                      rowData.id[0]
                                  }
                                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                                  alt={rowData.description}
                                />
                              </div>,
                            cellStyle: { width: "40%" },
                            editComponent: props => (
                              <input
                                accept="image/*"
                                type="file"
                                id="file"
                                onChange={(event) => { props.onChange(event.target) }}
                              />
                            )
                          },
                          {
                            title: 'Fecha',
                            field: 'date',
                            type: "date",
                            align: "center",
                            defaultSort: 'desc',
                            render: rowData => <span >{moment(rowData.date).format("DD/MM/YYYY")}</span>,
                            editComponent: props => (
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableFuture={true}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  value={props.date}
                                  onChange={(date) => props.onChange(date)}
                                  InputAdornmentProps={{
                                    className: classes.disableButton
                                  }}
                                  InputProps={{
                                    style: { fontSize: "13px" }
                                  }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  fullWidth
                                  disableFuture={{ style: { display: "none" } }}
                                />
                              </MuiPickersUtilsProvider>
                            ), cellStyle:{width:"20%"}
                          },
                          { title: 'Descripción', field: 'description', cellStyle:{width:"20%"} },
                        ]}
                        icons={Icons}
                        data={values.imagenes.concat(newImages)}
                        editable={{
                          onRowAdd: (newData) =>
                            new Promise(async (resolve, reject) => {
                              if (props.user.rol >= 1) {
                                if (newData.id) {
                                  if (newData.id.files.length > 0) {
                                    var data = newData
                                    if (newData.id.files[0].size < 5000000) {
                                      data.id = [URL.createObjectURL(newData.id.files[0]), newData.id.files[0]]
                                      var res = newImages
                                      res.push(data)
                                      setNewImages([...res])
                                    } else {
                                      reject()
                                      swal({
                                        title: "Límite excedido",
                                        text: `El límite de tamaño para una imagen es de 5MB`,
                                        icon: "error",
                                        buttons: {
                                          salir: {
                                            text: "Vale",
                                            value: false,
                                            className: 'popup-button-red',
                                          },
                                        },
                                      })
                                    }
                                  }
                                }
                              }
                              resolve()
                            }),
                          onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                              if (props.user.rol >= 1) {
                                if (values.imagenes.indexOf(oldData) !== -1) {
                                  var res = values.imagenes
                                  res.splice(values.imagenes.indexOf(oldData), 1)
                                  setValues({ ...values, ["imagenes"]: [...res] })
                                  var deleteImagesRes = deleteImages
                                  deleteImagesRes.push(oldData)
                                  setDeleteImages([...deleteImagesRes])
                                } else if (newImages.indexOf(oldData) !== -1) {
                                  var res = newImages
                                  res.splice(newImages.indexOf(oldData), 1)
                                  setNewImages([...res])
                                }
                              }
                              resolve();
                            })
                        }}
                        actions={[
                          {
                            icon: Icons.Download,
                            tooltip: 'Generar dosier',
                            isFreeAction: true,
                            onClick: (evt) => { pdff(values.imagenes.concat(newImages)) }
                          },
                          {
                            icon: forwardRef((props, ref) => (<IconButton {...props} ref={ref} style={{ display: "flex", flexDirection: "row", padding: "0" }}
                            classes={{
                              label: classes.columnsIcons
                          }}
                             color="secondary"
                             >
                              <GetApp fontSize="small" />
                              <div style={{ fontSize: "0.6rem" }}>
                                Descargar imagen
                              </div>
                            </IconButton >)),
                            onClick:(evt, rowData)=>{
                              var link = document.createElement("a");
                              link.href = `${rowData.id}`
                              link.setAttribute("download", moment(rowData.date).format("DD/MM/YYYY")+"-"+rowData.description+"."+rowData.id.split(".")[rowData.id.split(".").length -1])
                              link.target = "_blank"
                              link.click();
                              link.remove()
                             },
                          },
                        ]}
                        options={{
                          search: false,
                          pageSize: 5,
                          pageSizeOptions: [5, 10, 15, 20],
                          actionsColumnIndex: -1,
                          headerStyle: {
                            color: 'rgba(0, 0, 0, 0.54)'
                          },
                          rowStyle: {
                            color: 'rgba(0, 0, 0, 0.54)'
                          },
                        }}

                        localization={{
                          pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}',
                            labelRowsSelect: 'filas'
                          },
                          toolbar: {
                            nRowsSelected: '{0} Fila(s) seleccionada(s)',
                            searchPlaceholder: 'Buscar',
                            searchTooltip: 'Buscar',
                          },
                          header: {
                            actions: 'Acciones'
                          },
                          body: {
                            emptyDataSourceMessage: 'Sin datos',
                            filterRow: {
                              filterTooltip: 'Filtrar'
                            },
                            addTooltip: "Nueva imagen",
                            deleteTooltip: "Eliminar imagen",
                            editTooltip: "Editar imagen",
                            editRow: {
                              deleteText: "¿Estás seguro de eliminar la fila?",
                              cancelTooltip: "Cancelar",
                              saveTooltip: "Guardar"
                            },
                          },

                        }}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
          </div>
          {props.user.rol >= 1 &&
            <IconButton style={{ display: "flex", flexDirection: "column", padding: "0" }} className={classes.buttonSave} color="primary" onClick={save}>
              <SaveIcon color="primary" />
              <div style={{ fontSize: "1rem" }}>
                Guardar
              </div>
            </IconButton>
          }
        </div>
      </div>
    </div >
  );
}