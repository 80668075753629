import React from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import swal from 'sweetalert';
import axios from 'axios'
import Global from '../Global'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSignature } from '@fortawesome/free-solid-svg-icons'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
function findExp(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { exped: "Selecciona una opción válida" }
  }
}
function findDenom(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { denom: "Selecciona una opción válida" }
  }
}
function findCod(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { codigoConvenio: "Selecciona una opción válida" }
  }
}
function findNameConvenio(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i]._id === string) {
        return array[i]
      }
    }
  } else {
    return { nombreConvenio: "Selecciona una opción válida" }
  }
}
function findClaveContrato(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODCC === string) {
        return array[i]
      }
    }
  } else {
    return { CODCC: "Selecciona una opción válida", DESCC: "" }
  }
}
const useStyles = makeStyles((theme) => ({
  rootTwo: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  option: {
    fontSize: "0.8rem",
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputSelect: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputAutocomplete: {
    height: 3,
    fontSize: "0.8rem",
  },
  inputAutocompleteTable: {
    height: 17,
    fontSize: "13px",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tab: {
    height: "30px",
    marginTop: "0px",
    fontSize: "0.7rem",
  },
  tabs: {
    height: "48px"
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    padding: "0",
    zIndex: "30"
  },
  tabButton: {
    '&:hover': {
      backgroundColor: 'initial !important'
    },
    fontSize: 'inherit',
  },
}));
export default function InputAdornments(props) {
  const classes = useStyles();
  const [values, setValues] = React.useState(props.data);
  const [value, setValue] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (prop) => (value) => {
    if (props.user.rol >= 1) {
      setValues({ ...values, [prop]: value });
    }
  };
  const setObra = (value) => {
    const info = Global.validateObra(value)
    if (info.obligatorios.length === 0) {
      setValues({ ...values, ["idObra"]: value._id })
    } else {
      swal({
        html: true,
        title: "Faltan campos obligatorios por rellenar de la obra",
        text: `Antes de continuar tienes que rellenar todos los campos necesarios de la obra. \n Faltan: ${info.obligatorios.join(', ')}`,
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
      setValues({ ...values, ["idObra"]: "" })
    }
  }
  const setTS = (value) => {
    const info = Global.validateTS(value)
    if (info.obligatorios.length === 0) {
      setValues({ ...values, ["idTs"]: value._id })
    } else {
      swal({
        html: true,
        title: "Faltan campos obligatorios por rellenar de la tabla salarial",
        text: `Antes de continuar tienes que rellenar todos los campos necesarios de la tabla salarial. \n Faltan: ${info.obligatorios.join(', ')}`,
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
      setValues({ ...values, ["idTs"]: "" })
    }
  }
  const saveAndClose = () => {
    const infoObra = Global.validateObra(props.obra.find(element => element._id === values.idObra)).obligatorios
    const infoTS = Global.validateTS(props.ts.find(element => element._id === values.idTs)).obligatorios
    if (infoObra.length === 0 && infoTS.length === 0) {
      axios.post(Global.url + 'saveturnos', {turnos:values}, props.token).then(res => {
        if (res.data.status === "error") alert(res.data.message)
        else { 
          const turno = res.data.turnos.find(element => element._id === res.data._id)
          props.setState({ 
            open: true, 
            message: res.data.message, 
            severity: res.data.status, 
            turnos: res.data.turnos, 
            contratos:res.data.contratos, 
            edittionRow: turno, 
            edit: true,
           })
          props.close()
          
        }
      })
    } else {
      swal({
        title: "Faltan campos obligatorios por rellenar",
        text: "Obra: " + infoObra.obligatorios.join(', ') + ". \n Tabla salarial: " + infoTS.obligatorios.join(', ') + ". \n",
        icon: "warning",
        buttons: {
          salir: {
            text: "Salir sin guardar",
            value: false,
            className: 'popup-button-red',
          },
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      }).then(res => {
        if (!res) props.close()
      })
    }
  }
  const save = () => {
    const info = Global.validateTurno(values)
    if (info.obligatorios.length === 0) {
      saveAndClose()
    } else {
      swal({
        title: "Faltan campos obligatorios por rellenar",
        text: "Faltan: " + info.obligatorios.join(', '),
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
    }
  }
  const close = () => {
    if (props.user.rol >= 1) {
      const info = Global.validateTurno(values, props.data)
      if (info.obligatorios.length > 0) {
        swal({
          title: "Faltan campos obligatorios por rellenar",
          text: "Faltan: " + info.obligatorios.join(', ') + ".",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Vale",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (!res && info.equal) props.close()
          else if (!res && !info.equal && values.idAyun) {
            props.close()
          } else if (!values.idAyun && !res) {
            props.close()
          }
        })
      } else if (info.obligatorios.length === 0 && !info.equal) {
        swal({
          title: "¿Desea salir sin guardar?",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Guardar",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (res) saveAndClose()
          else props.close()
        })
      } else {
        props.close()
      }
    } else {
      props.close()
    }
  }
  React.useEffect(()=>{
    const res = props.obra.find(element => element._id === values.idObra)
    const turnosObra = props.turnos.filter(element => element.idObra === values.idObra).sort((a,b)=>Number(b.id.substring(7))-Number(a.id.substring(7)))
    if(res) {
      if(turnosObra.length > 0) setValues({...values, ["id"]:`T-${"0".repeat(4-String(res.id).length)}${res.id}-${"0".repeat(4-String(Number(turnosObra[0].id.substring(7))+1).length)}${String(Number(turnosObra[0].id.substring(7))+1)}`})
      else setValues({...values, ["id"]:`T-${"0".repeat(4-String(res.id).length)}${res.id}-0001`})
    }
    else setValues({...values, ["id"]:undefined})
  },[values.idObra])
  React.useEffect(() => {
    handleChange("fechaAlta")(moment())
  }, [])
  return (
    <div className='popup'>
      <div className="popup-container">
        <IconButton style={{ position: "absolute", top: "10px", right: "20px", padding: "0", zIndex: "30" }} color="primary" onClick={close}>
          <CloseIcon color="primary" fontSize="large" />
        </IconButton>

        <div className="popup-inner">
          <div className="popup-full-height"></div>
          <div className={classes.root}>
            <AppBar position="static" component="div" elevation={0} style={{ height: "48px", backgroundColor: "#eee", float: "left", flexDirection: "row", justifyContent: "flex-start" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                        Nuevo turno de trabajo
                      </Button>
                }
                  {...a11yProps(0)} className={classes.tab} />
              </Tabs>

            </AppBar>
            <TabPanel value={value} index={0} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={8}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Typography variant="body1" component="h2">Alta/Baja</Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={5} xl={2}> {/* FECHA ALTA */}
                        <Paper className={classes.paper}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <KeyboardDatePicker

                              label="Alta S.S."
                              format="dd/MM/yyyy"
                              value={values.fechaAlta}
                              onChange={handleChange('fechaAlta')}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              style={{ margin: "0px" }}
                              InputAdornmentProps={{
                                root: {
                                  padding: 0,
                                  marginBottom: "10px"
                                }
                              }}
                              InputProps={{
                                className: classes.input
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              keyboardIcon={<CalendarTodayIcon style={{ fontSize: 15, marginBottom: "10px" }}></CalendarTodayIcon>}
                            />
                          </MuiPickersUtilsProvider>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={5} xl={2}> {/* FECHA BAJA */}
                        <Paper className={classes.paper}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                            <KeyboardDatePicker

                              label="Baja S.S."
                              format="dd/MM/yyyy"
                              value={values.fechaBaja ? values.fechaBaja : null}
                              onChange={handleChange('fechaBaja')}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              style={{ margin: "0px" }}
                              InputAdornmentProps={{
                                root: {
                                  padding: 0,
                                  marginBottom: "10px"
                                }
                              }}
                              InputProps={{
                                className: classes.input
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              fullWidth
                              keyboardIcon={<CalendarTodayIcon style={{ fontSize: 15, marginBottom: "10px" }}></CalendarTodayIcon>}
                            />
                          </MuiPickersUtilsProvider>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Typography variant="body1" component="h2">Datos obra</Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Autocomplete
                            options={props.obra}
                            autoHighlight
                            classes={{
                              option: classes.option,
                              input: classes.inputAutocomplete
                            }}
                            value={findDenom(values.idObra, props.obra)}
                            onChange={(event, value) => {
                              if (value && props.user.rol >= 1) setObra(value)
                            }}
                            getOptionLabel={(option) => `${option.denom}`}
                            renderOption={(option) => (
                              <React.Fragment>{`${option.denom}`}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Denominación"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Autocomplete
                            options={props.obra}
                            autoHighlight
                            classes={{
                              option: classes.option,
                              input: classes.inputAutocomplete
                            }}
                            value={findExp(values.idObra, props.obra)}
                            onChange={(event, value) => {
                              if (value && props.user.rol >= 1) setObra(value)
                            }}
                            getOptionLabel={(option) => `${option.exped}`}
                            renderOption={(option) => (
                              <React.Fragment>{`${option.exped}`}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Cod. Expediente"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Typography variant="body1" component="h2">Tabla salarial asociada al contrato</Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Autocomplete
                            options={props.ts}
                            autoHighlight
                            classes={{
                              option: classes.option,
                              input: classes.inputAutocomplete
                            }}
                            value={findNameConvenio(values.idTs, props.ts)}
                            onChange={(event, value) => {
                              if (value && props.user.rol >= 1) setTS(value)
                            }}
                            getOptionLabel={(option) => `${option.nombreConvenio}`}
                            renderOption={(option) => (
                              <React.Fragment>{`${option.nombreConvenio}`}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Convenio"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Autocomplete
                            options={props.ts}
                            autoHighlight
                            classes={{
                              option: classes.option,
                              input: classes.inputAutocomplete
                            }}
                            value={findCod(values.idTs, props.ts)}
                            onChange={(event, value) => {
                              if (value && props.user.rol >= 1) handleChange("idTs")(value._id)
                            }}
                            getOptionLabel={(option) => `${option.codigoConvenio}`}
                            renderOption={(option) => (
                              <React.Fragment>{`${option.codigoConvenio}`}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Código"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Typography variant="body1" component="h2">Contrato</Typography>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Paper className={classes.paper} style={{ textAlign: "left" }}>
                          <Autocomplete
                            options={JSON.parse(localStorage.getItem("ZTA_claveContrato"))}
                            autoHighlight
                            classes={{
                              option: classes.option,
                              input: classes.inputAutocomplete
                            }}
                            value={findClaveContrato(values.claveContrato, JSON.parse(localStorage.getItem("ZTA_claveContrato")))}
                            onChange={(event, value) => {
                              if (value && props.user.rol >= 1) handleChange("claveContrato")(value.CODCC)
                            }}
                            getOptionLabel={(option) => `${option.CODCC} - ${option.DESCC}`}
                            renderOption={(option) => (
                              <React.Fragment><span style={{ fontSize: "110%", whiteSpace: "nowrap" }}>{`${option.CODCC} -`}</span> {`${option.DESCC}`}</React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Clave contrato"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                          />
                        </Paper>
                      </Grid>

                      <Grid item xs={12} sm={5} md={3}>
                        <Paper className={classes.paper}>
                          <TextField
                            label="Cod. Oferta de Empleo"
                            value={values.codOfertaEmpleo}
                            fullWidth
                            InputProps={{
                              className: classes.input,
                              inputComponent: Global.formatCodOfertaEmpleo
                            }}
                            onChange={(e) => handleChange('codOfertaEmpleo')(e.target.value)}
                            InputLabelProps={{
                              shrink: true
                            }}
                            autoComplete="something-new"
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={5} md={3}>
                        <Paper className={classes.paper}>
                          <TextField
                            label="Código turno"
                            value={values.id ?values.id :"Seleccione una obra"}
                            fullWidth
                            InputProps={{
                              className: classes.input,
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            autoComplete="something-new"
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} component={'div'}>
              <div className={classes.rootTwo}>
                <Grid container>

                </Grid>
              </div>
            </TabPanel>
          </div>
          {props.user.rol >= 1 &&
            <IconButton style={{ display: "flex", flexDirection: "column", padding: "0" }} className={classes.buttonSave} color="primary" onClick={save}>
              <SaveIcon color="primary" />
              <div style={{ fontSize: "1rem" }}>
                Crear turno
              </div>
            </IconButton>
          }
        </div>
      </div>
    </div >
  );
}