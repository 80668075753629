import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@material-ui/core";

import Forecast from "./Forecast";
import WeatherCardSubheader from "./WeatherCardSubheader";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  atmospheric: {
    fontSize: "14px",
    padding: "5px"
  },
  buttons: {
    color: "black"
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  error: {
    color: "red",
    padding: "10px"
  },
  fullList: {
    width: "auto"
  },
  layout: {
    marginTop: "20px"
  },
  recommendation: {
    padding: "10px 0px 10px 0px",
    textAlign: "center"
  },
  root: {
    flexiGrow: 1,
    color: "black"
  },
  wi: {
    color: theme.palette.primary.main
  },
  accordion: {
    boxShadow:"none",
    border:"none",
    paddingTop:"10px"
  }
}));

export default function AppLayout(props) {
  const classes = useStyles();
  const { currentWeather, forecast, icon } = props;

  return (
          <WeatherCard
            currentWeather={currentWeather}
            forecast={forecast}
            icon={icon}
          />
  );
}

const WeatherCard = props => {
  const classes = useStyles();
  const humidity = "wi wi-humidity";
  const strongWind = "wi wi-strong-wind";
  const { currentWeather, forecast, icon } = props;

  return (
    <Card className={classes.card} elevation={0}>
      <CardHeader
        titleTypographyProps={{style:{fontSize:"1rem"}}}
        subheaderTypographyProps={{style:{fontSize:"0.8rem"}}}
        title={currentWeather.city + ", " + currentWeather.country}
        subheader={<WeatherCardSubheader currentWeather={currentWeather} />}
      />
      <CardContent>
        <CardMedia
          className={`${icon} ${classes.wi}`}
          src={icon}
          style={{ fontSize: "40px" }}
        />
        <Typography
          variant="h6"
          className="big-temp"
          color="textPrimary"
          component="h4"
          style={{ fontFamily: "Montserrat", paddingTop: "10px", fontSize:"1rem" }}
        >
          {Math.round(currentWeather.temperature)}&deg;C
        </Typography>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          gutterBottom
          style={{ paddingTop: "40px" }}
        >
          <span
            className={`${strongWind} ${classes.wi} ${classes.atmospheric}`}
          ></span>
          {currentWeather.wind_speed} km/h{" "}
          <span
            className={`${humidity} ${classes.wi} ${classes.atmospheric}`}
          ></span>
          {currentWeather.humidity}%
        </Typography>
        <Typography
          className={`${classes.recommendation} recommendation`}
        >
        </Typography>
        <Divider variant="middle" />
        <Accordion square={true} classes={{root:classes.accordion}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{fontSize:"0.8rem"}}/>}
        >
          <Typography style={{fontSize:"0.8rem"}}>Próximos 5 días</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding:"0"}}>
          <Forecast forecast={forecast} />
        </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};
