import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, Slider, Button, Dialog, AppBar, Toolbar, IconButton } from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import Global from '../Global';
import axios from 'axios';
import ConfigFS from '../components/configPuesto/ConfigPuestoFS'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: "800px",
    margin: "150px auto"
  },
  row: {
    padding: "2rem 0rem"
  },
}));
const marks = [
  {
    value: 50,
    label: '50%',
  },
  {
    value: 75,
    label: '75%',
  },
  {
    value: 100,
    label: '100%',
  },
  {
    value: 125,
    label: '125%',
  },
  {
    value: 150,
    label: '150%',
  },
];

function valuetext(value) {
  return `${value}°C`;
}

export default function Trabajadores(props) {
  const classes = useStyles();
  const [date, setDate] = useState(localStorage.getItem("ZTAUpdated"))
  const [step, setStep] = useState(0)
  const [open, setOpen] = useState(0)

  const getZTA = () => {
    axios.post(Global.url + "getzta", { "date": false }, props.token).then(ress => {
      if (ress.data.status !== "ok") {
        for (var key in ress.data.data) {
          localStorage.setItem(key, JSON.stringify(ress.data.data[key]))
        }
        localStorage.setItem("ZTAUpdated", moment())
        setDate(moment())
      }
    })
  }
  const disabled = moment().diff(date, "day") < 1
  React.useEffect(()=>{
      setStep(0)
  },[open])
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Paper>
          <Grid container style={{ backgroundColor: "#fff", padding: "2rem" }}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2" style={{ textAlign: "center", marginBottom: "2rem" }}>Configuración del puesto</Typography>
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <Typography gutterBottom>
                Gestor de archivos
                            </Typography>
              <Button onClick={()=>setOpen(true)} variant="outlined" color="primary" style={{ margin: "25px 0" }}>Actualizar directorios</Button>
                <Typography style={{fontSize:"0.7rem"}}>
                  Con la actualización de Chrome es posible gestionar los ficheros generados por la aplicación. <br/> 
                  Los archivos irán a su carpeta seleccionada.<br/> 
                  Si es incapaz de configurarlo, póngase en contacto con nuestro equipo de soporte técnico.
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <Typography gutterBottom>
                Tamaño de letra
                            </Typography>
              <Slider
                defaultValue={Number(props.fontSize)}
                min={50}
                max={150}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                marks={marks}
                step={5}
                onChangeCommitted={(e, val) => props.setFontSize(val)}
              />
            </Grid>
            <Grid item xs={12} className={classes.row}>
              <Typography gutterBottom>
                Tablas auxiliares
                            </Typography>
              <Typography variant="body2">
                Última actualización: {moment(date).format("DD/MM/YYYY HH:mm")}
              </Typography>
              <Button onClick={getZTA} disabled={disabled} variant="outlined" color="primary" style={{ margin: "25px 0" }}>Actualizar tablas auxiliares</Button>
              {disabled &&
                <Typography style={{fontSize:"0.7rem"}}>
                  Las tablas se han actualizado hace menos de 24 horas, por ello no es posible actualizarlas. <br/> 
                  Las tablas auxiliares se actualizarán automáticamente cuando algunas de ellas sufra algún cambio.<br/> 
                  Si tienes problemas póngase en contacto con el equipo de soporte técnico.
                </Typography>
              }

            </Grid>
          </Grid>
        </Paper>
        <Dialog fullScreen open={open} onClose={()=>{setOpen(false); setStep(0);}}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Gestor de archivos
            </Typography>
            <IconButton edge="end" color="inherit" onClick={()=>{setOpen(false); setStep(0);}} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
          <ConfigFS setStep={(h)=>{setStep(h);setOpen(false)}}></ConfigFS>
      </Dialog>
      </div>
    </React.Fragment>
  );
}