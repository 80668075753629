import { Grid, Button } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import Global from '../../Global';
import moment from 'moment';
import { useHistory } from 'react-router';

export default function Exchange() {
    const [inventories, setInventories] = useState([]);
    const getInventories = () => {
        axios.get(Global.url + 'inventory').then(res => {
            setInventories(res.data.reverse());
        }).catch(err => {
        });
    }
    useEffect(() => {
        getInventories();
    }, [])

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            style={{ marginTop: '2rem' }}
        >
            <Grid item>
                <Button component={Link} to="/old/inventory/add" variant="contained" color="primary">
                    Crear nuevo inventario
                </Button>
            </Grid>
            <Grid item>
                <InventoriesTable rows={inventories} />
            </Grid>
        </Grid>
    );
}

const columns = [
    { field: 'id', title: 'ID', width: 90 },
    {
        field: 'local',
        title: 'Local',
        width: 150,
    },
    {
        field: 'name',
        title: 'Nombre',
        width: 170,
    },
    {
        field: 'date',
        title: 'Fecha',
        sortable: false,
        width: 150,
        render: rowData => moment(rowData.date).format('DD/MM/YYYY')
    },
];

function InventoriesTable({ rows }) {
    const history = useHistory();
    return (
        <div style={{ width: '100%', maxWidth: '800px' }}>
            <MaterialTable
                title="Inventarios"
                columns={columns}
                data={rows}
                options={{
                    pageSize: 25,
                    pageSizeOptions: [25, 50, 100, 200],
                    search: false
                }}
                onRowClick={(event, rowData, togglePanel) => {
                    history.push('/old/inventory/' + rowData._id);
                }}
                localization={{
                    pagination: {
                        labelDisplayedRows: '{from}-{to} de {count}',
                        labelRowsSelect: 'filas'
                    },
                    toolbar: {
                        nRowsSelected: '{0} row(s) seleccionada',
                        searchPlaceholder: 'Buscar',
                        searchTooltip: 'Buscar',
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'Sin datos',
                        filterRow: {
                            filterTooltip: 'Filtrar'
                        }
                    }
                }}
            />
        </div>
    );
}
