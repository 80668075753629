import React from 'react'
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer'
import moment from 'moment'
import 'moment/locale/es'

import Global from '../Global'
const PrintDoc = ({ rowData, ayun, parad }) => {
  return (
    <Document>
      <Page style={styles.page} size="A4" wrap>
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={styles.headerImage} >
              <Image
                style={styles.image}
                src={`${ayun.imgDoc}`}
                fixed
              />
            </View>
            <View style={styles.headerText} >
              <Text>DOCUMENTO DE ENTREGA</Text>
              <Text>EQUIPOS DE PROTECCIÓN INDIVIDUAL LABORAL</Text>
            </View>
          </View>
        </View>
        <View style={[styles.container, styles.row]}>
        <Text style={styles.columnTitle}>Nombre:</Text>
          <Text style={styles.columnDataDos}>{parad.nombre + " " + parad.primerApellido + " " + parad.segundoApellido}</Text>
          <Text style={styles.columnTitle}>DNI:</Text>
          <Text style={styles.columnData}>{parad.dni}</Text>

        </View>

        <Text style={styles.text}>
          La persona empleada cuyos datos se han consignado, declara haber recibido del {ayun.nombre} los siguientes equipos y/o prendas:
  </Text>
  <View style={[styles.container, styles.row]}>
          <Text style={[styles.columnTitleData]}>Fecha</Text>
          <Text style={[styles.columnTitleData]}>Cantidad</Text>
          <Text style={[styles.columnTitleData]}>Tipo</Text>
          <Text style={[styles.columnTitleData]}>Detalles</Text>
          {rowData.sort((a, b) => a.date - b.date).map((x, i) => {
            return (
              <React.Fragment>
                <Text style={[styles.columnDataData]}>{moment(x.date).format("DD/MM/YYYY")}</Text>
            <Text style={[styles.columnDataData]}>{x.quantity}</Text>
                <Text style={[styles.columnDataData]}>{x.type}</Text>
                <Text style={[styles.columnDataData]}>{x.typeTwo}</Text>
              </React.Fragment>
            )
          })}
        </View>
        <View style={styles.container}>
          <Text style={styles.text}>La persona empleada queda debidamente informado de las siguientes normas en cuanto a la utilizaci&oacute;n en el desempe&ntilde;o de su actividad laboral de dichos equipos y/o prendas y se compromete a su exacto cumplimiento:
</Text>
          <Text style={styles.textDos}>1.Los equipos y/o prendas entregados a la persona empleada son propiedad del {ayun.nombre} y deben ser devueltos al mismo al t&eacute;rmino de la relaci&oacute;n funcionarial o laboral o, si ello no fuera posible por deterioro, extrav&iacute;o u otras causas, justificar esos extremos. Como excepci&oacute;n, en el caso de prendas de uso personal exclusivo como guantes, botas, etc., entregadas como consecuencia de una relaci&oacute;n laboral de car&aacute;cter temporal, el trabajador custodiar&aacute; dichas prendas a la finalizaci&oacute;n de su contrato, comprometi&eacute;ndose a aportar las mismas en perfecto estado si fuera nuevamente contratado en el periodo de dos a&ntilde;os desde la fecha de este documento. En caso de incumplimiento no justificado de esta obligaci&oacute;n, el importe de reposici&oacute;n de los equipos o prendas necesarios ser&aacute; satisfecho por el trabajador.
</Text>
          <Text style={styles.textDos}>2. La persona empleada se compromete al debido cuidado y mantenimiento de los equipos y/o prendas que se entregan, durante el periodo estimado de duraci&oacute;n de los mismos, comunicando al Ayuntamiento cualquier incidencia que hiciera necesaria la reparaci&oacute;n o renovaci&oacute;n de un elemento.
</Text><Text style={styles.textDos}>3. El uso de las prendas de trabajo que se entregan es obligatorio durante la totalidad de la jornada de trabajo, de manera que la prestaci&oacute;n del trabajo ha de realizarse siempre con dicha uniformidad completa de acuerdo con las circunstancias climatol&oacute;gicas que concurran y sin prendas exteriores que cubran u oculten las entregadas.
</Text><Text style={styles.textDos}>4. El uso de equipos de trabajo y/o de protecci&oacute;n personal es obligatorio en el desempe&ntilde;o de las tareas que as&iacute; lo exijan, conforme a las normas de aplicaci&oacute;n y los conocimientos profesionales del propio empleado.
</Text><Text style={styles.textDos}>5. No est&aacute; permitido el uso de las prendas y/o equipos de trabajo fuera de la jornada laboral del empleado, con excepci&oacute;n en su caso del tiempo de traslado del empleado desde su domicilio al centro de trabajo y regreso.
</Text><Text style={styles.textDos}>6. Sin perjuicio de la posible existencia de normas que regulen la uniformidad en el puesto de trabajo indicado o la adopci&oacute;n de medidas concretas de seguridad en dicha actividad, el empleado declara expresamente conocer y aceptar que el uso obligatorio de prendas y/o equipos de trabajo responde a la necesidad de potenciar la imagen de servicio p&uacute;blico de la administraci&oacute;n municipal y, especialmente, de garantizar la seguridad de los propios empleados p&uacute;blicos y del resto de ciudadanos, por lo que queda advertido que el incumplimiento de estas normas conllevar&aacute; la exigencia de responsabilidad.
</Text><Text style={styles.text}>
            Y para que as&iacute; conste, firmo el presente documento en {ayun.localidad}, a {moment().locale('es').format('LL')}.
  </Text>
        </View>
        <View style={[styles.container, styles.row]}>
          <Text style={[styles.columnFirma]}>
            EL EMPLEADO
      </Text>
          <View style={[styles.columnFirma]}>
            <Text >
              Ante mí
      </Text>
            <Text>
              EL RESPONSABLE
      </Text>
          </View>

        </View>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  )
};

export default PrintDoc;

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',

  },
  headerText: {
    width: '60%',
    fontSize: 11,
  },
  headerImage: {
    width: '40%',
    fontSize: 11,
  },
  image: {
    width: "35%"
  },
  columnTitle: {
    width: '10%',
    paddingLeft: 5,
    marginTop: -1,
    marginLeft: -1,
    paddingTop: 2,
    border: '1 solid black'
  },
  columnData: {
    width: '25%',
    paddingLeft: 5,
    marginTop: -1,
    marginLeft: -1,
    paddingTop: 2,
    border: '1 solid black'
  },
  columnTitleData: {
    width: '25%',
    paddingLeft: 5,
    marginTop: -1,
    marginLeft: -1,
    paddingTop: 2,
    backgroundColor: "#eee",
    textAlign: "center",
    border: '1 solid black'
  },
  columnDataData: {
    width: '25%',
    paddingLeft: 5,
    marginTop: -1,
    marginLeft: -1,
    paddingTop: 2,
    fontSize: 9,
    textAlign: "center",
    border: '1 solid black'
  },
  columnDataDos: {
    width: '55%',
    paddingLeft: 7,
    marginTop: -1,
    marginLeft: -2,
    marginRigth: -20,
    paddingTop: 2,
    border: '1 solid black',
    borderLeft: '0 solid black'
  },
  columnFirma: {
    width: '40%',
    textAlign: "center",
    fontSize: 11,
  },
  text: {
    marginTop: 10,
    fontSize: 10,
    textAlign: "justify",
  },
  textDos: {
    paddingLeft: 10,
    marginTop: 5,
    textAlign: "justify",
  },
  container: {
    marginTop: 20,
    fontSize: 10
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  page: { padding: 40 },

});