import React, { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table'
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import Typography from '@material-ui/core/Typography'
import swal from 'sweetalert';
import Tooltip from '@material-ui/core/Tooltip'
import Popper from '@material-ui/core/Popper'
import moment from 'moment'
import axios from 'axios'
import Global from '../Global'
import stockImage from '../assets/img/trabajador.svg'
import stockImageMujer from '../assets/img/mujer.svg'
import stockImageHombre from '../assets/img/hombre.svg'
import EpiSalud from './EpiSalud'
import Contratos from './Contratos'

import Icons from '../components/Icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartbeat, faUserTag, faBalanceScaleLeft, faFileSignature } from '@fortawesome/free-solid-svg-icons'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
function findNacional(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODPAI === string) {
        return array[i]
      }
    }
  } else {
    return { "CODPAI": string, "NOMPAI": string }
  }
}
function findTV(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODTVP === string) {
        return array[i]
      }
    }
  } else {
    return { "CODTVP": string, "DESTVP": string }
  }
}
function findCMCP(array, string) {
  var res = []
  for (var i in array) {
    if (array[i].CODMUN.substring(0, 2) === string) {
      res.push(array[i])
    }
  }
  return res
}
function findCM(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODMUN === string) {
        return array[i]
      }
    }
  } else {
    return { "CODMUN": string, "NOMMUN": string }
  }
}
function findSSIPF(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODIPF === string) {
        return array[i]
      }
    }
  } else {
    return { "CODIPF": string, "DESIPF": string }
  }
}
function findSEPEIPF(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODIPF === string) {
        return array[i]
      }
    }
  } else {
    return { "CODIPF": string, "DESIPF": string }
  }
}
function findCP(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODDEL === string) {
        return array[i]
      }
    }
  } else {
    return { "CODDEL": string, "CODDEL": string }
  }
}
function findclaveIRPF(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODCPE === string) {
        return array[i]
      }
    }
  } else {
    return { "CODCPE": string, "DESCPE": string }
  }
}
function findsubclaveIRPF(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODSIRPF === string) {
        return array[i]
      }
    }
  } else {
    return { "CODSIRPF": "SELECCIONA UNA CLAVE", "DESSIRPF": string }
  }
}
function findsubclaveIRPFOptions(array, clave) {
  var res = []
  for (var i in array) {
    if (array[i].CODSIRPF.includes(clave)) {
      res.push(array[i])
    }
  }
  return res
}
function findTipoRelacion(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODTR === string) {
        return array[i]
      }
    }
  } else {
    return { "CODTR": string, "DESTR": string }
  }
}
function findsitFamilia(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODSF === string) {
        return array[i]
      }
    }
  } else {
    return { "CODSF": string, "DESSF": string }
  }
}
function findNFO(string, array) {
  if (typeof string === 'string') {
    for (var i in array) {
      if (array[i].CODNFO === string) {
        return array[i]
      }
    }
  } else {
    return { "CODNFO": string, "DESNFO": string }
  }
}
const useStyles = makeStyles((theme) => ({
  rootTwo: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  option: {
    fontSize: "0.8rem",
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  input: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputMultiline: {
    fontSize: "0.8rem",
  },
  inputSelect: {
    height: 15,
    fontSize: "0.8rem",
  },
  inputAutocomplete: {
    height: 3,
    fontSize: "0.8rem",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  tab: {
    height: "30px",
    marginTop: "0px",
    fontSize: "0.7rem",
  },
  tabs: {
    height: "48px"
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    padding: "0",
    zIndex: "30"
  },
  disableButton: {
    display: "none"
  },
  tabButton: {
    '&:hover': {
      backgroundColor: 'initial !important'
    },
    fontSize: 'inherit',
  },
  userData: {
      position: "absolute",
      right: "50px",
      top: "6px",
      zIndex: "30",
  },
  userInfo: {
      paddingRight: '0.8rem',
      float: "rigth",
      fontSize: "0.8rem",
      fontWeight: "bold",
  },
  userWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flexShrink: "0",
  },
}));
export default function InputAdornments(props) {
  const fileUploader = React.useRef(null);
  const classes = useStyles();
  const [values, setValues] = React.useState(props.data);
  const [value, setValue] = React.useState(0);
  const [dniError, setDniError] = React.useState(false);
  const [dniErrorText, setDniErrorText] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorText, setEmailErrorText] = React.useState("");
  const [IBAN, setIBAN] = React.useState(props.data.IBAN)
  const [ibanError, setIbanError] = React.useState(false);
  const [ibanErrorText, setIbanErrorText] = React.useState("");
  const [image, setImage] = React.useState(props.data.imagen)
  const [url, setUrl] = React.useState(stockImage)
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = (prop) => (event) => {
    if (props.user.rol >= 1) {
      if (prop === 'dni' && values.claveIPF === "1") {
        setDniError(Global.validateDNI(event.target.value).status)
        setDniErrorText(Global.validateDNI(event.target.value).message)
      }
      if (prop === 'email') {
        setEmailError(Global.validateEmail(event.target.value).status)
        setEmailErrorText(Global.validateEmail(event.target.value).message)
      }
      setValues({ ...values, [prop]: event.target.value });
    }
  };
  const handleChangeIBAN = () => {
    const res = Global.checkIBAN(IBAN)
    if (res) {
      setIbanError(false)
      setIbanErrorText("")
      setValues({ ...values, ["IBAN"]: IBAN });
    } else {
      setIbanError(true)
      setIbanErrorText("IBAN no válido, revíselo")
    }
  }
  const handleBlur = (e) => {
    var num = parseFloat(values.rirpf);
    var cleanNum = num.toFixed(2);
    setValues({ ...values, ["rirpf"]: cleanNum })
  }
  const handleChangefn = (date) => {
    setValues({ ...values, ['fechaNacimiento']: date });
  };
  const saveAndClose = () => {
    axios.post(Global.url + 'updateworker', values, props.token).then(res => {
      if (res.data.status === "error") alert(res.data.message)
      else {
        if (props.data.imagen !== image) {
          var formData = new FormData();
          formData.append('file0', image, image.name)
          axios.post(Global.url + 'updateimageworker/' + res.data.doc._id, formData, props.token).then(ress => {
            if (res.data.status === "error") alert(ress.data.message)
            else { props.setState({ open: true, message: ress.data.message, severity: ress.data.status, parad: ress.data.parad }) }
          })
        } else {
          props.setState({ open: true, message: res.data.message, severity: res.data.status, parad: res.data.parad })
        }
      }

    })

  }
  const save = () => {
    const info = Global.validateWorker(values)
    if (info.obligatorios.length === 0) {
      saveAndClose()
    } else {
      swal({
        title: "Faltan campos obligatorios por rellenar",
        text: "Faltan: " + info.obligatorios.join(', '),
        icon: "warning",
        buttons: {
          ok: {
            text: "Vale",
            className: 'popup-button',
            value: true,
          },
        },
      })
    }
  }
  const close = () => {
    if (props.user.rol >= 1) {
      const info = Global.validateWorker(values, props.data)
      if (info.obligatorios.length > 0) {
        swal({
          title: "Faltan campos obligatorios por rellenar",
          text: "Faltan: " + info.obligatorios.join(', ') + ".",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Vale",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (!res && info.equal) props.close()
          else if (!res && !info.equal && values.idAyun) {
            swal({
              title: "¿Desea salir sin guardar?",
              icon: "warning",
              buttons: {
                salir: {
                  text: "Salir",
                  value: false,
                  className: 'popup-button-red',
                },
                ok: {
                  text: "Guardar",
                  className: 'popup-button',
                  value: true,
                },
              },
            }).then(res => {
              if (res) saveAndClose()
              else props.close()
            })
          } else if (!values.idAyun && !res) {
            props.close()
          }
        })
      } else if (info.obligatorios.length === 0 && !info.equal) {
        swal({
          title: "¿Desea salir sin guardar?",
          icon: "warning",
          buttons: {
            salir: {
              text: "Salir",
              value: false,
              className: 'popup-button-red',
            },
            ok: {
              text: "Guardar",
              className: 'popup-button',
              value: true,
            },
          },
        }).then(res => {
          if (res) saveAndClose()
          else props.close()
        })
      } else {
        props.close()
      }
    } else {
      props.close()
    }
  }
  const saveImage = (event) => {
    if (event.target.files.length === 0) {
      setImage(props.data.imagen)
    } else {
      setImage(event.target.files[0])
    }
  }
  React.useEffect(() => {
    if (image && typeof image === "string") {
      axios.get(values.imagen, {
        responseType: "arraybuffer",
      }).then(response => {
        const data = `data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`;
        setUrl(data)
      })
    } else if (!image) {
      if (values.hombre === true) {
        setUrl(stockImageHombre)
      } else if (values.hombre === false) {
        setUrl(stockImageMujer)
      } else {
        setUrl(stockImage)
      }

    } else {
      setUrl(URL.createObjectURL(image))
    }
  }, [image])
  return (
    <div className='popup'>
      <div className="popup-container">
        <div className={classes.userData}>
          <div className={classes.userWrapper}>
            <div className={classes.userInfo}>
              <span>{values.primerApellido ?`${values.primerApellido}`:``}{ values.segundoApellido ?` ${values.segundoApellido}`:``}{ values.nombre ?`, ${values.nombre}`:``}</span>
              <div className="clearfix"></div>
              <span style={{ float: "right" }}>{values.dni ?values.dni :""}</span>
            </div>
          </div>
        </div>
        <IconButton style={{ position: "absolute", top: "10px", right: "20px", padding: "0", zIndex: "30" }} color="primary" onClick={close}>
          <CloseIcon color="primary" fontSize="large" />
        </IconButton>
        {value === 0 &&
          <div className="foto-carnet-wrapper" onClick={() => fileUploader.current.click()}>
            <img src={url} alt={"foto-" + values.nombre} style={{ height: "calc( 100% - 20px)", width: "100%" }}></img>
            <spam>Relación 4:3 (200px:150px)</spam>
            <input
              accept="image/*"
              type="file"
              id="file"
              ref={fileUploader}
              onChange={saveImage}
              style={{ display: "none" }} />
          </div>
        }

        <div className="popup-inner">
          <div className="popup-full-height"></div>
          <div className={classes.root}>
            <AppBar position="static" component="div" elevation={0} style={{ height: "48px", backgroundColor: "#eee", float: "left", flexDirection: "row", justifyContent: "flex-start" }}>
              <Tabs
                value={value}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                <Tab
                  label={
                    <Button color='inherit' className={classes.tabButton}>
                      <FontAwesomeIcon icon={faUserTag} style={{ marginRight: "7px" }} />
                      Datos Personales
                    </Button>
                  }
                  {...a11yProps(0)}
                  className={classes.tab}
                />
                <Tab label={
                  <Button color='inherit' className={classes.tabButton}>
                    <FontAwesomeIcon icon={faBalanceScaleLeft} style={{ marginRight: "7px" }} />
                      Datos IRPF
                    </Button>
                }
                  {...a11yProps(1)} className={classes.tab} />
                <Tab
                  style={{ pointerEvents: "auto" }}
                  label={
                    values._id !== undefined ?
                      <Button color='inherit' className={classes.tabButton}>
                        <FontAwesomeIcon icon={faHeartbeat} style={{ marginRight: "7px" }} />
                        PREVENCIÓN
                      </Button>
                      :
                      <Tooltip title="Primero debes guardar el trabajador">
                        <Button color='inherit' className={classes.tabButton}>
                          <FontAwesomeIcon icon={faHeartbeat} style={{ marginRight: "7px" }} />
                          PREVENCIÓN
                    </Button>
                      </Tooltip>

                  }
                  {...a11yProps(2)}
                  className={classes.tab}
                  disabled={values._id !== undefined ? false : true}

                />
                <Tab
                  style={{ pointerEvents: "auto" }}
                  label={
                    values._id !== undefined ?
                      <Button color='inherit' className={classes.tabButton}>
                        <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                        Contratos
                      </Button>
                      :
                      <Tooltip title="Primero debes guardar el trabajador">
                        <Button color='inherit' className={classes.tabButton}>
                          <FontAwesomeIcon icon={faFileSignature} style={{ marginRight: "7px" }} />
                          Contratos
                        </Button>
                      </Tooltip>
                  }
                  disabled={values._id !== undefined ? false : true}
                  {...a11yProps(3)}
                  className={classes.tab} />
              </Tabs>

            </AppBar>
            <TabPanel value={value} index={0} component={'span'}>
              <div className={classes.rootTwo}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* IPF 1 */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_SS_IPF"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findSSIPF(values.claveIPF, JSON.parse(localStorage.getItem("ZTA_SS_IPF")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) {
                            if (value.CODIPF === "1") {
                              setValues({ ...values, ["claveIPF"]: value.CODIPF, ["idenimen"]: "D" })
                            } else if (value.CODIPF === "6") {
                              setValues({ ...values, ["claveIPF"]: value.CODIPF, ["idenimen"]: "E" })
                            } else {
                              setValues({ ...values, ["claveIPF"]: value.CODIPF })
                            }
                          }
                        }}
                        getOptionLabel={(option) => option.CODIPF + " - " + option.DESIPF}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODIPF}&nbsp;-&nbsp;</span>{option.DESIPF}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="IPF"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* IPF 2 */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_SEPE_IPF"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findSEPEIPF(values.idenimen, JSON.parse(localStorage.getItem("ZTA_SEPE_IPF")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) {
                            setValues({ ...values, ["idenimen"]: value.CODIPF })
                          }
                        }}
                        getOptionLabel={(option) => option.CODIPF + " - " + option.DESIPF}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODIPF}&nbsp;-&nbsp;</span>{option.DESIPF}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="IPF-SEPE"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} xl={2}> {/* DNI */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Documento"
                        id="dni"
                        value={values.dni}
                        onChange={handleChange('dni')}
                        error={dniError}
                        helperText={dniErrorText}
                        defaultValue={props.data.dni}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={6} sm={4} md={2} xl={1}> {/* SEXO */}
                    <Paper className={classes.paper}>
                      <TextField
                        select
                        InputProps={{
                          className: classes.inputSelect
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        label="Sexo"
                        value={values.hombre}
                        defaultValue={props.data.hombre}
                        onChange={handleChange('hombre')}
                        fullWidth
                        autoComplete="something-new"
                      >
                        <MenuItem value={true}>Hombre</MenuItem>
                        <MenuItem value={false}>Mujer</MenuItem>
                      </TextField>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8} md={3} xl={2}> {/* NACIONALIDAD */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_Pais"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findNacional(values.nacional, JSON.parse(localStorage.getItem("ZTA_Pais")))}
                        onChange={(event, value) => { if (value && props.user.rol >= 1) setValues({ ...values, ["nacional"]: value.CODPAI }) }}
                        getOptionLabel={(option) => option.CODPAI + " - " + option.NOMPAI}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODPAI}&nbsp;-&nbsp;</span>{option.NOMPAI}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Nacionalidad"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={8} md={3} xl={2}> {/* NOMBRE */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Nombre"
                        value={values.nombre}
                        onChange={handleChange('nombre')}
                        defaultValue={props.data.nombre}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2} xl={2}> {/* 1 APELLIDO */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Apellido 1"
                        value={values.primerApellido}
                        onChange={handleChange('primerApellido')}
                        defaultValue={props.data.primerApellido}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2} xl={2}> {/* 2 APELLIDO */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Apellido 2"
                        value={values.segundoApellido}
                        onChange={handleChange('segundoApellido')}
                        defaultValue={props.data.segundoApellido}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* FECHA NACIMIENTO */}
                    <Paper className={classes.paper}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                        <KeyboardDatePicker

                          label="Fecha nacimiento"
                          format="dd/MM/yyyy"
                          value={values.fechaNacimiento}
                          onChange={handleChangefn}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          style={{ margin: "0px" }}
                          InputAdornmentProps={{
                            root: {
                              padding: 0,
                              marginBottom: "10px"
                            }
                          }}
                          InputProps={{
                            className: classes.input
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          keyboardIcon={<CalendarTodayIcon style={{ fontSize: 15, marginBottom: "10px" }}></CalendarTodayIcon>}
                        />
                      </MuiPickersUtilsProvider>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={7} sm={5} md={3} xl={2}> {/* DOMSIG */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_TipoVia"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findTV(values.domsig, JSON.parse(localStorage.getItem("ZTA_TipoVia")))}
                        onChange={(event, value) => { if (value && props.user.rol >= 1) setValues({ ...values, ["domsig"]: value.CODTVP }) }}
                        getOptionLabel={(option) => option.CODTVP + " - " + option.DESTVP}
                        renderOption={(option) => (
                          <React.Fragment>
                            {option.CODTVP}:&nbsp;{option.DESTVP}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo vía"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={7} md={3} xl={3}> {/* NOMBRE VÍA */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Nombre vía"
                        value={values.domicilio}
                        onChange={handleChange('domicilio')}
                        defaultValue={props.data.domicilio}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4} md={1} xl={1}> {/* DOMNUM */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Número"
                        value={values.domnum}
                        type="number"
                        onChange={handleChange('domnum')}
                        defaultValue={props.data.domnum}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={0} md={12}></Grid>
                  <Grid item xs={4} md={1} xl={1}> {/* DOMBLO */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Bloque"
                        value={values.domblo}
                        onChange={handleChange('domblo')}
                        defaultValue={props.data.domblo}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4} md={1} xl={1}> {/* DOMESC */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Escalera"
                        value={values.domesc}
                        onChange={handleChange('domesc')}
                        defaultValue={props.data.domesc}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4} md={1} xl={1}> {/* DOMPIS */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Piso"
                        value={values.dompis}
                        onChange={handleChange('dompis')}
                        defaultValue={props.data.dompis}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4} md={1} xl={1}> {/* DOMPUE */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Puerta"
                        value={values.dompue}
                        onChange={handleChange('dompue')}
                        defaultValue={props.data.dompue}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={4} sm={3} xl={1}> {/* POSTAL */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="CP"
                        value={values.postal}
                        onChange={handleChange('postal')}
                        defaultValue={props.data.postal}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3} xl={2}> {/* CIUDAD */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Ciudad"
                        value={values.ciudad}
                        onChange={handleChange('ciudad')}
                        defaultValue={props.data.ciudad}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={5} sm={4} md={3} xl={2}> {/* PROVINCIA */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_CodProvincia"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findCP(values.provincia, JSON.parse(localStorage.getItem("ZTA_CodProvincia")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) {
                            setValues({ ...values, ["provincia"]: value.CODDEL })
                          }
                        }}
                        getOptionLabel={(option) => option.CODDEL + " - " + option.NOMDEL}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODDEL}&nbsp;-&nbsp;</span>{option.NOMDEL}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cod. Provincia"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={7} sm={6} md={4} xl={2}> {/* CODIGO MUNICIPIO */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={findCMCP(JSON.parse(localStorage.getItem("ZTA_CodMunicipio")), values.provincia)}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findCM(values.municipio, JSON.parse(localStorage.getItem("ZTA_CodMunicipio")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) {
                            setValues({ ...values, ["municipio"]: value.CODMUN })
                          }
                        }}
                        getOptionLabel={(option) => option.CODMUN + " - " + option.NOMMUN}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODMUN}&nbsp;-&nbsp;</span>{option.NOMMUN}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cod. Municipio"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8} md={3} xl={2}> {/* PAIS RESIDENCIA */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        options={JSON.parse(localStorage.getItem("ZTA_Pais"))}
                        autoHighlight
                        value={findNacional(values.paisResidencia, JSON.parse(localStorage.getItem("ZTA_Pais")))}
                        onChange={(event, value) => { if (value && props.user.rol >= 1) setValues({ ...values, ["paisResidencia"]: value.CODPAI }) }}
                        getOptionLabel={(option) => option.CODPAI + " - " + option.NOMPAI}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODPAI}&nbsp;-&nbsp;</span>{option.NOMPAI}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="País"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={8} md={3} xl={2}> {/* TELÉFONO */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Teléfono"
                        value={values.telf}
                        onChange={handleChange('telf')}
                        defaultValue={props.data.telf}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8} md={3} xl={2}> {/* EMAIL */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="E-Mail"
                        value={values.email}
                        onChange={handleChange('email')}
                        defaultValue={props.data.email}
                        error={emailError}
                        helperText={emailErrorText}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={8} sm={6} md={2} xl={2}> {/* NOMBRE PADRE */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Nombre padre"
                        id="nombrePadre"
                        value={values.nombrePadre}
                        onChange={handleChange('nombrePadre')}
                        defaultValue={props.data.nombrePadre}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={8} sm={6} md={2} xl={2}> {/* NOMBRE MADRE */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Nombre madre"
                        id="nombreMadre"
                        value={values.nombreMadre}
                        onChange={handleChange('nombreMadre')}
                        defaultValue={props.data.nombreMadre}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* IBAN */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="IBAN"
                        value={IBAN}
                        onChange={(e) => { const res = e.target.value.replace(/ /g, "").replace(/_/g, ""); setIBAN(res) }}
                        onBlur={(e) => { handleChangeIBAN(e) }}
                        error={ibanError}
                        helperText={ibanErrorText}
                        fullWidth
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatIBAN
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={8} sm={5} md={2} xl={2}> {/* NÚMERO SS */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="NºSS"
                        value={values.numss}
                        onChange={handleChange('numss')}
                        defaultValue={props.data.numss}
                        fullWidth
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatSS
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"

                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={5} sm={3} md={1} xl={1}> {/* AGRÍCOLA */}
                    <Paper className={classes.paper}>
                      <TextField
                        select
                        InputProps={{
                          className: classes.inputSelect
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        label="Agrícola"
                        value={values.agricola}
                        defaultValue={props.data.agricola}
                        onChange={handleChange('agricola')}
                        fullWidth
                        autoComplete="something-new"
                      >
                        <MenuItem value={true}>Sí</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </TextField>
                    </Paper>
                  </Grid>
                  <Grid item xs={8} sm={6} md={2} xl={2}> {/* PROFESIÓN */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Profesión"
                        id="profes"
                        value={values.profes}
                        onChange={handleChange('profes')}
                        defaultValue={props.data.profes}
                        fullWidth
                        InputProps={{
                          className: classes.input
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={5} sm={3} md={1} xl={1}> {/* DISCAPACIDAD */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Discapacidad"
                        id="discapacidad"
                        value={values.discapacidad}
                        defaultValue={props.data.discapacidad}
                        fullWidth
                        onChange={handleChange('discapacidad')}
                        InputProps={{
                          className: classes.input,
                          inputComponent: Global.formatPor
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={5} sm={3} md={2} xl={1}> {/* MOVILIDAD REDUCIDA */}
                    <Paper className={classes.paper}>
                      <TextField
                        select
                        InputProps={{
                          className: classes.inputSelect
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        label="Mov. Red."
                        value={values.movilidadReducida}
                        defaultValue={props.data.movilidadReducida}
                        onChange={handleChange('movilidadReducida')}
                        fullWidth
                        autoComplete="something-new"
                      >
                        <MenuItem value={true}>Sí</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </TextField>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} xl={2}> {/* NIVEL FORMACIÓN */}
                    <Paper className={classes.paper}>
                      <Autocomplete
                        options={JSON.parse(localStorage.getItem("ZTA_nivelFormacion"))}
                        autoHighlight
                        classes={{
                          option: classes.option,
                          input: classes.inputAutocomplete
                        }}
                        value={findNFO(values.nivelForm, JSON.parse(localStorage.getItem("ZTA_nivelFormacion")))}
                        onChange={(event, value) => {
                          if (value && props.user.rol >= 1) setValues({ ...values, ["claveIRPF"]: value.CODNFO })
                        }}
                        getOptionLabel={(option) => option.CODNFO + " - " + option.DESNFO}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{option.CODNFO}&nbsp;-&nbsp;</span>{option.DESNFO}
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Nv. Form."
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} sm={6}> {/* OBSERVACIONES */}
                    <Paper className={classes.paper}>
                      <TextField
                        label="Observaciones"
                        id="observaciones"
                        multiline
                        rows={3}
                        onChange={handleChange('observaciones')}
                        defaultValue={props.data.observaciones}
                        fullWidth
                        InputProps={{
                          className: classes.inputMultiline
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        autoComplete="something-new"
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={5} sm={3} md={1} xl={1}> {/* ACTIVE */}
                    <Paper className={classes.paper}>
                      <TextField
                        select
                        InputProps={{
                          className: classes.inputSelect
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        label="Activo"
                        value={values.active}
                        defaultValue={props.data.active}
                        onChange={handleChange('active')}
                        fullWidth
                        autoComplete="something-new"
                      >
                        <MenuItem value={true}>Sí</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                      </TextField>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} component={'span'}>
              <Grid container spacing={1}>
                <Grid item xs={5} sm={3} md={2} xl={1}> {/* RETENCION IRPF */}
                  <Paper className={classes.paper}>
                    <TextField
                      label="Ret. IRPF"
                      id="rirpf"
                      value={values.rirpf}
                      defaultValue={props.data.rirpf}
                      fullWidth
                      onChange={handleChange('rirpf')}
                      onBlur={handleBlur}
                      InputProps={{
                        className: classes.input,
                        inputComponent: Global.formatPor
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      autoComplete="something-new"
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={2}> {/* CLAVE IRPF */}
                  <Paper className={classes.paper}>
                    <Autocomplete
                      options={JSON.parse(localStorage.getItem("ZTA_ClaveIRPF"))}
                      autoHighlight
                      classes={{
                        option: classes.option,
                        input: classes.inputAutocomplete
                      }}
                      value={findclaveIRPF(values.claveIRPF, JSON.parse(localStorage.getItem("ZTA_ClaveIRPF")))}
                      onChange={(event, value) => {
                        if (value && props.user.rol >= 1) setValues({ ...values, ["claveIRPF"]: value.CODCPE, ["subclirpf"]: "" })
                      }}
                      getOptionLabel={(option) => option.CODCPE + " - " + option.DESCPE}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span>{option.CODCPE}&nbsp;-&nbsp;</span>{option.DESCPE}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Clave IRPF"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                      PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={2}> {/* SUBCLAVE IRPF */}
                  <Paper className={classes.paper}>
                    <Autocomplete
                      options={findsubclaveIRPFOptions(JSON.parse(localStorage.getItem("ZTA_subclaveIRPF")), values.claveIRPF)}
                      autoHighlight
                      classes={{
                        option: classes.option,
                        input: classes.inputAutocomplete
                      }}
                      value={findsubclaveIRPF(values.subclirpf, JSON.parse(localStorage.getItem("ZTA_subclaveIRPF")), values.claveIRPF)}
                      onChange={(event, value) => {
                        if (value && props.user.rol >= 1) setValues({ ...values, ["subclirpf"]: value.CODSIRPF })
                      }}
                      getOptionLabel={(option) => option.CODSIRPF + " - " + option.DESSIRPF}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span>{option.CODSIRPF}&nbsp;-&nbsp;</span>{option.DESSIRPF}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Subclave IRPF"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                      PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={2}> {/* TIPO RELACIÓN */}
                  <Paper className={classes.paper}>
                    <Autocomplete
                      options={JSON.parse(localStorage.getItem("ZTA_IRPF_tipoRelacion"))}
                      autoHighlight
                      classes={{
                        option: classes.option,
                        input: classes.inputAutocomplete
                      }}
                      value={findTipoRelacion(values.tipoRelacion, JSON.parse(localStorage.getItem("ZTA_IRPF_tipoRelacion")))}
                      onChange={(event, value) => {
                        if (value && props.user.rol >= 1) setValues({ ...values, ["tipoRelacion"]: value.CODTR })
                      }}
                      getOptionLabel={(option) => option.CODTR + " - " + option.DESTR}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span>{option.CODTR}&nbsp;-&nbsp;</span>{option.DESTR}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo relación"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                      PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3} xl={2}> {/* SITUACIÓN FAMILIAR */}
                  <Paper className={classes.paper}>
                    <Autocomplete
                      options={JSON.parse(localStorage.getItem("ZTA_IRPF_situacionFamiliar"))}
                      autoHighlight
                      classes={{
                        option: classes.option,
                        input: classes.inputAutocomplete
                      }}
                      value={findsitFamilia(values.sitFamilia, JSON.parse(localStorage.getItem("ZTA_IRPF_situacionFamiliar")))}
                      onChange={(event, value) => {
                        if (value && props.user.rol >= 1) setValues({ ...values, ["sitFamilia"]: value.CODSF })
                      }}
                      getOptionLabel={(option) => option.CODSF + " - " + option.DESSF}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span>{option.CODSF}&nbsp;-&nbsp;</span>{option.DESSF}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Situación familiar"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                      PopperComponent={(e) => { return (<Popper {...e} style={{ maxWidth: "400px", width: "100%" }} placement='bottom-start'></Popper>) }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={8} sm={6} md={2} xl={2}> {/* NIF CONYUGE */}
                  <Paper className={classes.paper}>
                    <TextField
                      label="NIF Conyuge"
                      id="nifConyuge"
                      value={values.nifConyuge}
                      onChange={handleChange('nifConyuge')}
                      defaultValue={props.data.nifConyuge}
                      fullWidth
                      InputProps={{
                        className: classes.input
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      autoComplete="something-new"
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} lg={6}> {/* DESCENDIENTES */}
                  <Paper className={classes.paper}>
                    <MaterialTable
                      title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Descendientes</Typography>}
                      icons={Icons}
                      columns={[
                        {
                          title: 'Fecha nacimiento',
                          field: 'fechaNacimiento',
                          type: "date",
                          align: "center",
                          render: rowData => <span >{moment(rowData.fechaNacimiento).format("DD/MM/YYYY") + " (" + (Math.trunc(moment().diff(moment(rowData.fechaNacimiento), 'years', true))) + " años)"}</span>,
                          editComponent: props => (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableFuture={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                label="Fec. Nac."
                                value={props.value}
                                onChange={(date) => props.onChange(date)}
                                InputAdornmentProps={{
                                  className: classes.disableButton
                                }}
                                InputProps={{
                                  className: classes.input
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                fullWidth
                                disableFuture={{ style: { display: "none" } }}
                              />
                            </MuiPickersUtilsProvider>
                          )
                        },
                        { title: 'Discapacidad', field: 'discapacidad', type: "numeric", align: "center", render: rowData => <span>{rowData.discapacidad} %</span> },
                        { title: 'Movilidad reducida', field: 'movilidadReducida', type: "boolean", align: "center" },
                        { title: 'Computa por entero', field: 'compPorEntero', type: "boolean", align: "center" },
                      ]}
                      data={values.descendientes}
                      editable={{
                        onRowAdd: (newData) =>
                          new Promise((resolve) => {
                            setTimeout(() => {
                              resolve();
                              if (value && props.user.rol >= 1) setValues(prevState => {
                                const data = [...prevState.descendientes];
                                data.push(newData);
                                return { ...prevState, ["descendientes"]: data };
                              })
                            }, 600);
                          }),
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve) => {
                            setTimeout(() => {
                              resolve();
                              if (oldData) {
                                if (value && props.user.rol >= 1) setValues(prevState => {
                                  const data = [...prevState.descendientes];
                                  data[data.indexOf(oldData)] = newData;
                                  return { ...prevState, ["descendientes"]: data };
                                });
                              }
                            }, 600);
                          }),
                        onRowDelete: (oldData) =>
                          new Promise((resolve) => {
                            setTimeout(() => {
                              resolve();
                              if (value && props.user.rol >= 1) setValues(prevState => {
                                const data = [...prevState.descendientes];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, ["descendientes"]: data };
                              });
                            }, 600);
                          }),
                      }}
                      options={{
                        pageSize: 5,
                        search: false,
                        pageSizeOptions: [5, 10, 15, 20],
                        headerStyle: {
                          color: 'rgba(0, 0, 0, 0.54)'
                        },
                        rowStyle: {
                          color: 'rgba(0, 0, 0, 0.54)'
                        },
                      }}
                      localization={{
                        pagination: {
                          labelDisplayedRows: '{from}-{to} de {count}',
                          labelRowsSelect: 'filas'
                        },
                        toolbar: {
                          nRowsSelected: '{0} row(s) seleccionada',
                          searchPlaceholder: 'Buscar',
                          searchTooltip: 'Buscar',
                        },
                        header: {
                          actions: 'Acciones'
                        },
                        body: {
                          emptyDataSourceMessage: 'Sin datos',
                          filterRow: {
                            filterTooltip: 'Filtrar'
                          },
                          addTooltip: "Nuevo descendiente",
                          deleteTooltip: "Eliminar descendiente",
                          editTooltip: "Editar descendiente",
                          editRow: {
                            deleteText: "¿Estás seguro de eliminar la fila?",
                            cancelTooltip: "Cancelar",
                            saveTooltip: "Guardar"
                          },
                        },

                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} lg={6}> {/* ASCENDIENTES */}
                  <Paper className={classes.paper}>
                    <MaterialTable
                      title={<Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Ascendientes</Typography>}
                      icons={Icons}
                      columns={[
                        {
                          title: 'Fecha nacimiento',
                          field: 'fechaNacimiento',
                          type: "date",
                          align: "center",
                          render: rowData => <span >{moment(rowData.fechaNacimiento).format("DD/MM/YYYY") + " (" + (Math.trunc(moment().diff(moment(rowData.fechaNacimiento), 'years', true))) + " años)"}</span>,
                          editComponent: props => (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableFuture={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                label="Fec. Nac."
                                value={props.value}
                                onChange={(date) => props.onChange(date)}
                                InputAdornmentProps={{
                                  className: classes.disableButton
                                }}
                                InputProps={{
                                  className: classes.input
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                fullWidth
                                disableFuture={{ style: { display: "none" } }}
                              />
                            </MuiPickersUtilsProvider>
                          )
                        },
                        {
                          title: 'Discapacidad',
                          field: 'discapacidad',
                          type: "numeric",
                          align: "center",
                          render: rowData => <span>{rowData.discapacidad} %</span>
                        },
                        { title: 'Movilidad reducida', field: 'movilidadReducida', type: "boolean", align: "center" },
                        { title: 'Computa por entero', field: 'compPorEntero', type: "boolean", align: "center" },
                      ]}
                      data={values.ascendientes}
                      editable={{
                        onRowAdd: (newData) =>
                          new Promise((resolve) => {
                            setTimeout(() => {
                              resolve();
                              if (value && props.user.rol >= 1) setValues(prevState => {
                                const data = [...prevState.ascendientes];
                                data.push(newData);
                                return { ...prevState, ["ascendientes"]: data };
                              })
                            }, 600);
                          }),
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve) => {
                            setTimeout(() => {
                              resolve();
                              if (oldData) {
                                if (value && props.user.rol >= 1) setValues(prevState => {
                                  const data = [...prevState.ascendientes];
                                  data[data.indexOf(oldData)] = newData;
                                  return { ...prevState, ["ascendientes"]: data };
                                });
                              }
                            }, 600);
                          }),
                        onRowDelete: (oldData) =>
                          new Promise((resolve) => {
                            setTimeout(() => {
                              resolve();
                              if (value && props.user.rol >= 1) setValues(prevState => {
                                const data = [...prevState.ascendientes];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, ["ascendientes"]: data };
                              });
                            }, 600);
                          }),
                      }}
                      options={{
                        pageSize: 5,
                        search: false,
                        pageSizeOptions: [5, 10, 15, 20],
                      }}
                      localization={{
                        pagination: {
                          labelDisplayedRows: '{from}-{to} de {count}',
                          labelRowsSelect: 'filas'
                        },
                        toolbar: {
                          nRowsSelected: '{0} row(s) seleccionada',
                          searchPlaceholder: 'Buscar',
                          searchTooltip: 'Buscar',
                        },
                        header: {
                          actions: 'Acciones'
                        },
                        body: {
                          emptyDataSourceMessage: 'Sin datos',
                          filterRow: {
                            filterTooltip: 'Filtrar'
                          },
                          addTooltip: "Nuevo ascendiente",
                          deleteTooltip: "Eliminar ascendiente",
                          editTooltip: "Editar ascendiente",
                          editRow: {
                            deleteText: "¿Estás seguro de eliminar la fila?",
                            cancelTooltip: "Cancelar",
                            saveTooltip: "Guardar"
                          },
                        }
                      }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2} component={'span'}>
              <div className={classes.rootTwo}>
                <EpiSalud
                  parad={values}
                  token={props.token}
                  user={props.user}
                  ayun={props.ayun}
                />
              </div>
            </TabPanel>
            <TabPanel value={value} index={3} component={'span'}>
              <div className={classes.rootTwo}>
                <Contratos
                  localization={"P"}
                  contratos={props.contratos.filter(element => element.idParad === values._id)}
                  parad={values}
                  setState={props.setState}
                />
              </div>
            </TabPanel>
          </div>
          {(props.user.rol >= 1 && value !== 2 && value !== 3) &&
            <IconButton style={{ display: "flex", flexDirection: "column", padding: "0" }} className={classes.buttonSave} color="primary" onClick={save}>
              <SaveIcon color="primary" />
              <div style={{ fontSize: "1rem" }}>
                Guardar
              </div>
            </IconButton>
          }
        </div>
      </div>
    </div>
  );
}